import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
  Divider,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import '../../../../styles/Settings/Section/PlatformAccessControls/PlatformAccessControls.css';
import { updateOrganizationAccessControls } from '../../../../api/beaverApi';
import { useDeviceFilter } from '../../../../contexts/DeviceFilterContext';

const PlatformAccessControls = ({
  userId,
  isGuard,
  isOverwatch,
  userRole,
  parentOrgUserId,
  organizationAccessControls,
}) => {
  const { selectedOrg } = useDeviceFilter();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [hasChanges, setHasChanges] = useState(false);

  // Access control settings
  const [accessControls, setAccessControls] = useState(
    organizationAccessControls?.accessControls || {
      limitedAccess: {
        liveView: true,
        dashboard: true,
        reports: true,
        assetManagement: true,
      },
      fullAccess: {
        liveView: true,
        dashboard: true,
        reports: true,
        assetManagement: true,
      },
    }
  );

  // Check for changes when accessControls are updated
  useEffect(() => {
    const originalControls = organizationAccessControls?.accessControls;
    if (!originalControls) return;

    const hasChanges = JSON.stringify(originalControls) !== JSON.stringify(accessControls);
    setHasChanges(hasChanges);
  }, [accessControls, organizationAccessControls]);

  // Toggle a specific access control
  const handleToggleAccess = (accessType, feature) => {
    setAccessControls((prevControls) => ({
      ...prevControls,
      [accessType]: {
        ...prevControls[accessType],
        [feature]: !prevControls[accessType][feature],
      },
    }));
  };

  // Save access control settings
  const handleSaveSettings = async () => {
    try {
      setSaving(true);
      await updateOrganizationAccessControls(accessControls);
      showMessage('Access control settings saved successfully');
    } catch (error) {
      console.error('Error saving access control settings:', error);
      showMessage('Failed to save access control settings', 'error');
    } finally {
      setSaving(false);
    }
  };

  // Show snackbar message
  const showMessage = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Handle snackbar close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="platform-access-container">
      <div className="platform-access-header">
        <h2>Platform Access Controls</h2>
      </div>

      <div className="platform-access-personnel-info">
        <FontAwesomeIcon icon={faLock} className="platform-access-personnel-icon" />
        <span>Configure which platform features are available for limited access users.</span>
      </div>

      <div className="platform-access-content">
        {/* Limited Access Card */}
        <Paper elevation={1} className="platform-access-card platform-access-limited">
          <div className="platform-access-card-header">
            <Typography variant="h6">Limited Access</Typography>
            <Typography variant="body2">Configure permissions for limited access users</Typography>
          </div>
          <div className="platform-access-card-content">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={accessControls.limitedAccess.liveView}
                    onChange={() => handleToggleAccess('limitedAccess', 'liveView')}
                    color="primary"
                    className="platform-access-switch"
                  />
                }
                label="Live View"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={accessControls.limitedAccess.dashboard}
                    onChange={() => handleToggleAccess('limitedAccess', 'dashboard')}
                    color="primary"
                    className="platform-access-switch"
                  />
                }
                label="Dashboard"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={accessControls.limitedAccess.reports}
                    onChange={() => handleToggleAccess('limitedAccess', 'reports')}
                    color="primary"
                    className="platform-access-switch"
                  />
                }
                label="Reports"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={accessControls.limitedAccess.assetManagement}
                    onChange={() => handleToggleAccess('limitedAccess', 'assetManagement')}
                    color="primary"
                    className="platform-access-switch"
                  />
                }
                label="Asset Management"
              />
            </FormGroup>
          </div>
        </Paper>

        <div className="platform-access-save-container">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveSettings}
            disabled={saving || !hasChanges}
            className="platform-access-save-button"
          >
            {saving ? <CircularProgress size={24} /> : 'Save Settings'}
          </Button>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PlatformAccessControls;
