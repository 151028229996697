import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Paper } from '@mui/material';
import '../../../../styles/Settings/Section/AdvancedSettings/AdvancedSettings.css';

const AdvancedSettings = ({ featureMode }) => {
  if (featureMode !== 'dev') {
    return null;
  }

  return (
    <div className="advanced-settings-container">
      <h2>Advanced Settings</h2>
      <Paper elevation={1} className="advanced-settings-card">
        <Typography variant="h6" gutterBottom>
          Device Management
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          Access detailed device configuration and management options.
        </Typography>
        <Link to="/device-management" className="device-management-link">
          Manage Devices
        </Link>
      </Paper>

      {/* Additional advanced settings can be added here */}
    </div>
  );
};

export default AdvancedSettings;
