import React, { useState, useMemo, useEffect } from 'react';
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventInfo/LocationInfo.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faUser,
  faMapMarkerAlt,
  faClock,
  faCalendar,
  faStopwatch,
  faFileLines,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import { getCategories, getCategoryDisplay, shouldDisplayCategory } from '../../../../../../../utils/categoryUtils';

// Helper function to get the geofence name
const getGeofenceName = (geofenceId, geofences) => {
  if (geofences && Array.isArray(geofences)) {
    const geofence = geofences.find((g) => g.id === geofenceId);
    if (geofence) {
      // Return nickname for polygon geofences or any geofence with a nickname
      if (geofence.nickname) {
        return geofence.nickname;
      }
    }
  }

  // Fallback to truncating the geofence ID if no nickname is found
  if (geofenceId && geofenceId.length > 30) {
    const parts = geofenceId.split(',');
    if (parts[0].match(/\d/)) {
      return `${parts[0]}`.trim();
    }
    return parts[0].trim();
  }

  return geofenceId || 'Unknown Site';
};

const LocationInfo = ({
  event,
  assignedTo,
  alertData,
  allEvents,
  showDetails,
  eventTypes,
  shiftEndTime,
  isShiftInProgress,
  locations,
  geofences = [], // Add geofences prop with default empty array
}) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const fetchedCategories = await getCategories();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setCategories([]);
      }
    };

    fetchCategories();
  }, []);

  const getEscalationCategory = (alertData) => {
    if (!alertData) return null;

    let categoryLabel = alertData.Label;
    if (alertData.Label === 'Escalation') {
      categoryLabel = alertData.CategorizationResult[alertData.DeployedModelUsed];
    }
    return categoryLabel;
  };

  const getEventTitle = (event, index, events) => {
    if (event.type === 'start') {
      return index === 0 ? 'Shift Started' : 'Activated';
    } else if (event.type === 'end') {
      return index === events.length - 1 ? 'Shift Ended' : 'Deactivated';
    } else if (event.type === 'locationEnter') {
      return 'Site';
    } else {
      return event.type.charAt(0).toUpperCase() + event.type.slice(1);
    }
  };

  const getTitle = () => {
    if (!event.event) {
      return 'Event Info';
    }
    // First check if it's a report
    if (alertData && alertData.IsReport) {
      return 'Report';
    }
    const category = getEscalationCategory(alertData);
    if (alertData && shouldDisplayCategory(category, categories)) {
      return getCategoryDisplay(category, categories);
    } else if (alertData && alertData.InteractionFlag === 'on') {
      return alertData.CategorizationResult['gpt4o-interaction-i'];
    }
    return getEventTitle(event.event, event.index, allEvents);
  };

  const initialTitle = useMemo(getTitle, []);
  const [infoTitle, setInfoTitle] = useState(initialTitle);
  const [hasEventInfo, setHasEventInfo] = useState(!!event.event);

  useEffect(() => {
    setHasEventInfo(!!event.event);
    setInfoTitle(getTitle());
  }, [event, alertData, allEvents]);

  const handleGoToRecording = () => {
    const timestamp = alertData.RelativeTimestamp;
    const encodedFilename = encodeURIComponent(alertData.VideoFileName);
    return `/videos?filename=${encodedFilename}&timestamp=${timestamp}`;
  };

  const formatTime = (event) => {
    const dateString = event.timestamp || event.start || event.timestampStart;
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? 'Invalid Date'
      : date.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' });
  };

  const formatDate = (event) => {
    const dateString = event.timestamp || event.start || event.timestampStart;
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? 'Invalid Date'
      : date.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  };

  // Update the address determination to use the getGeofenceName function for locationEnter events
  const address =
    locations.length === 0
      ? 'Address unavailable'
      : event.event.type === 'locationEnter'
        ? getGeofenceName(event.event.geofenceId, geofences)
        : event.event.location
          ? event.event.location.address
          : 'Address not available';

  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600000);
    const minutes = Math.floor((duration % 3600000) / 60000);
    return `${hours}h ${minutes}m`;
  };

  const calculateDuration = (startTime, endTime) => {
    const start = new Date(startTime);
    let end;

    if (endTime) {
      end = new Date(endTime);
    } else if (isShiftInProgress) {
      if (event.event.lastSeenTime) {
        end = new Date(event.event.lastSeenTime);
      } else {
        end = new Date();
      }
    } else {
      end = new Date(shiftEndTime);
    }

    return formatDuration(end - start);
  };

  const getLastSeen = (event) => {
    const lastSeenTime = event.exitTime || event.lastSeenTime || event.timestamp;
    const now = new Date();
    const timeDiff = now - new Date(lastSeenTime);
    const minutes = Math.floor(timeDiff / 60000);
    return minutes < 60 ? `${minutes}m ago` : `${Math.floor(minutes / 60)}h ago`;
  };

  return (
    <div className="location-info-container">
      <div className="location-info-header">
        <div className="title-container">
          <h3>{infoTitle}</h3>
        </div>
        {hasEventInfo ? (
          <>
            {alertData && (
              <>
                <Tooltip title="Go to recording">
                  <Link to={handleGoToRecording()} className="info-button recording-available" title="View Video">
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="info-icon" style={{ color: 'gray' }} />
                  </Link>
                </Tooltip>
                {alertData.IsReport && alertData.ReportId && (
                  <Tooltip title="Go to Report">
                    <Link
                      to={`/reports?reportId=${alertData.ReportId}`}
                      className="info-button report-available"
                      title="View Report"
                      style={{ marginLeft: '10px' }}
                    >
                      <FontAwesomeIcon icon={faFileLines} className="info-icon" style={{ color: 'gray' }} />
                    </Link>
                  </Tooltip>
                )}
              </>
            )}
          </>
        ) : (
          <p>No event information available</p>
        )}
      </div>
      {showDetails ? (
        <div className="location-details">
          <div className="location-detail-row">
            <div className="location-detail-icon">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div className="location-detail-value">{assignedTo || 'N/A'}</div>
          </div>
          {event.event.type === 'locationEnter' && (
            <div className="location-detail-row">
              <div className="location-detail-icon">
                <FontAwesomeIcon icon={faStopwatch} />
              </div>
              <div className="location-detail-value">
                {calculateDuration(event.event.timestamp, event.event.exitTime)}
              </div>
            </div>
          )}
          <div className="location-detail-row">
            <div className="location-detail-icon">
              <FontAwesomeIcon icon={faClock} />
            </div>
            <div className="location-detail-value">{formatTime(event.event)}</div>
          </div>
          <div className="location-detail-row">
            <div className="location-detail-icon">
              <FontAwesomeIcon icon={faCalendar} />
            </div>
            <div className="location-detail-value">{formatDate(event.event)}</div>
          </div>
          <div className="location-detail-row">
            <div className="location-detail-icon">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </div>
            <div className="location-detail-value">{address}</div>
          </div>
        </div>
      ) : (
        <div className="location-details">
          {event.event.type === 'locationEnter' && (
            <div className="location-detail-row">
              <div className="location-detail-icon">
                <FontAwesomeIcon icon={faStopwatch} />
              </div>
              <div className="location-detail-value">
                {calculateDuration(event.event.timestamp, event.event.exitTime)}
              </div>
            </div>
          )}
          <div className="location-detail-row">
            <div className="location-detail-icon">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </div>
            <div className="location-detail-value">{address}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationInfo;
