import { useState, useCallback, useEffect, useRef } from 'react';
import L from 'leaflet';
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventInfo/SingleMapView.css';
import 'leaflet/dist/leaflet.css';

// This component will update the map's center when the location changes
// const ChangeMapView = ({ center }) => {
//   const map = useMap();
//   useEffect(() => {
//     map.setView(center, 17);
//   }, [center, map]);
//   return null;
// };

const SingleMapView = ({ location, size = 'large' }) => {
  const [map, setMap] = useState(null);
  const markerRef = useRef(null);
  const containerRef = useRef(null);

  // Replace state with derived value
  // console.log('location from single map view', location);
  const isValidLocation = Boolean(
    location && typeof location.latitude === 'number' && typeof location.longitude === 'number'
  );

  const createCustomIcon = useCallback(() => {
    return L.divIcon({
      className: 'custom-icon',
      html: `<svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
               <circle cx="25" cy="25" r="20" fill="red" opacity="0.4"/>
             </svg>`,
      iconSize: [50, 50],
      iconAnchor: [25, 25],
      popupAnchor: [0, -25],
    });
  }, []);

  const center = isValidLocation ? [location.latitude, location.longitude] : [0, 0];
  // console.log('isValidLocation', isValidLocation, center);
  const displayMap = useCallback(
    (node) => {
      if (node !== null) {
        containerRef.current = node;

        // Wait for next tick to ensure container is mounted and sized
        setTimeout(() => {
          if (map === null && isValidLocation && containerRef.current) {
            try {
              const newMap = L.map(containerRef.current, {
                center,
                zoom: 17,
                zoomControl: true,
                dragging: true,
                scrollWheelZoom: true,
                doubleClickZoom: true,
                attributionControl: false,
              });

              L.tileLayer(
                `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN}`,
                {
                  attribution:
                    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                }
              ).addTo(newMap);

              markerRef.current = L.marker(center, { icon: createCustomIcon() }).addTo(newMap);

              setMap(newMap);
            } catch (error) {
              // Silently handle the error
              // Optionally log it for debugging
              console.debug('Map initialization error:', error);
            }
          }
        }, 0);
      }
    },
    [map, center, isValidLocation, createCustomIcon]
  );

  useEffect(() => {
    if (map && isValidLocation) {
      map.setView(center, 17);
      if (markerRef.current) {
        markerRef.current.setLatLng(center);
      }
    }
  }, [map, center, isValidLocation]);

  // Add cleanup effect
  useEffect(() => {
    return () => {
      if (map) {
        map.remove();
        setMap(null);
      }
    };
  }, [map]);

  if (!isValidLocation) {
    return <div className={`single-map-view-container ${size}`}>Location not available.</div>;
  }
  // console.log('isValidLocation2', isValidLocation, location);
  return (
    <div
      className={`single-map-view-container ${size}`}
      style={{ height: size === 'large' ? '600px' : '300px' }}
      ref={displayMap}
    />
  );
};

export default SingleMapView;
