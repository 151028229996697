import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import 'plyr-react/plyr.css';
import '../styles/VideoManagement.css';
import { cosmosClient } from '../utils/eventstorage';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton } from '@mui/x-data-grid';
import {
  Select,
  MenuItem,
  Box,
  FormControl,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { stringToColor } from '../utils/utilsUI';
import { useDeviceFilter } from '../contexts/DeviceFilterContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faCalendar,
  faTimes,
  faExclamationTriangle,
  faDownload,
  faPlay, // Add play icon
} from '@fortawesome/free-solid-svg-icons';
import DeblurIcon from '@mui/icons-material/Deblur';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import VideoPlayer from './VideoPlayer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Archive, Unarchive } from '@mui/icons-material';
import InboxIcon from '@mui/icons-material/Inbox';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import {
  fetchVideosForDevices,
  createSharedVideo,
  createSharedImage, // Add this import
  fetchImagesForDevices,
  archiveContent,
  unarchiveContent,
} from '../api/beaverApi';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';
// import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { logUserInteraction, redactVideo } from '../api/beaverApi';
import {
  trackVideoManagementView,
  trackVideoSelected,
  trackVideoSearch,
  trackVideoShared,
  trackVideoArchived,
  trackDownloadArchivedVideo,
  trackVideoUnarchived,
} from '../utils/analytics';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FaceRetouchingOffIcon from '@mui/icons-material/FaceRetouchingOff';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import RefreshIcon from '@mui/icons-material/Refresh';

const calculateDaysRemaining = (uploadDate, retentionPeriodDays) => {
  const currentDate = new Date();
  const uploadDateTime = new Date(uploadDate);
  const timeDiff = uploadDateTime.getTime() - currentDate.getTime() + retentionPeriodDays * 24 * 60 * 60 * 1000;
  const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return daysRemaining > 0 ? daysRemaining : 0;
};

const formatDuration = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const hours = Math.floor(mins / 60);
  const remainingMins = mins % 60;
  const remainingSecs = seconds % 60;
  return `${hours > 0 ? `${hours}h ` : ''}${remainingMins}m ${remainingSecs}s`;
};

const areEqual = (prevProps, nextProps) => {
  if (!prevProps.selectedVideo || !nextProps.selectedVideo) {
    return prevProps.selectedVideo === nextProps.selectedVideo;
  }

  return (
    prevProps.selectedVideo.BlobSAS === nextProps.selectedVideo.BlobSAS &&
    prevProps.selectedVideo.BlobPath === nextProps.selectedVideo.BlobPath &&
    JSON.stringify(prevProps.selectedVideo.Tags) === JSON.stringify(nextProps.selectedVideo.Tags) &&
    prevProps.onVideoArchived === nextProps.onVideoArchived &&
    prevProps.isArchived === nextProps.isArchived &&
    JSON.stringify(prevProps.videoEvents) === JSON.stringify(nextProps.videoEvents) // Add this line
  );
};

export const addTagStyles = (tagText) => {
  const className = `tag-style-${tagText.replace(/\s+/g, '-')}`;
  const color = stringToColor(tagText);

  if (!document.head.querySelector(`.${className}`)) {
    const style = document.createElement('style');
    style.innerHTML = `
      .${className} {
        background-color: ${color} !important;
        color: #000 !important;
      }
    `;
    document.head.appendChild(style);
  }

  return className;
};

const getTagProps = (tag, index) => ({
  key: index,
  className: addTagStyles(tag.text),
});

const MarkerControlPanel = ({ markers, currentTime, onJumpToMarker, preStart = 10 }) => {
  const [currentMarkerIndex, setCurrentMarkerIndex] = useState(0);

  useEffect(() => {
    const newIndex = markers.findIndex((marker) => marker.time > currentTime);
    setCurrentMarkerIndex(newIndex === -1 ? markers.length - 1 : Math.max(0, newIndex - 1));
  }, [currentTime, markers]);

  const handlePrevious = () => {
    if (currentMarkerIndex > 0) {
      const newIndex = currentMarkerIndex - 1;
      onJumpToMarker(Math.max(0, markers[newIndex].time - preStart));
    }
  };

  const handleNext = () => {
    if (currentMarkerIndex < markers.length - 1) {
      const newIndex = currentMarkerIndex + 1;
      onJumpToMarker(Math.max(0, markers[newIndex].time - preStart));
    }
  };

  return (
    <div className="marker-control-panel">
      <button onClick={handlePrevious} disabled={currentMarkerIndex <= 0 || markers.length <= 1}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      <button onClick={handleNext} disabled={currentMarkerIndex >= markers.length - 1 || markers.length <= 1}>
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </div>
  );
};

// Update the isImage function to safely handle undefined/null values
const isImage = (item) => {
  if (!item) return false;

  return item.Category === 'BWC photo' || (item.FileName && /\.(jpg|jpeg|png|gif|bmp)$/i.test(item.FileName));
};

// Modify the MemoizedVideoPlayer component to handle images
const MemoizedVideoPlayer = React.memo(
  ({
    videoFileName,
    markers,
    token,
    onTimeUpdate,
    transcriptUrl,
    initialTime,
    playerRef,
    isIngesting,
    deviceVersion,
    assignedTo,
    redactionStatus,
    isImage,
    imagePath,
  }) => {
    if (isIngesting) {
      return (
        <div className="video-placeholder">
          <AccessTimeIcon className="placeholder-icon" />
          <p>Video currently processing</p>
        </div>
      );
    }

    // Only show redaction placeholder when status is in progress
    if (redactionStatus && redactionStatus !== 'done') {
      return (
        <div className="video-placeholder">
          <DeblurIcon className="placeholder-icon" />
          <p>Video currently being redacted</p>
        </div>
      );
    }

    if (!videoFileName || !token) {
      return (
        <div className="video-placeholder">
          <AccessTimeIcon className="placeholder-icon" />
          <p>Content currently unavailable</p>
        </div>
      );
    }

    // Render image if isImage is true
    if (isImage) {
      return (
        <div className={`video-player-wrapper`}>
          <div className={`video-player-container ${deviceVersion !== 'Mocha' ? 'square-video' : ''}`}>
            <div className="image-display">
              <img src={`${imagePath}?${token}`} alt={videoFileName} className="displayed-image" />
            </div>
          </div>
        </div>
      );
    }

    // Otherwise render video player as before
    return (
      <div className={`video-player-wrapper`}>
        <div className={`video-player-container ${deviceVersion !== 'Mocha' ? 'square-video' : ''}`}>
          <VideoPlayer
            ref={playerRef}
            videoFileName={videoFileName}
            markers={markers}
            token={token}
            preStart={2}
            onTimeUpdate={onTimeUpdate}
            transcriptUrl={transcriptUrl}
            initialTime={initialTime}
            deviceVersion={deviceVersion}
            assignedTo={assignedTo}
          />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.videoFileName === nextProps.videoFileName &&
      prevProps.token === nextProps.token &&
      prevProps.initialTime === nextProps.initialTime &&
      prevProps.isIngesting === nextProps.isIngesting &&
      JSON.stringify(prevProps.markers) === JSON.stringify(nextProps.markers) &&
      prevProps.deviceVersion === nextProps.deviceVersion &&
      prevProps.assignedTo === nextProps.assignedTo &&
      prevProps.redactionStatus === nextProps.redactionStatus &&
      prevProps.isImage === nextProps.isImage
    );
  }
);

const VideoDisplay = React.memo(
  ({
    selectedVideo,
    onVideoArchived,
    onVideoUnarchived,
    searchResults,
    initialTimestamp,
    urlFileName,
    videos,
    isArchived,
    retentionPeriodDays,
    videoEvents,
    userId,
    userName,
    userRole,
  }) => {
    const [shareError, setShareError] = useState(null);
    const generateDefaultPassword = () => {
      // Generate a password with 2 uppercase letters, 2 numbers, and 2 lowercase letters
      const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      const lowerChars = 'abcdefghijklmnopqrstuvwxyz';

      const getRandomChars = (charSet, count) => {
        return Array(count)
          .fill()
          .map(() => charSet[Math.floor(Math.random() * charSet.length)])
          .join('');
      };

      const pass = [getRandomChars(upperChars, 2), getRandomChars(numbers, 2), getRandomChars(lowerChars, 2)].join('');

      // Shuffle the password
      return pass
        .split('')
        .sort(() => Math.random() - 0.5)
        .join('');
    };

    // Add redaction state variables
    const [redactionSettings, setRedactionSettings] = useState({
      faces: true,
      // licensePlates: true,
      audio: false,
    });
    const [redactionError, setRedactionError] = useState(null);
    const [redactionInProgress, setRedactionInProgress] = useState(false);
    const [redactionSuccess, setRedactionSuccess] = useState(false);
    const [showRedactConfirmation, setShowRedactConfirmation] = useState(false);

    // Existing state variables
    const [showVideo, setShowVideo] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const playerRef = useRef(null);
    const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
    const [showUnarchiveConfirmation, setShowUnarchiveConfirmation] = useState(false);
    const [showUnarchiveSuccess, setShowUnarchiveSuccess] = useState(false);
    const [showShareDialog, setShowShareDialog] = useState(false);
    const [showCopySuccess, setShowCopySuccess] = useState(false);
    const [sharedVideoId, setSharedVideoId] = useState(null);

    const [shareSettings, setShareSettings] = useState({
      downloadable: false,
      expirationDays: 7,
      password: generateDefaultPassword(),
      useStartTime: true,
      startTime: currentTime,
    });
    const [showPassword, setShowPassword] = useState(true);
    const formatTimeDisplay = (totalSeconds) => {
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };
    useEffect(() => {
      if (selectedVideo) {
        const timer = setTimeout(() => setShowVideo(true), 0);
        return () => clearTimeout(timer);
      } else {
        setShowVideo(false);
      }
    }, [selectedVideo]);

    console.log('selectedVideo: ', selectedVideo);

    const videoToDisplay = selectedVideo || (urlFileName ? videos.find((v) => v.FileName === urlFileName) : null);
    console.log('videoToDisplay: ', videoToDisplay);

    const renderVideoContent = () => {
      if (!selectedVideo) {
        return (
          <div className="video-placeholder">
            <ErrorOutlineIcon className="placeholder-icon" />
            <p>No content selected</p>
          </div>
        );
      }

      const isImageContent = isImage(selectedVideo);

      return (
        <MemoizedVideoPlayer
          videoFileName={selectedVideo.FileName}
          markers={isImageContent ? [] : markers}
          token={selectedVideo.BlobSAS}
          onTimeUpdate={isImageContent ? null : handleTimeUpdate}
          transcriptUrl={selectedVideo.transcriptUrl}
          initialTime={isImageContent ? 0 : initialTimestamp}
          playerRef={playerRef}
          isIngesting={
            selectedVideo && selectedVideo.generatedEmbeddings && !selectedVideo.TranscriptVTTPath && !isImageContent
          }
          deviceVersion={selectedVideo.deviceVersion}
          assignedTo={selectedVideo.AssignedTo}
          redactionStatus={selectedVideo.redactionStatus}
          isImage={isImageContent}
          imagePath={selectedVideo.BlobPath}
        />
      );
    };

    if (!selectedVideo) {
      return (
        <div className="default-message">
          <i className="fas fa-hand-pointer fa-5x"></i>
          <p>Select a video on the left to view details</p>
        </div>
      );
    }

    const markers = [
      ...(videoToDisplay.FileName === urlFileName && initialTimestamp
        ? [
            {
              time: initialTimestamp,
              label: 'Alert',
            },
          ]
        : []),
      ...searchResults
        .filter((result) => result.fileName === videoToDisplay.FileName)
        .flatMap((result) => {
          const timestamps = Array.isArray(result.timestamp) ? result.timestamp : [result.timestamp];
          return timestamps.map((time) => {
            const truncatedQuery = result.query.length > 10 ? `${result.query.slice(0, 10)}...` : result.query;
            return {
              time,
              label: `"${truncatedQuery}"`,
            };
          });
        }),
    ];

    const handleRedactConfirmation = () => {
      setShowRedactConfirmation(true);
    };

    const handleRedactCancel = () => {
      setShowRedactConfirmation(false);
      // Reset all redaction-related state variables
      setRedactionSettings({
        faces: true,
        audio: false,
      });
      setRedactionError(null);
      setRedactionInProgress(false);
      setRedactionSuccess(false);
    };

    const handleRedactConfirm = async () => {
      if (
        !redactionSettings.faces &&
        // !redactionSettings.licensePlates &&
        !redactionSettings.audio &&
        !(redactionSettings.text && redactionSettings.customText.trim())
      ) {
        setRedactionError('Please select at least one item to redact');
        return;
      }

      setRedactionError(null);
      setRedactionInProgress(true);

      try {
        // Call the redactVideo API function
        await redactVideo(selectedVideo.id, redactionSettings, userName);

        setRedactionSuccess(true);
        setRedactionInProgress(false);
      } catch (error) {
        console.error('Error redacting video:', error);

        // Check if this is a 409 error (conflict - redacted video already exists)
        if (error.response && error.response.status === 409 && error.response.data.existingVideoFileName) {
          const existingVideoFileName = error.response.data.existingVideoFileName;
          setRedactionError(
            <span>
              A redacted version of this video with these settings already exists.{' '}
              <a href={`/videos?filename=${existingVideoFileName}&timestamp=0`}>View existing redacted video</a>
            </span>
          );
        } else {
          setRedactionError('Failed to redact video. Please try again later.');
        }

        setRedactionInProgress(false);
      }
    };

    const handleRedactionSettingChange = (setting, value) => {
      setRedactionSettings((prev) => ({
        ...prev,
        [setting]: value,
      }));
    };

    const handleCreateSharedVideo = async () => {
      if (!isArchived && calculateDaysRemaining(selectedVideo.TimeUploaded, retentionPeriodDays) <= 0) {
        return;
      }

      const maxExpirationDays = isArchived
        ? 30
        : Math.min(30, calculateDaysRemaining(selectedVideo.TimeUploaded, retentionPeriodDays));

      // Check if it's an image or video and set appropriate initial settings
      if (isImage(selectedVideo)) {
        setShareSettings({
          downloadable: false,
          expirationDays: maxExpirationDays,
          password: generateDefaultPassword(),
          useStartTime: false, // Not needed for images
          startTime: 0, // Not needed for images
        });
      } else {
        // For videos, keep the existing logic
        const currentTimeInSeconds = Math.floor((playerRef.current?.plyr?.currentTime * 1000) / 1000) || 0;
        setShareSettings({
          downloadable: false,
          expirationDays: maxExpirationDays,
          password: generateDefaultPassword(),
          useStartTime: true,
          startTime: currentTimeInSeconds,
        });
      }

      setSharedVideoId(null);
      setShowShareDialog(true);
    };

    const handleShareSubmit = async () => {
      if (!shareSettings.password?.trim()) {
        return;
      }

      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + shareSettings.expirationDays);

      try {
        setShareError(null); // Reset error state before attempting
        let response;

        if (isImage(selectedVideo)) {
          // Use createSharedImage for images
          response = await createSharedImage(
            videoToDisplay.FileName,
            shareSettings.downloadable,
            expirationDate.toISOString(),
            shareSettings.password,
            userId
          );
        } else {
          // Use createSharedVideo for videos
          response = await createSharedVideo(
            videoToDisplay.FileName,
            shareSettings.downloadable,
            expirationDate.toISOString(),
            shareSettings.password,
            shareSettings.useStartTime ? shareSettings.startTime : 0,
            userId
          );
        }

        setSharedVideoId(response.id);
        await logUserInteraction(
          'create share link',
          {
            videoId: selectedVideo.id,
            sharedVideoId: response.id,
            deviceId: selectedVideo.deviceId,
            fileName: selectedVideo.FileName,
          },
          userId,
          userName
        );
        trackVideoShared(selectedVideo.id);
      } catch (error) {
        console.error('Error creating shareable link:', error);
        setShareError('Failed to create shareable link. Please try again later.');
        setSharedVideoId(null);
      }
    };
    const handleSettingChange = (newSettings) => {
      setShareSettings(newSettings);
      setSharedVideoId(null); // Clear existing share link when settings change
    };

    const handleTimeUpdate = (event) => {
      setCurrentTime(event.target.currentTime);
      console.log('currentTime: ', currentTime);
    };

    const handleArchiveConfirmation = () => {
      setShowArchiveConfirmation(true);
    };

    const handleUnarchiveConfirmation = () => {
      setShowUnarchiveConfirmation(true);
    };

    const handleUnarchiveCancel = () => {
      setShowUnarchiveConfirmation(false);
    };

    const handleUnarchiveConfirm = async () => {
      setShowUnarchiveConfirmation(false);
      try {
        await unarchiveVideo(selectedVideo);
        onVideoUnarchived(selectedVideo.id);
      } catch (error) {
        console.error('Error unarchiving video:', error);
      }
    };

    const unarchiveVideo = async (content) => {
      console.log('Unarchiving content:', content);
      try {
        await unarchiveContent(content, userId, userName);
      } catch (error) {
        console.error('Error unarchiving content:', error);
        throw error;
      }
    };

    const handleArchiveCancel = () => {
      setShowArchiveConfirmation(false);
    };

    const handleArchiveConfirm = async () => {
      setShowArchiveConfirmation(false);
      try {
        await archiveVideo(selectedVideo);
        onVideoArchived(selectedVideo.id);
      } catch (error) {
        console.error('Error archiving video:', error);
      }
    };

    const archiveVideo = async (content) => {
      console.log('Archiving content:', content);
      try {
        await archiveContent(content, userId, userName);
      } catch (error) {
        console.error('Error archiving content:', error);
        throw error;
      }
    };

    const getRetentionWarning = () => {
      const uploadDate = new Date(selectedVideo.TimeUploaded);
      const currentDate = new Date();
      const daysElapsed = Math.floor((currentDate - uploadDate) / (1000 * 60 * 60 * 24));
      const daysUntilDeletion = retentionPeriodDays - daysElapsed;

      if (daysUntilDeletion <= 0) {
        return {
          severity: 'error',
          message:
            'Warning: This video is past its retention period of ' +
            retentionPeriodDays +
            ' days and will be deleted immediately upon unarchiving. This action cannot be undone.',
        };
      } else if (daysUntilDeletion <= 7) {
        return {
          severity: 'warning',
          message:
            'Warning: This video is scheduled for deletion in ' +
            (daysUntilDeletion === 1 ? ' less than 24 hours' : daysUntilDeletion + ' days') +
            ' based on the retention policy.',
        };
      } else {
        return {
          severity: 'info',
          message:
            'This video is scheduled for deletion in ' + daysUntilDeletion + ' days based on the retention policy.',
        };
      }
      return null;
    };

    const retentionWarning = getRetentionWarning();

    const renderAuditLogRows = () => {
      const rows = [...videoEvents];

      // Add 'Uploaded' and 'Recorded' entries only if this is not a redacted video
      console.log('rows: ', rows);
      const isRedactedVideo = rows.some((event) => event.action === 'create redacted video');

      if (!isRedactedVideo) {
        // Add 'Uploaded' entry
        if (selectedVideo.TimeUploaded) {
          rows.push({
            userName: selectedVideo.AssignedTo || 'Unknown',
            action: 'Upload',
            timestamp: selectedVideo.TimeUploaded,
          });
        }

        // Add 'Recorded' entry
        if (selectedVideo.TimeRecorded) {
          rows.push({
            userName: selectedVideo.AssignedTo || 'Unknown',
            action: 'Record',
            timestamp: selectedVideo.TimeRecorded,
          });
        }
      }

      // Sort rows by timestamp, newest first
      rows.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      return rows.map((event, index) => (
        <TableRow key={index}>
          <TableCell>{event.userName}</TableCell>
          <TableCell>
            {event.action === 'redact video' && event.linkedVideo ? (
              <span>
                Redact
                <a
                  href={`/videos?filename=${event.linkedVideo}&timestamp=0`}
                  className="redacted-video-link info-button redaction-badge-tooltip"
                  data-tooltip="View redacted video"
                >
                  <OpenInNewIcon className="info-icon" fontSize="small" />
                </a>
                {event.redactionSettings && (
                  <span className="redaction-badge-container">
                    {event.redactionSettings.faces && (
                      <span className="redaction-badge faces redaction-badge-tooltip" data-tooltip="Faces redacted">
                        <FaceRetouchingOffIcon fontSize="small" className="redaction-icon-small" />
                      </span>
                    )}
                    {event.redactionSettings.audio && (
                      <span className="redaction-badge audio redaction-badge-tooltip" data-tooltip="Audio redacted">
                        <VolumeOffIcon fontSize="small" className="redaction-icon-small" />
                      </span>
                    )}
                  </span>
                )}
              </span>
            ) : event.action === 'create redacted video' && event.linkedVideo ? (
              <span>
                Redacted from
                <a
                  href={`/videos?filename=${event.linkedVideo}&timestamp=0`}
                  className="redacted-video-link info-button redaction-badge-tooltip"
                  data-tooltip="View original video"
                >
                  <OpenInNewIcon className="info-icon" fontSize="small" />
                </a>
                {event.redactionSettings && (
                  <span className="redaction-badge-container">
                    {event.redactionSettings.faces && (
                      <span className="redaction-badge faces redaction-badge-tooltip" data-tooltip="Faces redacted">
                        <FaceRetouchingOffIcon fontSize="small" className="redaction-icon-small" />
                      </span>
                    )}
                    {event.redactionSettings.audio && (
                      <span className="redaction-badge audio redaction-badge-tooltip" data-tooltip="Audio redacted">
                        <VolumeOffIcon fontSize="small" className="redaction-icon-small" />
                      </span>
                    )}
                  </span>
                )}
              </span>
            ) : (
              event.action
                .split(' ')
                .map((word) => word[0].toUpperCase() + word.slice(1))
                .join(' ')
            )}
          </TableCell>
          <TableCell>{new Date(event.timestamp).toLocaleString()}</TableCell>
        </TableRow>
      ));
    };

    // Modify the dialog close handler to clear the error state
    const handleCloseShareDialog = () => {
      setShowShareDialog(false);
      setShareError(null); // Clear the error state when dialog is closed
    };

    return (
      <>
        {showVideo && renderVideoContent()}
        <div className="video-metadata">
          <div className="metadata-header">
            <h2 className="filename">
              {typeof videoToDisplay.FileName === 'string' ? videoToDisplay.FileName : 'Untitled'}
            </h2>
            {userRole !== 'guard' && (
              <div className="metadata-actions">
                {/* Only show redact button for videos */}
                {!selectedVideo.redactionStatus && !isImage(selectedVideo) && (
                  <Tooltip
                    title="Redact"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#333',
                          color: '#fff',
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: '12px',
                          borderRadius: '4px',
                          padding: '8px 12px',
                        },
                      },
                      arrow: {
                        sx: {
                          color: '#333',
                        },
                      },
                    }}
                  >
                    <div onClick={handleRedactConfirmation} className="archive-button">
                      <DeblurIcon />
                    </div>
                  </Tooltip>
                )}
                {!isArchived ? (
                  <Tooltip
                    title={
                      selectedVideo.watermarkedVideo === false
                        ? 'Archive will be available after watermarking'
                        : 'Archive'
                    }
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#333',
                          color: '#fff',
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: '12px',
                          borderRadius: '4px',
                          padding: '8px 12px',
                        },
                      },
                      arrow: {
                        sx: {
                          color: '#333',
                        },
                      },
                    }}
                    disableInteractive={false}
                    disableFocusListener={false}
                    disableTouchListener={false}
                    disableHoverListener={false}
                  >
                    <span style={{ display: 'inline-block' }}>
                      <div
                        onClick={selectedVideo.watermarkedVideo === false ? undefined : handleArchiveConfirmation}
                        className={`archive-button ${selectedVideo.watermarkedVideo === false ? 'disabled' : ''}`}
                      >
                        <Archive />
                      </div>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Unarchive"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#333',
                          color: '#fff',
                          fontFamily: "'Montserrat', sans-serif",
                          fontSize: '12px',
                          borderRadius: '4px',
                          padding: '8px 12px',
                        },
                      },
                      arrow: {
                        sx: {
                          color: '#333',
                        },
                      },
                    }}
                  >
                    <div onClick={handleUnarchiveConfirmation} className="archive-button">
                      <Unarchive />
                    </div>
                  </Tooltip>
                )}
                <Tooltip
                  title="Share"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: '#333',
                        color: '#fff',
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: '12px',
                        borderRadius: '4px',
                        padding: '8px 12px',
                      },
                    },
                    arrow: {
                      sx: {
                        color: '#333',
                      },
                    },
                  }}
                >
                  <div onClick={handleCreateSharedVideo} className="share-button">
                    <ShareIcon />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
          <div className="metadata-content">
            <div className="metadata-details">
              <div className="metadata-item">
                <span className="metadata-label">Owner:</span>
                <span className="metadata-value">{videoToDisplay.AssignedTo ? videoToDisplay.AssignedTo : '--'}</span>
              </div>
              <div className="metadata-item">
                <span className="metadata-label">Date:</span>
                <span className="metadata-value">{videoToDisplay.date}</span>
              </div>
              <div className="metadata-item">
                <span className="metadata-label">Time:</span>
                <span className="metadata-value">{videoToDisplay.time}</span>
              </div>
              {!isImage(selectedVideo || {}) && (
                <div className="metadata-item">
                  <span className="metadata-label">Duration:</span>
                  <span className="metadata-value">{videoToDisplay.formattedDuration}</span>
                </div>
              )}
              <div className="metadata-item">
                <span className="metadata-label">Type:</span>
                <span className="metadata-value">{isImage(videoToDisplay) ? 'Image' : 'Video'}</span>
              </div>
              {videoToDisplay.redactionStatus === 'done' && videoToDisplay.redactionStartedTimestamp && (
                <>
                  <div className="metadata-item">
                    <span className="metadata-label">Redacted on:</span>
                    <span className="metadata-value">
                      {new Date(videoToDisplay.redactionStartedTimestamp).toLocaleString()}
                    </span>
                  </div>
                  {videoToDisplay.redactionSettings && (
                    <div className="metadata-item">
                      <span className="metadata-label">Redaction applied:</span>
                      <span className="metadata-value">
                        {[
                          videoToDisplay.redactionSettings.faces && 'Faces',
                          videoToDisplay.redactionSettings.audio && 'Audio',
                        ]
                          .filter(Boolean)
                          .join(', ') || 'None'}
                      </span>
                    </div>
                  )}
                </>
              )}
              {!isArchived ? (
                <div className="metadata-item">
                  <span className="metadata-label">Scheduled for deletion on:</span>
                  <span className="metadata-value">
                    {new Date(
                      new Date(videoToDisplay.TimeUploaded).getTime() + retentionPeriodDays * 24 * 60 * 60 * 1000
                    ).toLocaleString()}
                    {videoToDisplay.age >= retentionPeriodDays - 7 && (
                      <Tooltip
                        title={`This video is scheduled for deletion in ${retentionPeriodDays - videoToDisplay.age > 1 ? `${retentionPeriodDays - videoToDisplay.age} days!` : 'less than 1 day!'}`}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: '#333',
                              color: '#fff',
                              fontFamily: "'Montserrat', sans-serif",
                              fontSize: '12px',
                              borderRadius: '4px',
                              padding: '8px 12px',
                              width: 'auto',
                              maxWidth: 'none',
                              whiteSpace: 'nowrap',
                            },
                          },
                          arrow: {
                            sx: {
                              color: '#333',
                            },
                          },
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          style={{
                            color: '#eed202',
                            marginLeft: '5px',
                            padding: '2px',
                          }}
                        />
                      </Tooltip>
                    )}
                  </span>
                </div>
              ) : (
                <div className="metadata-item">
                  <span className="metadata-label">Archived on:</span>
                  <span className="metadata-value">
                    {videoToDisplay.archivedOnTimestamp
                      ? new Date(videoToDisplay.archivedOnTimestamp).toLocaleString()
                      : 'Unavailable'}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="video-events">
          <h4 className="audit-log-heading">Audit Trail</h4>
          <TableContainer component={Paper} className="audit-log-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Timestamp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderAuditLogRows()}</TableBody>
            </Table>
          </TableContainer>
        </div>
        <Dialog
          open={showRedactConfirmation}
          onClose={handleRedactCancel}
          aria-labelledby="redact-dialog-title"
          aria-describedby="redact-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="redact-dialog-title" sx={{ m: 0, p: 2 }}>
            Redact Video
            <IconButton
              aria-label="close"
              onClick={handleRedactCancel}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: 2,
                mb: 3,
                minWidth: '400px',
              }}
            >
              <Typography variant="body2" sx={{ mb: 1 }}>
                Select items to redact:
              </Typography>

              <FormControlLabel
                control={
                  <Switch
                    checked={redactionSettings.faces}
                    onChange={(e) => handleRedactionSettingChange('faces', e.target.checked)}
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#cb2d3e',
                        '&:hover': {
                          backgroundColor: 'rgba(203, 45, 62, 0.08)',
                        },
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#cb2d3e',
                      },
                      '& .MuiSwitch-thumb': {
                        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
                      },
                    }}
                  />
                }
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <FaceRetouchingOffIcon fontSize="small" sx={{ color: '#cb2d3e' }} />
                    <Typography>Faces</Typography>
                  </Box>
                }
              />

              {/* <FormControlLabel
                control={
                  <Switch
                    checked={redactionSettings.licensePlates}
                    onChange={(e) => handleRedactionSettingChange('licensePlates', e.target.checked)}
                  />
                }
                label="License Plates"
              /> */}

              <FormControlLabel
                control={
                  <Switch
                    checked={redactionSettings.audio}
                    onChange={(e) => handleRedactionSettingChange('audio', e.target.checked)}
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#cb2d3e',
                        '&:hover': {
                          backgroundColor: 'rgba(203, 45, 62, 0.08)',
                        },
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#cb2d3e',
                      },
                      '& .MuiSwitch-thumb': {
                        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
                      },
                    }}
                  />
                }
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <VolumeOffIcon fontSize="small" sx={{ color: '#cb2d3e' }} />
                    <Typography>Audio</Typography>
                  </Box>
                }
              />

              {redactionError && (
                <Typography
                  color="error"
                  sx={{
                    mt: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    backgroundColor: '#fff4f4',
                    padding: 2,
                    borderRadius: 1,
                  }}
                >
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  {redactionError}
                </Typography>
              )}

              {redactionSuccess ? (
                <Typography
                  color="success"
                  sx={{
                    mt: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    backgroundColor: '#f4fff4',
                    padding: 2,
                    borderRadius: 1,
                  }}
                >
                  <span className="redaction-success">
                    Redacted video will appear in the Archived Videos{' '}
                    <Box component="span" sx={{ verticalAlign: 'top' }}>
                      <InventoryIcon fontSize="small" />
                    </Box>{' '}
                    section when complete.
                  </span>
                </Typography>
              ) : (
                <Button variant="contained" onClick={handleRedactConfirm} disabled={redactionInProgress} sx={{ mt: 2 }}>
                  {redactionInProgress ? (
                    <>
                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      Processing...
                    </>
                  ) : (
                    <>
                      <DeblurIcon sx={{ mr: 1 }} />
                      Redact Video
                    </>
                  )}
                </Button>
              )}

              <Typography variant="caption" sx={{ mt: 1 }}>
                Note: Redaction will create a new copy, the original video will remain unchanged.
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
        <Dialog
          open={showArchiveConfirmation}
          onClose={handleArchiveCancel}
          aria-labelledby="archive-dialog-title"
          aria-describedby="archive-dialog-description"
        >
          <DialogTitle id="archive-dialog-title">Archive {isImage(selectedVideo) ? 'image' : 'video'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="archive-dialog-description">
              Are you sure you want to archive this {isImage(selectedVideo) ? 'image' : 'video'}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="dialog-actions">
              <Button onClick={handleArchiveCancel}>Cancel</Button>
              <Button onClick={handleArchiveConfirm} autoFocus>
                Archive
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showUnarchiveConfirmation}
          onClose={handleUnarchiveCancel}
          aria-labelledby="unarchive-dialog-title"
          aria-describedby="unarchive-dialog-description"
        >
          <DialogTitle id="unarchive-dialog-title">Unarchive {isImage(selectedVideo) ? 'image' : 'video'}</DialogTitle>
          <DialogContent>
            {retentionWarning && (
              <MuiAlert severity={retentionWarning.severity} sx={{ mb: 2 }}>
                {retentionWarning.message}
              </MuiAlert>
            )}
          </DialogContent>
          <DialogActions>
            <div className="dialog-actions">
              <Button onClick={handleUnarchiveCancel}>Cancel</Button>
              <Button onClick={handleUnarchiveConfirm} autoFocus>
                Unarchive
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showShareDialog}
          onClose={handleCloseShareDialog} // Use the new handler instead of direct state setter
          aria-labelledby="share-dialog-title"
          aria-describedby="share-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="share-dialog-title" sx={{ m: 0, p: 2 }}>
            Share {isImage(selectedVideo) ? 'Image' : 'Video'}
            <IconButton
              aria-label="close"
              onClick={handleCloseShareDialog} // Use the new handler here as well
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: 2,
                mb: 3,
                minWidth: '400px',
              }}
            >
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '120px' }}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Expiration <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    type="number"
                    size="small"
                    value={shareSettings.expirationDays}
                    onChange={(e) => {
                      const maxDays = isArchived
                        ? 30
                        : Math.min(30, calculateDaysRemaining(selectedVideo.TimeUploaded, retentionPeriodDays));
                      const newValue = Math.min(Math.max(1, parseInt(e.target.value) || 0), maxDays);
                      handleSettingChange({
                        ...shareSettings,
                        expirationDays: newValue,
                      });
                    }}
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: isArchived
                          ? 30
                          : Math.min(30, calculateDaysRemaining(selectedVideo.TimeUploaded, retentionPeriodDays)),
                      },
                      endAdornment: (
                        <InputAdornment position="end" className="days-adornment">
                          <span className="days-adornment-text">days</span>
                        </InputAdornment>
                      ),
                    }}
                    disabled={
                      !isArchived && calculateDaysRemaining(selectedVideo.TimeUploaded, retentionPeriodDays) <= 0
                    }
                  />
                  <div className="max-days-wrapper">
                    <Typography variant="caption">
                      {isArchived
                        ? 'Max 30 days'
                        : `Max ${Math.min(30, calculateDaysRemaining(selectedVideo.TimeUploaded, retentionPeriodDays))} days`}
                    </Typography>
                  </div>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Password <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    size="small"
                    value={shareSettings.password}
                    onChange={(e) =>
                      handleSettingChange({
                        ...shareSettings,
                        password: e.target.value,
                      })
                    }
                    type={showPassword ? 'text' : 'password'}
                    required={true}
                    error={!shareSettings.password?.trim()}
                    helperText={!shareSettings.password?.trim() ? 'Password is required' : ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                            className="visibility-toggle-button"
                          >
                            {showPassword ? (
                              <VisibilityOff className="visibility-toggle-icon" />
                            ) : (
                              <Visibility className="visibility-toggle-icon" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={shareSettings.downloadable}
                      onChange={(e) =>
                        handleSettingChange({
                          ...shareSettings,
                          downloadable: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Allow Download"
                />
                {!isImage(selectedVideo) && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={shareSettings.useStartTime}
                          onChange={(e) =>
                            handleSettingChange({
                              ...shareSettings,
                              useStartTime: e.target.checked,
                              startTime: 0,
                            })
                          }
                        />
                      }
                      label="Set Start Time"
                    />
                    {shareSettings.useStartTime && (
                      <TextField
                        size="small"
                        value={formatTimeDisplay(shareSettings.startTime)}
                        onChange={(e) => {
                          const [minutes, seconds] = e.target.value.split(':').map((num) => parseInt(num) || 0);
                          const totalSeconds = minutes * 60 + seconds;
                          const durationInSeconds = Math.floor(parseInt(selectedVideo.Duration) / 1000);
                          const validatedTime = Math.min(totalSeconds, durationInSeconds);
                          handleSettingChange({
                            ...shareSettings,
                            startTime: validatedTime,
                          });
                        }}
                        sx={{ width: '100px' }}
                        inputProps={{
                          pattern: '[0-9]{2}:[0-9]{2}',
                          placeholder: '00:00',
                        }}
                      />
                    )}
                    {shareSettings.useStartTime && (
                      <Typography
                        variant="caption"
                        className="max-days-caption"
                        sx={{
                          color: 'var(--text-secondary)',
                          fontSize: '0.75rem',
                          marginTop: '4px',
                          display: 'block',
                        }}
                      >
                        / {formatTimeDisplay(Math.floor(parseInt(selectedVideo.Duration) / 1000))}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>

              {!sharedVideoId ? (
                <>
                  {shareError ? (
                    <Typography
                      color="error"
                      sx={{
                        mt: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        backgroundColor: '#fff4f4',
                        padding: 2,
                        borderRadius: 1,
                      }}
                    >
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                      {shareError}
                    </Typography>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleShareSubmit}
                      sx={{ mt: 'auto' }}
                      className="share-dialog-button"
                      disabled={
                        !shareSettings.password?.trim() ||
                        (!isArchived && calculateDaysRemaining(selectedVideo.TimeUploaded, retentionPeriodDays) <= 0)
                      }
                    >
                      <LinkIcon fontSize="small" />
                      Generate Shareable Link
                    </Button>
                  )}
                </>
              ) : (
                <div className="share-link-container" style={{ marginTop: 'auto' }}>
                  <input
                    type="text"
                    value={`${window.location.origin}/${isImage(selectedVideo) ? 'sharedimage' : 'sharedvideo'}/${sharedVideoId}`}
                    readOnly
                    className="share-link-input"
                  />
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.origin}/${isImage(selectedVideo) ? 'sharedimage' : 'sharedvideo'}/${sharedVideoId}`
                      );
                      setShowCopySuccess(true);
                    }}
                    variant="contained"
                  >
                    Copy
                  </Button>
                </div>
              )}
              {!isArchived && calculateDaysRemaining(selectedVideo.TimeUploaded, retentionPeriodDays) <= 0 && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  This {isImage(selectedVideo) ? 'image' : 'video'} cannot be shared as it has exceeded its retention
                  period.
                </Typography>
              )}
            </Box>
          </DialogContent>
        </Dialog>

        <Snackbar
          open={showCopySuccess}
          autoHideDuration={2000}
          onClose={() => setShowCopySuccess(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <MuiAlert onClose={() => setShowCopySuccess(false)} severity="success">
            Link copied to clipboard!
          </MuiAlert>
        </Snackbar>
      </>
    );
  },
  areEqual
);

// Create a custom renderer for the rows that will show the play icon for the selected row
const renderCustomRow = (selectedRowId) => (params) => {
  const isSelected = params.id === selectedRowId;

  return (
    <div className={`custom-row ${isSelected ? 'playing-video' : ''}`}>
      {isSelected && (
        <div className="playing-indicator">
          <FontAwesomeIcon icon={faPlay} />
        </div>
      )}
      {params.value}
    </div>
  );
};

const VideoManagement = (props) => {
  const [viewArchived, setViewArchived] = useState(false);
  const [videos, setVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [user] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  // Add a state to track the selected row ID
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [searchType, setSearchType] = useState('video');
  const [showModal, setShowModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [shouldFadeIn, setShouldFadeIn] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initialTimestamp, setInitialTimestamp] = useState(0);
  const [urlFileName, setUrlFileName] = useState(null);
  const location = useLocation();
  const [dateRange, setDateRange] = useState([null, null]);
  const [ownerFilter, setOwnerFilter] = useState('');
  const [archivedVideos, setArchivedVideos] = useState([]);
  const [showArchiveSuccess, setShowArchiveSuccess] = useState(false);
  const [filteredArchivedVideos, setFilteredArchivedVideos] = useState([]);
  const [showUnarchiveSuccess, setShowUnarchiveSuccess] = useState(false);
  const [videoEvents, setVideoEvents] = useState([]);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  // Add contentFilter state
  const [contentFilter, setContentFilter] = useState('all'); // 'all', 'videos', 'images'

  // Add handler function for tab changes
  const handleContentFilterChange = (event, newValue) => {
    setContentFilter(newValue);

    // Apply filter to either archived or non-archived content based on current view
    if (viewArchived) {
      let filteredList = archivedVideos;
      if (newValue === 'videos') {
        filteredList = archivedVideos.filter((item) => !isImage(item));
      } else if (newValue === 'images') {
        filteredList = archivedVideos.filter((item) => isImage(item));
      }
      setFilteredArchivedVideos(filteredList);
    } else {
      let filteredList = videos;
      if (newValue === 'videos') {
        filteredList = videos.filter((item) => !isImage(item));
      } else if (newValue === 'images') {
        filteredList = videos.filter((item) => isImage(item));
      }
      setFilteredVideos(filteredList);
    }
  };

  // Add a ref for the DataGrid
  const dataGridRef = useRef(null);

  const { devices, selectedOrg } = useDeviceFilter();

  // Move this to the top of the component, before any functions that use it
  const [loggingContainer, setLoggingContainer] = useState(null);

  // Add useEffect to scroll to selected row when it changes
  useEffect(() => {
    if (selectedRowId) {
      // Short timeout to ensure the grid is fully rendered
      setTimeout(() => {
        // Find the selected row element
        const selectedRow = document.querySelector(`.video-datagrid .MuiDataGrid-row[data-id="${selectedRowId}"]`);
        if (selectedRow) {
          // Scroll the row into view with smooth behavior
          selectedRow.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }, 100);
    }
  }, [selectedRowId]);

  useEffect(() => {
    const initializeLoggingContainer = async () => {
      try {
        const container = cosmosClient.database('Videos').container('VideoEvents');
        setLoggingContainer(container);
      } catch (error) {
        console.error('Error initializing logging container:', error);
      }
    };

    initializeLoggingContainer();
  }, []);

  const searchVideos = async (query, userId, isAudio, videos = [], searchTarget = 'all') => {
    if (!query) {
      return videos.map((video) => ({ fileName: video.FileName, timestamp: 0, query: '' }));
    }

    console.log('query: ', query);
    const lowerCaseQuery = query.toLowerCase();

    let url = !isAudio ? 'https://vectordb.plix.ai/v1/videosearch' : 'https://vectordb.plix.ai/v1/transcriptsearch';

    const data = {
      query: lowerCaseQuery,
      userId:
        props.userRole === 'ASP'
          ? props.parentOrgUserId
          : props.userId === PLIX_OVERWATCH_ACCOUNT
            ? selectedOrg
            : userId,
      searchTarget: searchTarget,
    };

    try {
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const videoMap = new Map(videos.map((video) => [video.FileName, video]));

      const filteredVideos = response.data
        .filter((result) => videoMap.has(result.filename))
        .map((result) => {
          const video = videoMap.get(result.filename);
          return {
            fileName: video.FileName,
            timestamp: Array.isArray(result.timestamp) ? result.timestamp : [result.timestamp],
            query,
          };
        });

      return filteredVideos;
    } catch (error) {
      console.error('Error:', error);
      return videos;
    }
  };

  useEffect(() => {
    if (videos.length === 0) {
      setShouldFadeIn(true);
    } else {
      setShouldFadeIn(false);
    }
  }, [videos.length]);

  useEffect(() => {
    if (devices.length > 0 && props.retentionPeriodDays) {
      fetchVideos();
    }
  }, [devices.length, props.retentionPeriodDays, selectedOrg]);

  // Now the fetchVideoEvents function can safely use loggingContainer
  const fetchVideoEvents = async (fileName) => {
    if (!fileName) return;

    try {
      const token = localStorage.getItem('access_token');
      const response = await axios.get(`${process.env.REACT_APP_BEAVER_API_URL}/api/content/events/${fileName}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Content events fetched:', response.data);
      setVideoEvents(response.data);
    } catch (error) {
      console.error('Error fetching content events:', error);
      setVideoEvents([]);
    }
  };

  // Modify the useEffect that handles URL parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filename = searchParams.get('filename');
    const timestamp = searchParams.get('timestamp');

    if (filename && timestamp) {
      setInitialTimestamp(parseFloat(timestamp));
      setUrlFileName(filename);

      // Directly fetch video events for the URL filename
      if (loggingContainer) {
        fetchVideoEvents(filename);
      }

      // Reset selectedRowId when changing URL parameters
      // We'll set it properly when the video is found
      setSelectedRowId(null);
    } else {
      setInitialTimestamp(0);
      setUrlFileName(null);
      setSelectedVideo(null);
      setSelectedRowId(null);
      setVideoEvents([]);
    }
  }, [location, loggingContainer]);

  useEffect(() => {
    if (urlFileName) {
      // First check if the video exists in the current view
      const videoInCurrentView = videos.find((v) => v.FileName === urlFileName);

      if (videoInCurrentView) {
        // If we found the video in the current view, select it
        selectVideo(videoInCurrentView);
      } else if (!viewArchived) {
        // If we didn't find it and we're not in archived view, check archived videos
        fetchArchivedVideosForUrlFile();
      }
    }
  }, [videos, urlFileName, viewArchived]);

  // Modify the fetchArchivedVideosForUrlFile function
  const fetchArchivedVideosForUrlFile = async () => {
    if (!urlFileName || devices.length === 0) return;

    setIsLoading(true);
    try {
      const deviceIds = devices.map((device) => device.deviceId);
      const response = await axios.get(`${process.env.REACT_APP_BEAVER_API_URL}/api/archived-content/${urlFileName}`, {
        params: {
          deviceIds: JSON.stringify(deviceIds),
          userId: props.userId === PLIX_OVERWATCH_ACCOUNT ? selectedOrg : undefined,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      const items = response.data;

      if (items.length > 0) {
        // If we found the video in archived videos, switch to archived view
        console.log('Found video in archived videos, switching to archived view');
        setViewArchived(true);

        // Fetch all archived videos
        await fetchArchivedVideos();

        // After fetching, find and select the video
        const formattedVideo = formatArchivedVideo(items[0]);
        setSelectedVideo(formattedVideo);
        // Also set the selected row ID for proper highlighting
        setSelectedRowId(formattedVideo.id);

        // Explicitly fetch video events again to ensure they're loaded
        await fetchVideoEvents(formattedVideo.FileName);
      }
    } catch (error) {
      console.error('Error checking for archived video:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Add a useEffect to refetch video events when selectedVideo changes
  useEffect(() => {
    if (selectedVideo && selectedVideo.FileName) {
      fetchVideoEvents(selectedVideo.FileName);
    }
  }, [selectedVideo?.id]); // Only depend on the ID to prevent infinite loops

  // Helper function to format an archived video
  const formatArchivedVideo = (item) => {
    const timeUploaded = new Date(item.TimeRecorded || item.TimeUploaded);
    const time = timeUploaded.toLocaleTimeString();
    const date = timeUploaded.toLocaleDateString();
    const durationMilliseconds = parseInt(item.Duration);
    const durationSeconds = isNaN(durationMilliseconds) ? 0 : Math.floor(durationMilliseconds / 1000);
    const hours = Math.floor(durationSeconds / 3600);
    const minutes = Math.floor((durationSeconds % 3600) / 60);
    const seconds = durationSeconds % 60;
    const formattedDuration = `${hours > 0 ? `${hours}h ` : ''}${minutes}m ${seconds}s`;

    const currentDate = new Date();
    const age = Math.floor((currentDate - timeUploaded) / (1000 * 60 * 60 * 24));

    return {
      ...item,
      time,
      date,
      formattedDuration,
      timeUploaded: timeUploaded.getTime(),
      age: age,
      timeToDeletion: calculateDaysRemaining(timeUploaded, props.retentionPeriodDays),
      initialTimestamp: parseFloat(initialTimestamp),
      urlFileName: urlFileName,
      transcriptUrl: item.TranscriptVTTPath,
    };
  };

  const fetchVideos = async () => {
    setIsLoading(true);
    try {
      const rawVideosList = await fetchVideosForDevices(props.userId, devices, props.retentionPeriodDays, selectedOrg);
      const rawImagesList = await fetchImagesForDevices(props.userId, devices, props.retentionPeriodDays, selectedOrg);

      console.log('rawVideosList: ', rawVideosList);
      console.log('rawImagesList: ', rawImagesList);

      // Format videos
      const formattedVideosList = rawVideosList
        .map((item) => {
          const timeUploaded = new Date(item.TimeRecorded || item.TimeUploaded);
          const time = timeUploaded.toLocaleTimeString();
          const date = timeUploaded.toLocaleDateString();
          const durationMilliseconds = parseInt(item.Duration);
          const durationSeconds = isNaN(durationMilliseconds) ? 0 : Math.floor(durationMilliseconds / 1000);
          const hours = Math.floor(durationSeconds / 3600);
          const minutes = Math.floor((durationSeconds % 3600) / 60);
          const seconds = durationSeconds % 60;
          const formattedDuration = `${hours > 0 ? `${hours}h ` : ''}${minutes}m ${seconds}s`;

          const currentDate = new Date();
          const age = Math.floor((currentDate - timeUploaded) / (1000 * 60 * 60 * 24));

          return {
            ...item,
            time,
            date,
            formattedDuration,
            timeUploaded: timeUploaded.getTime(),
            age: age,
            timeToDeletion: calculateDaysRemaining(timeUploaded, props.retentionPeriodDays),
            AssignedTo: item.assignedTo || devices.find((d) => d.deviceId === item.deviceId)?.assignedTo,
            transcriptUrl: item.TranscriptVTTPath,
            contentType: 'video',
          };
        })
        .filter((item) => parseInt(item.Duration) > 1000 * 10);

      // Format images
      const formattedImagesList = rawImagesList.map((item) => {
        const timeUploaded = new Date(item.TimeUploaded);
        const time = timeUploaded.toLocaleTimeString();
        const date = timeUploaded.toLocaleDateString();

        const currentDate = new Date();
        const age = Math.floor((currentDate - timeUploaded) / (1000 * 60 * 60 * 24));

        return {
          ...item,
          time,
          date,
          formattedDuration: 'N/A', // Images don't have duration
          timeUploaded: timeUploaded.getTime(),
          age: age,
          timeToDeletion: calculateDaysRemaining(timeUploaded, props.retentionPeriodDays),
          AssignedTo: item.assignedTo || devices.find((d) => d.deviceId === item.deviceId)?.assignedTo,
          contentType: 'image',
        };
      });

      // Combine videos and images into a single list
      const combinedList = [...formattedVideosList, ...formattedImagesList];

      // Sort by upload time, newest first
      combinedList.sort((a, b) => b.timeUploaded - a.timeUploaded);

      // Apply content filter if it's set
      let filteredList = combinedList;
      if (contentFilter === 'videos') {
        filteredList = combinedList.filter((item) => !isImage(item));
      } else if (contentFilter === 'images') {
        filteredList = combinedList.filter((item) => isImage(item));
      }

      setVideos(combinedList);
      setFilteredVideos(filteredList);
    } catch (error) {
      console.error('Error fetching content:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const selectVideo = (video) => {
    const isUrlVideo = video.FileName === urlFileName;
    const updatedVideo = {
      ...video,
      initialTimestamp: isUrlVideo ? initialTimestamp : 0,
      urlFileName: isUrlVideo ? urlFileName : null,
    };

    setSelectedVideo(updatedVideo);
    // Set the selected row ID when selecting a video
    setSelectedRowId(video.id);

    if (!isUrlVideo) {
      setInitialTimestamp(0);
      setUrlFileName(null);
    }

    // We'll let the useEffect handle fetching video events

    logUserInteraction(
      'view',
      {
        videoId: video.id,
        fileName: video.FileName,
        deviceId: video.deviceId,
        accountId: video.AccountId,
        blobPath: video.BlobPath,
        blobSAS: video.BlobSAS,
        duration: video.Duration,
        assignedTo: video.AssignedTo,
        isIngesting: video && video.generatedEmbeddings && !video.TranscriptVTTPath,
      },
      props.userId,
      props.userName
    );
    trackVideoSelected(video.id);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDateChange = (update) => {
    setDateRange(update);
    // Apply the filter whenever date range changes
    const endDate = update[1] ? new Date(update[1]) : null;
    if (endDate) {
      // Set the end date to the end of the day for inclusive filtering
      endDate.setHours(23, 59, 59, 999);
    }
    handleSearch(searchQuery, update[0], endDate, ownerFilter);
  };

  const handleSearch = async (query, startDate, endDate, owner = ownerFilter) => {
    setIsLoading(true);
    setSelectedVideo(null);

    try {
      const results = await searchVideos(
        query,
        props.userId,
        searchType === 'audio',
        viewArchived ? archivedVideos : videos,
        viewArchived ? 'archive' : 'all'
      );
      setSearchResults(results);
      const resultFileNames = results.map((result) => result.fileName);

      const videoMap = new Map((viewArchived ? archivedVideos : videos).map((video) => [video.FileName, video]));
      let filteredVideos =
        resultFileNames.length > 0 && query
          ? resultFileNames.map((fileName) => videoMap.get(fileName)).filter(Boolean)
          : viewArchived
            ? archivedVideos
            : videos;

      // Apply date filter if dates are provided
      if (startDate && endDate) {
        filteredVideos = filteredVideos.filter((video) => {
          // Use a consistent date field from the video object
          const videoDate = new Date(video.TimeUploaded || video.date);
          return videoDate >= startDate && videoDate <= endDate;
        });
      }

      // Apply owner filter if provided
      if (owner) {
        filteredVideos = filteredVideos.filter((video) => video.AssignedTo === owner);
      }

      if (viewArchived) {
        setFilteredArchivedVideos(filteredVideos);
      } else {
        setFilteredVideos(filteredVideos);
      }
    } catch (error) {
      console.error('Error during search:', error);
      if (viewArchived) {
        setFilteredArchivedVideos([]);
      } else {
        setFilteredVideos([]);
      }
    } finally {
      setIsLoading(false);
    }
    trackVideoSearch(query);
  };

  const resetFilters = () => {
    setSearchQuery('');
    setDateRange([null, null]);
    setOwnerFilter('');
    setContentFilter('all'); // Reset content filter to 'all'
    if (viewArchived) {
      setFilteredArchivedVideos(archivedVideos);
    } else {
      setFilteredVideos(videos);
    }
    setSearchResults([]);
    setSelectedVideo(null);
    setSelectedRowId(null); // Clear selected row ID when resetting filters
  };

  const uniqueOwners = [...new Set(videos.map((video) => video.AssignedTo))].filter(Boolean);

  const CustomInput = React.forwardRef(({ value, onClick, onClear }, ref) => (
    <div className="custom-date-input">
      <input
        value={value || 'Filter by date'}
        onClick={onClick}
        readOnly
        ref={ref}
        className="date-input-field"
        placeholder="Filter by date"
      />
      {value && (
        <button className="clear-date-button" onClick={onClear}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
    </div>
  ));

  const handleClearDate = () => {
    setDateRange([null, null]);
    handleSearch(searchQuery, null, null);
  };

  const handleOwnerFilterChange = (event) => {
    const newOwnerFilter = event.target.value;
    setOwnerFilter(newOwnerFilter);
    handleSearch(searchQuery, dateRange[0], dateRange[1], newOwnerFilter);
  };

  const handleVideoArchived = useCallback(
    (archivedVideoId) => {
      const archivedItem = videos.find((v) => v.id === archivedVideoId);
      setVideos((prevVideos) => prevVideos.filter((video) => video.id !== archivedVideoId));
      setFilteredVideos((prevFilteredVideos) => prevFilteredVideos.filter((video) => video.id !== archivedVideoId));
      setSelectedVideo(null);
      setSelectedRowId(null);
      setShowArchiveSuccess(true);
      trackVideoArchived(archivedVideoId);
    },
    [videos]
  );

  const handleCloseArchiveSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowArchiveSuccess(false);
  };

  const fetchArchivedVideos = async () => {
    setIsLoading(true);
    try {
      const deviceIds = devices.map((device) => device.deviceId);
      const response = await axios.get(`${process.env.REACT_APP_BEAVER_API_URL}/api/archived-content`, {
        params: {
          deviceIds: JSON.stringify(deviceIds),
          userId: props.userId === PLIX_OVERWATCH_ACCOUNT ? selectedOrg : undefined,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      const allArchivedItems = response.data;
      const formattedItems = allArchivedItems.map((item) => {
        const timeUploaded = new Date(item.TimeRecorded || item.TimeUploaded);
        const time = timeUploaded.toLocaleTimeString();
        const date = timeUploaded.toLocaleDateString();

        // Only calculate duration for videos
        const durationMilliseconds = parseInt(item.Duration);
        const durationSeconds = isNaN(durationMilliseconds) ? 0 : Math.floor(durationMilliseconds / 1000);
        const formattedDuration = isImage(item) ? 'N/A' : formatDuration(durationSeconds);

        const age = Math.floor((new Date() - timeUploaded) / (1000 * 60 * 60 * 24));

        return {
          ...item,
          time,
          date,
          formattedDuration,
          timeUploaded: timeUploaded.getTime(),
          age,
          timeToDeletion: calculateDaysRemaining(timeUploaded, props.retentionPeriodDays),
          AssignedTo: item.assignedTo || devices.find((d) => d.deviceId === item.deviceId)?.assignedTo || 'Unknown',
        };
      });

      // Apply content filter if it's set
      let filteredList = formattedItems;
      if (contentFilter === 'videos') {
        filteredList = formattedItems.filter((item) => !isImage(item));
      } else if (contentFilter === 'images') {
        filteredList = formattedItems.filter((item) => isImage(item));
      }

      setArchivedVideos(formattedItems);
      setFilteredArchivedVideos(filteredList);
    } catch (error) {
      console.error('Error fetching archived content:', error);
    }
    setIsLoading(false);
  };

  const handleArchive = useCallback(() => {
    setViewArchived((prevState) => {
      const newViewArchived = !prevState;
      if (newViewArchived) {
        setFilteredVideos([]);
        fetchArchivedVideos();
      } else {
        setArchivedVideos([]);
        setFilteredArchivedVideos([]);
        fetchVideos();
      }

      // Clear URL parameters when switching views
      if (urlFileName) {
        // Remove URL parameters without refreshing the page
        window.history.replaceState({}, document.title, window.location.pathname);
        setUrlFileName(null);
        setInitialTimestamp(0);
      }

      setSelectedVideo(null);
      setSelectedRowId(null);
      setSearchQuery('');
      setDateRange([null, null]);
      setOwnerFilter('');
      setSearchResults([]);

      // Keep the content filter when switching views
      // The fetchVideos/fetchArchivedVideos functions will handle the filtering

      return newViewArchived;
    });
  }, [fetchArchivedVideos, fetchVideos, urlFileName]);

  const handleVideoUnarchived = useCallback(
    (unarchivedVideoId) => {
      const unarchivedItem = archivedVideos.find((v) => v.id === unarchivedVideoId);
      setArchivedVideos((prevArchivedVideos) => prevArchivedVideos.filter((video) => video.id !== unarchivedVideoId));
      setFilteredArchivedVideos((prevFilteredArchivedVideos) =>
        prevFilteredArchivedVideos.filter((video) => video.id !== unarchivedVideoId)
      );
      setSelectedVideo(null);
      setSelectedRowId(null);
      setShowUnarchiveSuccess(true);
      fetchVideos();
      trackVideoUnarchived(unarchivedVideoId);
    },
    [archivedVideos, fetchVideos]
  );

  const handleCloseUnarchiveSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowUnarchiveSuccess(false);
  };

  // Define columns with their original renderers
  const columns = [
    { field: 'FileName', headerName: 'Title', flex: 2.5 },
    {
      field: 'contentType',
      headerName: 'Type',
      width: 100,
      renderCell: (params) => {
        if (!params || !params.row) return '';
        const isImageContent = isImage(params.row);
        return (
          <div className="content-type-cell">
            {isImageContent ? <PhotoLibraryIcon fontSize="small" /> : <VideoLibraryIcon fontSize="small" />}
          </div>
        );
      },
      valueGetter: (params) => {
        if (!params || !params.row) return '';
        return isImage(params.row) ? 'Image' : 'Video';
      },
    },
    {
      field: 'AssignedTo',
      headerName: 'Owner',
      width: 170,
      renderCell: (params) => {
        const owner = params.value || 'Unassigned';
        const redactionStatus = params.row.redactionStatus;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <div>{owner}</div>

            {redactionStatus && (
              <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
                {redactionStatus === 'done' ? (
                  <Tooltip title="Redaction Completed">
                    <DeblurIcon fontSize="small" sx={{ color: '#cb2d3e', opacity: 0.8 }} />
                  </Tooltip>
                ) : redactionStatus !== 'done' ? (
                  <Tooltip title="Redaction In Progress">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <DeblurIcon
                        fontSize="small"
                        sx={{
                          color: '#888',
                          animation: 'pulse 1.5s infinite ease-in-out',
                          '@keyframes pulse': {
                            '0%': { opacity: 0.6 },
                            '50%': { opacity: 1 },
                            '100%': { opacity: 0.6 },
                          },
                        }}
                      />
                    </Box>
                  </Tooltip>
                ) : null}
              </Box>
            )}
          </Box>
        );
      },
    },
    { field: 'date', headerName: 'Date', flex: 1, hide: true, hideable: false },
    { field: 'time', headerName: 'Time', flex: 1, hide: true, hideable: false },
    {
      field: 'formattedDuration',
      headerName: 'Duration',
      flex: 1,
      renderCell: (params) => {
        if (isImage(params.row)) {
          return <span>—</span>;
        }
        return <span>{params.value}</span>;
      },
    },
    {
      field: 'timeToDeletion',
      headerName: 'Time to Deletion',
      flex: 1,
      renderCell: (params) => {
        if (props.retentionPeriodDays === 100000) {
          return <span style={{ fontSize: '1.2em' }}>∞</span>;
        }
        const daysRemaining = calculateDaysRemaining(params.row.TimeRecorded, props.retentionPeriodDays);
        const text = daysRemaining <= 1 ? '< 1 day' : `${daysRemaining} days`;
        return <span style={{ color: daysRemaining <= 1 ? '#cb2d3e' : 'inherit' }}>{text}</span>;
      },
    },
  ];

  const archivedColumns = [
    ...columns.filter((column) => column.field !== 'timeToDeletion'),
    {
      field: 'download',
      headerName: 'Download',
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleDownload(params.row);
            }}
            className="download-button"
          >
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
      ),
    },
  ];

  // Create a modified version of columns that includes the custom renderer
  // but preserves special column renderers
  const getColumnsWithCustomRenderer = useCallback(
    (baseColumns) => {
      return baseColumns.map((column) => {
        // Only apply our custom renderer to the FileName column
        // For other columns, preserve their original renderer if they have one
        if (column.field === 'FileName') {
          return {
            ...column,
            renderCell: renderCustomRow(selectedRowId),
          };
        }
        // Keep the original column definition untouched
        return column;
      });
    },
    [selectedRowId]
  );

  const CustomToolbar = () => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px 16px 8px 6px' }}
      >
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
        </GridToolbarContainer>
        <Typography variant="inherit" style={{ display: 'flex', alignItems: 'center' }}>
          <AccessTimeIcon style={{ marginRight: '4px', fontSize: '1rem' }} />
          {props.retentionPeriodDays >= 100000
            ? 'Assets are retained indefinitely'
            : `Assets are retained for ${props.retentionPeriodDays} days`}
        </Typography>
      </div>
    );
  };

  const handleDownload = async (video) => {
    const downloadUrl = `${video.BlobPath}?${video.BlobSAS}`;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = video.FileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    await logUserInteraction(
      'download',
      {
        videoId: video.id,
        fileName: video.FileName,
        deviceId: video.deviceId,
        accountId: video.AccountId,
        blobPath: video.BlobPath,
        blobSAS: video.BlobSAS,
        duration: video.Duration,
        timeUploaded: video.TimeUploaded,
        timeRecorded: video.TimeRecorded,
        userId: video.userId,
        transcriptVTTPath: video.transcriptUrl,
        assignedTo: video.AssignedTo,
      },
      props.userId,
      props.userName
    );
    trackDownloadArchivedVideo(video.id);
  };

  useEffect(() => {
    trackVideoManagementView(props.userId, props.userRole);
  }, [props.userId, props.userRole]);

  const getHeaderTitle = () => {
    if (viewArchived) {
      return 'Archived Media';
    }

    if (contentFilter === 'videos' || contentFilter === 'images') {
      return (
        <div className="breadcrumb-container">
          <span className="breadcrumb-root" onClick={() => handleContentFilterChange(null, 'all')}>
            Asset Library
          </span>
          <span className="breadcrumb-separator">/</span>
          <span className="breadcrumb-current">{contentFilter === 'videos' ? 'Videos' : 'Images'}</span>
        </div>
      );
    } else {
      return 'Asset Library';
    }
  };

  const headerTitle = getHeaderTitle();

  // Create a filtered version of columns based on the content filter
  const getFilteredColumns = useCallback(
    (baseColumns) => {
      // If we're in a specific content type tab, exclude the contentType column
      if (contentFilter === 'videos' || contentFilter === 'images') {
        return baseColumns.filter((column) => column.field !== 'contentType');
      }
      return baseColumns;
    },
    [contentFilter]
  );

  // Update the columns definition to use our custom renderer
  const enhancedColumns = getColumnsWithCustomRenderer(getFilteredColumns(columns));
  const enhancedArchivedColumns = getColumnsWithCustomRenderer(getFilteredColumns(archivedColumns));

  return (
    <div className="app-container">
      {isLoading && (
        <div className="loading-spinner-overlay">
          <div className="loading-spinner">
            <i className="fas fa-spinner spinner"></i>
          </div>
        </div>
      )}
      <div className="main-content">
        <div className="controls-container-simplified">
          <div className="controls-header">
            <h1 className="video-management-title">{headerTitle}</h1>
            <div className="search-section">
              <div className="search-bar-container-video">
                <div className="search-input-wrapper">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch(searchQuery, dateRange[0], dateRange[1]);
                      }
                    }}
                    placeholder={`Search`}
                    className="search-input search-input-rounded"
                  />
                </div>

                <ToggleButtonGroup
                  value={searchType}
                  exclusive
                  onChange={(e, newValue) => setSearchType(newValue)}
                  aria-label="search type"
                  size="small"
                  className="search-type-toggle"
                >
                  <ToggleButton value="video" aria-label="video search">
                    <strong>Video</strong>
                  </ToggleButton>
                  <ToggleButton value="audio" aria-label="audio search">
                    <strong>Audio</strong>
                  </ToggleButton>
                </ToggleButtonGroup>

                <Button
                  variant="contained"
                  onClick={() => handleSearch(searchQuery, dateRange[0], dateRange[1])}
                  className="filter-button"
                >
                  Search
                </Button>
                <Button variant="outlined" onClick={resetFilters} className="reset-button">
                  Reset
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="video-filters-sidebar">
            <div className="sidebar-section">
              <h3 className="sidebar-section-title">Media Type</h3>
              <div className="content-type-filters">
                <div
                  className={`content-filter-option ${contentFilter === 'all' ? 'active' : ''}`}
                  onClick={() => handleContentFilterChange(null, 'all')}
                >
                  <AllInboxIcon />
                  <span>All Assets</span>
                </div>
                <div
                  className={`content-filter-option ${contentFilter === 'videos' ? 'active' : ''}`}
                  onClick={() => handleContentFilterChange(null, 'videos')}
                >
                  <VideoLibraryIcon />
                  <span>Videos Only</span>
                </div>
                <div
                  className={`content-filter-option ${contentFilter === 'images' ? 'active' : ''}`}
                  onClick={() => handleContentFilterChange(null, 'images')}
                >
                  <PhotoLibraryIcon />
                  <span>Images Only</span>
                </div>
              </div>
            </div>

            <div className="sidebar-section">
              <h3 className="sidebar-section-title">Date Filter</h3>
              <div className="sidebar-date-picker">
                <DatePicker
                  selected={dateRange[0]}
                  onChange={handleDateChange}
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  selectsRange
                  customInput={<CustomInput onClear={handleClearDate} />}
                  className="date-range-filter-sidebar"
                />
              </div>
            </div>

            <div className="sidebar-section">
              <h3 className="sidebar-section-title">User Filter</h3>
              <FormControl variant="outlined" fullWidth>
                <Select value={ownerFilter} onChange={handleOwnerFilterChange} displayEmpty className="owner-filter">
                  <MenuItem value="">All Users</MenuItem>
                  {uniqueOwners.map((owner) => (
                    <MenuItem key={owner} value={owner}>
                      {owner}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="sidebar-section">
              <Button
                variant="outlined"
                onClick={resetFilters}
                fullWidth
                startIcon={<RefreshIcon />}
                className="reset-filters-button"
              >
                Reset Filters
              </Button>
            </div>

            {props.userRole !== 'guard' && (
              <div className="sidebar-section">
                <h3 className="sidebar-section-title">Archive</h3>
                <Button
                  variant="outlined"
                  onClick={handleArchive}
                  className={`archived-toggle-button ${viewArchived ? 'active' : ''}`}
                  startIcon={viewArchived ? <InboxIcon /> : <InventoryIcon />}
                  fullWidth
                >
                  {viewArchived ? 'Show All Assets' : 'Show Archived'}
                </Button>
              </div>
            )}
          </div>
          <div className="video-content-area">
            <div className="video-management-content-container">
              {viewArchived ? (
                <Box
                  className="archived-videos-container"
                  sx={{
                    height: '100%',
                    width: '50%',
                    minWidth: 500,
                    overflow: 'auto',
                    resize: 'horizontal',
                  }}
                >
                  {filteredArchivedVideos.length > 0 ? (
                    <DataGrid
                      ref={dataGridRef}
                      rows={filteredArchivedVideos}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            FileName: false,
                            date: true,
                            time: true,
                          },
                        },
                      }}
                      columns={enhancedArchivedColumns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      onRowClick={(params) => selectVideo(params.row)}
                      checkboxSelection={false}
                      disableMultipleSelection={true}
                      selectionModel={selectedRowId ? [selectedRowId] : []}
                      keepNonExistentRowsSelected={true}
                      slots={{
                        toolbar: CustomToolbar,
                      }}
                      slotProps={{
                        columnsPanel: {
                          disableSearch: true,
                        },
                        toolbar: {
                          showQuickFilter: false,
                          quickFilterProps: { debounceMs: 500 },
                          filterToolbarProps: {
                            showFilterPanel: false,
                            showColumnsSelector: false,
                          },
                          csvOptions: { disableToolbarButton: true },
                          printOptions: { disableToolbarButton: true },
                        },
                      }}
                      componentsProps={{
                        columnsPanel: {
                          disableSearch: true,
                        },
                      }}
                      sx={{
                        height: '100%',
                        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                          outline: 'none',
                          width: '10%',
                          cursor: 'pointer',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        },
                        '& .MuiDataGrid-row.Mui-selected': {
                          backgroundColor: '#ff3c52 !important',
                          color: 'white !important',
                          fontWeight: 'bold !important',
                          borderLeft: '6px solid #ff7a85 !important',
                          borderRight: '6px solid #ff7a85 !important',
                          boxShadow: '0 0 8px rgba(255, 60, 82, 0.5) !important',
                          transform: 'scale(1.01) !important',
                          zIndex: 999,
                          transition: 'all 0.1s ease-in-out !important',
                          '&:hover': {
                            backgroundColor: '#ff3c52 !important',
                          },
                          '& .MuiDataGrid-cell': {
                            color: 'white !important',
                            fontWeight: 'bold !important',
                            textShadow: '0 0 2px rgba(0, 0, 0, 0.5) !important',
                          },
                        },
                      }}
                      className="video-datagrid"
                    />
                  ) : (
                    <div className="filtered-empty-state">
                      {contentFilter === 'videos' ? (
                        <>
                          <VideoLibraryIcon />
                          <h3>No Videos Available</h3>
                          <p>There are no videos matching your current filters.</p>
                        </>
                      ) : contentFilter === 'images' ? (
                        <>
                          <PhotoLibraryIcon />
                          <h3>No Images Available</h3>
                          <p>There are no images matching your current filters.</p>
                        </>
                      ) : (
                        <>
                          <AllInboxIcon />
                          <h3>No Assets Available</h3>
                          <p>There are no assets matching your current filters.</p>
                        </>
                      )}
                    </div>
                  )}
                </Box>
              ) : (
                <Box
                  className="resizable-container"
                  sx={{
                    height: '100%',
                    width: '50%',
                    minWidth: 500,
                    overflow: 'auto',
                    resize: 'horizontal',
                  }}
                >
                  {filteredVideos.length > 0 ? (
                    <DataGrid
                      ref={dataGridRef}
                      rows={filteredVideos}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            date: true,
                            time: true,
                            FileName: false,
                            timeToDeletion: true,
                          },
                        },
                      }}
                      columns={enhancedColumns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      onRowClick={(params) => selectVideo(params.row)}
                      checkboxSelection={false}
                      disableMultipleSelection={true}
                      selectionModel={selectedRowId ? [selectedRowId] : []}
                      keepNonExistentRowsSelected={true}
                      slots={{
                        toolbar: CustomToolbar,
                      }}
                      slotProps={{
                        columnsPanel: {
                          disableSearch: true,
                        },
                        toolbar: {
                          showQuickFilter: false,
                          quickFilterProps: { debounceMs: 500 },
                          filterToolbarProps: {
                            showFilterPanel: false,
                            showColumnsSelector: false,
                          },
                          csvOptions: { disableToolbarButton: true },
                          printOptions: { disableToolbarButton: true },
                        },
                      }}
                      componentsProps={{
                        columnsPanel: {
                          disableSearch: true,
                        },
                      }}
                      getRowClassName={(params) => (isImage(params.row) ? 'image-row' : 'video-row')}
                      sx={{
                        height: '100%',
                        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                          outline: 'none',
                          width: '10%',
                          cursor: 'pointer',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        },
                        '& .MuiDataGrid-row.Mui-selected': {
                          backgroundColor: '#ff3c52 !important',
                          color: 'white !important',
                          fontWeight: 'bold !important',
                          borderLeft: '6px solid #ff7a85 !important',
                          borderRight: '6px solid #ff7a85 !important',
                          boxShadow: '0 0 8px rgba(255, 60, 82, 0.5) !important',
                          transform: 'scale(1.01) !important',
                          zIndex: 999,
                          transition: 'all 0.1s ease-in-out !important',
                          '&:hover': {
                            backgroundColor: '#ff3c52 !important',
                          },
                          '& .MuiDataGrid-cell': {
                            color: 'white !important',
                            fontWeight: 'bold !important',
                            textShadow: '0 0 2px rgba(0, 0, 0, 0.5) !important',
                          },
                        },
                      }}
                      className="video-datagrid"
                    />
                  ) : (
                    <div className="filtered-empty-state">
                      {contentFilter === 'videos' ? (
                        <>
                          <VideoLibraryIcon />
                          <h3>No Videos Available</h3>
                          <p>There are no videos matching your current filters.</p>
                        </>
                      ) : contentFilter === 'images' ? (
                        <>
                          <PhotoLibraryIcon />
                          <h3>No Images Available</h3>
                          <p>There are no images matching your current filters.</p>
                        </>
                      ) : (
                        <>
                          <AllInboxIcon />
                          <h3>No Assets Available</h3>
                          <p>There are no assets matching your current filters.</p>
                        </>
                      )}
                    </div>
                  )}
                </Box>
              )}

              <div className="video-display">
                {selectedVideo ? (
                  <VideoDisplay
                    retentionPeriodDays={props.retentionPeriodDays}
                    selectedVideo={selectedVideo}
                    onVideoArchived={handleVideoArchived}
                    onVideoUnarchived={handleVideoUnarchived}
                    searchResults={searchResults}
                    initialTimestamp={initialTimestamp}
                    urlFileName={urlFileName}
                    videos={videos}
                    transcriptUrl={selectedVideo.transcriptUrl}
                    isArchived={viewArchived}
                    handleDownload={handleDownload}
                    videoEvents={videoEvents}
                    userId={props.userId}
                    userName={props.userName}
                    userRole={props.userRole}
                  />
                ) : filteredVideos.length > 0 || filteredArchivedVideos.length > 0 ? (
                  <div className="default-message">
                    <i className="fas fa-hand-pointer fa-5x"></i>
                    <p>Select a video on the left to view details</p>
                  </div>
                ) : (
                  <div className="default-message">
                    <i className="fas fa-search fa-5x"></i>
                    <p>{isLoading ? 'Searching...' : 'No videos found. Try a different search.'}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal-backdrop">
          <div className="modal-content">
            <span className="modal-close" onClick={toggleModal}>
              &times;
            </span>
            <h3>Upload Video</h3>
            <input type="file" accept="video/*" className="modal-input" on />
            <button className="modal-save">Upload</button>
            <progress value={uploadProgress} max="100"></progress>
            <p>{uploadProgress}% uploaded</p>
          </div>
        </div>
      )}

      <Snackbar
        open={showArchiveSuccess}
        autoHideDuration={6000}
        onClose={handleCloseArchiveSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert onClose={handleCloseArchiveSuccess} severity="success" sx={{ width: '100%' }}>
          Content archived successfully!
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={showUnarchiveSuccess}
        autoHideDuration={6000}
        onClose={handleCloseUnarchiveSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert onClose={handleCloseUnarchiveSuccess} severity="success" sx={{ width: '100%' }}>
          Content unarchived successfully!
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default VideoManagement;
