import React, { useState, useEffect } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventDetail/ImageView.css';
import { fetchImageByFileName } from '../../../../../../../api/beaverApi';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const fetchImageWithRetry = async (fileName, retries = 0) => {
  try {
    return await fetchImageByFileName(fileName);
  } catch (err) {
    if (retries < MAX_RETRIES) {
      console.log(`Retrying fetch for image ${fileName}, attempt ${retries + 1}`);
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      return fetchImageWithRetry(fileName, retries + 1);
    }
    throw new Error(`Failed to fetch image data after ${MAX_RETRIES} attempts: ${err.message}`);
  }
};

const ImageView = React.memo(({ event, events, retentionPeriodDays, isMobile, assignedTo }) => {
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageCollection, setImageCollection] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        console.log('ImageView received event:', event);

        // Use the events array from the grouped event object, or fall back to single event
        const photosToFetch = event.events || [event];
        console.log('Photos to fetch:', photosToFetch);

        // Fetch data for all images in the group
        const imagesData = await Promise.all(
          photosToFetch.map(async (photoEvent) => {
            const data = await fetchImageWithRetry(photoEvent.fileName);
            return {
              ...data,
              fileName: photoEvent.fileName,
              assignedTo: assignedTo,
            };
          })
        );
        console.log('Fetched images data:', imagesData);

        setImageCollection(imagesData);

        // Find the current image index based on the fileName in the parent event
        const currentFileName = event.fileName;
        console.log('Current fileName:', currentFileName);

        const currentIndex = photosToFetch.findIndex((photo) => photo.fileName === currentFileName);
        console.log('Found currentIndex:', currentIndex);

        setCurrentImageIndex(currentIndex >= 0 ? currentIndex : 0);
        setImageData(imagesData[currentIndex >= 0 ? currentIndex : 0]);
      } catch (err) {
        console.error('Error in fetchData:', err);
        setError('Failed to fetch image data: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [event, assignedTo]);

  const checkRetentionPeriod = (timestamp) => {
    if (!retentionPeriodDays || !timestamp) return true;
    const recordingDate = new Date(timestamp);
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - recordingDate.getTime();
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff <= retentionPeriodDays;
  };

  const handlePrevImage = () => {
    console.log('handlePrevImage called, currentIndex:', currentImageIndex);
    if (currentImageIndex > 0) {
      const newIndex = currentImageIndex - 1;
      console.log('Setting new index to:', newIndex);
      setCurrentImageIndex(newIndex);
      setImageData(imageCollection[newIndex]);
    }
  };

  const handleNextImage = () => {
    console.log('handleNextImage called, currentIndex:', currentImageIndex);
    console.log('imageCollection length:', imageCollection.length);
    if (currentImageIndex < imageCollection.length - 1) {
      const newIndex = currentImageIndex + 1;
      console.log('Setting new index to:', newIndex);
      setCurrentImageIndex(newIndex);
      setImageData(imageCollection[newIndex]);
    }
  };

  const renderImageContent = () => {
    if (loading) {
      return (
        <div className="image-placeholder">
          <AccessTimeIcon className="placeholder-icon" />
          <p>Loading image...</p>
        </div>
      );
    }

    if (error) {
      return (
        <div className="image-placeholder">
          <ErrorOutlineIcon className="placeholder-icon" />
          <p>{error}</p>
        </div>
      );
    }

    if (imageData && imageData.BlobPath && !imageData.isPermanentlyDeleted) {
      const showNavigation = imageCollection.length > 1;

      return (
        <div className="image-view-container">
          {showNavigation && (
            <button className="nav-button prev" onClick={handlePrevImage} disabled={currentImageIndex === 0}>
              <NavigateBeforeIcon />
            </button>
          )}

          <img src={`${imageData.BlobPath}?${imageData.BlobSAS}`} alt="Event capture" className="event-image" />

          {showNavigation && (
            <button
              className="nav-button next"
              onClick={handleNextImage}
              disabled={currentImageIndex === imageCollection.length - 1}
            >
              <NavigateNextIcon />
            </button>
          )}

          {showNavigation && (
            <div className="image-counter">
              {currentImageIndex + 1} / {imageCollection.length}
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="image-placeholder">
        <ErrorOutlineIcon className="placeholder-icon" />
        <p>Image currently unavailable</p>
      </div>
    );
  };

  if (isMobile) {
    return renderImageContent();
  }

  return (
    <div className={`event-detail-image-view-container ${isMobile ? 'mobile' : ''}`}>
      <div className="event-detail-image-container">{renderImageContent()}</div>
    </div>
  );
});

export default ImageView;
