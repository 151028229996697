export const DEVICE_FEEDBACK_INFO = `
  <strong>Balanced Mode:</strong> Standard device operation with audio feedback.<br/>
  <strong>Stealth Mode:</strong> Quiet operation with minimal visual and audio cues.<br/>
  <strong>Deterrent Mode:</strong> Enhanced audio and visual cues for maximum visibility.
`;

export const RECORDING_ACTIVATION_INFO = `
  <strong>Shift Based:</strong> Recording automatically starts/stops based on shift schedule.<br/>
  <strong>Flexible Control:</strong> Recording can be triggered manually or by keywords.
`;
