import SectionsTab from './SectionsTab';
import '../../../styles/Settings/SettingsControls/SettingsControls.css';

const SettingsControls = ({ selectedSection, setSelectedSection, featureMode }) => {
  return (
    <div className="settings-controls-container">
      <div className="settings-controls-left">
        <SectionsTab
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          featureMode={featureMode}
        />
      </div>
    </div>
  );
};

export default SettingsControls;
