import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { cosmosClient } from '../../../../utils/eventstorage';
import '../../../../styles/Settings/Section/TriggerPhrases/TriggerPhrases.css';
import { useDeviceFilter } from '../../../../contexts/DeviceFilterContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalkieTalkie } from '@fortawesome/free-solid-svg-icons';

const TriggerPhrases = ({ userId, isGuard, isOverwatch, userRole, parentOrgUserId }) => {
  const { selectedOrg } = useDeviceFilter();
  const [triggerPhrases, setTriggerPhrases] = useState([]);
  const [stopPhrase, setStopPhrase] = useState('');
  const [isAddingPhrase, setIsAddingPhrase] = useState(false);
  const [newPhrase, setNewPhrase] = useState('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [phraseToDelete, setPhraseToDelete] = useState(null);
  const [editingStopPhrase, setEditingStopPhrase] = useState(false);
  const [editingStopPhraseValue, setEditingStopPhraseValue] = useState('');
  const [editingPhraseIndex, setEditingPhraseIndex] = useState(null);
  const [editingPhraseValue, setEditingPhraseValue] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Get effective user ID helper function
  const getEffectiveUserId = () => {
    if (isOverwatch) {
      return selectedOrg;
    }
    if (userRole === 'ASP') {
      return parentOrgUserId;
    }
    return userId;
  };

  useEffect(() => {
    fetchPhrases();
  }, []);

  const fetchPhrases = async () => {
    try {
      const effectiveUserId = getEffectiveUserId();
      const container = cosmosClient.database('Organizations').container('Organizations');

      const { resources: orgs } = await container.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length > 0) {
        const org = orgs[0];
        setTriggerPhrases(org.activationKeyword ? org.activationKeyword.split(',') : []);
        setStopPhrase(org.stopPhrase || 'Stop Recording');
      }
    } catch (error) {
      console.error('Error fetching phrases:', error);
    }
  };

  const updateDeviceKeywords = async (activationKeywords) => {
    const devicesContainer = cosmosClient.database('Devices').container('Devices');
    const orgContainer = cosmosClient.database('Organizations').container('Organizations');

    try {
      // Fetch devices
      const effectiveUserId = getEffectiveUserId();
      const { resources: devices } = await devicesContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.userId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      // Update devices
      const updateDevicePromises = devices.map(async (device) => {
        const updatedDevice = {
          ...device,
          activationKeyword: activationKeywords || undefined,
        };

        await devicesContainer.item(device.id, device.deviceId).replace(updatedDevice);
      });

      await Promise.all(updateDevicePromises);
    } catch (error) {
      console.error('Failed to update device keywords:', error);
      throw error;
    }
  };

  const handleAddPhrase = async () => {
    if (!newPhrase.trim()) return;

    try {
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');

      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];
      const updatedPhrases = [...triggerPhrases, newPhrase];
      const activationKeywords = updatedPhrases
        .map((phrase) => phrase.trim())
        .filter(Boolean)
        .join(',');

      const updatedOrg = {
        ...org,
        activationKeyword: activationKeywords || undefined,
      };

      await orgContainer.item(org.id, org.orgName).replace(updatedOrg);

      // Update devices with the new activation keywords
      await updateDeviceKeywords(activationKeywords);

      // Update local state
      setTriggerPhrases(updatedPhrases);
      setNewPhrase('');
      setIsAddingPhrase(false);

      // Show success message
      setSnackbarMessage('Trigger phrase added successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to add trigger phrase:', error);
      setSnackbarMessage('Failed to add trigger phrase');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeletePhrase = async (phraseToDelete) => {
    try {
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');

      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];
      const updatedPhrases = triggerPhrases.filter((phrase) => phrase !== phraseToDelete);
      const activationKeywords = updatedPhrases
        .map((phrase) => phrase.trim())
        .filter(Boolean)
        .join(',');

      const updatedOrg = {
        ...org,
        activationKeyword: activationKeywords || undefined,
      };

      await orgContainer.item(org.id, org.orgName).replace(updatedOrg);

      // Update devices with the new activation keywords
      await updateDeviceKeywords(activationKeywords);

      // Update local state
      setTriggerPhrases(updatedPhrases);

      // Show success message
      setSnackbarMessage('Trigger phrase deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to delete trigger phrase:', error);
      setSnackbarMessage('Failed to delete trigger phrase');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSaveStopPhrase = async () => {
    try {
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');
      const devicesContainer = cosmosClient.database('Devices').container('Devices');

      // Update organization
      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];
      const updatedOrg = {
        ...org,
        stopPhrase: editingStopPhraseValue,
      };

      await orgContainer.item(org.id, org.orgName).replace(updatedOrg);

      // Update all devices with new stop phrase
      const { resources: devices } = await devicesContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.userId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      const updateDevicePromises = devices.map(async (device) => {
        const updatedDevice = {
          ...device,
          stopPhrase: editingStopPhraseValue,
        };
        await devicesContainer.item(device.id, device.deviceId).replace(updatedDevice);

        // Trigger device config update
        await fetch('https://utils.plix.ai/v1/updateconfig', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            deviceId: device.deviceId,
            updateType: 'fetch and update',
          }),
        });
      });

      await Promise.all(updateDevicePromises);

      // Update local state
      setStopPhrase(editingStopPhraseValue);
      setEditingStopPhrase(false);
      setEditingStopPhraseValue('');

      setSnackbarMessage('Stop phrase saved successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to save stop phrase:', error);
      setSnackbarMessage('Failed to save stop phrase');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleStartEditStopPhrase = () => {
    setEditingStopPhrase(true);
    setEditingStopPhraseValue(stopPhrase);
  };

  const handleCancelEditStopPhrase = () => {
    setEditingStopPhrase(false);
    setEditingStopPhraseValue('');
  };

  const validateField = (field, value) => {
    switch (field) {
      case 'stopPhrase':
        if (!value || !value.trim()) return 'Stop phrase is required';
        if (value.trim().length < 4) return 'Stop phrase must be at least 4 characters long';
        if (value.trim().split(' ').length > 3 || value.length > 50) return 'Must be at most 3 words and 50 characters';
        if (/[^\w\s]/.test(value)) return 'Punctuation is not allowed in the stop phrase';
        // Check if any trigger phrase contains the stop phrase
        if (
          triggerPhrases.some(
            (phrase) =>
              phrase.toLowerCase().includes(value.toLowerCase()) || value.toLowerCase().includes(phrase.toLowerCase())
          )
        ) {
          return 'Stop phrase cannot be contained in any trigger phrase';
        }
        break;
      default:
        break;
    }
    return '';
  };

  const handleEditPhrase = async (index) => {
    try {
      const effectiveUserId = getEffectiveUserId();
      const container = cosmosClient.database('Organizations').container('Organizations');

      const { resources: orgs } = await container.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length > 0) {
        const org = orgs[0];
        const updatedPhrases = [...triggerPhrases];
        updatedPhrases[index] = editingPhraseValue;

        await container.item(org.id, org.orgName).replace({
          ...org,
          activationKeyword: updatedPhrases.join(','),
        });

        setTriggerPhrases(updatedPhrases);
        setEditingPhraseIndex(null);
        setEditingPhraseValue('');
      }
    } catch (error) {
      console.error('Error editing trigger phrase:', error);
    }
  };

  return (
    <div className="trigger-phrases-container">
      <div className="trigger-phrases-section">
        <div className="trigger-phrases-header">
          <h2>Trigger Phrases</h2>
          {!isGuard && !isAddingPhrase && (
            <button className="add-account-btn" onClick={() => setIsAddingPhrase(true)}>
              Add Phrase
            </button>
          )}
        </div>
        <div className="personnel-info">
          <FontAwesomeIcon icon={faWalkieTalkie} className="personnel-icon" />
          <span>Trigger an escalation and start recording when a user says a particular phrase.</span>
        </div>
        {/* <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
          <span className="trigger-phrases-description">
            Trigger an escalation and start recording when a user says any of these phrases.
          </span>
        </Typography> */}

        <List>
          {isAddingPhrase && (
            <ListItem
              sx={{
                bgcolor: 'background.paper',
                mb: 1,
                borderRadius: 1,
                boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
                border: '1px solid rgba(0,0,0,0.12)',
              }}
            >
              <TextField
                autoFocus
                fullWidth
                value={newPhrase}
                onChange={(e) => setNewPhrase(e.target.value)}
                placeholder="Enter new trigger phrase"
                size="small"
              />
              <Box sx={{ ml: 1, display: 'flex', gap: 1 }}>
                <IconButton onClick={handleAddPhrase} color="success" size="small">
                  <CheckIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setIsAddingPhrase(false);
                    setNewPhrase('');
                  }}
                  color="error"
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </ListItem>
          )}

          {triggerPhrases.map((phrase, index) => (
            <ListItem
              key={index}
              sx={{
                bgcolor: 'background.paper',
                mb: 1,
                borderRadius: 1,
                boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
                border: '1px solid rgba(0,0,0,0.12)',
              }}
            >
              {editingPhraseIndex === index ? (
                <>
                  <TextField
                    autoFocus
                    fullWidth
                    value={editingPhraseValue}
                    onChange={(e) => setEditingPhraseValue(e.target.value)}
                    size="small"
                  />
                  <Box sx={{ ml: 1, display: 'flex', gap: 1 }}>
                    <IconButton onClick={() => handleEditPhrase(index)} color="success" size="small">
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setEditingPhraseIndex(null);
                        setEditingPhraseValue('');
                      }}
                      color="error"
                      size="small"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </>
              ) : (
                <>
                  <ListItemText
                    primary={phrase}
                    onClick={() => {
                      if (!isGuard) {
                        setEditingPhraseIndex(index);
                        setEditingPhraseValue(phrase);
                      }
                    }}
                    sx={{ cursor: isGuard ? 'default' : 'pointer' }}
                  />
                  {!isGuard && (
                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={() => handleDeletePhrase(phrase)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </>
              )}
            </ListItem>
          ))}
        </List>
      </div>

      <div className="stop-phrase-section">
        <h3>Stop Phrase</h3>
        <Typography variant="body2" sx={{ mb: 2 }}>
          <span className="stop-phrase-description">
            Say this phrase to stop a recording that was started with a trigger phrase.
          </span>
        </Typography>

        {editingStopPhrase ? (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <TextField
              fullWidth
              value={editingStopPhraseValue}
              onChange={(e) => setEditingStopPhraseValue(e.target.value)}
              size="small"
              className="stop-phrase-input"
            />
            <IconButton onClick={handleSaveStopPhrase} color="success" size="small">
              <CheckIcon />
            </IconButton>
            <IconButton onClick={handleCancelEditStopPhrase} color="error" size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <ListItem
            sx={{
              bgcolor: 'background.paper',
              borderRadius: 1,
              boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
              border: '1px solid rgba(0,0,0,0.12)',
            }}
            onClick={handleStartEditStopPhrase}
          >
            <ListItemText primary={stopPhrase} />
          </ListItem>
        )}
      </div>
    </div>
  );
};

export default TriggerPhrases;
