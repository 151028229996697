import React, { useState } from 'react';
import '../styles/PlatformHelp.css';

const PlatformHelp = () => {
  const [selectedSection, setSelectedSection] = useState('all-tabs');
  const [selectedItem, setSelectedItem] = useState(null);

  const sections = [
    {
      id: 'all-tabs',
      title: 'All Videos',
      icon: 'icon-map',
      content: [
        {
          title: 'Creating and Editing Geofences',
          description: 'Learn how to create and edit geofences in the Live View',
          videoUrl: 'https://embed.app.guidde.com/playbooks/skdcEEpouQetDnS9i2J2at',
        },
        {
          title: 'Review AI Generated Incident Reports',
          description: 'Learn how to review and manage AI-generated incident reports',
          videoUrl: 'https://embed.app.guidde.com/playbooks/9xWHmAy4dvm6J2oDDSsz7Z',
        },
        {
          title: 'Navigating Asset Library',
          description: 'Learn how to effectively navigate and manage the asset library',
          videoUrl: 'https://embed.app.guidde.com/playbooks/9opMbLmsTScJrbLvBDM5m4',
        },
        {
          title: 'Navigating Settings',
          description: 'Learn how to configure and customize platform settings',
          videoUrl: 'https://embed.app.guidde.com/playbooks/3vHeNswqXS7p9SeusXb3rb',
        },
      ],
    },
    {
      id: 'liveview',
      title: 'Live View',
      icon: 'icon-map',
      content: [
        {
          title: 'Creating and Editing Geofences',
          description: 'Learn how to create and edit geofences in the Live View',
          videoUrl: 'https://embed.app.guidde.com/playbooks/skdcEEpouQetDnS9i2J2at',
        },
        // {
        //   title: 'Real-time Tracking',
        //   description: 'Monitor guard movements and activities in real-time',
        // },
        // {
        //   title: 'Map Controls',
        //   description: 'Master the map controls and navigation features',
        // },
      ],
    },
    // {
    //   id: 'dashboard',
    //   title: 'Dashboard',
    //   content: [
    //     {
    //       title: 'Dashboard Overview',
    //       description: 'Understanding your dashboard metrics and data visualization',
    //     },
    //     {
    //       title: 'Performance Metrics',
    //       description: 'Track and analyze guard performance metrics',
    //     },
    //     {
    //       title: 'Custom Reports',
    //       description: 'Generate and customize dashboard reports',
    //     }
    //   ]
    // },
    {
      id: 'reports',
      title: 'Reports',
      icon: 'icon-mail',
      content: [
        {
          title: 'Review AI Generated Incident Reports',
          description: 'Learn how to review and manage AI-generated incident reports',
          videoUrl: 'https://embed.app.guidde.com/playbooks/9xWHmAy4dvm6J2oDDSsz7Z',
        },
        // {
        //   title: 'Report Generation',
        //   description: 'Create detailed reports for various security activities',
        // },
        // {
        //   title: 'Data Export',
        //   description: 'Export and share report data in different formats',
        // }
      ],
    },
    {
      id: 'assetmanagement',
      title: 'Asset Management',
      icon: 'icon-folder',
      content: [
        {
          title: 'Navigating Asset Library',
          description: 'Learn how to effectively navigate and manage the asset library',
          videoUrl: 'https://embed.app.guidde.com/playbooks/9opMbLmsTScJrbLvBDM5m4',
        },
        // {
        //   title: 'Asset Tracking',
        //   description: 'Track and monitor asset locations and status',
        // },
        // {
        //   title: 'Maintenance Records',
        //   description: 'Manage asset maintenance schedules and records',
        // }
      ],
    },
    {
      id: 'settings',
      title: 'Settings',
      icon: 'icon-settings',
      content: [
        {
          title: 'Navigating Settings',
          description: 'Learn how to configure and customize platform settings',
          videoUrl: 'https://embed.app.guidde.com/playbooks/3vHeNswqXS7p9SeusXb3rb',
        },
        // {
        //   title: 'User Management',
        //   description: 'Manage user accounts and permissions',
        // },
        // {
        //   title: 'System Configuration',
        //   description: 'Configure system-wide settings and preferences',
        // }
      ],
    },
  ];

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleClosePopup = () => {
    setSelectedItem(null);
  };

  return (
    <div className="help-container">
      <div className="help-header">
        <div className="help-header-left">
          <h1>Help</h1>
        </div>
      </div>

      <div className="help-body">
        <div className="help-sections">
          {sections.map((section) => (
            <button
              key={section.id}
              className={`help-section-button ${selectedSection === section.id ? 'active' : ''}`}
              onClick={() => setSelectedSection(section.id)}
            >
              <i className={section.icon}></i>
              {section.title}
            </button>
          ))}
        </div>

        <div className="help-content">
          {sections
            .find((section) => section.id === selectedSection)
            ?.content.map((item, index) => (
              <div key={index} className="help-item" onClick={() => handleItemClick(item)}>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                {item.videoUrl && (
                  <div className="help-video-preview">
                    <iframe
                      src={item.videoUrl}
                      title={item.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>

      {selectedItem && (
        <div className="help-popup-overlay" onClick={handleClosePopup}>
          <div className="help-popup" onClick={(e) => e.stopPropagation()}>
            <button className="help-popup-close" onClick={handleClosePopup}>
              ✕
            </button>
            <h2>{selectedItem.title}</h2>
            <p>{selectedItem.description}</p>
            {selectedItem.videoUrl && (
              <div className="help-video-expanded">
                <iframe
                  src={selectedItem.videoUrl}
                  title={selectedItem.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PlatformHelp;
