import React, { useState } from 'react';
import SettingsControls from './SettingsControls/SettingsControls';
import Section from './Section/Section';
import '../../styles/Settings/Settings.css';
import { useAuth0 } from '@auth0/auth0-react';
import { useDeviceFilter } from '../../contexts/DeviceFilterContext';

const Settings = ({ userId, featureMode, userRole, parentOrgUserId, organizationAccessControls }) => {
  const [selectedSection, setSelectedSection] = useState('devices');
  const { user } = useAuth0();

  // Now using the props passed from App.js
  const isGuard = userRole === 'guard';
  const isOverwatch = featureMode === 'dev' && user.name.startsWith('overwatch');

  return (
    <div className="settings-container">
      <div className="settings-header">
        <div className="settings-header-left">
          <h1>Settings</h1>
        </div>
      </div>
      <div className="settings-body">
        <SettingsControls
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          featureMode={featureMode}
        />
        <Section
          selectedSection={selectedSection}
          userId={userId}
          isGuard={isGuard}
          isOverwatch={isOverwatch}
          userRole={userRole}
          parentOrgUserId={parentOrgUserId}
          featureMode={featureMode}
          organizationAccessControls={organizationAccessControls}
        />
      </div>
    </div>
  );
};

export default Settings;
