import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../../styles/Settings/Section/SettingsDialog.css';

/**
 * A reusable dialog component for Settings sections with consistent styling
 *
 * @param {Object} props - Component props
 * @param {boolean} props.open - Whether the dialog is open
 * @param {function} props.onClose - Function to call when dialog is closed
 * @param {string} props.title - Dialog title
 * @param {React.ReactNode} props.children - Dialog content
 * @param {React.ReactNode} props.actions - Dialog action buttons
 * @param {string} props.maxWidth - Dialog max width (xs, sm, md, lg, xl)
 * @param {boolean} props.fullWidth - Whether dialog should take full width
 * @param {Object} props.dialogProps - Additional props to pass to MUI Dialog
 */
const SettingsDialog = ({
  open,
  onClose,
  title,
  children,
  actions,
  maxWidth = 'sm',
  fullWidth = true,
  dialogProps = {},
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      className="settings-dialog"
      {...dialogProps}
    >
      <DialogTitle className="settings-dialog-title">
        <Typography variant="h6">{title}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="settings-dialog-close-button"
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="settings-dialog-content">{children}</DialogContent>
      {actions && <DialogActions className="settings-dialog-actions">{actions}</DialogActions>}
    </Dialog>
  );
};

export default SettingsDialog;
