import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faCheck,
  faSave,
  faCopy,
  faFilePdf,
  faExclamationCircle,
  faUser,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { fetchAlertFromApi, updateReport } from '../api/beaverApi';
import Transcript from './Transcript';
import VideoView from './NewDashboard/ShiftReview/ShiftCardList/ShiftCard/ShiftExpanded/EventDetail/VideoView';
import MapView from './NewDashboard/ShiftReview/ShiftCardList/ShiftCard/ShiftExpanded/EventDetail/MapView';
import '../styles/Reports/ReportEditor.css';
import { formatDuration } from '../utils/timeUtils';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import HardBreak from '@tiptap/extension-hard-break';
import { Citation } from './Citation'; // <-- your custom extension above
import { Question } from './Question';
import VideoPlayer from './VideoPlayer';
const formatTimestamp = (timestamp) => {
  return new Date(timestamp).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
};

const ReportEditor = ({ report, userEmail, userId, isMobile, userRole, parentOrgUserId }) => {
  const [editedReport, setEditedReport] = useState(() => ({
    reportId: report?.reportId || undefined,
    authorEmail: userEmail,
    assignedTos: report?.assignedTos || [],
    incidentTime: report?.incidentTime || new Date().toISOString().slice(0, 16),
    narrative: report?.narrative || '',
    auditLog: report?.auditLog || [],
  }));
  const [narrativeText, setNarrativeText] = useState(editedReport.narrative);
  const [isNarrativeTextParsed, setIsNarrativeTextParsed] = useState(false);
  const [relevantShift, setRelevantShift] = useState(null);
  // const relevantShiftRef = useRef(null);
  // const incidentTimeRef = useRef(null);
  const [selectedEvents, setSelectedEvents] = useState([]);

  const [viewedVideo, setViewedVideo] = useState(null);
  const viewedVideoRef = useRef(null);
  const [showEvidenceDetail, setShowEvidenceDetail] = useState(false);
  const [alertData, setAlertData] = useState(null);
  const videoPlayerRef = useRef(null);
  // const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const playerStateRef = useRef({ currentTime: 0, isPlaying: false });
  const [markers, setMarkers] = useState([]);

  const [leftSegment, setLeftSegment] = useState('Narrative');
  const [rightSegment, setRightSegment] = useState('Video');
  const [locations, setLocations] = useState([]);
  const [focusLocation, setFocusLocation] = useState(null);
  const [expandedLocationGroups, setExpandedLocationGroups] = useState({});
  // const [isGeneratingNarrative, setIsGeneratingNarrative] = useState(false);

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [changedFields, setChangedFields] = useState({});

  const navigate = useNavigate();

  const [events, setEvents] = useState([]);

  const [toast, setToast] = useState({ show: false, message: '', type: 'success' });

  const [selectedNarrative, setSelectedNarrative] = useState(null);

  const [detailedNarrative, setDetailedNarrative] = useState('');
  const [conciseNarrative, setConciseNarrative] = useState('');

  const [isReviewed, setIsReviewed] = useState(report?.reviewed || false);

  const parseNarratives = useCallback((narrativeText) => {
    const detailedMatch = narrativeText.match(/<DETAILED_NARRATIVE>([\s\S]*?)<\/DETAILED_NARRATIVE>/);
    const conciseMatch = narrativeText.match(/<CONCISE_NARRATIVE>([\s\S]*?)<\/CONCISE_NARRATIVE>/);

    setDetailedNarrative(detailedMatch ? detailedMatch[1].trim() : '');
    setConciseNarrative(conciseMatch ? conciseMatch[1].trim() : '');
  }, []);

  useEffect(() => {
    if (report) {
      setEditedReport({
        reportId: report.reportId,
        authorEmail: userEmail,
        assignedTos: report.assignedTos || [],
        incidentTime: report.incidentTime,
        narrative: report.narrative || '',
        auditLog: report.auditLog || [],
        alertId: report.alertId,
      });

      if (report.narrative) {
        parseNarratives(report.narrative);
      }

      // Auto-select concise narrative if report is not reviewed
      if (!report.reviewed) {
        setSelectedNarrative('concise');
      }
    }
  }, [report, userEmail, parseNarratives]);

  const showToast = (message, type = 'success') => {
    setToast({ show: true, message, type });
    setTimeout(() => {
      setToast((prev) => ({ ...prev, show: false }));
    }, 3000);
  };

  useEffect(() => {
    if (report) {
      setEditedReport({
        reportId: report.reportId,
        authorEmail: userEmail,
        assignedTos: report.assignedTos || [],
        incidentTime: report.incidentTime,
        narrative: report.narrative || '',
        auditLog: report.auditLog || [],
        alertId: report.alertId,
      });
      setNarrativeText(report.narrative || '');
      setSelectedEvents(report.selectedEvents || []);
      if (report.selectedEvents?.length > 0) {
        setViewed(report.selectedEvents[0]);
      }
    }
  }, [report, userEmail]);

  useEffect(() => {
    if (report?.selectedEvents) {
      const eventLocations = report.selectedEvents
        .filter((event) => event.location)
        .map((event) => ({
          time: new Date(event.timestamp).toISOString(),
          latitude: event.location.latitude,
          longitude: event.location.longitude,
          address: event.location.address,
        }));
      setLocations(eventLocations);

      const mappedEvents = report.selectedEvents.map((event) => ({
        ...event,
        timestamp: new Date(event.timestamp).toISOString(),
      }));
      setEvents(mappedEvents);
    }
  }, [report]);

  const setViewed = (vid) => {
    if (vid) {
      if (vid.type === 'escalation' && vid.alertId) {
        fetchAlertFromApi(vid.alertId)
          .then((data) => {
            setAlertData(data);
          })
          .catch((err) => {
            console.error('Failed to fetch alert data:', err);
          });
      } else if (vid.type === 'recordingVideo') {
        setAlertData(null);
      }
    }
    setViewedVideo(vid);
    console.log('viewedVideo', viewedVideo);
    // console.log('alertData', alertData);
    viewedVideoRef.current = vid;
  };

  useEffect(() => {
    if (viewedVideo && locations.length > 0) {
      const eventTime = new Date(viewedVideo.timestamp);
      const closestLocation = locations.reduce((prev, curr) => {
        const prevDiff = Math.abs(new Date(prev.time) - eventTime);
        const currDiff = Math.abs(new Date(curr.time) - eventTime);
        return currDiff < prevDiff ? curr : prev;
      });
      setFocusLocation(closestLocation);
    } else {
      setFocusLocation(null);
    }
  }, [viewedVideo, locations]);

  const handleEventClick = (event) => {
    setViewed(event);
    if (isMobile) {
      setShowEvidenceDetail(true);
    }
    if (event.type !== 'escalation' && event.type !== 'recordingVideo') {
      setRightSegment('Map');
    } else {
      setRightSegment('Video');
    }
  };

  const handleEventSelect = (event) => {
    const newSelectedEvents = selectedEvents.some((selectedEvent) => areEventsEqual(selectedEvent, event))
      ? selectedEvents.filter((e) => !areEventsEqual(e, event))
      : [...selectedEvents, event];
    setSelectedEvents(newSelectedEvents);
    setEditedReport((prev) => ({ ...prev, selectedEvents: newSelectedEvents }));
    setUnsavedChanges(true);
    setChangedFields((prev) => ({ ...prev, selectedEvents: true }));
  };

  const handlePlayerUpdate = useCallback(({ currentTime, isPlaying }) => {
    playerStateRef.current = { currentTime, isPlaying };
    // setCurrentVideoTime(currentTime);
  }, []);

  const handleTranscriptClick = useCallback((timestamp) => {
    if (videoPlayerRef.current && videoPlayerRef.current.plyr) {
      videoPlayerRef.current.plyr.currentTime = timestamp;
    }
  }, []);

  const getPlayerState = useCallback(() => playerStateRef.current, []);

  const areEventsEqual = (event1, event2) => {
    return event1.timestamp === event2.timestamp && event1.type === event2.type;
  };

  const renderEventCard = (event) => {
    if (event.type === 'locationGroup') {
      const isExpanded = expandedLocationGroups[event.startTime] || false;
      const toggleExpand = () => {
        setExpandedLocationGroups((prev) => ({
          ...prev,
          [event.startTime]: !isExpanded,
        }));
      };

      if (isExpanded) {
        return event.events.map(renderEventCard);
      }

      return (
        <div key={event.startTime} className="location-group-summary" onClick={toggleExpand}>
          <span className="location-count">{`${event.events.length} site${event.events.length > 1 ? 's' : ''} visited`}</span>
          {/* <span className="expand-prompt">Click to expand</span> */}
        </div>
      );
    }

    const startTime = new Date(event.timestamp);
    let endTime = null;
    const isVideoEvent = event.type === 'escalation' || event.type === 'recordingVideo';

    if (event.type === 'recordingVideo' && event.duration) {
      endTime = new Date(startTime.getTime() + parseInt(event.duration, 10));
    } else if (event.type === 'locationEnter' && event.exitTime) {
      endTime = new Date(event.exitTime);
    }

    let addressText = '';
    let fullAddress = '';
    if (event.location && event.location.address) {
      const addressParts = event.location.address.split(', ');
      addressText = addressParts.slice(0, 2).join(', ');
      fullAddress = event.location.address;
    } else if (event.geofenceId) {
      const geofenceParts = event.geofenceId.split(', ');
      addressText = geofenceParts.slice(0, 2).join(', ');
      fullAddress = event.geofenceId;
    }

    let eventTypeText = '';
    switch (event.type) {
      case 'start':
        eventTypeText = 'Shift Started';
        break;
      case 'end':
        eventTypeText = 'Shift Ended';
        break;
      case 'locationEnter':
        eventTypeText = '';
        break;
      case 'recordingVideo':
        eventTypeText = 'Recording';
        break;
      case 'escalation':
        if (event.alertId) {
          fetchAlertFromApi(event.alertId)
            .then((data) => {
              eventTypeText =
                data && data.CategorizationResult ? data.CategorizationResult[data.DeployedModelUsed] || '' : '';
            })
            .catch((err) => {
              console.error('Failed to fetch alert data:', err);
            });
        }
        break;
      default:
        eventTypeText = event.type;
    }

    const isSelected = selectedEvents.some((selectedEvent) => areEventsEqual(selectedEvent, event));

    return (
      <div
        key={event.id || event.timestamp}
        className={`video-card ${viewedVideo === event ? 'viewed' : ''} ${isVideoEvent ? 'video-event' : 'non-video-event'}`}
        onClick={() => handleEventClick(event)}
      >
        <div className="video-assigned-to">{editedReport.assignedTos[0]}</div>
        <div className="video-card-content">
          <div className="video-time-container">
            <div className="start-time-container">
              <span className="time-badge">
                {startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </span>
              <span className="online-time-decimal">{startTime.toLocaleDateString()}</span>
            </div>
            {endTime && (
              <>
                <div className="duration-container">
                  <span className="online-time-decimal">{formatDuration(endTime - startTime)}</span>
                  <hr className="duration-divider" />
                </div>
                <div className="end-time-container">
                  <span className="time-badge">
                    {endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  </span>
                  <span className="online-time-decimal">{endTime.toLocaleDateString()}</span>
                </div>
              </>
            )}
          </div>
          {!isMobile && (
            <div className="event-info-container">
              <div
                className="event-address-container"
                data-tooltip-id="address-tooltip"
                data-tooltip-content={fullAddress}
              >
                <span className="event-address">{addressText}</span>
                <span className="event-name">{eventTypeText}</span>
              </div>
            </div>
          )}
        </div>
        {isVideoEvent && (
          <div className="video-checkbox">
            <input
              type="checkbox"
              id={`video-checkbox-${event.id || event.timestamp}`}
              checked={isSelected}
              onChange={() => handleEventSelect(event)}
              onClick={(e) => e.stopPropagation()}
            />
            <label htmlFor={`video-checkbox-${event.id || event.timestamp}`}>
              <FontAwesomeIcon icon={faCheck} />
            </label>
          </div>
        )}
      </div>
    );
  };

  // Helper function to clean text content
  const cleanContent = (content) => {
    return (
      content
        // First convert HTML to text while preserving intentional line breaks
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '\n\n')
        .replace(/<br\s*\/?>/g, '\n')
        .replace(/<[^>]*>/g, '') // Remove any other HTML tags
        // Then clean up the markers without adding any spaces
        .replace(/\[CITE \d+(?:, \d+)*\]/g, '')
        .replace(/\[\d+:\d+\]/g, '')
        .replace(/\[QUESTION:.*?\]/g, '')
        // Clean up whitespace
        .replace(/\s+/g, ' ') // Normalize spaces
        .replace(/\n\s+/g, '\n') // Clean up spaces at start of lines
        .replace(/\s+\n/g, '\n') // Clean up spaces at end of lines
        .replace(/\n{3,}/g, '\n\n') // Replace multiple line breaks with double
        .trim()
    );
  };

  // Updated copy handler
  const handleCopy = () => {
    if (editor) {
      const content = editor.getHTML();
      const cleanedContent = cleanContent(content)
        .replace(/<[^>]*>/g, '\n') // Convert HTML tags to newlines
        .replace(/&nbsp;/g, ' ')
        .replace(/\n\s*\n/g, '\n')
        .trim();

      navigator.clipboard
        .writeText(cleanedContent)
        .then(() => {
          showToast('Copied to clipboard!', 'success');
        })
        .catch(() => {
          showToast('Failed to copy text', 'error');
        });
    }
  };

  const createAuditEntries = () => {
    const entries = [];

    if (!editedReport.reportId) {
      entries.push({
        action: 'Create',
        usingAI: false,
        timestamp: new Date().toISOString(),
        userName: userEmail,
      });
    }

    if (changedFields.narrative) {
      entries.push({
        action: 'EditNarrative',
        usingAI: changedFields.usingAI || false,
        timestamp: new Date().toISOString(),
        userName: userEmail,
      });
    }

    if (changedFields.selectedEvents) {
      entries.push({
        action: 'EditSelectedEvidence',
        usingAI: false,
        timestamp: new Date().toISOString(),
        userName: userEmail,
      });
    }

    return entries;
  };

  // Save

  const handleSaveReport = async () => {
    try {
      const newAuditEntries = createAuditEntries();
      const updatedReport = {
        ...editedReport,
        auditLog: [...(editedReport.auditLog || []), ...newAuditEntries],
      };

      const doc = editor.state.doc;
      const finalText = getPlainTextWithCitations(doc).trim();
      let effectiveUserId = userId;
      if (userRole === 'ASP' || userRole === 'guard') {
        effectiveUserId = parentOrgUserId;
      }

      // For mobile devices, trigger the share dialog
      if (isMobile) {
        try {
          await navigator.share({
            title: 'Incident Report',
            text: finalText,
            // You can add a URL if needed
            // url: window.location.href
          });
        } catch (err) {
          console.log('Share failed:', err);
          // Fall back to normal save if sharing fails
          const savedReport = await performSave(effectiveUserId, updatedReport, finalText);
          updateStateAfterSave(savedReport);
        }
      } else {
        const savedReport = await performSave(effectiveUserId, updatedReport, finalText);
        updateStateAfterSave(savedReport);
      }
    } catch (error) {
      console.error('Error saving report:', error);
      setSaveStatus('Error saving');
      setTimeout(() => setSaveStatus(''), 3000);
    }
  };

  const updateStateAfterSave = (savedReport) => {
    setIsReviewed(true);
    setNarrativeText(savedReport.narrative);
    setSelectedEvents(savedReport.selectedEvents || []);
    setUnsavedChanges(false);
    setChangedFields({});
    setSaveStatus('Saved!');
    setTimeout(() => setSaveStatus(''), 3000);

    // Update state while preserving authorEmail
    setEditedReport((prev) => ({
      ...savedReport,
      authorEmail: prev.authorEmail,
    }));
  };

  const performSave = async (effectiveUserId, updatedReport, finalText) => {
    const savedReport = await updateReport(
      effectiveUserId,
      {
        ...updatedReport,
        narrative: finalText,
        selectedEvents,
        reviewed: true,
        reviewedAt: new Date().toISOString(),
        reviewedBy: userEmail,
      },
      true
    );

    return savedReport;
  };

  const goToTimestamp = useCallback((timestamp) => {
    if (videoPlayerRef.current && videoPlayerRef.current.plyr) {
      videoPlayerRef.current.plyr.currentTime = timestamp;
    }
  }, []);

  // Citations

  const paragraphs = narrativeText.split('\n').map((line) => ({
    type: 'paragraph',
    content: [{ type: 'text', text: line }],
  }));

  // 1. Initialize Tiptap editor:
  const editor = useEditor({
    extensions: [
      StarterKit,
      HardBreak,
      Citation.configure({
        onCitationClick: goToTimestamp,
      }),
      Question,
    ],
    content: report?.isReviewed ? narrativeText : '',
    onUpdate: ({ editor }) => {
      const newContent = editor.getHTML();
      setNarrativeText(newContent);
      setEditedReport((prev) => ({ ...prev, narrative: newContent }));
      setUnsavedChanges(true);
      setChangedFields((prev) => ({ ...prev, narrative: true }));
    },
  });

  function getPlainTextWithCitations(doc) {
    let result = '';
    doc.descendants((node, pos, parent) => {
      if (node.isText) {
        // Normal text node
        result += node.text;
      } else if (node.type.name === 'citation') {
        // Citation node
        const timecodes = node.attrs.timecodes || [];
        result += `[CITE ${timecodes.join(', ')}]`;
      } else if (node.type.name === 'question') {
        result += `[QUESTION: ${node.attrs.q}]`;
      }

      // If this node is a paragraph and we're at the *end* of it, add a newline.
      // Or you could do something like a double newline if you prefer separate paragraphs.
      if (node.isBlock && node.type.name === 'paragraph') {
        result += '\n\n';
      }
    });
    return result;
  }

  // simple example that replaces `[CITE 156]` with <span data-citation> text
  function parseCitationsToHtml(plainText) {
    // Updated regex to match both [CITE 68] and [CITE 68, CITE 70] formats
    const citationRegex = /\[CITE\s+(\d+)(?:,\s*CITE\s+\d+)*\]/g;
    const questionRegex = /\[QUESTION:\s*(.*?)\]/g;

    const paragraphs = plainText.split(/\n\s*\n/g);

    const paragraphsAsHtml = paragraphs.map((par) => {
      const withCitations = par.replace(citationRegex, (match, firstTimecode) => {
        return `<span data-citation data-timecodes="${firstTimecode}">
        [CITE ${firstTimecode}]
      </span>`;
      });

      const withQuestionsAndCitations = withCitations.replace(questionRegex, (_, questionText) => {
        return `<span data-question data-q="${questionText}">
        Q: ${questionText}
      </span>`;
      });

      const withBreaks = withQuestionsAndCitations.replace(/\n/g, '<br>');

      return `<p>${withBreaks}</p>`;
    });

    return paragraphsAsHtml.join('');
  }

  useEffect(() => {
    if (editor && narrativeText && narrativeText.length > 0 && !isNarrativeTextParsed) {
      // Convert the raw text into HTML with paragraphs, <br>, and citation spans.
      const replacedHtml = parseCitationsToHtml(narrativeText);

      // Set Tiptap's content as HTML, which preserves paragraphs, line breaks, etc.
      editor.commands.setContent(replacedHtml);

      setIsNarrativeTextParsed(true);
    }
  }, [editor, narrativeText, isNarrativeTextParsed]);

  // Updated export function
  const exportToPdf = () => {
    if (editor) {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });

      doc.setFont('helvetica');

      // Format the lastUpdated date nicely
      const lastUpdatedDate = new Date(editedReport.lastUpdated || new Date());
      const formattedDate = lastUpdatedDate.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });

      // Add title
      doc.setFontSize(20);
      doc.text(`Incident Report`, 20, 30);

      // Add last updated date
      doc.setFontSize(14);
      doc.setTextColor(100);
      doc.text(`Last Updated: ${formattedDate}`, 20, 40);

      // Add author
      doc.setFontSize(12);
      doc.text(`Author: ${editedReport.authorEmail}`, 20, 50);

      // Reset text color
      doc.setTextColor(0);

      // Get content and clean it while preserving intentional line breaks
      const content = editor.getHTML();
      // console.log("html content", content);
      const cleanedContent = content
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '\n\n') // Convert paragraphs to double line breaks
        .replace(/<br\s*\/?>/g, '\n') // Convert <br> to single line breaks
        .replace(/<[^>]*>/g, '') // Remove any other HTML tags
        .replace(/\[CITE \d+(?:, \d+)*\]/g, '') // Remove citations
        .replace(/\[\d+:\d+\]/g, '') // Remove timestamps
        .replace(/\[QUESTION:.*?\]/g, '') // Remove questions
        .replace(/\s+\./g, '.') // Fix periods with extra spaces
        .replace(/\n\s+/g, '\n') // Clean up spaces at start of lines
        .replace(/\s+\n/g, '\n') // Clean up spaces at end of lines
        .replace(/\n{3,}/g, '\n\n') // Replace multiple line breaks with double
        .trim();

      // Add content with word wrap
      doc.setFontSize(12);
      const lineHeight = 7; // Adjust this value to control spacing between lines
      const pageWidth = doc.internal.pageSize.width;
      const margin = 20;
      const maxWidth = pageWidth - margin * 2;

      // Split content into paragraphs
      const paragraphs = cleanedContent.split('\n\n');
      let yPosition = 60;

      paragraphs.forEach((paragraph) => {
        // Split paragraph into lines that fit within page width
        const lines = doc.splitTextToSize(paragraph, maxWidth);

        // Check if we need to add a new page
        if (yPosition + lines.length * lineHeight > doc.internal.pageSize.height - margin) {
          doc.addPage();
          yPosition = margin;
        }

        // Add lines to PDF
        doc.text(lines, margin, yPosition);
        yPosition += lines.length * lineHeight + 5; // Add extra spacing between paragraphs
      });

      // Format filename - updated format
      const month = lastUpdatedDate.toLocaleString('en-US', { month: 'long' });
      const date = lastUpdatedDate.getDate();
      const year = lastUpdatedDate.getFullYear();
      const hours = lastUpdatedDate.getHours().toString().padStart(2, '0');
      const minutes = lastUpdatedDate.getMinutes().toString().padStart(2, '0');

      const filename = `plix-incident-report-${month}-${date}-${year}-${hours}${minutes}.pdf`;

      // Save PDF
      try {
        doc.save(filename);
        showToast('PDF exported successfully!', 'success');
      } catch (error) {
        console.error('PDF generation error:', error);
        showToast('Failed to export PDF', 'error');
      }
    }
  };

  const handleBack = () => {
    // Force a refresh of the reports list
    navigate('/reports', { state: { refresh: true } });
  };

  // Function to clean citations from display text
  const cleanCitations = (text) => {
    return text.replace(/\[CITE \d+\]/g, '');
  };

  const renderNarrativeSelector = () => {
    if (isReviewed || report?.reviewed) {
      return null;
    }

    return (
      <div className="narrative-selector">
        <div
          className={`narrative-option ${selectedNarrative === 'detailed' ? 'selected' : ''}`}
          onClick={() => setSelectedNarrative('detailed')}
        >
          <div className="narrative-option-header">
            <span className="narrative-option-title">Detailed</span>
            <span className="narrative-option-badge">Comprehensive</span>
          </div>
          <div className="narrative-option-content">{cleanCitations(detailedNarrative)}</div>
        </div>

        <div
          className={`narrative-option ${selectedNarrative === 'concise' ? 'selected' : ''}`}
          onClick={() => setSelectedNarrative('concise')}
        >
          <div className="narrative-option-header">
            <span className="narrative-option-title">Concise</span>
            <span className="narrative-option-badge">Bullet Points</span>
          </div>
          <div className="narrative-option-content">{cleanCitations(conciseNarrative)}</div>
        </div>
      </div>
    );
  };

  // Update the editor initialization effect
  useEffect(() => {
    if (editor && report) {
      if (report.reviewed) {
        // If report is reviewed, show the saved narrative
        const replacedHtml = parseCitationsToHtml(report.narrative || '');
        editor.commands.setContent(replacedHtml);
      } else if (!selectedNarrative) {
        // If not reviewed and no narrative selected, show empty editor
        editor.commands.setContent('');
      } else {
        // If not reviewed and a narrative is selected, show the selected version
        const content = selectedNarrative === 'detailed' ? detailedNarrative : conciseNarrative;
        const replacedHtml = parseCitationsToHtml(content);
        editor.commands.setContent(replacedHtml);
      }
    }
  }, [editor, report, selectedNarrative, detailedNarrative, conciseNarrative]);

  // Add an effect to set initial reviewed state
  useEffect(() => {
    if (report) {
      setIsReviewed(report.isReviewed || false);
    }
  }, [report]);

  // Memoize the video view component props
  const videoViewProps = useMemo(
    () => ({
      event: viewedVideo,
      alertData: alertData,
      videoPlayerRef: videoPlayerRef,
      onPlayerUpdate: handlePlayerUpdate,
      retentionPeriodDays: 30,
      markers: markers,
      isMobile: isMobile,
    }),
    [viewedVideo, alertData, markers, isMobile, handlePlayerUpdate]
  );

  return (
    <div className="report-editor-fullpage">
      <div className="report-editor-header">
        <div className="header-left">
          <button onClick={handleBack} className="back-button">
            <FontAwesomeIcon icon={faArrowLeft} />
            <span>Back to Reports</span>
          </button>
        </div>
        <div className="report-title-section">
          <h1 className="report-title">Report Editor</h1>
          <div className="report-metadata">
            <div className="report-metadata-item">
              <FontAwesomeIcon icon={faUser} />
              <span>{editedReport.authorEmail}</span>
            </div>
            <div className="report-metadata-item">
              <FontAwesomeIcon icon={faClock} />
              <span>{formatTimestamp(report.lastUpdated)}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="report-editor-content">
        <div className="left-panel">
          <div className="narrative-section">
            {renderNarrativeSelector()}
            <div className="editor-container">
              <div className="editor-header">
                <div className="editor-actions-left">
                  {unsavedChanges && <span className="unsaved-changes">Unsaved changes!</span>}
                  {saveStatus && <span className="save-status">{saveStatus}</span>}
                  <button onClick={handleSaveReport} className="editor-button save-button">
                    <FontAwesomeIcon icon={faSave} /> {isMobile ? 'Save Report' : 'Save Report'}
                  </button>
                </div>
                {!isMobile && (
                  <div className="editor-actions-right">
                    <button onClick={handleCopy} className="editor-button">
                      <FontAwesomeIcon icon={faCopy} /> Copy
                    </button>
                    <button onClick={exportToPdf} className="editor-button">
                      <FontAwesomeIcon icon={faFilePdf} /> Export PDF
                    </button>
                  </div>
                )}
              </div>
              <div className="narrative-editor">
                <EditorContent editor={editor} />
              </div>
            </div>
          </div>
        </div>

        <div className="right-panel">
          {/* Commenting out segmented control
          <div className="segmented-control">
            {viewedVideo && (viewedVideo.type === 'escalation' || viewedVideo.type === 'recordingVideo') && (
              <button
                className={`segment ${rightSegment === 'Video' ? 'active' : ''}`}
                onClick={() => setRightSegment('Video')}
              >
                Video
              </button>
            )}
            <button
              className={`segment ${rightSegment === 'Map' ? 'active' : ''}`}
              onClick={() => setRightSegment('Map')}
            >
              Map
            </button>
          </div>
          */}
          {viewedVideo && (viewedVideo.type === 'escalation' || viewedVideo.type === 'recordingVideo') && (
            <>
              <div className="report-editor-video-player-container">
                <VideoView {...videoViewProps} />
              </div>
              {/* <div className="video-player-wrapper">
                <div className="video-player-container">
                  <VideoPlayer
                    ref={videoPlayerRef}
                    videoFileName={alertData.VideoFileName}
                    markers={markers}
                    token={process.env.REACT_APP_BLOB_SAS_TOKEN}
                    onPlayerUpdate={handlePlayerUpdate}
                    transcriptUrl={
                      viewedVideo.type === 'recordingVideo'
                        ? `https://plixvideostorage.blob.core.windows.net/transcripts-vtt/${viewedVideo.fileName.replace('.mp4', '.vtt')}?${process.env.REACT_APP_BLOB_SAS_TOKEN_2}`
                        : null
                    }
                  />
                </div>
              </div> */}
              <div className="report-editor-transcript-container">
                <Transcript
                  transcriptUrl={
                    viewedVideo.type === 'recordingVideo'
                      ? `https://plixvideostorage.blob.core.windows.net/transcripts-vtt/${viewedVideo.fileName.replace('.mp4', '.vtt')}?${process.env.REACT_APP_BLOB_SAS_TOKEN_2}`
                      : null
                  }
                  alert={viewedVideo.type === 'escalation' ? alertData : null}
                  defaultShowTranscript={true}
                  onTranscriptClick={handleTranscriptClick}
                  inShiftExpanded={false}
                  showTimeline={true}
                  setMarkers={setMarkers}
                  getPlayerState={getPlayerState}
                />
              </div>
            </>
          )}
          {/* Commenting out map view
          {rightSegment === 'Map' && (
            <div className="map-container">
              <MapView
                locations={locations}
                size="large"
                event={viewedVideo}
                events={events}
                geofences={[]}
                focusLocation={focusLocation}
              />
            </div>
          )}
          */}
        </div>
      </div>
      <Tooltip id="address-tooltip" />
      {toast.show && (
        <div className={`toast-notification ${toast.type}`}>
          <FontAwesomeIcon icon={toast.type === 'success' ? faCheck : faExclamationCircle} className="icon" />
          <span className="message">{toast.message}</span>
        </div>
      )}
      {isMobile && (
        <button onClick={handleCopy} className="mobile-copy-button">
          <FontAwesomeIcon icon={faCopy} />
        </button>
      )}
    </div>
  );
};

export default ReportEditor;
