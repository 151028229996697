import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen, faPlus, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
// import { deleteGeofence } from '../utils/dashboardTimescaleQueries';
import {
  addGeofence,
  updateGeofence,
  deleteGeofence,
  fetchPolygonGeofencesForOrganization,
  deletePolygonGeofence,
  updatePolygonGeofence,
} from '../api/beaverApi';
import { stringToColor } from '../utils/utilsUI';
import AddressAutocomplete from './AddressAutocomplete';
import AddressAutocompleteWrapper from './AddressAutocompleteWrapper';
import { Modal, Box, Typography, TableSortLabel } from '@mui/material';
import GeofenceControls from './GeofenceControls';

import { getDistance, isPointInPolygon } from 'geolib';
import '../styles/SiteAssignment.css';

const DEFAULT_RADIUS = 150; // in meters

const formatLastShiftTime = (lastShiftTime, shiftStatus, timeInShift) => {
  if (shiftStatus) {
    const formattedTime = formatTimeInShift(timeInShift);
    return formattedTime ? `Now (${formattedTime})` : 'Now';
  }
  if (!lastShiftTime) return '';
  const date = new Date(lastShiftTime);
  const now = new Date();
  if (date > now) return 'Future date';
  if (date.toDateString() === now.toDateString()) {
    return `Today at ${format(date, 'h:mm a')}`;
  }
  if (date.toDateString() === new Date(now.getTime() - 86400000).toDateString()) {
    return `Yesterday at ${format(date, 'h:mm a')}`;
  }
  if (date.getFullYear() === now.getFullYear()) {
    return format(date, 'M/d h:mm a');
  }
  return format(date, 'M/d/yyyy h:mm a');
};

const formatTimeInShift = (timeInShift) => {
  if (timeInShift === null) return '';
  if (timeInShift < 1) return 'Just started';
  if (timeInShift < 60) return `${timeInShift}m`;

  const hours = Math.floor(timeInShift / 60);
  const remainingMinutes = timeInShift % 60;
  return `${hours}h ${remainingMinutes}m`;
};

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3;
  const φ1 = (lat1 * Math.PI) / 180;
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

const PersonnelPill = React.memo(({ name, deviceId, onClick }) => {
  const backgroundColor = stringToColor(name);

  return (
    <span
      style={{
        backgroundColor,
        color: '#000',
        padding: '2px 8px',
        borderRadius: '12px',
        margin: '2px 3px',
        display: 'inline-block',
        fontSize: '0.7rem',
        lineHeight: '1.4',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
      }}
      onClick={() => onClick(deviceId)}
    >
      {name}
    </span>
  );
});

const SiteAssignment = React.memo(
  ({
    geofences,
    devices,
    deviceShifts,
    onSiteClick,
    organizationId,
    onGeofenceUpdate,
    handleDeviceSelect,
    onPreviewGeofence,
    selectedGeofence,
    setSelectedGeofence,
    userRole,
    parentOrgUserId,
    polygonGeofences = [],
    setPolygonGeofences,
    editMode,
    setEditMode,
    activeGeofence,
    setActiveGeofence,
    originalGeofenceRef,
    storeOriginalGeofenceState,
    clearOriginalGeofenceState,
    getOriginalGeofenceState,
  }) => {
    const [localGeofences, setLocalGeofences] = useState(geofences);
    const [editedRow, setEditedRow] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedSite, setSelectedSite] = useState(null);
    const [previewGeofence, setPreviewGeofence] = useState(null);
    const [showGeofenceControls, setShowGeofenceControls] = useState(false);
    const [isGeofenceModified, setIsGeofenceModified] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('nickname');
    const [sortDirection, setSortDirection] = useState('asc');

    useEffect(() => {
      setLocalGeofences(geofences);
    }, [geofences]);

    useEffect(() => {
      if (selectedGeofence) {
        setActiveGeofence(selectedGeofence);
        setShowGeofenceControls(true);
      } else {
        // Clear all geofence control states when selectedGeofence becomes null
        setActiveGeofence(null);
        setShowGeofenceControls(false);
        setPreviewGeofence(null);
        setIsGeofenceModified(false);
        clearOriginalGeofenceState();
      }
    }, [selectedGeofence, clearOriginalGeofenceState]);

    const onEditModeToggle = useCallback(() => {
      setEditMode(!editMode);
    }, [editMode, setEditMode]);

    const handleDeleteGeofence = useCallback(
      async (id) => {
        try {
          console.log(`Deleting circle geofence with id: ${id}`);
          await deleteGeofence(id, organizationId);
          console.log(`Successfully deleted circle geofence with id: ${id}`);

          const updatedGeofences = localGeofences.filter((geofence) => geofence.id !== id);
          console.log(
            `Updated circle geofences (removed ${localGeofences.length - updatedGeofences.length})`,
            updatedGeofences
          );

          setLocalGeofences(updatedGeofences);
          onGeofenceUpdate(updatedGeofences);

          if (selectedGeofence?.id === id) {
            setSelectedGeofence(null);
            setActiveGeofence(null);
            setShowGeofenceControls(false);
          }
        } catch (error) {
          console.error('Failed to delete geofence:', error);
        }
      },
      [organizationId, localGeofences, selectedGeofence, onGeofenceUpdate]
    );

    const handleDeletePolygonGeofence = useCallback(
      async (id) => {
        try {
          console.log(`Deleting polygon geofence with id: ${id}`);
          await deletePolygonGeofence(id, organizationId);
          console.log(`Successfully deleted polygon geofence with id: ${id}`);

          // Filter out the deleted geofence by checking both id and geofence_id
          const updatedPolygonGeofences = polygonGeofences.filter((geofence) => {
            const geofenceId = geofence.id || geofence.geofence_id;
            return geofenceId !== id;
          });

          console.log(
            `Updated polygon geofences (removed ${polygonGeofences.length - updatedPolygonGeofences.length})`,
            updatedPolygonGeofences
          );

          setPolygonGeofences(updatedPolygonGeofences);

          // Clear selection if we just deleted the selected geofence
          if (selectedGeofence?.id === id) {
            setSelectedGeofence(null);
            setActiveGeofence(null);
            setShowGeofenceControls(false);
          }
        } catch (error) {
          console.error('Failed to delete polygon geofence:', error);
        }
      },
      [organizationId, polygonGeofences, setPolygonGeofences, selectedGeofence]
    );

    const handleUpdateGeofence = useCallback(
      async (newRow) => {
        const geofence = localGeofences.find((g) => g.id === newRow.id);
        if (!geofence) return newRow;
        if (userRole === 'ASP' && parentOrgUserId) {
          organizationId = parentOrgUserId;
        }
        try {
          const result = await updateGeofence(
            newRow.id,
            organizationId,
            newRow.nickname,
            [geofence.longitude, geofence.latitude],
            geofence.radius
          );
          if (result.success) {
            const updatedGeofences = localGeofences.map((g) =>
              g.id === newRow.id ? { ...g, nickname: newRow.nickname } : g
            );
            setLocalGeofences(updatedGeofences);
            onGeofenceUpdate(updatedGeofences);
            setEditedRow(newRow);
            return newRow;
          } else {
            console.error('Failed to update geofence:', result.error);
            return geofence;
          }
        } catch (error) {
          console.error('Error updating geofence:', error);
          return geofence;
        }
      },
      [organizationId, localGeofences]
    );

    const siteData = useMemo(() => {
      // Process traditional circular geofences
      const uniqueGeofences = localGeofences.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      const circularGeofencesData = uniqueGeofences.map((site) => {
        const nearbyDevices = devices.filter(
          (device) =>
            getDistance(
              { latitude: site.latitude, longitude: site.longitude },
              { latitude: device.latitude, longitude: device.longitude }
            ) <= site.radius
        );

        const activeDevices = nearbyDevices.filter((device) => deviceShifts[device.deviceId]?.shiftStatus === true);

        const activePersonnel = activeDevices
          .map((device) => ({
            name: device.assignedTo || device.deviceName,
            deviceId: device.deviceId,
          }))
          .filter((person) => Boolean(person.name));

        return {
          id: site.id,
          nickname: site.nickname || site.id,
          place: site.id,
          personnelInShift: activePersonnel,
          latitude: site.latitude,
          longitude: site.longitude,
          radius: site.radius,
          type: 'circle', // Add type to distinguish between circle and polygon
        };
      });

      // Process polygon geofences
      const polygonGeofencesData = polygonGeofences.map((geofence) => {
        // Calculate center point of polygon for display purposes
        let centerLat = 0;
        let centerLng = 0;
        let validPoints = 0;

        // Check if we have boundaryCoordinates in the correct format
        const boundaryPoints = geofence.boundaryCoordinates || [];

        if (boundaryPoints && boundaryPoints.length > 0) {
          try {
            // Check if boundaryCoordinates is an array of objects with lng and lat properties
            if (typeof boundaryPoints[0] === 'object' && 'lng' in boundaryPoints[0] && 'lat' in boundaryPoints[0]) {
              // Calculate the center as the average of all valid points
              boundaryPoints.forEach((point) => {
                if (!isNaN(point.lng) && !isNaN(point.lat)) {
                  centerLng += point.lng;
                  centerLat += point.lat;
                  validPoints++;
                }
              });
            }
            // Fallback for old format (array of arrays)
            else if (Array.isArray(boundaryPoints[0])) {
              boundaryPoints.forEach((point) => {
                if (!isNaN(point[0]) && !isNaN(point[1])) {
                  centerLng += point[0];
                  centerLat += point[1];
                  validPoints++;
                }
              });
            }

            if (validPoints > 0) {
              centerLat /= validPoints;
              centerLng /= validPoints;
            } else {
              console.warn('No valid coordinates found for geofence:', geofence.id);
              // Default to 0,0 if no valid points
              centerLat = 0;
              centerLng = 0;
            }
          } catch (error) {
            console.error('Error calculating centroid for geofence:', error, geofence);
            // Default to 0,0 on error
            centerLat = 0;
            centerLng = 0;
          }
        }

        // Use geofence.id if available, otherwise fall back to geofence.geofence_id
        const geofenceId =
          geofence.id || geofence.geofence_id || `polygon-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

        // Check for devices inside the polygon
        const devicesInsidePolygon = [];

        // Only process if we have valid boundary points
        if (boundaryPoints && boundaryPoints.length >= 3) {
          // Format polygon points for geolib
          const polygonPoints = [];

          // Process points based on format
          if (typeof boundaryPoints[0] === 'object' && 'lng' in boundaryPoints[0] && 'lat' in boundaryPoints[0]) {
            // Convert {lng, lat} format to {longitude, latitude} format for geolib
            boundaryPoints.forEach((point) => {
              if (!isNaN(point.lng) && !isNaN(point.lat)) {
                polygonPoints.push({ longitude: point.lng, latitude: point.lat });
              }
            });
          } else if (Array.isArray(boundaryPoints[0])) {
            boundaryPoints.forEach((point) => {
              if (!isNaN(point[0]) && !isNaN(point[1])) {
                polygonPoints.push({ longitude: point[0], latitude: point[1] });
              }
            });
          }

          // If we have valid polygon points, check each device
          if (polygonPoints.length >= 3) {
            devices.forEach((device) => {
              // Check if the device is inside this polygon geofence
              if (isPointInPolygon({ latitude: device.latitude, longitude: device.longitude }, polygonPoints)) {
                devicesInsidePolygon.push(device);
              }
            });
          }
        }

        // Filter for active devices in shift
        const activeDevices = devicesInsidePolygon.filter(
          (device) => deviceShifts[device.deviceId]?.shiftStatus === true
        );

        // Map to personnel format
        const activePersonnel = activeDevices
          .map((device) => ({
            name: device.assignedTo || device.deviceName,
            deviceId: device.deviceId,
          }))
          .filter((person) => Boolean(person.name));

        return {
          id: geofenceId,
          nickname: geofence.nickname || 'Unnamed Polygon',
          place: geofenceId,

          personnelInShift: activePersonnel,
          latitude: centerLat || 0,
          longitude: centerLng || 0,
          boundaryCoordinates: boundaryPoints,
          type: 'polygon', // Add type to distinguish between circle and polygon
        };
      });

      // Combine both types of geofences
      const combinedData = [...circularGeofencesData, ...polygonGeofencesData];

      // Sort by nickname
      return combinedData.sort((a, b) => a.nickname.localeCompare(b.nickname));
    }, [localGeofences, devices, deviceShifts, polygonGeofences]);

    console.log('siteData: ', siteData);

    const handlePersonnelClick = useCallback(
      (deviceId) => {
        console.log('personnel click: ', deviceId);
        handleDeviceSelect(deviceId);
      },
      [handleDeviceSelect]
    );

    const handleGeofenceSelect = useCallback(
      (geofence) => {
        if (!geofence) {
          console.error('Geofence is undefined or null');
          return;
        }

        console.log('Selected geofence:', JSON.stringify(geofence));

        // Only allow polygon geofences to be selected for editing
        if (geofence.type !== 'polygon') {
          // For circular geofences, just show it on the map without selecting for editing
          if (
            typeof geofence.latitude === 'number' &&
            !isNaN(geofence.latitude) &&
            typeof geofence.longitude === 'number' &&
            !isNaN(geofence.longitude)
          ) {
            onSiteClick(geofence.latitude, geofence.longitude, geofence.radius, false);
          } else {
            console.error('Invalid coordinates for circular geofence:', geofence);
          }
          return;
        }

        // Store original state regardless of how we enter edit mode
        storeOriginalGeofenceState(geofence);

        setSelectedGeofence(geofence);
        setActiveGeofence(geofence);
        setShowGeofenceControls(true);
        setPreviewGeofence(null);
        onPreviewGeofence(null);
        setIsGeofenceModified(false);

        try {
          if (
            typeof geofence.latitude === 'number' &&
            !isNaN(geofence.latitude) &&
            typeof geofence.longitude === 'number' &&
            !isNaN(geofence.longitude)
          ) {
            console.log('Using centroid coordinates:', geofence.latitude, geofence.longitude);
            // Pass false as the fourth parameter to prevent automatic zooming
            onSiteClick(geofence.latitude, geofence.longitude, undefined, false);
          } else {
            console.error('Invalid centroid coordinates for polygon geofence:', geofence);
            onSiteClick(0, 0, undefined, false);
          }
        } catch (error) {
          console.error('Error in handleGeofenceSelect:', error);
          if (typeof onSiteClick === 'function') {
            onSiteClick(0, 0, undefined, false);
          }
        }
      },
      [onSiteClick, onPreviewGeofence, setSelectedGeofence, storeOriginalGeofenceState]
    );

    const handleGeofenceChange = useCallback(
      (updatedGeofence) => {
        console.log('Updating geofence:', updatedGeofence);

        // Ensure we have the original state stored
        if (!originalGeofenceRef.current) {
          console.log('Storing original state in handleGeofenceChange');
          storeOriginalGeofenceState(updatedGeofence);
        }

        setActiveGeofence(updatedGeofence);
        setIsGeofenceModified(true);

        if (updatedGeofence.id === 'preview') {
          onPreviewGeofence(updatedGeofence);
        }
      },
      [onPreviewGeofence, storeOriginalGeofenceState, originalGeofenceRef]
    );

    const handleGeofenceSave = useCallback(
      async (updatedGeofence) => {
        // Determine the correct organization ID
        const effectiveOrgId = userRole === 'ASP' && parentOrgUserId ? parentOrgUserId : organizationId;

        // Handle polygon geofence updates
        if (updatedGeofence.type === 'polygon') {
          try {
            // Get the current coordinates from the geofence object
            // This ensures we use the most up-to-date coordinates that may have been modified by dragging
            const coordinates = updatedGeofence.boundaryCoordinates || [];

            // Call the updatePolygonGeofence API function
            const result = await updatePolygonGeofence(
              updatedGeofence.id,
              effectiveOrgId,
              updatedGeofence.nickname,
              coordinates // Use the coordinates from the geofence object
            );

            if (result.success) {
              // Update the polygon geofences state with the new nickname and reset the isModified flag
              const updatedPolygonGeofences = polygonGeofences.map((geofence) =>
                geofence.id === updatedGeofence.id
                  ? {
                      ...geofence,
                      nickname: updatedGeofence.nickname,
                      boundaryCoordinates: coordinates,
                      isModified: false, // Reset the modified flag
                    }
                  : geofence
              );
              setPolygonGeofences(updatedPolygonGeofences);
            } else {
              console.error('Failed to update polygon geofence:', result.error);
            }

            setShowGeofenceControls(false);
            setSelectedGeofence(null);
            setPreviewGeofence(null);
            setActiveGeofence(null);
            onPreviewGeofence(null);
            setIsGeofenceModified(false);
            clearOriginalGeofenceState();
            return;
          } catch (error) {
            console.error('Error updating polygon geofence:', error);
            return;
          }
        }

        // Handle regular circular geofence updates (existing code)
        if (updatedGeofence.id === 'preview') {
          // Check if a geofence with the same name already exists
          const existingGeofence = localGeofences.find(
            (geofence) => geofence.id === selectedAddress.full_address || geofence.name === updatedGeofence.nickname
          );

          if (existingGeofence) {
            console.error('A geofence with this name or ID already exists.');
            return;
          }

          // Handle adding new geofence
          const newGeofenceId = selectedAddress.full_address;
          const result = await addGeofence(
            newGeofenceId,
            effectiveOrgId, // Use the effective organization ID
            [updatedGeofence.longitude, updatedGeofence.latitude],
            updatedGeofence.radius,
            updatedGeofence.nickname
          );
          if (result.success) {
            const newGeofence = {
              ...updatedGeofence,
              id: newGeofenceId,
              name: newGeofenceId, // Set name to full address
              nickname: updatedGeofence.nickname, // Preserve the user-entered nickname
            };
            setLocalGeofences([...localGeofences, newGeofence]);
            onGeofenceUpdate([...localGeofences, newGeofence]);
          } else {
            console.error('Failed to add geofence:', result.error);
          }
        } else {
          // Handle updating existing geofence
          const result = await updateGeofence(
            updatedGeofence.id,
            effectiveOrgId, // Use the effective organization ID
            updatedGeofence.nickname,
            [updatedGeofence.longitude, updatedGeofence.latitude],
            updatedGeofence.radius
          );
          if (result.success) {
            const updatedGeofences = localGeofences.map((g) =>
              g.id === updatedGeofence.id ? { ...g, nickname: updatedGeofence.nickname } : g
            );
            setLocalGeofences(updatedGeofences);
            onGeofenceUpdate(updatedGeofences);
          } else {
            console.error('Failed to update geofence:', result.error);
          }
        }
        setShowGeofenceControls(false);
        setSelectedGeofence(null);
        setPreviewGeofence(null);
        setActiveGeofence(null);
        onPreviewGeofence(null);
        setIsGeofenceModified(false);
        clearOriginalGeofenceState();
      },
      [
        localGeofences,
        organizationId,
        onGeofenceUpdate,
        onPreviewGeofence,
        selectedAddress,
        userRole,
        parentOrgUserId,
        polygonGeofences,
        setPolygonGeofences,
        clearOriginalGeofenceState,
      ]
    );

    const handleGeofenceCancel = useCallback(() => {
      if (!originalGeofenceRef.current) {
        console.error('No original geofence state found for cancellation');
        // Reset all control states even if we don't have an original state
        setShowGeofenceControls(false);
        setSelectedGeofence(null);
        setPreviewGeofence(null);
        setActiveGeofence(null);
        onPreviewGeofence(null);
        setIsGeofenceModified(false);
        return;
      }

      try {
        // Handle polygon geofence cancellation
        if (originalGeofenceRef.current.type === 'polygon') {
          // Create a deep copy of the original state to avoid reference issues
          const originalState = JSON.parse(JSON.stringify(originalGeofenceRef.current));

          console.log('Reverting polygon geofence to:', originalState);

          const updatedPolygonGeofences = polygonGeofences.map((g) => (g.id === originalState.id ? originalState : g));

          setActiveGeofence(originalState);
          setPolygonGeofences(updatedPolygonGeofences);

          // If the polygon has a centroid, update the map view without zooming
          if (originalState.latitude && originalState.longitude) {
            onSiteClick(originalState.latitude, originalState.longitude, undefined, false);
          }
        } else {
          // Handle regular circular geofence cancellation
          if (originalGeofenceRef.current.id !== 'preview') {
            const updatedGeofences = localGeofences.map((g) =>
              g.id === originalGeofenceRef.current.id ? originalGeofenceRef.current : g
            );
            setLocalGeofences(updatedGeofences);
            onGeofenceUpdate(updatedGeofences);
            setActiveGeofence(originalGeofenceRef.current);

            // Update map view for circular geofence without zooming
            if (originalGeofenceRef.current.latitude && originalGeofenceRef.current.longitude) {
              onSiteClick(
                originalGeofenceRef.current.latitude,
                originalGeofenceRef.current.longitude,
                originalGeofenceRef.current.radius,
                false
              );
            }
          }
        }
      } catch (error) {
        console.error('Error in handleGeofenceCancel:', error);
      } finally {
        // Always reset control states
        setShowGeofenceControls(false);
        setSelectedGeofence(null);
        setPreviewGeofence(null);
        setActiveGeofence(null);
        onPreviewGeofence(null);
        setIsGeofenceModified(false);
        clearOriginalGeofenceState();
      }
    }, [
      onPreviewGeofence,
      localGeofences,
      onGeofenceUpdate,
      polygonGeofences,
      setPolygonGeofences,
      onSiteClick,
      originalGeofenceRef,
      clearOriginalGeofenceState,
    ]);

    // Replace local effect with using the passed functions
    useEffect(() => {
      if (selectedGeofence && !originalGeofenceRef.current) {
        console.log('Setting original state from selectedGeofence change');
        storeOriginalGeofenceState(selectedGeofence);
      }
    }, [selectedGeofence, storeOriginalGeofenceState, originalGeofenceRef]);

    const handleRadiusChange = useCallback(
      (newRadius) => {
        if (activeGeofence) {
          const updatedGeofence = { ...activeGeofence, radius: newRadius };
          setActiveGeofence(updatedGeofence);
          if (updatedGeofence.id === 'preview') {
            onPreviewGeofence(updatedGeofence);
          } else {
            // Update existing geofence
            const updatedGeofences = localGeofences.map((g) => (g.id === updatedGeofence.id ? updatedGeofence : g));
            setLocalGeofences(updatedGeofences);
            onGeofenceUpdate(updatedGeofences);
          }
        }
      },
      [activeGeofence, onPreviewGeofence, localGeofences, onGeofenceUpdate]
    );

    const handleGeofenceDelete = useCallback(
      async (geofence) => {
        try {
          if (geofence.type === 'polygon') {
            // Use the polygon-specific delete function
            await deletePolygonGeofence(geofence.id, organizationId);
            // The parent component will handle refreshing polygon geofences
            const updatedPolygonGeofences = polygonGeofences.filter((g) => g.id !== geofence.id);
            setPolygonGeofences(updatedPolygonGeofences);
          } else {
            // Use the regular geofence delete function
            await deleteGeofence(geofence.id, organizationId);
            const updatedGeofences = localGeofences.filter((g) => g.id !== geofence.id);
            setLocalGeofences(updatedGeofences);
            onGeofenceUpdate(updatedGeofences);
          }

          // Clear selection
          setSelectedGeofence(null);
          setActiveGeofence(null);
          setShowGeofenceControls(false);
        } catch (error) {
          console.error('Failed to delete geofence:', error);
        }
      },
      [organizationId, localGeofences, onGeofenceUpdate, polygonGeofences, setPolygonGeofences]
    );

    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
    };

    // Handle column header click for sorting
    const handleRequestSort = (property) => {
      const isAsc = sortBy === property && sortDirection === 'asc';
      setSortDirection(isAsc ? 'desc' : 'asc');
      setSortBy(property);
    };

    const filteredSiteData = useMemo(() => {
      if (!searchTerm.trim()) return siteData;

      const searchLower = searchTerm.toLowerCase();
      return siteData.filter((site) => site.nickname.toLowerCase().includes(searchLower));
    }, [siteData, searchTerm]);

    // Add sorting to filtered data
    const sortedSiteData = useMemo(() => {
      return [...filteredSiteData].sort((a, b) => {
        const multiplier = sortDirection === 'asc' ? 1 : -1;

        if (sortBy === 'nickname') {
          return a.nickname.localeCompare(b.nickname) * multiplier;
        }

        if (sortBy === 'personnel') {
          return (a.personnelInShift.length - b.personnelInShift.length) * multiplier;
        }

        return 0;
      });
    }, [filteredSiteData, sortBy, sortDirection]);

    return (
      <div className="site-assignment-container">
        <div className="site-assignment-search-container">
          <div className="site-assignment-autocomplete-container">
            <input
              type="text"
              placeholder="Search sites"
              value={searchTerm}
              onChange={handleSearchChange}
              className="site-search-input"
            />
            {searchTerm && (
              <button className="clear-search-button" onClick={() => setSearchTerm('')}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
          </div>
        </div>
        <div className="site-assignment-table">
          <table>
            <thead>
              <tr>
                <th>
                  <TableSortLabel
                    active={sortBy === 'nickname'}
                    direction={sortDirection}
                    onClick={() => handleRequestSort('nickname')}
                  >
                    Name
                  </TableSortLabel>
                </th>
                <th>
                  <TableSortLabel
                    active={sortBy === 'personnel'}
                    direction={sortDirection}
                    onClick={() => handleRequestSort('personnel')}
                  >
                    Devices
                  </TableSortLabel>
                </th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {sortedSiteData.map((site) => (
                <tr key={site.id} onClick={() => handleGeofenceSelect(site)}>
                  <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>{site.nickname}</span>
                      {site.type === 'polygon' && (
                        <span style={{ marginLeft: '5px', color: 'blue', fontSize: '0.7rem' }}></span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        maxHeight: '100%',
                        overflow: 'hidden',
                      }}
                    >
                      {site.personnelInShift.length > 0 ? (
                        site.personnelInShift.map((person, index) => (
                          <PersonnelPill
                            key={`${person.deviceId}-${index}`}
                            name={person.name}
                            deviceId={person.deviceId}
                            onClick={handlePersonnelClick}
                          />
                        ))
                      ) : (
                        <span
                          style={{
                            fontSize: '0.8rem',
                            color: '#999',
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                          }}
                        >
                          {' '}
                          -{' '}
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={(e) => {
                          e.stopPropagation();
                          const id = site.id;

                          try {
                            console.log('Deleting row:', site);

                            if (site.type === 'polygon') {
                              handleDeletePolygonGeofence(id);
                              // Force UI update by updating both ways the ID might be stored
                              setPolygonGeofences((prev) =>
                                prev.filter((geofence) => geofence.id !== id && geofence.geofence_id !== id)
                              );
                            } else {
                              handleDeleteGeofence(id);
                              setLocalGeofences((prev) => prev.filter((geofence) => geofence.id !== id));
                            }
                          } catch (error) {
                            console.error('Error deleting geofence:', error);
                          }
                        }}
                        style={{
                          cursor: 'pointer',
                          color: 'var(--text-primary)',
                          padding: '8px',
                          position: 'relative',
                          zIndex: 10,
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="site-assignment-count-info">
          <span className="site-assignment-count">
            Showing {sortedSiteData.length} of {siteData.length} sites
          </span>
        </div>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="site-info-modal"
          aria-describedby="site-info-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="site-info-modal" variant="h6" component="h2">
              Site Information
            </Typography>
            <Typography id="site-info-description" sx={{ mt: 2 }}>
              Nickname: {selectedSite?.nickname}
              <br />
              Full Address: {selectedSite?.id}
              <br />
              Latitude: {selectedSite?.latitude}
              <br />
              Longitude: {selectedSite?.longitude}
              <br />
              Radius: {selectedSite?.radius} meters
            </Typography>
          </Box>
        </Modal>
        {(showGeofenceControls || previewGeofence) && activeGeofence && (
          <GeofenceControls
            geofence={activeGeofence}
            onSave={handleGeofenceSave}
            onCancel={handleGeofenceCancel}
            onChange={handleGeofenceChange}
            isPreview={activeGeofence.id === 'preview'}
            onRadiusChange={handleRadiusChange}
            isModified={activeGeofence.isModified || isGeofenceModified}
            onDelete={handleGeofenceDelete}
            editMode={editMode}
            onEditModeToggle={onEditModeToggle}
          />
        )}
      </div>
    );
  }
);

export default SiteAssignment;
