import axios from 'axios';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Clear invalid token
      localStorage.removeItem('access_token');
      localStorage.removeItem('auth_version');
      // Force App.js to get new token
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

const rateLimiter = {
  getLastReload() {
    const storedTime = localStorage.getItem('last_reload_time');
    return storedTime ? parseInt(storedTime) : this.lastReload || 0;
  },
  lastReload: 0, // Initialize lastReload to 0 for backward compatibility
  getLastReload() {
    const storedTime = localStorage.getItem('last_reload_time');
    return storedTime ? parseInt(storedTime) : this.lastReload || 0;
  },
  lastReload: 0, // Initialize lastReload to 0 for backward compatibility
  minInterval: 5000, // 5 seconds

  canReload() {
    const now = Date.now();
    const lastReload = this.getLastReload();
    if (now - lastReload < this.minInterval) {
      return false;
    }
    this.setLastReload(now);
    return true;
  },
};

export const checkTokenValidity = () => {
  const token = localStorage.getItem('access_token');
  const authVersion = localStorage.getItem('auth_version');
  const REQUIRED_AUTH_VERSION = '1.0';
  const tokenFetchInProgress = localStorage.getItem('token_fetch_in_progress');
  // console.log("7898 token", token);
  // console.log('Token Validation Check:', {
  //   hasToken: !!token,
  //   tokenValue: token?.substring(0, 10) + '...', // Show first 10 chars of token
  //   authVersion,
  //   requiredVersion: REQUIRED_AUTH_VERSION,
  //   tokenFetchInProgress: !!tokenFetchInProgress
  // });

  // If token fetch is in progress, wait
  if (tokenFetchInProgress) {
    console.log('Token fetch in progress branch');
    const startTime = parseInt(tokenFetchInProgress);
    if (Date.now() - startTime < 10000) {
      console.log('Token fetch in progress, waiting...');
      return false;
    }
    localStorage.removeItem('token_fetch_in_progress');
  }

  // No token or invalid version
  if (!token || authVersion !== REQUIRED_AUTH_VERSION) {
    if (rateLimiter.canReload()) {
      // Instead of immediate reload, redirect to home
      window.location.href = '/';
      return false;
    }
    return false;
  }

  console.log('Token validation successful');
  return true;
};

export const fetchAlertFromApi = async (alertId) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/alerts/${alertId}`;

    console.log('URL:', url);
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Response:', response.data);

    if (!response.data.IsCustomerVisible) {
      return null; // Return null if the alert shouldn't be shown to the customer
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching alert from API:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch alert: ${error.message}`);
  }
};

export const fetchRecentEscalationAlerts = async (userId, deviceIds, lastTimestamp, pageSize = 20) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/alerts/recent`;

    const response = await axios.post(
      url,
      {
        userId,
        deviceIds,
        lastTimestamp,
        pageSize,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Recent escalation alerts response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching recent escalation alerts:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch recent escalation alerts: ${error.message}`);
  }
};

export const getAlertLabelingMetrics = async (userId, deviceIds) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }
    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/alerts/labeling-metrics`;

    const response = await axios.post(
      url,
      { userId, deviceIds },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error getting alert labeling metrics:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to get alert labeling metrics: ${error.message}`);
  }
};

export const filterShiftEvents = async (shift) => {
  try {
    const shiftId = shift.id;
    console.log('Shift:', shift);

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }
    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/shifts/filter`;

    const response = await axios.post(
      url,
      { shiftId },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error filtering shift events:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to filter shift events: ${error.message}`);
  }
};

export const fetchShiftsForDevices = async (userId, deviceIds, page = 1, pageSize = 50) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/shifts`;

    const response = await axios.post(
      url,
      {
        userId,
        deviceIds,
        page,
        pageSize,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching shifts for devices:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch shifts for devices: ${error.message}`);
  }
};

export const fetchAllFlaggedShifts = async (deviceIds) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/flagged-shifts`;

    const response = await axios.post(
      url,
      { deviceIds },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching flagged shifts:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch flagged shifts: ${error.message}`);
  }
};

export const sendReport = async (orgUserId, emailAddress, reportType, frequency) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/send-report`;
    console.log('send report url: ', url);

    const response = await axios.post(
      url,
      {
        org_user_id: orgUserId,
        email_address: emailAddress,
        report_type: reportType,
        frequency: frequency,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('send report response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error send report:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to send report: ${error.message}`);
  }
};

export const fetchDevicesFromUserId = async (userId) => {
  try {
    // Store the result of checkTokenValidity
    const isTokenValid = checkTokenValidity();
    if (!isTokenValid) {
      throw new Error('Token validation failed');
    }

    let token = localStorage.getItem('access_token');
    console.log('token:', token);
    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/devices/user`;
    console.log('url:', url);

    const response = await axios.post(
      url,
      { userId },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.data) {
      throw new Error('No data received from API');
    }

    console.log('Devices response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching devices for user:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw error; // Throw the error instead of wrapping it
  }
};

export const fetchDeviceData = async (deviceId) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/device/${deviceId}`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Device data response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching device data:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch device data: ${error.message}`);
  }
};

export const updateDeviceData = async (deviceId, updateData) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/devices/${deviceId}`;

    const response = await axios.post(url, updateData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Update device data response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating device data:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to update device data: ${error.message}`);
  }
};

export const fetchAssignedToFromDeviceIds = async (deviceIds) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/devices/assigned-to`;

    const response = await axios.post(
      url,
      { deviceIds },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('AssignedTo response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching assignedTo from deviceIds:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch assignedTo from deviceIds: ${error.message}`);
  }
};

export const fetchVideosForDevices = async (userId, devices, retentionPeriodDays, selectedOrg) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/videos`;

    // Extract only the device IDs from the devices array
    const deviceIds = devices.map((device) => device.deviceId);

    const response = await axios.post(
      url,
      { userId, deviceIds, retentionPeriodDays, selectedOrg },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Videos response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching videos for devices:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch videos for devices: ${error.message}`);
  }
};

export const fetchVideoByFileName = async (fileName) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/videos/${fileName}`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Video response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching video by fileName:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch video by fileName: ${error.message}`);
  }
};

export const updateShiftFlagStatus = async (id, orgUserId, isFlagged) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/shifts/flag`;

    const response = await axios.post(
      url,
      { id, orgUserId, isFlagged },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Shift response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating shift flag status:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to update shift flag status: ${error.message}`);
  }
};

export const fetchLatestLocationEvent = async (deviceId, endTime) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/location/latest/${deviceId}/${endTime}`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Latest location event response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching latest location event:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch latest location event: ${error.message}`);
  }
};

export const fetchOrganizationsFromCosmos = async () => {
  try {
    if (!checkTokenValidity()) return;

    // For production, use the token from localStorage
    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/organizations`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Organizations response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching organizations from Cosmos:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch organizations from Cosmos: ${error.message}`);
  }
};

export const updateAlertLabels = async (alertId, deviceId, newLabels) => {
  try {
    if (!checkTokenValidity()) return;

    let token;

    // For production, use the token from localStorage
    token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/alerts/update-labels`;

    const response = await axios.post(
      url,
      { alertId, deviceId, newLabels },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Alert labels response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating alert labels:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to update alert labels: ${error.message}`);
  }
};

export const fetchGeofencesForOrganization = async (organizationId) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/geofences/fetch/${organizationId}`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Geofences response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching geofences for organization:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch geofences for organization: ${error.message}`);
  }
};

export const addGeofence = async (name, organization, center, radius, nickname) => {
  try {
    if (!checkTokenValidity()) return;

    let token;

    // For production, use the token from localStorage
    token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/geofences/add`;

    const response = await axios.post(
      url,
      { name, organization, center, radius, nickname },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // console.log('Alert labels response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error adding geofence:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to add geofence: ${error.message}`);
  }
};

export const updateGeofence = async (id, organization, newName, center, radius) => {
  try {
    if (!checkTokenValidity()) return;
    console.log('updateGeofence: ', id, organization, newName, center, radius);
    let token;

    // For production, use the token from localStorage
    token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/geofences/update`;

    const response = await axios.post(
      url,
      { id, organization, newName, center, radius },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // console.log('Alert labels response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error adding geofence:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to update geofence: ${error.message}`);
  }
};

export const deleteGeofence = async (id, organization) => {
  try {
    if (!checkTokenValidity()) return;

    let token;

    // For production, use the token from localStorage
    token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/geofences/delete`;

    const response = await axios.post(
      url,
      { id, organization },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // console.log('Alert labels response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error adding geofence:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to delete geofence: ${error.message}`);
  }
};

export const fetchLocationAtTimestamp = async (deviceId, timestamp) => {
  try {
    if (!checkTokenValidity()) return;

    let token;

    // For production, use the token from localStorage
    token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/location/time/${deviceId}/${timestamp}`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Location at timestamp response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching location at timestamp:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch location at timestamp: ${error.message}`);
  }
};

/**
 * Fetches locations for a shift, if no locations are found in the time range, it will return the latest location
 * @param {*} deviceId
 * @param {*} startTime
 * @param {*} endTime
 * @returns
 */
export const fetchLocationsForShift = async (deviceId, startTime, endTime) => {
  try {
    if (!checkTokenValidity()) return;

    let token;

    // For production, use the token from localStorage
    token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/location/shift/${deviceId}/${startTime}/${endTime}`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Locations for shift response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching locations for shift:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch locations for shift: ${error.message}`);
  }
};

// Device Configs API (ported from deviceConfig.js)

export const fetchDeviceConfigs = async (userId) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/devices/config/${userId}`;
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching device configs:', error);
    throw error;
  }
};

export const pingDevices = async (userId, lastChargeAndRecordOnly = false, userRole = null) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/devices/ping/${userId}`;
    const response = await axios.get(url, {
      params: {
        lastChargeAndRecordOnly,
        userRole,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error pinging devices:', error);
    throw error;
  }
};

// Create a single axios instance
// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   timeout: 30000,
// });

// Shared video endpoint should use its own instance since it doesn't need auth
const publicApi = axios.create({
  baseURL: process.env.REACT_APP_BEAVER_API_URL,
  // baseURL: 'http://localhost:4000',
  timeout: 30000,
});
export const createSharedVideo = async (
  videoFileName,
  isDownloadable,
  expirationDate,
  password = null,
  initialTime = 0,
  userId
) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');
    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/shared-video/create`;
    // const url = `http://localhost:4000/api/shared-video/create`;

    const requestBody = { videoFileName, isDownloadable, expirationDate, password, initialTime, userId };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    console.log('Create Shared Video Request:');
    console.log('URL:', url);
    console.log('Method: POST');
    console.log('Headers:', JSON.stringify(headers, null, 2));
    console.log('Body:', JSON.stringify(requestBody, null, 2));

    const response = await axios.post(url, requestBody, { headers });

    console.log('Response:', JSON.stringify(response.data, null, 2));

    return response.data;
  } catch (error) {
    console.error('Error creating shared video:', error);
    throw error;
  }
};

export const createSharedImage = async (imageFileName, isDownloadable, expirationDate, password = null, userId) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');
    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/shared-image/create`;
    // const url = `http://localhost:4000/api/shared-video/create`;

    const requestBody = { imageFileName, isDownloadable, expirationDate, password, userId };
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    console.log('Create Shared Image Request:');
    console.log('URL:', url);
    console.log('Method: POST');
    console.log('Headers:', JSON.stringify(headers, null, 2));
    console.log('Body:', JSON.stringify(requestBody, null, 2));

    const response = await axios.post(url, requestBody, { headers });

    console.log('Response:', JSON.stringify(response.data, null, 2));

    return response.data;
  } catch (error) {
    console.error('Error creating shared image:', error);
    throw error;
  }
};

export const fetchSharedVideo = async (sharedVideoId, password = '') => {
  try {
    const response = await publicApi.get(`/api/shared-video/${sharedVideoId}`, {
      headers: password ? { 'X-Video-Password': password } : {},
    });
    return response.data;
  } catch (error) {
    // Rethrow the error with the response details
    throw error;
  }
};
export const fetchSharedImage = async (sharedImageId, password = '') => {
  try {
    const response = await publicApi.get(`/api/shared-image/${sharedImageId}`, {
      headers: password ? { 'X-Image-Password': password } : {},
    });
    return response.data;
  } catch (error) {
    // Rethrow the error with the response details
    throw error;
  }
};

export const logUserInteraction = async (action, metadata, userId, userName) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    // Determine if the content is an image based on metadata
    const isImage = metadata.fileName && /\.(jpg|jpeg|png|gif|bmp)$/i.test(metadata.fileName);

    const response = await publicApi.post(
      '/api/content/log-interaction',
      {
        action,
        metadata: {
          ...metadata,
          contentType: isImage ? 'image' : 'video',
        },
        userId,
        userName,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error logging user interaction:', error);
    throw error;
  }
};

export const logDownloadInteraction = async (action, metadata, userId, userName, password) => {
  try {
    const response = await publicApi.post(
      `/api/shared-video/log-download-interaction`,
      { action, metadata, userId, userName },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Video-Password': password,
        },
      }
    );
    return response.data;
  } catch (error) {
    // Rethrow the error with the response details
    throw error;
  }
};

export const logImageDownloadInteraction = async (action, metadata, userId, userName, password) => {
  try {
    const response = await publicApi.post(
      `/api/shared-image/log-image-download-interaction`,
      { action, metadata, userId, userName },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Image-Password': password,
        },
      }
    );
    return response.data;
  } catch (error) {
    // Rethrow the error with the response details
    throw error;
  }
};

export const searchAlerts = async (
  userId,
  query,
  deviceIds,
  geofenceIds,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/alerts/search`;

    const response = await axios.post(
      url,
      {
        userId,
        query,
        deviceIds,
        geofenceIds,
        timezone,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Search alerts response:', response.data);

    // If the alert shouldn't be shown to the customer, return null
    if (response.data && !response.data.IsCustomerVisible) {
      return null;
    }

    return response.data;
  } catch (error) {
    console.error('Error searching alerts:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to search alerts: ${error.message}`);
  }
};

export const fetchUserMapping = async (getAccessTokenSilently) => {
  try {
    // Get fresh token directly instead of relying on localStorage
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'openid profile email',
      },
    });

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/debug/user-mapping`;
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    // Validate response structure
    if (!response?.data?.mapped?.userId || !response?.data?.mapped?.mode) {
      console.error('Invalid mapping response:', response.data);
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const status = error.response?.status;
      if (status === 401) {
        // throw new Error('Authentication failed. Please log in again.');
        console.error('Authentication failed. Please log in again.');
      } else if (status === 403) {
        // throw new Error('You do not have permission to access this resource.');
        console.error('You do not have permission to access this resource.');
      }
    }
    console.error('Error fetching user mapping:', error);
    throw error;
  }
};

export const getUserRoleFromToken = (user) => {
  try {
    // Access the roles claim from the user object
    const roles = user['https://beaver.plix.ai/roles'] || [];
    const isGuard = roles.includes('guard');
    const isASP = roles.includes('ASP');
    return isASP ? 'ASP' : isGuard ? 'guard' : 'admin'; // or whatever your role logic is
  } catch (error) {
    console.error('Error getting user role from token:', error);
    return null;
  }
};

// Leaderboard

export const fetchWeeklyLeaderboard = async (organizationId, weekStart, weekEnd) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/leaderboard/weekly`;

    const response = await axios.get(url, {
      params: { organizationId, weekStart, weekEnd },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Leaderboard response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching weekly leaderboard:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch weekly leaderboard: ${error.message}`);
  }
};

export const fetchDashboardLeaderboard = async (organizationId, weekStart, weekEnd, deviceIds) => {
  try {
    if (!checkTokenValidity()) return;

    const token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/leaderboard/dashboard`;
    const response = await axios.post(
      url,
      { organizationId, weekStart, weekEnd, deviceIds },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching dashboard leaderboard:', error);
    throw error;
  }
};

// SM Config API

export const fetchConfig = async (userId) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/config/${userId}`;
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching config:', error);
    throw error;
  }
};

export const updateConfig = async (userId, config) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/config/${userId}`;
    const response = await axios.post(url, config, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error updating config:', error);
    throw error;
  }
};

export const fetchRetentionPeriod = async (userId) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/config/retention-period/${userId}`;
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.retentionPeriodDays;
  } catch (error) {
    console.error('Error fetching retention period:', error);
    throw error;
  }
};

export const fetchShiftsByDateRange = async (startDate, endDate, userId = null, deviceId = null, assignedTo = null) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/shift/date`;
    const response = await axios.post(
      url,
      { startDate, endDate, userId, deviceId, assignedTo },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching shifts by date range:', error);
    throw error;
  }
};

export const updateDeviceTag = async (deviceId, deviceTag) => {
  try {
    if (!checkTokenValidity()) return;

    const token = localStorage.getItem('access_token');
    if (!token) {
      throw new Error('No access token found');
    }

    const url = 'https://utils.plix.ai/v1/addtag';
    const response = await axios.post(
      url,
      { deviceId, deviceTag },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(`Error updating device tag for device ${deviceId}:`, error);
    throw error;
  }
};

export const triggerConfigUpdate = async (deviceId) => {
  try {
    if (!checkTokenValidity()) return;

    const url = 'https://utils.plix.ai/v1/updateconfig';
    const response = await axios.post(
      url,
      {
        deviceId: deviceId,
        updateType: 'fetch config',
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    console.log(`Configuration update triggered for device ${deviceId}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Failed to trigger configuration update for device ${deviceId}:`, error);
    throw error;
  }
};

// Reports

export const fetchReports = async (userId, options = {}) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');
    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/reports/${userId}`;

    // Add query parameters for filtering
    const params = new URLSearchParams();
    if (options.authorEmail) params.append('authorEmail', options.authorEmail);
    if (options.timeRange) params.append('timeRange', options.timeRange);
    if (options.deviceId) {
      // Split, clean, and rejoin to ensure proper formatting
      const deviceIds = options.deviceId
        .split(',')
        .map((id) => id.trim())
        .join(',');
      params.append('deviceId', deviceIds);
    }

    const response = await axios.get(`${url}?${params.toString()}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching reports:', error);
    throw error;
  }
};
export const updateReport = async (userId, reportData, markAsReviewed = false) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');
    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/reports/update-report`;

    const response = await axios.post(
      url,
      {
        ...reportData,
        userId,
        markAsReviewed,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error updating report:', error);
    throw error;
  }
};

export const deleteReport = async (reportId, userId) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');
    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/reports/${reportId}?userId=${userId}`;

    const response = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error deleting report:', error);
    throw error;
  }
};

// In beaverApi.js

export const createPersonnelAccount = async (
  userId,
  emailAddress,
  deviceIds,
  fullName,
  accessLevel = 'guard',
  phoneNumber = null
) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/personnel-accounts/create`;

    const response = await axios.post(
      url,
      {
        userId,
        emailAddress,
        deviceIds,
        fullName,
        accessLevel,
        phoneNumber,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error creating personnel account:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
    throw error;
  }
};

export const fetchPersonnelAccounts = async (userId) => {
  try {
    if (!checkTokenValidity()) return;
    // console.log('fetching personnel accounts for userId: ', userId);
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/personnel-accounts/fetch/${userId}`;
    console.log('fetching personnel accounts for userId: ', userId);
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching personnel accounts:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
    throw error;
  }
};

export const deletePersonnelAccount = async (personnelUserId) => {
  try {
    console.log('Deleting personnel account with ID:', personnelUserId);
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/personnel-accounts/delete/${personnelUserId}`;

    const response = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error deleting personnel account:', error);
    if (error.response) {
      // Handle specific error cases
      if (error.response.status === 403) {
        throw new Error('Unauthorized to delete this personnel account');
      }
      if (error.response.status === 404) {
        throw new Error('Personnel account not found');
      }
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
    throw error;
  }
};

export const fetchOrganizationByUserId = async (userId) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');
    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/organizations/fetch`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching organization by user ID:', error);
    throw error;
  }
};

export const generateNarrativeFromAlert = async (alertId, deviceId, existingNarrative = null, instruction = null) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');
    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/reports/generate-narrative-from-alert`;

    // Get the local timezone offset
    const timezoneOffset = new Date().getTimezoneOffset();

    const response = await axios.post(
      url,
      {
        alertId,
        deviceId,
        timezoneOffset,
        existingNarrative,
        instruction,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error generating narrative from alert:', error);
    throw error;
  }
};

export const updatePersonnelAccount = async (
  personnelUserId,
  deviceIds,
  fullName,
  accessLevel,
  accessLevelChanged = false,
  phoneNumber = null
) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/personnel-accounts/update-devices/${personnelUserId}`;

    const response = await axios.put(
      url,
      { deviceIds, fullName, accessLevel, accessLevelChanged, phoneNumber },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error updating personnel devices:', error);
    if (error.response) {
      // Handle specific error cases
      if (error.response.status === 403) {
        throw new Error('Unauthorized to update this personnel account');
      }
      if (error.response.status === 404) {
        throw new Error('Personnel account not found');
      }
      if (error.response.status === 400) {
        throw new Error('Invalid device IDs provided');
      }
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
    throw error;
  }
};

export const fetchStaticDevices = async (userId, userRole = null) => {
  try {
    if (!checkTokenValidity()) return [];
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/devices/static/${userId}`;
    const response = await axios.get(url, {
      params: { userRole },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching static device data:', error);
    throw error;
  }
};

export const fetchDeviceStatuses = async (userId, deviceIds = [], userRole = null) => {
  try {
    if (!checkTokenValidity()) return {};
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/devices/status/${userId}`;
    const response = await axios.post(
      url,
      { deviceIds, userRole }, // Send data in request body now
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching device status:', error);
    throw error;
  }
};

export const redactVideo = async (videoId, redactionSettings, userName) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/redact-video`;
    const response = await axios.post(
      url,
      { videoId, redactionSettings, userName },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error redacting video:', error);
    throw error;
  }
};

export const fetchPolygonGeofencesForOrganization = async (organization) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/geofences/fetch-polygon-geofences/${organization}`;
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching polygon geofences for organization:', error);
    throw error;
  }
};
export const addPolygonGeofence = async (organization, polygon, nickname) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/geofences/add-polygon`;
    const response = await axios.post(
      url,
      { organization, polygon, nickname },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error adding polygon to geofence:', error);
    throw error;
  }
};

export const deletePolygonGeofence = async (id, organization) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/geofences/delete-polygon`;
    const response = await axios.post(
      url,
      { id, organization },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error deleting polygon from geofence:', error);
    throw error;
  }
};

export const updatePolygonGeofence = async (id, organization, newName, polygon) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/geofences/update-polygon`;
    const response = await axios.post(
      url,
      { id, organization, newName, polygon },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error updating polygon geofence:', error);
    throw error;
  }
};

export const fetchImagesForDevices = async (userId, devices, retentionPeriodDays, selectedOrg) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/images`;

    // Extract only the device IDs from the devices array
    const deviceIds = devices.map((device) => device.deviceId);

    const response = await axios.post(
      url,
      { userId, deviceIds, retentionPeriodDays, selectedOrg },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log('Images response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching images for devices:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch images for devices: ${error.message}`);
  }
};

export const archiveContent = async (content, userId, userName) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    // Determine content type based on the content object
    const contentType =
      content.Category === 'BWC photo' || (content.FileName && /\.(jpg|jpeg|png|gif|bmp)$/i.test(content.FileName))
        ? 'image'
        : 'video';

    // Format the request to match backend expectations
    const requestBody = {
      content: {
        fileName: content.FileName,
        deviceId: content.deviceId,
        contentType: contentType,
        id: content.id, // Make sure id is explicitly included
        // Include other necessary fields without spreading the whole object
        // This prevents potential issues with unexpected fields
      },
      userId,
      userName,
    };

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/archive`;
    const response = await axios.post(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error archiving content:', error);
    throw error;
  }
};

export const unarchiveContent = async (content, userId, userName) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    // Determine content type based on the content object
    const contentType =
      content.Category === 'BWC photo' || (content.FileName && /\.(jpg|jpeg|png|gif|bmp)$/i.test(content.FileName))
        ? 'image'
        : 'video';

    const requestBody = {
      content: {
        fileName: content.FileName,
        deviceId: content.deviceId,
        contentType: contentType,
        id: content.id, // Make sure id is explicitly included
      },
      userId,
      userName,
    };

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/unarchive`;
    const response = await axios.post(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error unarchiving content:', error);
    throw error;
  }
};

export const fetchImageByFileName = async (fileName) => {
  try {
    if (!checkTokenValidity()) return;

    let token = localStorage.getItem('access_token');

    if (!token) {
      throw new Error('No access token found');
    }

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/images/${fileName}`;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    console.log('Image response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching image by fileName:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    throw new Error(`Failed to fetch image by fileName: ${error.message}`);
  }
};

export const fetchThemeForOrganization = async (organizationId) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/organizations/get-theme/${organizationId}`;
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching theme for organization:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
    throw error;
  }
};

export const setThemeForOrganization = async (organizationId, theme) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/organizations/set-theme/${organizationId}`;
    const response = await axios.post(
      url,
      { theme },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error setting theme for organization:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
    throw error;
  }
};

export const fetchOrganizationAccessControls = async () => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/organizations/get-access-controls`;
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching organization access controls:', error);
    throw error;
  }
};

export const updateOrganizationAccessControls = async (accessControls) => {
  try {
    if (!checkTokenValidity()) return;
    const token = localStorage.getItem('access_token');

    const url = `${process.env.REACT_APP_BEAVER_API_URL}/api/organizations/update-access-controls`;
    const response = await axios.post(
      url,
      { accessControls },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error updating organization access controls:', error);
    throw error;
  }
};
