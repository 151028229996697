import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl,
  Circle,
  useMapEvents,
  useMap,
  Polygon,
} from 'react-leaflet';
import { getDistance } from 'geolib';
import { format, formatDistanceToNowStrict, isThisYear } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import AddressAutocompleteWrapper from './AddressAutocompleteWrapper';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geometryutil';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import '../styles/MapViewMain.css';
import { FeatureGroup } from 'react-leaflet';
import debounce from 'lodash/debounce';

import { HLSVideoPlayer, StreamControls, getBatteryIcon } from './LivestreamComponents';
import AlertPopup from './AlertPopup';
import VideoPopup from './VideoPopup';
import { Modal } from './LiveStreamModal';
import '../styles/LivePopup.css';
import '../styles/MapControls.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faBellSlash,
  faExclamationCircle,
  faTimes,
  faPlus,
  faCheck,
  faPen,
  faTrash,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';

// Import the images directly
import satellitePreview from '../assets/map-satellite-preview.png';
import monochromePreview from '../assets/map-monochrome-preview.png';

import {
  fetchPolygonGeofencesForOrganization,
  addPolygonGeofence,
  deletePolygonGeofence,
  updatePolygonGeofence,
} from '../api/beaverApi';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import UnsavedChangesPopup from './UnsavedChangesPopup';

const MAP_STYLES = {
  CUSTOM: {
    url: `https://api.mapbox.com/styles/v1/plix-mapbox/cm7f5t14f00bo01rg2m1y4hia/tiles/{z}/{x}/{y}`,
    name: 'Monochrome View',
  },
  STREETS: {
    url: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/{z}/{x}/{y}`,
    name: 'Satellite View',
  },
};

const ZoomDependentSiteMarker = ({ position, icon: initialIcon, eventHandlers }) => {
  const map = useMap();
  const [zoomLevel, setZoomLevel] = useState(map.getZoom());

  useEffect(() => {
    const handleZoomEnd = () => {
      setZoomLevel(map.getZoom());
    };

    map.on('zoomend', handleZoomEnd);

    return () => {
      map.off('zoomend', handleZoomEnd);
    };
  }, [map]);

  const getMarkerSize = () => {
    if (zoomLevel >= 14) return 60;
    if (zoomLevel >= 10) return 40;
    return 20;
  };

  const getMarkerOpacity = () => {
    if (zoomLevel >= 14) return 0.2;
    if (zoomLevel >= 10) return 0.1;
    return 0.05;
  };

  const customIcon = L.divIcon({
    className: 'custom-site-icon',
    html: `<svg width="${getMarkerSize()}" height="${getMarkerSize()}" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="25" fill="red" opacity="${getMarkerOpacity()}"/>
    </svg>`,
    iconSize: [getMarkerSize(), getMarkerSize()],
    iconAnchor: [getMarkerSize() / 2, getMarkerSize() / 2],
    popupAnchor: [0, -getMarkerSize() / 2],
  });

  return <Marker position={position} icon={customIcon} eventHandlers={eventHandlers} />;
};

const DynamicGeofenceCircle = ({ geofence, isSelected, eventHandlers }) => {
  const map = useMap();
  const [zoomLevel, setZoomLevel] = useState(map.getZoom());

  useEffect(() => {
    const handleZoomEnd = () => {
      setZoomLevel(map.getZoom());
    };

    map.on('zoomend', handleZoomEnd);

    return () => {
      map.off('zoomend', handleZoomEnd);
    };
  }, [map]);

  const getOpacity = () => {
    if (zoomLevel >= 14) return 0.2;
    if (zoomLevel >= 10) return 0.2;
    return 0;
  };

  const getWeight = () => {
    return isSelected ? 1 : 0;
  };

  return (
    <Circle
      center={[geofence.latitude, geofence.longitude]}
      radius={geofence.radius}
      pathOptions={{
        color: 'red',
        fillColor: 'red',
        fillOpacity: getOpacity(),
        weight: getWeight(),
      }}
      eventHandlers={eventHandlers}
    />
  );
};

const AccuracyCircle = ({ position, accuracy, visible }) => {
  // only show the circle when the map is not moving to prevent rendering issues
  const map = useMap();
  const [isMapMoving, setIsMapMoving] = useState(false);

  useEffect(() => {
    const handleMoveStart = () => setIsMapMoving(true);
    const handleMoveEnd = () => {
      // Add a small delay to ensure the map has fully settled
      setTimeout(() => setIsMapMoving(false), 100);
    };

    map.on('movestart', handleMoveStart);
    map.on('moveend', handleMoveEnd);

    return () => {
      map.off('movestart', handleMoveStart);
      map.off('moveend', handleMoveEnd);
    };
  }, [map]);

  if (!visible || !accuracy || isMapMoving) return null;

  return (
    <Circle
      center={position}
      radius={accuracy}
      pathOptions={{
        color: '#34d399',
        fillColor: '#34d399',
        fillOpacity: 0.1,
        weight: 1.5,
      }}
    />
  );
};

// function to calculate offsets for overlapping markers
const calculateOffsetPositions = (devices) => {
  const locationMap = new Map();
  const offsetPositions = {};

  // Group devices by location
  devices.forEach((device) => {
    // Create a key from lat,lng with limited precision to group very close points
    const locationKey = `${device.location[0].toFixed(5)},${device.location[1].toFixed(5)}`;

    if (!locationMap.has(locationKey)) {
      locationMap.set(locationKey, []);
    }
    locationMap.get(locationKey).push(device.deviceId);
  });

  // Calculate offsets for overlapping markers
  locationMap.forEach((deviceIds, locationKey) => {
    // If there's only one device at this location, no offset needed
    if (deviceIds.length === 1) return;

    // Calculate offsets for multiple devices
    const [lat, lng] = locationKey.split(',').map(Number);
    const offsetDistance = 0.00005; // ~5.5 meters offset

    deviceIds.forEach((deviceId, index) => {
      // Skip first device (keep it at the original position)
      if (index === 0) {
        offsetPositions[deviceId] = [lat, lng];
        return;
      }

      // Calculate offset angle based on index
      const angle = (Math.PI * 2 * index) / deviceIds.length;

      // Calculate offset position
      const offsetLat = lat + Math.cos(angle) * offsetDistance;
      const offsetLng = lng + Math.sin(angle) * offsetDistance;

      offsetPositions[deviceId] = [offsetLat, offsetLng];
    });
  });

  return offsetPositions;
};

// Create a separate component for popup content to prevent the entire marker tree from re-rendering
const DevicePopupContent = React.memo(({ device, streamingDevices, handleToggleStream }) => {
  /**
   * Formats a date for tooltip display
   * @param {Date|string|number} date - The date to format. Can be:
   *   - A JavaScript Date object
   *   - An ISO 8601 string (e.g. "2023-04-15T14:32:17Z")
   *   - A timestamp in milliseconds
   * @returns {string} Formatted date string for display in tooltips
   */
  const formatTooltipTime = (date) => {
    // console.log('7898 date', date);
    if (!date) return 'Unknown';
    const now = new Date();
    const timeFormat = 'h:mm a'; // Format for time (e.g. "2:30 PM")

    // If the date is from the current year
    if (isThisYear(date)) {
      if (date.toDateString() === now.toDateString()) {
        // Same day
        return `Last located today at ${format(date, timeFormat)}`;
      } else if (date.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString()) {
        // Yesterday
        return `Last located yesterday at ${format(date, timeFormat)}`;
      } else {
        // Different day but same year
        return `Last located ${format(date, 'M/d')} at ${format(date, timeFormat)}`; // e.g. "4/15"
      }
    } else {
      // Different year
      return `Last located ${format(date, 'M/d/yyyy')} at ${format(date, timeFormat)}`; // e.g. "4/15/2022"
    }
  };

  const formatLastLocatedTime = (date) => {
    if (!date) return 'Unknown';
    const now = new Date();
    const distance = formatDistanceToNowStrict(date, { addSuffix: true });
    console.log('7898 distance', distance, date);
    // Replace words with numbers and remove spaces between numbers and units
    const formattedDistance = distance
      .replace(/^about /, '')
      .replace(/^less than /, '')
      .replace(/^an? /, '1')
      .replace(/(\d+|\d+\.\d+)\s+(second|minute|hour|day|month|year)s?/i, (match, p1, p2) => {
        const unit = p2.toLowerCase();
        // Use 'min' for minutes and 'm' for months
        if (unit === 'minute') return `${Math.round(parseFloat(p1))}m`;
        if (unit === 'month') return `${Math.round(parseFloat(p1))}mo`;
        return `${Math.round(parseFloat(p1))}${unit.charAt(0)}`;
      });

    if (formattedDistance.includes('month') || formattedDistance.includes('year')) {
      return formattedDistance;
    } else {
      return formattedDistance
        .replace('minutes', 'm')
        .replace('minute', 'm')
        .replace('hours', 'h')
        .replace('hour', 'h')
        .replace('days', 'd')
        .replace('day', 'd')
        .replace('seconds', 's')
        .replace('second', 's');
    }
  };

  return (
    <div className="video-popup-inner">
      <div className="live-popup-header">
        <div className="device-info">
          <Tooltip title="Owner of device" placement="top">
            <span className="assigned-to">{device.assignedTo}</span>
          </Tooltip>
          <Tooltip title="Device ID shown on device" placement="top">
            <span className="device-id">{device.deviceId}</span>
          </Tooltip>
          <Tooltip title="Battery level" placement="top">
            <span className="device-status">
              {getBatteryIcon(device.batteryStatus, device.isCharging)}
              {device.batteryStatus}%
            </span>
          </Tooltip>
          {formatLastLocatedTime(device.lastLocatedTime) !== 'Unknown' && (
            <Tooltip title={formatTooltipTime(device.lastLocatedTime)} placement="top">
              <span className="last-located-time">
                <span className="pulsating-icon-wrapper">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="pulsating-icon" />
                </span>
                <span className="time-text">{formatLastLocatedTime(device.lastLocatedTime)}</span>
              </span>
            </Tooltip>
          )}
          {!device.isOffline &&
            device.deviceState !== 'Offline' &&
            !device.isRecording &&
            device.isSmartRecordingOff &&
            device.deviceVersion !== 'Mocha' &&
            false && (
              <Tooltip title={'Smart recording off because of poor connection'} placement="top">
                <span className="last-located-time">
                  <span className="pulsating-icon-wrapper">
                    <FontAwesomeIcon icon={faBellSlash} className="" />
                  </span>
                  {/* <span className="time-text">Smart recording off because of poor connection</span> */}
                </span>
              </Tooltip>
            )}
          {/* {device.temperature !== null && device.temperature !== undefined && (
            <Tooltip title="Device Temperature" placement="top">
              <span className="device-temperature">
                <FontAwesomeIcon icon={faThermometerHalf} />
                {convertCelsiusToFahrenheit(device.temperature)}°F
              </span>
            </Tooltip>
          )} */}
        </div>
      </div>
      {device.config.streamAddress &&
      device.config.streamAddress.endsWith('.m3u8') &&
      device.deviceState !== 'Offline' &&
      device.deviceState !== 'Unknown' &&
      (device.liveViewPolicy === 'Only During Recording'
        ? device.deviceState === 'Recording'
        : device.liveViewPolicy === undefined || device.liveViewPolicy === 'During Shift') ? (
        <HLSVideoPlayer
          hlsUrl={device.config.streamAddress}
          deviceId={device.deviceId}
          isStreaming={streamingDevices[device.deviceId] || false}
          onToggleStream={handleToggleStream}
          onError={(error) => console.error('HLS Player Error:', error)}
          deviceState={device.deviceState}
          isOffline={device.isOffline}
          deviceVersion={device?.deviceVersion || ''}
          locationAccuracy={device.locationAccuracy}
        />
      ) : (
        <>
          <div className="offline-message">
            <p>
              {device.tailscaleStatus === 'disconnected'
                ? 'Device is offline'
                : device.isOffline
                  ? 'Device is not activated'
                  : device.deviceState === 'Offline'
                    ? 'Device is not activated'
                    : device.liveViewPolicy === 'Only During Recording' && device.deviceState !== 'Recording'
                      ? 'Live view can only be started if recording'
                      : 'Live stream not available'}
            </p>
          </div>
          {device.locationAccuracy && (
            <div className="location-accuracy-message">
              <p>Approximate location shown</p>
            </div>
          )}
          <StreamControls
            deviceId={device.deviceId}
            deviceState={device.deviceState}
            isStreaming={streamingDevices[device.deviceId] || false}
            onToggleStream={handleToggleStream}
            isOffline={device.isOffline}
          />
        </>
      )}
    </div>
  );
});

// Custom hook to initialize Geoman drawing tools
const useGeomanDrawing = (
  isDrawingMode,
  editMode,
  onCreated,
  storeDrawingLayer,
  setIsActivelyDrawing,
  setEditModeToMove,
  clearError,
  calculatePolygonArea,
  setCurrentDrawingArea,
  setIsAreaSufficient,
  isActivelyDrawing
) => {
  const map = useMap();

  // Initialize auto-panning functionality
  useEffect(() => {
    if (!map || !isDrawingMode || editMode !== 'draw' || !isActivelyDrawing) return;

    let autoPanInterval = null;
    let isPanning = false;
    const edgeThreshold = 50; // Distance from edge to trigger panning
    const maxPanSpeed = 15; // Maximum pixels to pan per interval
    const panInterval = 10; // Milliseconds between pans

    // Create indicator elements
    const container = map.getContainer();
    const leftIndicator = document.createElement('div');
    leftIndicator.className = 'autopan-indicator autopan-indicator-left';
    const rightIndicator = document.createElement('div');
    rightIndicator.className = 'autopan-indicator autopan-indicator-right';
    const topIndicator = document.createElement('div');
    topIndicator.className = 'autopan-indicator autopan-indicator-top';
    const bottomIndicator = document.createElement('div');
    bottomIndicator.className = 'autopan-indicator autopan-indicator-bottom';

    // Add indicators to the map container
    container.appendChild(leftIndicator);
    container.appendChild(rightIndicator);
    container.appendChild(topIndicator);
    container.appendChild(bottomIndicator);

    // Calculate dynamic pan speed based on distance from edge
    const calculatePanSpeed = (distance) => {
      // Closer to edge = faster panning
      // Convert distance to a value between 0 and 1 (0 = at edge, 1 = at threshold)
      const normalizedDistance = Math.min(distance / edgeThreshold, 1);
      // Invert and apply easing for a more natural feel
      const speedFactor = 1 - Math.pow(normalizedDistance, 2);
      // Scale to max speed
      return Math.ceil(speedFactor * maxPanSpeed);
    };

    const handleMouseMove = (e) => {
      if (!isActivelyDrawing) return;

      // Get map container dimensions
      const rect = container.getBoundingClientRect();

      // Calculate distance from edges
      const distFromLeft = e.clientX - rect.left;
      const distFromRight = rect.right - e.clientX;
      const distFromTop = e.clientY - rect.top;
      const distFromBottom = rect.bottom - e.clientY;

      // Determine if cursor is near an edge
      const isNearLeftEdge = distFromLeft < edgeThreshold;
      const isNearRightEdge = distFromRight < edgeThreshold;
      const isNearTopEdge = distFromTop < edgeThreshold;
      const isNearBottomEdge = distFromBottom < edgeThreshold;

      // Update indicator visibility
      leftIndicator.classList.toggle('active', isNearLeftEdge);
      rightIndicator.classList.toggle('active', isNearRightEdge);
      topIndicator.classList.toggle('active', isNearTopEdge);
      bottomIndicator.classList.toggle('active', isNearBottomEdge);

      // Clear existing interval if any
      if (autoPanInterval) {
        clearInterval(autoPanInterval);
        autoPanInterval = null;
        isPanning = false;
      }

      // If cursor is near any edge, start auto-panning
      if (isNearLeftEdge || isNearRightEdge || isNearTopEdge || isNearBottomEdge) {
        isPanning = true;

        autoPanInterval = setInterval(() => {
          // Calculate dynamic pan speeds based on distance from each edge
          const leftSpeed = isNearLeftEdge ? calculatePanSpeed(distFromLeft) : 0;
          const rightSpeed = isNearRightEdge ? calculatePanSpeed(distFromRight) : 0;
          const topSpeed = isNearTopEdge ? calculatePanSpeed(distFromTop) : 0;
          const bottomSpeed = isNearBottomEdge ? calculatePanSpeed(distFromBottom) : 0;

          const panX = isNearLeftEdge ? -leftSpeed : isNearRightEdge ? rightSpeed : 0;
          const panY = isNearTopEdge ? -topSpeed : isNearBottomEdge ? bottomSpeed : 0;

          if (panX !== 0 || panY !== 0) {
            map.panBy([panX, panY], { animate: false });
          }
        }, panInterval);

        // Emit custom event for coordination with other effects
        map.fire('autopan:start', { interval: autoPanInterval });
      }
    };

    // Listen for drawing end events to stop auto-panning
    const handleDrawEnd = () => {
      if (autoPanInterval) {
        clearInterval(autoPanInterval);
        autoPanInterval = null;

        // Hide all indicators
        leftIndicator.classList.remove('active');
        rightIndicator.classList.remove('active');
        topIndicator.classList.remove('active');
        bottomIndicator.classList.remove('active');
      }
    };

    // Add event listeners
    container.addEventListener('mousemove', handleMouseMove);
    map.on('pm:drawend', handleDrawEnd);
    map.on('pm:drawcancel', handleDrawEnd);

    return () => {
      // Clean up
      if (autoPanInterval) {
        clearInterval(autoPanInterval);
      }
      container.removeEventListener('mousemove', handleMouseMove);
      map.off('pm:drawend', handleDrawEnd);
      map.off('pm:drawcancel', handleDrawEnd);

      // Remove indicator elements
      if (container.contains(leftIndicator)) container.removeChild(leftIndicator);
      if (container.contains(rightIndicator)) container.removeChild(rightIndicator);
      if (container.contains(topIndicator)) container.removeChild(topIndicator);
      if (container.contains(bottomIndicator)) container.removeChild(bottomIndicator);
    };
  }, [map, isDrawingMode, editMode, isActivelyDrawing]);

  // Initialize Geoman once when the map is ready
  useEffect(() => {
    if (!map) return;

    // Make sure Geoman is initialized
    if (!map.pm) {
      console.error('Geoman is not available on the map object');
      return;
    }

    // Configure Geoman
    map.pm.setGlobalOptions({
      layerGroup: new L.FeatureGroup().addTo(map),
      // Remove premium measurement options
    });

    // Reference to auto-pan interval for cleanup
    let autoPanIntervalRef = null;

    // Function to clear auto-panning
    const clearAutoPanning = () => {
      if (autoPanIntervalRef) {
        clearInterval(autoPanIntervalRef);
        autoPanIntervalRef = null;

        // Remove active class from all indicators
        const indicators = document.querySelectorAll('.autopan-indicator');
        indicators.forEach((indicator) => {
          indicator.classList.remove('active');
        });
      }
    };

    // Add event listener for when a shape is created
    const handleCreate = (e) => {
      const layer = e.layer;
      const geoJson = layer.toGeoJSON();
      console.log('Created GeoJSON polygon:', geoJson);

      // Clear any auto-panning
      clearAutoPanning();

      // Store the layer reference for later removal
      if (storeDrawingLayer) {
        storeDrawingLayer(layer);
      }

      // Call the onCreated callback with the GeoJSON data
      if (onCreated) {
        onCreated(geoJson, layer);
      }

      // Reset drawing state
      setIsActivelyDrawing(false);
      setCurrentDrawingArea(0);
    };

    // Add event listener for when drawing starts
    const handleDrawStart = () => {
      console.log('Drawing started');
      setIsActivelyDrawing(true);
      setCurrentDrawingArea(0);
      setIsAreaSufficient(true);

      // Add a right-click handler to the map for finishing the polygon
      const handleRightClick = (e) => {
        console.log('Right-click detected, finishing polygon');
        // This is just for logging, the actual finishing is handled by Geoman

        // Clear any auto-panning
        clearAutoPanning();
      };

      // Add the right-click event listener
      map.on('contextmenu', handleRightClick);

      // Store the handler for cleanup
      map._rightClickHandler = handleRightClick;
    };

    // Add event listener for when drawing is cancelled
    const handleDrawCancel = () => {
      console.log('Drawing cancelled');
      setIsActivelyDrawing(false);
      setCurrentDrawingArea(0);

      // Clear any auto-panning
      clearAutoPanning();

      // Set edit mode to move when drawing is cancelled
      if (setEditModeToMove) {
        setEditModeToMove();
      }

      // Clear any errors
      if (clearError) {
        clearError();
      }

      // Remove the right-click handler if it exists
      if (map._rightClickHandler) {
        map.off('contextmenu', map._rightClickHandler);
        delete map._rightClickHandler;
      }
    };

    // Add event listener for when drawing is finished
    const handleDrawFinish = () => {
      console.log('Drawing finished');
      setCurrentDrawingArea(0);

      // Clear any auto-panning
      clearAutoPanning();

      // Remove the right-click handler if it exists
      if (map._rightClickHandler) {
        map.off('contextmenu', map._rightClickHandler);
        delete map._rightClickHandler;
      }
    };

    // Add event listener for vertex added during drawing
    const handleVertexAdded = (e) => {
      if (isActivelyDrawing && e.workingLayer) {
        const area = calculatePolygonArea(e.workingLayer);
        setCurrentDrawingArea(area);
        setIsAreaSufficient(area >= 10000); // 100m x 100m = 10000 sq meters
      }
    };

    // Add keyboard event listener for escape key to cancel drawing
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isActivelyDrawing) {
        console.log('Escape key pressed, cancelling polygon drawing');

        // Cancel the drawing
        if (map.pm && map.pm.Draw) {
          map.pm.Draw.disable();
        }

        // Reset drawing state
        setIsActivelyDrawing(false);
        setCurrentDrawingArea(0);

        // Clear any auto-panning
        clearAutoPanning();

        // Set edit mode to move
        if (setEditModeToMove) {
          setEditModeToMove();
        }

        // Clear any errors
        if (clearError) {
          clearError();
        }
      }
    };

    // Store reference to auto-pan interval
    map.on('autopan:start', (e) => {
      autoPanIntervalRef = e.interval;
    });

    // Add event listeners
    document.addEventListener('keydown', handleKeyDown);
    map.on('pm:create', handleCreate);
    map.on('pm:drawstart', handleDrawStart);
    map.on('pm:drawcancel', handleDrawCancel);
    map.on('pm:drawend', handleDrawFinish);
    map.on('pm:vertexadded', handleVertexAdded);

    return () => {
      // Clean up event listeners
      document.removeEventListener('keydown', handleKeyDown);
      map.off('pm:create', handleCreate);
      map.off('pm:drawstart', handleDrawStart);
      map.off('pm:drawcancel', handleDrawCancel);
      map.off('pm:drawend', handleDrawFinish);
      map.off('pm:vertexadded', handleVertexAdded);
      map.off('autopan:start');

      // Clear any auto-panning
      clearAutoPanning();

      // Remove the right-click handler if it exists
      if (map._rightClickHandler) {
        map.off('contextmenu', map._rightClickHandler);
        delete map._rightClickHandler;
      }
    };
  }, [
    map,
    onCreated,
    storeDrawingLayer,
    setIsActivelyDrawing,
    setEditModeToMove,
    clearError,
    calculatePolygonArea,
    setCurrentDrawingArea,
    setIsAreaSufficient,
    isActivelyDrawing,
  ]);

  // Handle drawing mode and edit mode changes
  useEffect(() => {
    if (!map || !map.pm) return;

    // Disable all drawing tools first
    map.pm.disableDraw();

    // Reset active drawing state when mode changes
    if (editMode !== 'draw') {
      setIsActivelyDrawing(false);
    }

    // Enable/disable map dragging based on mode
    if (isDrawingMode) {
      if (editMode === 'draw') {
        // Disable map dragging when drawing
        map.dragging.disable();

        // Add custom translations for tooltips
        const customTranslation = {
          tooltips: {
            placeMarker: 'Click to place marker',
            continueLine: 'Click to continue the boundary',
            finishLine: 'Click last point or right-click to finish line',
            finishPoly: 'Click first point/right-click to finish. Press ESC to cancel.',
            placeCircleMarker: 'Click to place circle marker',
            placeCircle: 'Click to place circle',
            placeRectangle: 'Click to place rectangle',
            placeText: 'Click to place text',
            firstVertex: 'Click to place the first point of the boundary',
          },
        };

        // Set custom language for tooltips
        map.pm.setLang('customTooltips', customTranslation, 'en');

        // Enable polygon drawing
        map.pm.enableDraw('Polygon', {
          snappable: true,
          allowSelfIntersection: false,
          templineStyle: {
            color: 'red',
          },
          hintlineStyle: {
            color: 'red',
            dashArray: [5, 5],
          },
          pathOptions: {
            color: 'red',
            fillColor: 'red',
            fillOpacity: 0.2,
          },
          finishOn: 'contextmenu', // Right-click to finish drawing
          tooltips: true,
          // Remove premium measurement options
        });
      } else {
        // Re-enable map dragging for move mode
        map.dragging.enable();
      }
    } else {
      // Re-enable map dragging when not in drawing mode
      map.dragging.enable();
    }
  }, [map, isDrawingMode, editMode, setIsActivelyDrawing]);
};

// Form for geofence properties
const GeofenceForm = ({ onSave, onCancel, initialNickname = '' }) => {
  const [nickname, setNickname] = useState(initialNickname);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(nickname || 'Unnamed Site');
    // Reset nickname after saving
    setNickname('');
  };

  const handleCancel = () => {
    // Reset nickname before canceling
    setNickname('');
    onCancel();
  };

  const startEditing = () => {
    setIsEditing(true);
    // Focus the input after it renders
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  // Update the nickname when initialNickname changes
  useEffect(() => {
    setNickname(initialNickname);
  }, [initialNickname]);

  // Start editing automatically when the form is first shown
  useEffect(() => {
    startEditing();
  }, []);

  return (
    <div className="geofence-popup new-site">
      <form onSubmit={handleSubmit}>
        <div className="geofence-header">
          {isEditing ? (
            <input
              ref={inputRef}
              type="text"
              className="geofence-title-input"
              placeholder="Enter site name"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
            />
          ) : (
            <div className="geofence-title" onClick={startEditing}>
              <h4>{nickname || 'Set Site Name'}</h4>
              <FontAwesomeIcon icon={faPen} className="edit-icon" />
            </div>
          )}
        </div>

        <div className="geofence-actions">
          <div></div>
          <div className="right-actions">
            <button type="button" className="cancel-button" onClick={handleCancel}>
              Cancel
            </button>
            <button type="submit" className="save-button">
              <FontAwesomeIcon icon={faCheck} style={{ marginRight: '6px' }} />
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

// Create a separate component for Geoman controls that will be rendered inside the MapContainer
const GeomanControls = ({
  isDrawingMode,
  editMode,
  onCreated,
  storeDrawingLayer,
  setIsActivelyDrawing,
  setEditModeToMove,
  clearError,
  calculatePolygonArea,
  setCurrentDrawingArea,
  setIsAreaSufficient,
  isActivelyDrawing,
}) => {
  // This hook can only be used inside a MapContainer
  useGeomanDrawing(
    isDrawingMode,
    editMode,
    onCreated,
    storeDrawingLayer,
    setIsActivelyDrawing,
    setEditModeToMove,
    clearError,
    calculatePolygonArea,
    setCurrentDrawingArea,
    setIsAreaSufficient,
    isActivelyDrawing
  );
  return null;
};

// Error notification component
const GeofenceErrorNotification = ({ message, onClose }) => {
  // Auto-close after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="geofence-error-notification">
      <div className="geofence-error-notification-content">
        <FontAwesomeIcon icon={faExclamationCircle} className="geofence-error-notification-icon" />
        <div className="geofence-error-notification-message">{message}</div>
      </div>
      <button className="geofence-error-notification-close" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
};

function MapViewMain({
  memoizedDevices = [],
  deviceShifts = {},
  handleToggleStream,
  streamingDevices,
  mapRef,
  markerRefs,
  alertMarkerRef,
  activeDevice,
  activeAlert,
  alertMarker,
  activePopup,
  handleOpenPopup,
  handleClosePopup,
  handleCloseAlertPopup,
  geofences = [],
  userId,
  previewGeofence,
  onPreviewGeofenceRadiusChange,
  selectedGeofence,
  setActiveDevice,
  selectedVideo,
  onCloseVideoPopup,
  handleVideoSelect,
  selectedOrg,
  onSiteClick,
  setSelectedGeofence,
  polygonGeofences = [],
  setPolygonGeofences,
  activeTab = 'devices',
  editMode,
  setEditMode,
  activeGeofence,
  setActiveGeofence,
  originalGeofenceRef,
  storeOriginalGeofenceState,
  clearOriginalGeofenceState,
  getOriginalGeofenceState,
  isMobile = false,
  userRole,
}) {
  // All state declarations
  const [modalDevice, setModalDevice] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedMarkerId, setSelectedMarkerId] = useState(null);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [geofenceEditMode, setGeofenceEditModeState] = useState('move');
  const [isActivelyDrawing, setIsActivelyDrawing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentDrawingArea, setCurrentDrawingArea] = useState(0);
  const [isAreaSufficient, setIsAreaSufficient] = useState(false);
  const [isSelectingGeofence, setIsSelectingGeofence] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const [showUnsavedChangesPrompt, setShowUnsavedChangesPrompt] = useState(false);
  const [isMapMoving, setIsMapMoving] = useState(false);

  // Track if map style has been manually overridden
  const [manualMapStyleOverride, setManualMapStyleOverride] = useState(() => {
    return localStorage.getItem('mapStyle') !== null;
  });

  // State for drawn geofences
  const [drawnGeofences, setDrawnGeofences] = useState([]);
  const [currentGeofence, setCurrentGeofence] = useState(null);
  const [showGeofenceForm, setShowGeofenceForm] = useState(false);
  const [currentDrawingLayer, setCurrentDrawingLayer] = useState(null);

  // Add a ref to track polygon layers
  const polygonLayersRef = useRef({});

  // Add a ref to track last valid polygon states
  const lastValidPolygonStatesRef = useRef({});

  // Use the passed originalGeofenceRef functions instead of direct manipulation
  useEffect(() => {
    if (editMode && selectedGeofence && !originalGeofenceRef.current) {
      storeOriginalGeofenceState(selectedGeofence);
    } else if (!editMode) {
      clearOriginalGeofenceState();
    }
  }, [editMode, selectedGeofence, storeOriginalGeofenceState, clearOriginalGeofenceState, originalGeofenceRef]);

  // Effect for enabling/disabling global edit mode
  useEffect(() => {
    if (mapRef.current) {
      // First disable global edit mode to reset everything
      mapRef.current.pm.disableGlobalEditMode();

      if (editMode && selectedGeofence) {
        // Enable global edit mode with options
        mapRef.current.pm.enableGlobalEditMode({
          allowSelfIntersection: false,
          snapDistance: 15,
          snapMiddle: true,
          snapSegment: true,
          finishOn: 'contextmenu',
          editable: true,
          draggable: true,
          removeVertexOn: 'contextmenu',
        });

        // Disable editing for all layers except the selected one
        Object.entries(polygonLayersRef.current).forEach(([id, layer]) => {
          if (id !== selectedGeofence.id) {
            // Disable editing for non-selected layers
            layer.pm.disable();
          } else {
            // Make sure the selected layer is editable
            layer.pm.enable();
          }
        });

        // If editing a polygon geofence, close any open device popups
        if (selectedGeofence.type === 'polygon') {
          // Close any open device popups
          Object.keys(markerRefs.current).forEach((deviceId) => {
            const marker = markerRefs.current[deviceId];
            if (marker && marker.isPopupOpen()) {
              marker.closePopup();
            }
          });

          // Close alert popup if open
          if (alertMarkerRef.current && alertMarkerRef.current.isPopupOpen()) {
            alertMarkerRef.current.closePopup();
            handleCloseAlertPopup();
          }

          // Close video popup if open
          if (videoMarkerRef.current && videoMarkerRef.current.isPopupOpen()) {
            videoMarkerRef.current.closePopup();
            onCloseVideoPopup();
          }

          // Reset active device
          if (activeDevice) {
            setActiveDevice(null);
          }
        }
      } else {
        // When edit mode is off, make sure all layers have editing disabled
        Object.values(polygonLayersRef.current).forEach((layer) => {
          layer.pm.disable();
        });
      }
    }
  }, [editMode, selectedGeofence, activeDevice, setActiveDevice, handleCloseAlertPopup, onCloseVideoPopup]);

  // Effect to close device popups when entering drawing mode
  useEffect(() => {
    if (isDrawingMode) {
      // Close any open device popups
      Object.keys(markerRefs.current).forEach((deviceId) => {
        const marker = markerRefs.current[deviceId];
        if (marker && marker.isPopupOpen()) {
          marker.closePopup();
        }
      });

      // Close alert popup if open
      if (alertMarkerRef.current && alertMarkerRef.current.isPopupOpen()) {
        alertMarkerRef.current.closePopup();
        handleCloseAlertPopup();
      }

      // Close video popup if open
      if (videoMarkerRef.current && videoMarkerRef.current.isPopupOpen()) {
        videoMarkerRef.current.closePopup();
        onCloseVideoPopup();
      }

      // Reset active device
      if (activeDevice) {
        setActiveDevice(null);
      }
    }
  }, [isDrawingMode, activeDevice, setActiveDevice, handleCloseAlertPopup, onCloseVideoPopup]);

  // Add a function to store polygon layer references
  const storePolygonLayerRef = useCallback((id, layer) => {
    polygonLayersRef.current[id] = layer;
  }, []);

  // Helper function to check if a polygon is large enough
  const isPolygonLargeEnough = useCallback((layer) => {
    if (!layer || !layer.getLatLngs || !layer._latlngs) {
      console.error('Invalid layer for size check');
      return false;
    }

    try {
      // Get the coordinates of the polygon
      const polygonCoords = layer.getLatLngs()[0];
      if (!polygonCoords || polygonCoords.length < 3) {
        console.error('Invalid polygon coordinates');
        return false;
      }

      // Calculate the area of the polygon in square meters
      const latlngs = polygonCoords.map((coord) => [coord.lat, coord.lng]);

      // Create a temporary Leaflet polygon to calculate area
      const tempPolygon = L.polygon(latlngs);
      const area = tempPolygon.getLatLngs()[0].reduce((area, point, i, points) => {
        const j = (i + 1) % points.length;
        return area + ((points[j].lng - point.lng) * (points[j].lat + point.lat)) / 2;
      }, 0);

      // Convert to square meters (approximate)
      // 1 degree of latitude is approximately 111,320 meters
      const areaInSquareMeters = Math.abs(area) * 111320 * 111320;

      console.log('Polygon area:', areaInSquareMeters, 'square meters');

      // Check if the area is at least 100 square meters (10m x 10m)
      return areaInSquareMeters >= 10000;
    } catch (error) {
      console.error('Error checking polygon size:', error);
      return false;
    }
  }, []);

  // Add handlePolygonEdit hook here at the top level
  const handlePolygonEdit = useCallback(
    (e, geofenceId) => {
      // Make sure we have a valid event and target
      if (!e || !e.target) {
        console.error('Invalid edit event or missing target');
        return;
      }

      const layer = e.target;

      // Check if the modified polygon is large enough
      if (!isPolygonLargeEnough(layer)) {
        // Show error notification
        setErrorMessage('Area must be at least the size of a street block.');

        // Try to get the last valid state first, fall back to original state if not available
        const lastValidState = lastValidPolygonStatesRef.current[geofenceId];
        const originalGeofence = getOriginalGeofenceState();
        const revertToState = lastValidState || originalGeofence;

        if (revertToState && revertToState.boundaryCoordinates) {
          try {
            // Temporarily disable editing to prevent conflicts during reset
            if (layer.pm) {
              layer.pm.disable();
            }

            // Reset the layer's coordinates to match the last valid state or original state
            const validCoords = revertToState.boundaryCoordinates.map((coord) => [coord.lat, coord.lng]);
            layer.setLatLngs(validCoords);

            // Force a redraw
            layer.redraw();

            // Re-enable editing with a slight delay to ensure the layer has been properly redrawn
            setTimeout(() => {
              if (layer.pm) {
                layer.pm.enable();
              }
            }, 50);
          } catch (error) {
            console.error('Error reverting polygon to valid state:', error);
          }

          return;
        }
        return;
      }

      try {
        const latLngs = layer.getLatLngs()[0];
        const coordinates = latLngs.map((latLng) => ({
          lat: latLng.lat,
          lng: latLng.lng,
        }));

        // This is a valid edit, so store it as the last valid state for this polygon
        lastValidPolygonStatesRef.current[geofenceId] = {
          id: geofenceId,
          type: 'polygon',
          boundaryCoordinates: coordinates,
        };

        // Instead of immediately updating the database, update the local state
        // and mark the geofence as modified
        setPolygonGeofences((prevGeofences) =>
          prevGeofences.map((g) =>
            g.id === geofenceId
              ? {
                  ...g,
                  boundaryCoordinates: coordinates,
                  isModified: true, // Add a flag to indicate this geofence has unsaved changes
                }
              : g
          )
        );

        // If this geofence is currently selected, update the selected geofence as well
        if (selectedGeofence && selectedGeofence.id === geofenceId) {
          setSelectedGeofence((prev) => ({
            ...prev,
            boundaryCoordinates: coordinates,
            isModified: true,
          }));
        }
      } catch (error) {
        console.error('Error processing polygon edit:', error);
      }
    },
    [
      setPolygonGeofences,
      selectedGeofence,
      setSelectedGeofence,
      isPolygonLargeEnough,
      setErrorMessage,
      getOriginalGeofenceState,
    ]
  );

  // Custom function to set geofence edit mode with proper state handling
  const setGeofenceEditMode = (mode) => {
    // If switching from draw mode to move mode while actively drawing
    if (geofenceEditMode === 'draw' && mode === 'move' && isActivelyDrawing) {
      // Cancel the active drawing if in progress
      if (mapRef.current && mapRef.current.pm) {
        mapRef.current.pm.Draw.disable();
        setIsActivelyDrawing(false);
      }
    }

    // Update the edit mode state
    setGeofenceEditModeState(mode);
  };

  // Initialize map style based on theme if no manual override exists
  const [currentMapStyle, setCurrentMapStyle] = useState(() => {
    const savedStyle = localStorage.getItem('mapStyle');
    if (savedStyle) {
      return MAP_STYLES[savedStyle];
    } else {
      const currentTheme = document.documentElement.getAttribute('data-theme');
      return currentTheme === 'dark' ? MAP_STYLES.CUSTOM : MAP_STYLES.STREETS;
    }
  });

  // All useRef declarations
  const videoMarkerRef = useRef(null);

  const [selectedAddress, setSelectedAddress] = useState(null);

  const handleAddressSelect = useCallback(
    (result) => {
      console.log('geofences: Address selected in MapViewMain', result);
      setSelectedAddress(result);
      console.log('selectedAddress', selectedAddress);
      onSiteClick(result.center[1], result.center[0]);
    },
    [onSiteClick]
  );

  const handleAddressInputChange = useCallback(() => {
    setSelectedAddress(null);
  }, []);

  // All useCallback declarations - these must come before they're used in other hooks

  // Function to store the drawing layer reference
  const storeDrawingLayer = useCallback(
    (layer) => {
      // If there's an existing layer, remove it first
      if (currentDrawingLayer) {
        currentDrawingLayer.remove();
      }
      setCurrentDrawingLayer(layer);
    },
    [currentDrawingLayer]
  );

  // Function to perform reverse geocoding to get address from coordinates
  const reverseGeocode = async (lat, lng) => {
    try {
      const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleMapsKey}`;

      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK' && data.results.length > 0) {
        // Get the first result as it's usually the most precise
        const result = data.results[0];

        // Return a formatted address or the address components that are most relevant
        return result.formatted_address;
      } else {
        console.warn('No results found or invalid status in geocoding response');
        return null;
      }
    } catch (error) {
      console.error('Error in reverse geocoding:', error);
      return null;
    }
  };

  // Handle created geofence
  const handleCreated = useCallback(
    async (geoJson, layer) => {
      // Check if the new polygon is large enough
      if (!isPolygonLargeEnough(layer)) {
        // Show error notification
        setErrorMessage('Area must be at least the size of a street block.');

        // Remove the drawn layer
        if (layer && layer.remove) {
          layer.remove();
        }

        // Re-enable drawing mode immediately without clearing the error
        if (mapRef.current && mapRef.current.pm) {
          // Don't clear the error message here
          setTimeout(() => {
            mapRef.current.pm.enableDraw('Polygon', {
              snappable: true,
              allowSelfIntersection: false,
              templineStyle: {
                color: 'red',
              },
              hintlineStyle: {
                color: 'red',
                dashArray: [5, 5],
              },
              pathOptions: {
                color: 'red',
                fillColor: 'red',
                fillOpacity: 0.2,
              },
              finishOn: 'contextmenu',
              tooltips: true,
            });
            setIsActivelyDrawing(true);
          }, 0);
        }

        return;
      }

      // Create a new geofence object
      const id = uuidv4();
      const now = new Date().getTime();

      // Create a new geofence object
      const newGeofence = {
        id,
        createdAt: now,
        updatedAt: now,
        type: 'polygon',
        geometry: geoJson.geometry,
      };

      // Calculate centroid to find nearest address
      let centerLat = 0;
      let centerLng = 0;
      let validPoints = 0;

      // Process coordinates to calculate the centroid
      if (geoJson.geometry && geoJson.geometry.coordinates && geoJson.geometry.coordinates.length > 0) {
        const coordinates = geoJson.geometry.coordinates[0];
        coordinates.forEach((coord) => {
          if (!isNaN(coord[0]) && !isNaN(coord[1])) {
            centerLng += coord[0];
            centerLat += coord[1];
            validPoints++;
          }
        });

        if (validPoints > 0) {
          centerLat = centerLat / validPoints;
          centerLng = centerLng / validPoints;

          // Only do reverse geocoding if we're not using a searched address
          if (!selectedAddress) {
            // Get address for the polygon centroid
            const address = await reverseGeocode(centerLat, centerLng);
            if (address) {
              // Set the initial nickname to the nearest address
              newGeofence.nickname = address;
            }
          } else {
            // If we have a selected address from search, use its name
            newGeofence.nickname = selectedAddress.place_name || '';
          }
        }
      }

      // Add the new geofence to the drawnGeofences array
      setDrawnGeofences((prev) => [...prev, newGeofence]);

      // Set the current geofence
      setCurrentGeofence(newGeofence);

      // Show the geofence form
      setShowGeofenceForm(true);

      // Switch back to move mode after drawing
      setGeofenceEditModeState('move');
    },
    [
      setDrawnGeofences,
      setCurrentGeofence,
      setShowGeofenceForm,
      setGeofenceEditModeState,
      setIsActivelyDrawing,
      isPolygonLargeEnough,
      selectedAddress,
    ]
  );

  // Function to clear error message
  const clearErrorMessage = useCallback(() => {
    setErrorMessage('');
  }, []);

  // Function to calculate the area of a polygon during drawing
  const calculatePolygonArea = useCallback((layer) => {
    if (!layer || !layer.getLatLngs || !layer._latlngs) {
      return 0;
    }

    try {
      // Get the coordinates of the polygon
      const polygonCoords = layer.getLatLngs()[0];
      if (!polygonCoords || polygonCoords.length < 3) {
        return 0;
      }

      // Calculate the area of the polygon in square meters
      const latlngs = polygonCoords.map((coord) => [coord.lat, coord.lng]);

      // Create a temporary Leaflet polygon to calculate area
      const tempPolygon = L.polygon(latlngs);
      const area = tempPolygon.getLatLngs()[0].reduce((area, point, i, points) => {
        const j = (i + 1) % points.length;
        return area + ((points[j].lng - point.lng) * (points[j].lat + point.lat)) / 2;
      }, 0);

      // Convert to square meters (approximate)
      // 1 degree of latitude is approximately 111,320 meters
      const areaInSquareMeters = Math.abs(area) * 111320 * 111320;

      return areaInSquareMeters;
    } catch (error) {
      console.error('Error calculating polygon area:', error);
      return 0;
    }
  }, []);

  // All useEffect declarations

  useEffect(() => {
    if (selectedVideo && videoMarkerRef.current) {
      videoMarkerRef.current.openPopup();
    }
  }, [selectedVideo]);

  // Make sure that circular geofences cannot get into edit mode
  useEffect(() => {
    if (editMode && selectedGeofence && selectedGeofence.type !== 'polygon') {
      // If trying to edit a circular geofence, disable edit mode
      console.log('Preventing edit mode for circular geofence');
      setEditMode(false);
    }
  }, [editMode, selectedGeofence, setEditMode]);

  const devicesWithShiftData = useMemo(() => {
    return memoizedDevices.map((device) => ({
      ...device,
      ...deviceShifts[device.deviceId],
    }));
  }, [memoizedDevices, deviceShifts]);

  // Calculate offset positions for overlapping markers
  const offsetPositions = useMemo(() => {
    return calculateOffsetPositions(devicesWithShiftData);
  }, [devicesWithShiftData]);

  const MapClickHandler = () => {
    const map = useMapEvents({
      click: () => {
        // Only clear active device if not in drawing mode
        if (!isDrawingMode) {
          setActiveDevice(null);
        }
      },
    });
    return null;
  };

  const fitMapToGeofence = useCallback(
    (geofence) => {
      if (!mapRef.current || !geofence) {
        console.error('Map reference or geofence is missing');
        return;
      }

      const map = mapRef.current;

      try {
        console.log('Fitting map to geofence:', JSON.stringify(geofence));

        if (geofence.type === 'polygon' && geofence.boundaryCoordinates && geofence.boundaryCoordinates.length > 0) {
          console.log('Fitting map to polygon geofence with boundary coordinates');

          // Create a bounds object from the boundary coordinates
          const bounds = L.latLngBounds();
          let validCoordinatesFound = false;

          // Add each valid coordinate to the bounds
          geofence.boundaryCoordinates.forEach((coord) => {
            if (
              coord &&
              typeof coord.lat === 'number' &&
              !isNaN(coord.lat) &&
              typeof coord.lng === 'number' &&
              !isNaN(coord.lng)
            ) {
              bounds.extend([coord.lat, coord.lng]);
              validCoordinatesFound = true;
              console.log('Added valid coordinate to bounds:', coord.lat, coord.lng);
            } else {
              console.warn('Invalid coordinate skipped:', coord);
            }
          });

          // If we have valid bounds, fit the map to them
          if (validCoordinatesFound && bounds.isValid()) {
            console.log('Fitting map to valid bounds');
            map.fitBounds(bounds, {
              padding: [50, 50], // Add padding to ensure the polygon is fully visible
              animate: true,
              duration: 1.5,
            });
          } else {
            // Fallback to centroid if bounds are invalid
            console.warn('Invalid bounds for polygon geofence, falling back to centroid');
            if (
              typeof geofence.latitude === 'number' &&
              !isNaN(geofence.latitude) &&
              typeof geofence.longitude === 'number' &&
              !isNaN(geofence.longitude)
            ) {
              console.log('Using centroid coordinates:', geofence.latitude, geofence.longitude);
              map.flyTo([geofence.latitude, geofence.longitude], 15, { animate: true, duration: 1.5 });
            } else {
              console.error('Invalid centroid coordinates, cannot focus on geofence');
            }
          }
        } else if (geofence.type === 'circle' || !geofence.type) {
          // Handle circular geofences or legacy geofences without a type
          console.log('Fitting map to circular geofence');

          if (
            typeof geofence.latitude === 'number' &&
            !isNaN(geofence.latitude) &&
            typeof geofence.longitude === 'number' &&
            !isNaN(geofence.longitude)
          ) {
            const center = [geofence.latitude, geofence.longitude];
            const radius = typeof geofence.radius === 'number' && !isNaN(geofence.radius) ? geofence.radius : 100; // Default radius if not provided

            try {
              console.log('Calculating zoom level for circular geofence');
              const zoom = map.getBoundsZoom(
                L.latLngBounds([
                  [
                    geofence.latitude - radius / 111320,
                    geofence.longitude - radius / (111320 * Math.cos((geofence.latitude * Math.PI) / 180)),
                  ],
                  [
                    geofence.latitude + radius / 111320,
                    geofence.longitude + radius / (111320 * Math.cos((geofence.latitude * Math.PI) / 180)),
                  ],
                ]),
                false,
                [50, 50] // Padding to ensure the circle is fully visible
              );
              console.log('Flying to center with calculated zoom:', zoom);
              map.flyTo(center, zoom, { animate: true, duration: 1.5 });
            } catch (error) {
              console.error('Error calculating zoom level:', error);
              // Fallback to a default zoom level
              console.log('Flying to center with default zoom');
              map.flyTo(center, 15, { animate: true, duration: 1.5 });
            }
          } else {
            console.error('Invalid coordinates for circular geofence:', geofence);
          }
        } else {
          console.error('Unknown geofence type or missing data:', geofence);
        }
      } catch (error) {
        console.error('Error in fitMapToGeofence:', error);
      }
    },
    [mapRef]
  );

  useEffect(() => {
    if (selectedGeofence && !editMode) {
      fitMapToGeofence(selectedGeofence);
    }
  }, [selectedGeofence, fitMapToGeofence, editMode]);

  // Update map style when theme changes (if not manually overridden)
  useEffect(() => {
    if (!manualMapStyleOverride) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === 'data-theme') {
            const newTheme = document.documentElement.getAttribute('data-theme');
            const themeBasedStyle = newTheme === 'dark' ? MAP_STYLES.CUSTOM : MAP_STYLES.STREETS;
            setCurrentMapStyle(themeBasedStyle);
          }
        });
      });

      observer.observe(document.documentElement, { attributes: true });

      return () => observer.disconnect();
    }
  }, [manualMapStyleOverride]);

  useEffect(() => {
    if (editMode) {
      setIsDrawingMode(true);
    }
  }, [editMode]);

  // Hide other UI elements when in drawing mode
  useEffect(() => {
    const devicesContainer = document.querySelector('.devices-container');
    const alertsContainer = document.querySelector('.alerts-container');

    if (isDrawingMode) {
      if (devicesContainer) devicesContainer.style.display = 'none';
      if (alertsContainer) alertsContainer.style.display = 'none';
    } else {
      if (devicesContainer) devicesContainer.style.display = '';
      if (alertsContainer) alertsContainer.style.display = '';
    }
  }, [isDrawingMode]);
  // Add debounced resize handler
  useEffect(() => {
    const handleResize = debounce(() => {
      if (mapRef.current) {
        mapRef.current.invalidateSize();
      }
    }, 100);

    window.addEventListener('resize', handleResize);

    return () => {
      handleResize.cancel();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Show drawing instructions when in draw mode
  // useEffect(() => {
  //   if (isDrawingMode && geofenceEditMode === 'draw') {
  //     // Add drawing instructions
  //     const instructionsDiv = L.DomUtil.create('div', 'drawing-instructions');
  //     instructionsDiv.innerHTML = `
  //       <p>Click to place points along the site's boundary.</p>
  //       <p>Move cursor to the edge of the screen to <strong>auto-pan</strong> and draw larger areas.</p>
  //       <p>Right-click to close and finish the polygon.</p>
  //       <p>Press <strong>ESC</strong> key to cancel drawing.</p>
  //     `;
  //     document.body.appendChild(instructionsDiv);

  //     return () => {
  //       // Remove instructions when unmounting or mode changes
  //       const instructions = document.querySelector('.drawing-instructions');
  //       if (instructions) {
  //         document.body.removeChild(instructions);
  //       }
  //     };
  //   }
  // }, [isDrawingMode, geofenceEditMode]);

  // Fetch polygon geofences
  const fetchAndLogPolygonGeofences = useCallback(async () => {
    const orgId =
      userId === PLIX_OVERWATCH_ACCOUNT ? (selectedOrg === PLIX_OVERWATCH_ACCOUNT ? undefined : selectedOrg) : userId;
    try {
      console.log('Fetching polygon geofences for organization:', orgId);
      const fetchedPolygonGeofences = await fetchPolygonGeofencesForOrganization(orgId);
      console.log('Fetched polygon geofences (raw):', JSON.stringify(fetchedPolygonGeofences));

      // Process the fetched geofences to ensure they have the correct format
      const processedGeofences = fetchedPolygonGeofences.map((geofence) => {
        // Ensure the geofence has an id
        if (!geofence.id && geofence.geofence_id) {
          geofence.id = geofence.geofence_id;
        }

        // Ensure the geofence has a type
        if (!geofence.type) {
          geofence.type = 'polygon';
        }

        // Ensure the geofence has a nickname
        if (!geofence.nickname) {
          geofence.nickname = geofence.name || 'Unnamed Polygon';
        }

        // Process boundary coordinates if they exist
        if (geofence.geometry && geofence.geometry.coordinates && geofence.geometry.coordinates.length > 0) {
          // Convert GeoJSON coordinates to the format expected by the application
          const coordinates = geofence.geometry.coordinates[0].map((coord) => ({
            lng: coord[0],
            lat: coord[1],
          }));

          geofence.boundaryCoordinates = coordinates;

          // Calculate centroid
          let centerLat = 0;
          let centerLng = 0;
          let validPoints = 0;

          coordinates.forEach((point) => {
            if (!isNaN(point.lng) && !isNaN(point.lat)) {
              centerLng += point.lng;
              centerLat += point.lat;
              validPoints++;
            }
          });

          if (validPoints > 0) {
            geofence.latitude = centerLat / validPoints;
            geofence.longitude = centerLng / validPoints;
          }
        }

        return geofence;
      });

      console.log('Processed polygon geofences:', JSON.stringify(processedGeofences));
      setPolygonGeofences(processedGeofences);
    } catch (error) {
      console.error('Error fetching polygon geofences:', error);
    }
  }, [userId, selectedOrg]);

  // Fetch geofences when component mounts or when userId/selectedOrg changes
  useEffect(() => {
    fetchAndLogPolygonGeofences();
  }, [fetchAndLogPolygonGeofences]);

  if (!memoizedDevices) {
    return null;
  }

  const createCustomIcon = (deviceState, isActive, shiftData, locationAccuracy, tailscaleStatus) => {
    const isRecording = shiftData?.isRecording;
    const isInShift = shiftData?.shiftStatus;

    // Determine base classes
    let iconWrapperClass = 'circle-icon-wrapper';
    let iconClass = 'circle-icon';
    let pulsingCircleClass = '';

    // Add status-specific classes
    if (deviceState === 'Recording' || isRecording) {
      iconClass += ' recording';
      pulsingCircleClass = 'pulsing-circle pulsing-circle-recording';
    } else if (isInShift) {
      iconClass += ' in-shift';
      pulsingCircleClass = 'pulsing-circle pulsing-circle-in-shift';
    } else if (deviceState === 'Offline') {
      iconClass += ' offline';
    }

    // Add active class if selected
    if (isActive) {
      iconClass += ' active';
    }

    // Add tailscale disconnected class if applicable
    if (tailscaleStatus === 'disconnected') {
      iconClass += ' tailscale-disconnected';
    }

    // Create pulsing circle HTML if needed
    const pulsingCircleHtml = pulsingCircleClass ? `<div class="${pulsingCircleClass}"></div>` : '';

    return L.divIcon({
      className: iconWrapperClass,
      html: `
        ${pulsingCircleHtml}
        <div class="${iconClass}">
          <img src="/plix_logo_black.svg" alt="Device" />
        </div>
      `,
      iconSize: [64, 64],
      iconAnchor: [32, 32],
      popupAnchor: [0, -16],
    });
  };

  // console.log('7898 devices', memoizedDevices);

  // Modified map style change handler - now sets manual override flag
  const handleMapStyleChange = () => {
    const newStyle = currentMapStyle === MAP_STYLES.CUSTOM ? MAP_STYLES.STREETS : MAP_STYLES.CUSTOM;
    setCurrentMapStyle(newStyle);
    setManualMapStyleOverride(true);
    localStorage.setItem('mapStyle', newStyle === MAP_STYLES.CUSTOM ? 'CUSTOM' : 'STREETS');
  };

  // Toggle drawing mode
  const toggleDrawingMode = () => {
    // Check for unsaved changes before exiting drawing mode
    if (isDrawingMode) {
      handlePotentialUnsavedChanges({
        type: 'FINISH_EDITING',
      });
      return;
    }

    // If no unsaved changes or entering drawing mode
    const newDrawingMode = !isDrawingMode;
    setSelectedGeofence(null);
    setIsDrawingMode(newDrawingMode);
    setGeofenceEditModeState('move');
    setEditMode(true);

    // If entering drawing mode, close any open popups
    if (newDrawingMode) {
      // Close any open device popups
      Object.keys(markerRefs.current).forEach((deviceId) => {
        const marker = markerRefs.current[deviceId];
        if (marker && marker.isPopupOpen()) {
          marker.closePopup();
        }
      });

      // Close alert popup if open
      if (alertMarkerRef.current && alertMarkerRef.current.isPopupOpen()) {
        alertMarkerRef.current.closePopup();
        handleCloseAlertPopup();
      }

      // Close video popup if open
      if (videoMarkerRef.current && videoMarkerRef.current.isPopupOpen()) {
        videoMarkerRef.current.closePopup();
        onCloseVideoPopup();
      }

      // Reset active device
      if (activeDevice) {
        setActiveDevice(null);
      }
    }

    // Clear any active geofence states in SiteAssignment
    if (setPolygonGeofences) {
      setPolygonGeofences((prevGeofences) => {
        const updatedGeofences = prevGeofences.map((geofence) => ({
          ...geofence,
          isSelected: false,
        }));
        return updatedGeofences;
      });
    }
  };

  // Save the current geofence
  const saveGeofence = async (name) => {
    if (!currentGeofence) return;

    try {
      // Get the organization ID
      const orgId =
        userId === PLIX_OVERWATCH_ACCOUNT ? (selectedOrg === PLIX_OVERWATCH_ACCOUNT ? undefined : selectedOrg) : userId;

      // Save to API
      const savedGeofence = await addPolygonGeofence(orgId, currentGeofence.geometry.coordinates, name);

      console.log('Saved polygon geofence:', savedGeofence);

      // Refresh the list of polygon geofences
      fetchAndLogPolygonGeofences();

      // Remove the drawn geofence from the temporary list
      setDrawnGeofences((prev) => prev.filter((g) => g.id !== currentGeofence.id));

      // Remove the current drawing layer if it exists
      if (currentDrawingLayer) {
        currentDrawingLayer.remove();
        setCurrentDrawingLayer(null);
      }

      // Reset current geofence and hide form
      setCurrentGeofence(null);
      setShowGeofenceForm(false);
    } catch (error) {
      console.error('Error saving polygon geofence:', error);
    }
  };

  // Cancel the current geofence
  const cancelGeofence = () => {
    // Cancel active drawing if in progress
    if (isActivelyDrawing && mapRef.current && mapRef.current.pm) {
      mapRef.current.pm.Draw.disable();
      setIsActivelyDrawing(false);
    }

    // Remove the current geofence from the drawnGeofences array
    if (currentGeofence) {
      setDrawnGeofences((prev) => prev.filter((g) => g.id !== currentGeofence.id));
    }

    // Remove the current drawing layer if it exists
    if (currentDrawingLayer) {
      currentDrawingLayer.remove();
      setCurrentDrawingLayer(null);
    }

    // Reset state
    setCurrentGeofence(null);
    setShowGeofenceForm(false);

    // Set edit mode to move
    setGeofenceEditModeState('move');

    // Clear any error messages
    setErrorMessage('');
  };

  // Finish editing geofences
  const finishEditing = () => {
    // Cancel any active drawing or unsaved geofence
    cancelGeofence();

    // Exit drawing mode
    setIsDrawingMode(false);
    setGeofenceEditModeState('move');
    setShowGeofenceForm(false);
    setEditMode(false);
    setSelectedGeofence(null);
    setSelectedAddress(null);

    // Reset UI states
    setIsSelectingGeofence(false);
    setIsActivelyDrawing(false);
    setErrorMessage('');

    // Clear any cached geofence states
    clearOriginalGeofenceState();

    // Clear the last valid state for the active geofence if it exists
    if (activeGeofence && activeGeofence.id) {
      // Delete the entry for this geofence
      delete lastValidPolygonStatesRef.current[activeGeofence.id];
    }

    // Allow selection of any geofence
    if (mapRef.current) {
      // Disable global edit mode
      mapRef.current.pm.disableGlobalEditMode();
    }
  };

  // Delete a polygon geofence
  const deletePolygonGeofenceById = async (geofenceId) => {
    if (!geofenceId) {
      console.error('No geofence ID provided for deletion');
      return;
    }

    try {
      // Get the organization ID
      const orgId =
        userId === PLIX_OVERWATCH_ACCOUNT ? (selectedOrg === PLIX_OVERWATCH_ACCOUNT ? undefined : selectedOrg) : userId;

      // Delete from API
      await deletePolygonGeofence(geofenceId, orgId);

      console.log('Deleted polygon geofence:', geofenceId);

      // Refresh the list of polygon geofences
      fetchAndLogPolygonGeofences();
    } catch (error) {
      console.error('Error deleting polygon geofence:', error);
      alert('Failed to delete geofence. Please try again.');
    }
  };

  // Function to check if there are unsaved changes
  const hasUnsavedChanges = () => {
    // Check if currently drawing or if there's an unsaved drawn geofence
    if (isActivelyDrawing || currentGeofence || drawnGeofences.length > 0) {
      return true;
    }

    // Check if there's a selected geofence with modifications
    if (selectedGeofence && selectedGeofence.isModified) {
      return true;
    }

    return false;
  };

  // Function to handle actions that might need the unsaved changes popup
  const handlePotentialUnsavedChanges = (action) => {
    const changes = hasUnsavedChanges();

    if (changes) {
      setShowUnsavedChangesPrompt(true);
      setPendingAction(action);
    } else {
      // If no unsaved changes, execute the action immediately
      executePendingAction(action);
    }
  };

  // Function to execute the pending action
  const executePendingAction = (action) => {
    if (!action) return;

    switch (action.type) {
      case 'SELECT_GEOFENCE':
        // Clear previous geofence reference before setting a new one
        clearOriginalGeofenceState();
        // Store the new geofence's original state
        storeOriginalGeofenceState(action.geofence);
        setSelectedGeofence(action.geofence);
        setActiveGeofence(action.geofence);

        // Automatically enable edit mode if we're in drawing mode but not actively drawing
        if (isDrawingMode && !isActivelyDrawing) {
          setEditMode(true);
        }
        break;
      case 'FINISH_EDITING':
        finishEditing();
        break;
      default:
        console.warn('Unknown action type:', action.type);
    }
  };

  // Function to handle discarding changes
  const handleDiscardChanges = () => {
    // If actively drawing or have unsaved drawn geofences, cancel them
    if (isActivelyDrawing || currentGeofence || drawnGeofences.length > 0) {
      // Cancel active drawing if in progress
      if (isActivelyDrawing && mapRef.current && mapRef.current.pm) {
        mapRef.current.pm.Draw.disable();
        setIsActivelyDrawing(false);
      }

      // Remove any drawn geofences
      if (currentDrawingLayer) {
        currentDrawingLayer.remove();
        setCurrentDrawingLayer(null);
      }

      // Clear drawn geofences array
      setDrawnGeofences([]);
      setCurrentGeofence(null);
      setShowGeofenceForm(false);
    }

    // If editing a geofence, reset its state using the original ref
    if (selectedGeofence && selectedGeofence.isModified) {
      const originalGeofence = getOriginalGeofenceState();

      if (originalGeofence) {
        // For polygon geofences
        if (originalGeofence.type === 'polygon') {
          // Clear the last valid state for this geofence
          if (originalGeofence.id) {
            delete lastValidPolygonStatesRef.current[originalGeofence.id];
          }

          // Reset the geofence to its original state
          setPolygonGeofences((prevGeofences) =>
            prevGeofences.map((g) => (g.id === originalGeofence.id ? { ...originalGeofence, isModified: false } : g))
          );

          // Reset the selected geofence state
          setSelectedGeofence({ ...originalGeofence, isModified: false });

          // Reset the layer's coordinates to match the original state
          const layer = polygonLayersRef.current[originalGeofence.id];
          if (layer) {
            // Disable editing first
            if (layer.pm) {
              layer.pm.disable();
            }

            // Reset coordinates
            const originalCoords = originalGeofence.boundaryCoordinates.map((coord) => [coord.lat, coord.lng]);
            layer.setLatLngs(originalCoords);

            // Force a redraw
            layer.redraw();
          }
        } else {
          // For circular geofences
          setSelectedGeofence({ ...originalGeofence, isModified: false });

          // Reset the circle's position and radius
          const layer = polygonLayersRef.current[originalGeofence.id];
          if (layer) {
            // Disable editing first
            if (layer.pm) {
              layer.pm.disable();
            }

            // Reset position and radius
            if (layer.setLatLng) {
              layer.setLatLng([originalGeofence.latitude, originalGeofence.longitude]);
            }
            if (layer.setRadius) {
              layer.setRadius(originalGeofence.radius);
            }

            // Force a redraw
            layer.redraw();
          }
        }
      }
    }

    // Disable global edit mode
    if (mapRef.current && mapRef.current.pm) {
      mapRef.current.pm.disableGlobalEditMode();
    }

    // Reset edit mode and clear selection if needed
    if (!pendingAction || pendingAction.type === 'FINISH_EDITING') {
      setEditMode(false);
      // Clear selection and original state reference
      setSelectedGeofence(null);
      setActiveGeofence(null);
      clearOriginalGeofenceState();
    } else if (pendingAction.type === 'SELECT_GEOFENCE') {
      // Ensure we're not in edit mode before proceeding to select a new geofence
      setEditMode(false);
      // Clear the current selection and original state before selecting a new geofence
      setSelectedGeofence(null);
      setActiveGeofence(null);
      clearOriginalGeofenceState();
    }

    setShowUnsavedChangesPrompt(false);

    // Execute the pending action after state is reset
    if (pendingAction) {
      // Use setTimeout to ensure state updates have completed
      setTimeout(() => {
        executePendingAction(pendingAction);
        setPendingAction(null);
      }, 0);
    } else {
      setPendingAction(null);
    }
  };

  // Function to handle going back (keeping changes)
  const handleGoBack = () => {
    setShowUnsavedChangesPrompt(false);
    setPendingAction(null);

    if (selectedGeofence) {
      fitMapToGeofence(selectedGeofence);
    }
    // If there's a geofence being drawn or just created, focus on it
    else if (currentGeofence || drawnGeofences.length > 0) {
      const geofenceToFocus = currentGeofence || drawnGeofences[drawnGeofences.length - 1];

      // Create a temporary geofence object with the necessary properties for focusing
      const tempGeofence = {
        id: geofenceToFocus.id,
        type: 'polygon',
        boundaryCoordinates: geofenceToFocus.geometry.coordinates[0].map((coord) => ({
          lat: coord[1],
          lng: coord[0],
        })),
      };

      fitMapToGeofence(tempGeofence);
    }
  };

  // Modify the click handler for saved polygon geofences
  const handlePolygonClick = (e, geofence) => {
    // If actively drawing, we should allow the click for vertex snapping
    // but prevent it from triggering edit mode
    if (isActivelyDrawing) {
      return;
    }

    try {
      console.log('Clicked on saved polygon:', geofence.id);

      // Focus on the geofence
      fitMapToGeofence(geofence);

      // If this is a different geofence than the currently selected one,
      // check for unsaved changes
      if ((!selectedGeofence || selectedGeofence.id !== geofence.id) && activeTab === 'sites') {
        handlePotentialUnsavedChanges({
          type: 'SELECT_GEOFENCE',
          geofence,
        });
        return;
      }

      // If we're in the sites tab, just select the geofence without enabling edit mode
      if (activeTab === 'sites' && !isActivelyDrawing) {
        // If clicking the same geofence that's already selected, don't do anything
        if (selectedGeofence?.id !== geofence.id) {
          // Use the storeOriginalGeofenceState function instead of directly setting the ref
          storeOriginalGeofenceState(geofence);
          setSelectedGeofence(geofence);
          setActiveGeofence(geofence);

          // Automatically enable edit mode if we're in drawing mode but not actively drawing
          if (isDrawingMode && !isActivelyDrawing) {
            console.log('Auto-enabling edit mode for polygon geofence');
            setEditMode(true);
          }
        } else if (isDrawingMode && !isActivelyDrawing && !editMode) {
          // If it's the same geofence and we're in drawing mode but not in edit mode, enable edit mode
          console.log('Auto-enabling edit mode for already selected polygon geofence');
          setEditMode(true);
        }
      }
    } catch (error) {
      console.error('Error handling saved polygon click:', error);
    }
  };

  // Modify the finishEditing function to check for unsaved changes
  const handleFinishEditing = () => {
    handlePotentialUnsavedChanges({
      type: 'FINISH_EDITING',
    });
  };

  return (
    <div className="map-view-main-container">
      {/* Error notification */}
      {errorMessage && <GeofenceErrorNotification message={errorMessage} onClose={clearErrorMessage} />}

      <MapContainer
        center={memoizedDevices.length > 0 ? memoizedDevices[0].location : [40.075044252652596, -98.80344386500497]}
        zoom={memoizedDevices.length > 0 ? 18 : 0}
        style={{ height: '100vh', width: '100%' }}
        zoomControl={false}
        whenCreated={(map) => {
          mapRef.current = map;
          // Any initial setup here
        }}
        ref={mapRef}
      >
        <TileLayer
          url={`${currentMapStyle.url}?access_token=${process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN}`}
          attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
          tileSize={512}
          zoomOffset={-1}
        />

        {/* Draw button - only show when activeTab is 'sites' and not in mobile mode */}
        {activeTab === 'sites' && !isMobile && userRole !== 'guard' && (
          <div className="draw-control">
            {!isDrawingMode && (
              <button onClick={toggleDrawingMode} className={`draw-button ${isDrawingMode ? 'active' : ''}`}>
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '8px' }} />
                Add Sites
              </button>
            )}
          </div>
        )}

        {/* Map style control */}
        <div className="map-style-control">
          <Tooltip
            title={`Switch to ${currentMapStyle === MAP_STYLES.CUSTOM ? 'Satellite' : 'Monochrome'}`}
            placement="right"
          >
            <button onClick={handleMapStyleChange} className="map-style-button">
              <div className="map-style-preview">
                {currentMapStyle === MAP_STYLES.CUSTOM ? (
                  <div className="satellite-preview" style={{ backgroundImage: `url(${satellitePreview})` }}></div>
                ) : (
                  <div className="monochrome-preview" style={{ backgroundImage: `url(${monochromePreview})` }}></div>
                )}
              </div>
            </button>
          </Tooltip>
        </div>

        {/* Add the GeomanControls component inside the MapContainer */}
        <GeomanControls
          isDrawingMode={isDrawingMode}
          editMode={geofenceEditMode}
          onCreated={handleCreated}
          storeDrawingLayer={storeDrawingLayer}
          setIsActivelyDrawing={setIsActivelyDrawing}
          setEditModeToMove={() => setGeofenceEditModeState('move')}
          clearError={clearErrorMessage}
          calculatePolygonArea={calculatePolygonArea}
          setCurrentDrawingArea={setCurrentDrawingArea}
          setIsAreaSufficient={setIsAreaSufficient}
          isActivelyDrawing={isActivelyDrawing}
        />

        {/* Always display drawn geofences */}
        <FeatureGroup>
          {drawnGeofences.map((geofence) => {
            if (geofence.geometry && geofence.geometry.type === 'Polygon') {
              const coordinates = geofence.geometry.coordinates[0].map((coord) => [coord[1], coord[0]]);
              return (
                <Polygon
                  key={geofence.id}
                  positions={coordinates}
                  pathOptions={{
                    color: 'red',
                    fillColor: 'red',
                    fillOpacity: 0.2,
                    weight: 2,
                  }}
                  eventHandlers={{
                    click: () => {
                      try {
                        console.log('Clicked on drawn polygon:', geofence.id);

                        // Create a temporary geofence object with the necessary properties for focusing
                        const tempGeofence = {
                          id: geofence.id,
                          type: 'polygon',
                          boundaryCoordinates: coordinates.map((coord) => ({
                            lat: coord[0],
                            lng: coord[1],
                          })),
                        };

                        // Focus on the geofence
                        fitMapToGeofence(tempGeofence);
                      } catch (error) {
                        console.error('Error handling drawn polygon click:', error);
                      }
                    },
                  }}
                />
              );
            }
            return null;
          })}
        </FeatureGroup>

        {/* Display saved polygon geofences from API */}
        <FeatureGroup>
          {polygonGeofences.map((geofence) => {
            // Skip invalid geofences
            if (!geofence || !geofence.id) {
              console.warn('Invalid geofence object skipped');
              return null;
            }

            // Check if we have boundaryCoordinates
            const boundaryPoints = geofence.boundaryCoordinates || [];

            if (!Array.isArray(boundaryPoints) || boundaryPoints.length < 3) {
              console.warn(`Geofence ${geofence.id} has insufficient boundary points`);
              return null;
            }

            try {
              // Convert boundary points to [lat, lng] format for Polygon component
              let coordinates = [];

              // Check if boundaryCoordinates is an array of objects with lng and lat properties
              if (typeof boundaryPoints[0] === 'object' && 'lng' in boundaryPoints[0] && 'lat' in boundaryPoints[0]) {
                // Simple approach: just extract lat/lng and filter out invalid points
                coordinates = boundaryPoints
                  .filter(
                    (point) =>
                      point &&
                      typeof point.lat === 'number' &&
                      !isNaN(point.lat) &&
                      typeof point.lng === 'number' &&
                      !isNaN(point.lng)
                  )
                  .map((point) => [point.lat, point.lng]);
              }
              // Fallback for old format (array of arrays)
              else if (Array.isArray(boundaryPoints[0])) {
                coordinates = boundaryPoints
                  .filter(
                    (point) =>
                      point &&
                      Array.isArray(point) &&
                      point.length >= 2 &&
                      typeof point[0] === 'number' &&
                      !isNaN(point[0]) &&
                      typeof point[1] === 'number' &&
                      !isNaN(point[1])
                  )
                  .map((point) => [point[1], point[0]]);
              }

              if (coordinates.length < 3) {
                console.warn(`Geofence ${geofence.id} has insufficient valid coordinates`);
                return null;
              }

              return (
                <Polygon
                  key={geofence.id}
                  positions={coordinates}
                  pathOptions={{
                    color: 'red',
                    fillColor: 'red',
                    fillOpacity: 0.2,
                    weight: 2,
                  }}
                  eventHandlers={{
                    click: (e) => {
                      try {
                        handlePolygonClick(e, geofence);
                      } catch (error) {
                        console.error('Error handling polygon click:', error);
                      }
                    },
                    'pm:edit': (e) => {
                      try {
                        handlePolygonEdit(e, geofence.id);
                      } catch (error) {
                        console.error('Error handling polygon edit:', error);
                      }
                    },
                    'pm:dragend': (e) => {
                      try {
                        handlePolygonEdit(e, geofence.id);
                      } catch (error) {
                        console.error('Error handling polygon drag end:', error);
                      }
                    },
                    'pm:vertexremoved': (e) => {
                      try {
                        handlePolygonEdit(e, geofence.id);
                      } catch (error) {
                        console.error('Error handling vertex removal:', error);
                      }
                    },
                    'pm:vertexadded': (e) => {
                      try {
                        handlePolygonEdit(e, geofence.id);
                      } catch (error) {
                        console.error('Error handling vertex addition:', error);
                      }
                    },
                    add: (e) => {
                      try {
                        storePolygonLayerRef(geofence.id, e.target);
                      } catch (error) {
                        console.error('Error storing polygon reference:', error);
                      }
                    },
                  }}
                />
              );
            } catch (error) {
              console.error('Error rendering polygon:', error, geofence);
              return null;
            }
          })}
        </FeatureGroup>

        {/* Existing geofences */}
        {geofences.map((geofence) => (
          <React.Fragment key={geofence.id}>
            <ZoomDependentSiteMarker
              position={[geofence.latitude, geofence.longitude]}
              eventHandlers={{
                click: (e) => {
                  // Prevent clicking on circular geofences
                  // They should be unclickable directly
                  e.originalEvent.stopPropagation();
                  return false;
                },
              }}
            />
            <DynamicGeofenceCircle
              geofence={geofence}
              isSelected={selectedGeofence && selectedGeofence.id === geofence.id}
              eventHandlers={{
                click: (e) => {
                  // Prevent clicking on circular geofences
                  // They should be unclickable directly
                  e.originalEvent.stopPropagation();
                  return false;
                },
              }}
            />
          </React.Fragment>
        ))}

        {previewGeofence && (
          <React.Fragment>
            <ZoomDependentSiteMarker position={[previewGeofence.latitude, previewGeofence.longitude]} />
            <DynamicGeofenceCircle geofence={previewGeofence} isSelected={true} />
          </React.Fragment>
        )}
        {/* Only render device markers when not in drawing mode and not editing polygon geofences */}
        {!isDrawingMode &&
          !(editMode && selectedGeofence?.type === 'polygon') &&
          devicesWithShiftData.map((device, index) => {
            // Use offset position if available, otherwise use original position
            const markerPosition = offsetPositions[device.deviceId] || device.location;

            return (
              <React.Fragment key={index}>
                <AccuracyCircle
                  position={device.location} // Always show accuracy circle at true position
                  accuracy={device.locationAccuracy}
                  visible={selectedMarkerId === device.deviceId}
                />
                <Marker
                  key={index}
                  position={markerPosition} // Use the offset position for marker
                  icon={createCustomIcon(
                    device.deviceState,
                    activeDevice === device.deviceId,
                    deviceShifts[device.deviceId],
                    device.locationAccuracy,
                    device.tailscaleStatus
                  )}
                  ref={(el) => {
                    if (el) {
                      markerRefs.current[device.deviceId] = el;
                    }
                  }}
                  eventHandlers={{
                    popupopen: (e) => {
                      handleOpenPopup(device.deviceId);
                      setSelectedMarkerId(device.deviceId);
                      const closeButton = e.popup._closeButton;
                      if (closeButton) {
                        closeButton.style.width = '30px';
                        closeButton.style.height = '30px';
                        closeButton.style.fontSize = '24px';
                        closeButton.style.lineHeight = '30px';

                        // Avoid adding listener directly to DOM node that might be modified by React
                        // Instead, use a custom property to track if we should handle the close event
                        closeButton._shouldHandleClose = true;

                        // Add click event to overlay (captures all clicks)
                        const handleClick = () => {
                          if (closeButton._shouldHandleClose) {
                            setActiveDevice(null);
                            handleClosePopup(device.deviceId);
                            setSelectedMarkerId(null);
                          }
                        };

                        closeButton._clickHandler = handleClick;
                        closeButton.addEventListener('click', closeButton._clickHandler);
                      }
                    },
                    popupclose: (e) => {
                      // Clean up the event listener when popup closes
                      const closeButton = e.popup?._closeButton;
                      if (closeButton && closeButton._clickHandler) {
                        // Set this to false first to prevent double-execution
                        closeButton._shouldHandleClose = false;
                        try {
                          closeButton.removeEventListener('click', closeButton._clickHandler);
                        } catch (error) {
                          console.error('Error removing event listener:', error);
                        }
                        delete closeButton._clickHandler;
                      }

                      // Then call the original handler (use setTimeout to ensure DOM operations complete first)
                      setTimeout(() => {
                        handleClosePopup(device.deviceId);
                        setSelectedMarkerId(null);
                      }, 0);
                    },
                  }}
                >
                  <Popup className="video-popup">
                    <DevicePopupContent
                      device={device}
                      handleToggleStream={handleToggleStream}
                      streamingDevices={streamingDevices}
                    />
                  </Popup>
                </Marker>
              </React.Fragment>
            );
          })}
        {/* Only show alert markers when not in drawing mode and not editing polygon geofences */}
        {!isDrawingMode && !(editMode && selectedGeofence?.type === 'polygon') && alertMarker && activeAlert && (
          <Marker
            position={alertMarker}
            icon={L.divIcon({
              className: 'alert-marker',
              html: '<div style="background-color: red; width: 20px; height: 20px; border-radius: 50%; opacity: 0.7;"></div>',
              iconSize: [20, 20],
              iconAnchor: [10, 10],
            })}
            ref={alertMarkerRef}
            eventHandlers={{
              popupopen: (e) => {
                const closeButton = e.popup._closeButton;
                if (closeButton) {
                  closeButton.style.width = '30px';
                  closeButton.style.height = '30px';
                  closeButton.style.fontSize = '24px';
                  closeButton.style.lineHeight = '30px';

                  // Avoid adding listener directly to DOM node that might be modified by React
                  // Instead, use a custom property to track if we should handle the close event
                  closeButton._shouldHandleClose = true;

                  // Add click event to overlay (captures all clicks)
                  const handleClick = () => {
                    if (closeButton._shouldHandleClose) {
                      setActiveDevice(null);
                      handleCloseAlertPopup();
                    }
                  };

                  closeButton._clickHandler = handleClick;
                  closeButton.addEventListener('click', closeButton._clickHandler);
                }
              },
              popupclose: (e) => {
                // Clean up the event listener when popup closes
                const closeButton = e.popup?._closeButton;
                if (closeButton && closeButton._clickHandler) {
                  // Set this to false first to prevent double-execution
                  closeButton._shouldHandleClose = false;
                  try {
                    closeButton.removeEventListener('click', closeButton._clickHandler);
                  } catch (error) {
                    console.error('Error removing event listener:', error);
                  }
                  delete closeButton._clickHandler;
                }

                // Then call the original handler (use setTimeout to ensure DOM operations complete first)
                setTimeout(() => {
                  handleCloseAlertPopup();
                }, 0);
              },
            }}
          >
            <Popup className="video-popup" onClose={handleCloseAlertPopup}>
              {activeAlert && <AlertPopup alert={activeAlert} userId={userId} />}
            </Popup>
          </Marker>
        )}
        {/* Only show video markers when not in drawing mode and not editing polygon geofences */}
        {!isDrawingMode &&
          !(editMode && selectedGeofence?.type === 'polygon') &&
          selectedVideo &&
          selectedVideo.location && (
            <Marker
              position={selectedVideo.location}
              icon={L.divIcon({
                className: 'video-marker',
                html: '<div style="background-color: red; width: 20px; height: 20px; border-radius: 50%; opacity: 0.7;"></div>',
                iconSize: [20, 20],
                iconAnchor: [10, 10],
              })}
              ref={videoMarkerRef}
              eventHandlers={{
                popupopen: (e) => {
                  const closeButton = e.popup._closeButton;
                  if (closeButton) {
                    closeButton.style.width = '30px';
                    closeButton.style.height = '30px';
                    closeButton.style.fontSize = '24px';
                    closeButton.style.lineHeight = '30px';

                    // Avoid adding listener directly to DOM node that might be modified by React
                    // Instead, use a custom property to track if we should handle the close event
                    closeButton._shouldHandleClose = true;

                    // Add click event to overlay (captures all clicks)
                    const handleClick = () => {
                      if (closeButton._shouldHandleClose) {
                        onCloseVideoPopup();
                      }
                    };

                    closeButton._clickHandler = handleClick;
                    closeButton.addEventListener('click', closeButton._clickHandler);
                  }
                },
                popupclose: (e) => {
                  // Clean up the event listener when popup closes
                  const closeButton = e.popup?._closeButton;
                  if (closeButton && closeButton._clickHandler) {
                    // Set this to false first to prevent double-execution
                    closeButton._shouldHandleClose = false;
                    try {
                      closeButton.removeEventListener('click', closeButton._clickHandler);
                    } catch (error) {
                      console.error('Error removing event listener:', error);
                    }
                    delete closeButton._clickHandler;
                  }

                  // Then call the original handler (use setTimeout to ensure DOM operations complete first)
                  setTimeout(() => {
                    onCloseVideoPopup();
                  }, 0);
                },
              }}
            >
              <Popup className="video-popup" onClose={onCloseVideoPopup}>
                <VideoPopup video={selectedVideo} />
              </Popup>
            </Marker>
          )}
        <ZoomControl position="bottomright" />
        <MapClickHandler />
      </MapContainer>
      {modalDevice && (
        <Modal isOpen={showModal} onClose={() => setShowModal(false)} modalDevice={modalDevice}>
          {modalDevice.config.streamAddress.endsWith('.m3u8') ? (
            <HLSVideoPlayer
              src={modalDevice.config.streamAddress}
              style={{ width: '100%', height: 'auto' }}
              deviceVersion={modalDevice?.deviceVersion || ''}
            />
          ) : (
            <img
              src={modalDevice.config.streamAddress}
              alt="Full Size Stream"
              style={{ width: '100%', height: 'auto' }}
            />
          )}
        </Modal>
      )}
      {showGeofenceForm && (
        <GeofenceForm
          onSave={saveGeofence}
          onCancel={cancelGeofence}
          initialNickname={currentGeofence?.nickname || (selectedAddress ? selectedAddress.place_name : '')}
        />
      )}
      {isDrawingMode && (
        <>
          {/* Address Autocomplete at top left */}
          <div className="draw-edit-bar-address-autocomplete-container">
            <FontAwesomeIcon icon={faSearch} className="address-search-icon" />
            <AddressAutocompleteWrapper onSelect={handleAddressSelect} onInputChange={handleAddressInputChange} />
          </div>

          {/* Draw/Cancel button centered */}
          <div className="draw-control-container">
            <div className="geofence-edit-bar">
              {isActivelyDrawing || showGeofenceForm || currentDrawingLayer ? (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <button
                    className="edit-bar-button clear-button active"
                    onClick={cancelGeofence}
                    title="Cancel Drawing"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                    Cancel
                  </button>

                  {/* Area calculation display */}
                  {currentDrawingArea > 0 && (
                    <div style={{ textAlign: 'right' }}>
                      <div className="area-calculation">
                        {/* Format area for display */}
                        {(() => {
                          const areaInSqMeters = currentDrawingArea;
                          if (!areaInSqMeters) return null;

                          // Convert to square feet (1 sq meter ≈ 10.764 sq ft)
                          const areaInSqFeet = areaInSqMeters * 10.764;

                          // If area is large enough, show in square miles
                          if (areaInSqFeet > 27878400) {
                            // > 1 sq mile
                            const areaInSqMiles = areaInSqFeet / 27878400;
                            return `${areaInSqMiles.toFixed(2)} sq mi`;
                          }

                          // Otherwise show in square feet
                          return `${Math.round(areaInSqFeet).toLocaleString()} sq ft`;
                        })()}
                      </div>
                      {!isAreaSufficient && (
                        <div className="minimum-area-warning">Area must be at least the size of a street block.</div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <button
                  className={`edit-bar-button ${geofenceEditMode === 'draw' ? 'active' : ''}`}
                  onClick={() => setGeofenceEditMode('draw')}
                  title="Draw Site Boundary"
                  disabled={editMode && selectedGeofence}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  Draw Site Boundary
                </button>
              )}
            </div>
          </div>

          {/* Done button at top right */}
          <div className="done-button-wrapper">
            <button
              className="draw-button"
              onClick={handleFinishEditing}
              disabled={isActivelyDrawing}
              title={isActivelyDrawing ? 'Finish drawing before clicking Done' : 'Done'}
            >
              <FontAwesomeIcon icon={faCheck} style={{ marginRight: '8px' }} />
              Done
            </button>
          </div>
        </>
      )}

      {showUnsavedChangesPrompt && <UnsavedChangesPopup onDiscard={handleDiscardChanges} onGoBack={handleGoBack} />}
    </div>
  );
}

export default React.memo(MapViewMain);
