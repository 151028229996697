import React, { useState, useEffect, useRef } from 'react';
import { useLoadScript } from '@react-google-maps/api';

const libraries = ['places'];

const AddressAutocompleteWrapper = ({ onSelect, onInputChange }) => {
  const [inputValue, setInputValue] = useState('');
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });

  useEffect(() => {
    if (isLoaded && !loadError) {
      const autocompleteOptions = {
        types: ['geocode', 'establishment'],
        fields: ['formatted_address', 'geometry', 'name'],
      };

      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, autocompleteOptions);
      autocompleteRef.current = autocomplete;

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          const fullAddress = place.formatted_address;
          setInputValue(fullAddress); // Update the input value with full address
          onSelect({
            place_name: place.name,
            full_address: fullAddress,
            center: [place.geometry.location.lng(), place.geometry.location.lat()],
          });
        }
      });
    }
  }, [isLoaded, loadError, onSelect]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    onInputChange(value);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="address-autocomplete-container">
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Jump to an address"
        className="mapboxgl-ctrl-geocoder"
      />
    </div>
  );
};

export default AddressAutocompleteWrapper;
