import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faMapMarkerAlt,
  faSearch,
  faHistory,
  faSpinner,
  faCheck,
  faTimes,
  fa0,
} from '@fortawesome/free-solid-svg-icons';
import { searchAlerts } from '../api/beaverApi';

// Simple fuzzy search function
function fuzzyMatch(text, pattern) {
  if (!text || !pattern) return false;
  text = text.toLowerCase();
  pattern = pattern.toLowerCase();

  let ti = 0;
  let pi = 0;
  while (ti < text.length && pi < pattern.length) {
    if (text[ti] === pattern[pi]) {
      pi++;
    }
    ti++;
  }
  return pi === pattern.length;
}

function MapSearch({ devices, geofences, onSelectDevice, onSelectSite, onSearchAlert, userId }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchStatus, setSearchStatus] = useState(null); // null, 'success', or 'error'
  const [isFadingOut, setIsFadingOut] = useState(false);
  const inputRef = useRef(null);
  const statusIconTimer = useRef(null);

  // Handle status icon fade out
  useEffect(() => {
    if (searchStatus) {
      // Start fade out after delay
      statusIconTimer.current = setTimeout(
        () => {
          setIsFadingOut(true);
          // Clear status after fade animation
          setTimeout(() => {
            setSearchStatus(null);
            setIsFadingOut(false);
          }, 500); // Match fade-out animation duration
        },
        searchStatus === 'success' ? 5000 : 3000
      );

      return () => {
        if (statusIconTimer.current) {
          clearTimeout(statusIconTimer.current);
        }
      };
    }
  }, [searchStatus]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (statusIconTimer.current) {
        clearTimeout(statusIconTimer.current);
      }
    };
  }, []);

  // Focus input on mount
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // Update suggestions when search term changes
  useEffect(() => {
    if (!searchTerm || searchTerm.trim() === '') {
      setSuggestions([]);
      return;
    }

    const term = searchTerm.toLowerCase();
    const matches = [];

    // Add matching devices (by assignedTo)
    const seenNames = new Set(); // To prevent duplicate names
    if (devices && Array.isArray(devices)) {
      devices.forEach((device) => {
        if (device.assignedTo && !seenNames.has(device.assignedTo)) {
          if (fuzzyMatch(device.assignedTo, term)) {
            matches.push({
              type: 'user',
              text: device.assignedTo,
              icon: faUser,
              data: device,
              subtext: 'Personnel',
            });
            seenNames.add(device.assignedTo);
          }
        }
      });
    }

    // Add matching sites (by nickname or id)
    if (geofences && Array.isArray(geofences)) {
      geofences.forEach((site) => {
        const nick = site.nickname || site.id;
        if (nick && fuzzyMatch(nick, term)) {
          matches.push({
            type: 'site',
            text: nick,
            icon: faMapMarkerAlt,
            data: site,
            subtext: 'Site',
          });
        }
      });
    }

    // Always add search alerts option at the bottom
    matches.push({
      type: 'search',
      text: 'Search recent alerts',
      icon: faHistory,
      data: null,
      isSearchOption: true,
    });

    setSuggestions(matches);
    setSelectedIndex(0);
  }, [searchTerm, devices, geofences]);

  const handleKeyDown = (e) => {
    if (suggestions.length === 0) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex((prev) => (prev < suggestions.length - 1 ? prev + 1 : prev));
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex((prev) => (prev > 0 ? prev - 1 : 0));
        break;
      case 'Enter':
        e.preventDefault();
        const selected = suggestions[selectedIndex] || suggestions[0];
        handleSelection(selected);
        break;
      default:
        break;
    }
  };

  const handleSelection = (suggestion) => {
    if (!suggestion) return;

    switch (suggestion.type) {
      case 'user':
        // Find all devices with this assignedTo and select the first one
        const matchingDevices = devices.filter((d) => d.assignedTo === suggestion.text);
        if (matchingDevices.length > 0) {
          onSelectDevice(matchingDevices[0].deviceId);
        }
        setSearchTerm('');
        break;
      case 'site':
        onSelectSite(suggestion.data);
        setSearchTerm('');
        break;
      case 'search':
        // Search alerts in past 3 days
        const deviceIdentifiers = devices.map((device) => device.assignedTo || device.deviceId);
        const geofenceIdentifiers = geofences.map((geofence) => geofence.nickname || geofence.id);

        // Get IANA timezone name (e.g. "America/Los_Angeles") using modern browser API
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        setIsSearching(true);
        setSuggestions([]); // Clear suggestions but keep search term

        searchAlerts(userId, searchTerm, deviceIdentifiers, geofenceIdentifiers, timezone)
          .then((alert) => {
            setIsSearching(false);
            if (alert) {
              setSearchStatus('success');
              onSearchAlert(alert);
              // Don't clear on success
            } else {
              setSearchStatus('error');
            }
          })
          .catch((error) => {
            console.error('Error searching alerts:', error);
            setIsSearching(false);
            setSearchStatus('error');
          });
        break;
      default:
        break;
    }

    // Clear search after selection
    setSuggestions([]);
  };

  const getContainerClass = () => {
    const classes = ['search-container-real-time'];
    if (searchTerm.length > 0) {
      classes.push('expanded');
    }
    return classes.join(' ');
  };

  const getSearchBoxClass = () => {
    const classes = ['search-box'];
    if (isFocused) classes.push('focused');
    if (searchTerm.length > 0) classes.push('expanded');
    return classes.join(' ');
  };

  return (
    <div className={getContainerClass()}>
      <div className={getSearchBoxClass()}>
        <input
          ref={inputRef}
          type="text"
          className="search-input-real-time"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          autoFocus
        />
        <div className={`status-icon ${isFadingOut ? 'fade-out' : ''}`}>
          {isSearching && <FontAwesomeIcon icon={faSpinner} className="spinner" />}
          {!isSearching && searchStatus === 'success' && <FontAwesomeIcon icon={faCheck} className="success" />}
          {!isSearching && searchStatus === 'error' && <FontAwesomeIcon icon={fa0} className="error-real-time" />}
        </div>
        <style>{`
          .search-box {
            position: relative;
          }
          .search-input-real-time {
            padding-right: 35px !important;
          }
          .status-icon {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            line-height: 1;
          }
          .spinner {
            animation: spin 1s linear infinite;
            color: #888;
            font-size: 18px;
          }
          .success {
            color: #4CAF50;
          }
          .error-real-time {
            color: #f44336;
          }
          .status-icon > * {
            transition: opacity 0.1s ease-in-out;
            opacity: 1;
          }
          .status-icon.fade-out > * {
            transition: opacity 0.5s ease-in-out;
            opacity: 0;
          }
          @keyframes spin {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          }
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
        `}</style>
      </div>
      <div className="search-suggestions">
        {suggestions.map((suggestion, index) => (
          <div
            key={`${suggestion.type}-${suggestion.text}`}
            className={`suggestion-item ${suggestion.isSearchOption ? 'search-alerts-option' : ''} ${
              index === selectedIndex ? 'selected' : ''
            }`}
            onClick={() => handleSelection(suggestion)}
          >
            <div className="suggestion-icon">
              <FontAwesomeIcon icon={suggestion.icon} />
            </div>
            <div className="suggestion-content">
              <div className="suggestion-text">{suggestion.text}</div>
              {suggestion.subtext && <div className="suggestion-subtext">{suggestion.subtext}</div>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MapSearch;
