import React from 'react';
import ActivityReports from './ActivityReports/ActivityReports';
import DeviceManagement from './DeviceManagement/DeviceManagement';
import PersonnelAccounts from './PersonnelAccounts/PersonnelAccounts';
import TriggerPhrases from './TriggerPhrases/TriggerPhrases';
import AdvancedSettings from './AdvancedSettings/AdvancedSettings';
import PlatformAccessControls from './PlatformAccessControls/PlatformAccessControls';
import '../../../styles/Settings/Section/Section.css';

const Section = ({
  selectedSection,
  userId,
  isGuard,
  isOverwatch,
  userRole,
  parentOrgUserId,
  featureMode,
  organizationAccessControls,
}) => {
  const renderSection = () => {
    switch (selectedSection) {
      case 'personnel':
        return (
          <PersonnelAccounts
            userId={userId}
            isGuard={isGuard}
            isOverwatch={isOverwatch}
            userRole={userRole}
            parentOrgUserId={parentOrgUserId}
          />
        );
      case 'devices':
        return (
          <DeviceManagement
            userId={userId}
            isGuard={isGuard}
            isOverwatch={isOverwatch}
            userRole={userRole}
            parentOrgUserId={parentOrgUserId}
          />
        );
      case 'reports':
        return (
          <ActivityReports
            userId={userId}
            isGuard={isGuard}
            isOverwatch={isOverwatch}
            userRole={userRole}
            parentOrgUserId={parentOrgUserId}
          />
        );
      case 'triggers':
        return (
          <TriggerPhrases
            userId={userId}
            isGuard={isGuard}
            isOverwatch={isOverwatch}
            userRole={userRole}
            parentOrgUserId={parentOrgUserId}
          />
        );
      case 'accessControls':
        return (
          <PlatformAccessControls
            userId={userId}
            isGuard={isGuard}
            isOverwatch={isOverwatch}
            userRole={userRole}
            parentOrgUserId={parentOrgUserId}
            organizationAccessControls={organizationAccessControls}
          />
        );
      case 'advanced':
        return (
          <AdvancedSettings
            userId={userId}
            isGuard={isGuard}
            isOverwatch={isOverwatch}
            userRole={userRole}
            parentOrgUserId={parentOrgUserId}
            featureMode={featureMode}
          />
        );
      default:
        return null;
    }
  };

  return <div className="settings-content">{renderSection()}</div>;
};

export default Section;
