// GeofenceControls.js
import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faTrash, faPen, faEdit } from '@fortawesome/free-solid-svg-icons';
import '../styles/GeofenceControls.css';
import { createPortal } from 'react-dom';

const MIN_RADIUS = 70;
const MAX_RADIUS = 500;

// This component will be rendered directly into the map container
const GeofenceControlsPortal = ({
  geofence,
  onSave,
  onCancel,
  onChange,
  isPreview,
  onRadiusChange,
  onDelete,
  isModified,
  editMode,
  onEditModeToggle,
}) => {
  const [nickname, setNickname] = useState(geofence.nickname || '');
  const [radius, setRadius] = useState(geofence.radius || MIN_RADIUS);
  const [isEdited, setIsEdited] = useState(false);
  const [isEditing, setIsEditing] = useState(editMode);
  const inputRef = useRef(null);
  const isPolygon = geofence.type === 'polygon';

  useEffect(() => {
    setNickname(geofence.nickname || '');
    setRadius(geofence.radius || MIN_RADIUS);
    // For polygon geofences, check if they have the isModified flag
    setIsEdited(isPolygon ? geofence.isModified || false : false);
    setIsEditing(editMode);

    // Focus the input after it renders if in edit mode
    if (editMode) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [geofence.id, geofence.isModified, isPolygon, editMode]);

  const handleNicknameChange = (e) => {
    const newNickname = e.target.value;
    setNickname(newNickname);
    onChange({ ...geofence, nickname: newNickname });
    setIsEdited(true);
  };

  const handleRadiusChange = (e) => {
    if (isPolygon) return; // Don't handle radius changes for polygon geofences

    const newRadius = parseInt(e.target.value, 10);
    setRadius(newRadius);
    onRadiusChange(newRadius);
    onChange({ ...geofence, radius: newRadius });
    setIsEdited(true);
  };

  const handleSave = () => {
    if (isPolygon) {
      onSave({ ...geofence, nickname });
    } else {
      onSave({ ...geofence, nickname, radius });
    }
  };

  const handleDelete = () => {
    onDelete(geofence);
  };

  const metersToFeetOrMiles = (meters) => {
    const feet = meters * 3.28084;
    if (feet > 528) {
      // 0.1 miles in feet
      return `${(feet / 5280).toFixed(1)} mi`;
    }
    return `${Math.round(feet)} ft`;
  };

  // Determine if the save button should be enabled
  // For polygon geofences, check both the local edit state and the geofence's isModified flag
  const isSaveEnabled = isEdited || (isPolygon && geofence.isModified);

  // View mode (non-edit mode) UI
  if (!editMode) {
    return (
      <div className="geofence-popup view-mode">
        <button className="close-button" onClick={onCancel}>
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <div className="geofence-header view-mode">
          <div className="geofence-title">
            <h4>{nickname || 'Unnamed Site'}</h4>
          </div>
        </div>

        <div className="geofence-actions">
          {!isPreview && (
            <button onClick={handleDelete} className="delete-button">
              <FontAwesomeIcon icon={faTrash} style={{ marginRight: '6px' }} /> Delete
            </button>
          )}
          <div className="right-actions">
            {isPolygon && (
              <button onClick={onEditModeToggle} className="edit-button">
                <FontAwesomeIcon icon={faEdit} style={{ marginRight: '6px' }} /> Edit
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  // Edit mode UI
  return (
    <div className="geofence-popup">
      <button className="close-button" onClick={onCancel}>
        <FontAwesomeIcon icon={faTimes} />
      </button>

      {isPolygon ? (
        <>
          <div className="geofence-header">
            <input
              ref={inputRef}
              type="text"
              id="nickname"
              value={nickname}
              onChange={handleNicknameChange}
              placeholder="Enter site name"
              className="geofence-title-input"
            />
          </div>

          <div className="geofence-actions">
            {!isPreview && (
              <button onClick={handleDelete} className="delete-button">
                <FontAwesomeIcon icon={faTrash} style={{ marginRight: '6px' }} /> Delete
              </button>
            )}
            <div className="right-actions">
              <button onClick={onCancel} className="cancel-button">
                Cancel
              </button>
              <button onClick={handleSave} className="save-button" disabled={!isSaveEnabled}>
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '6px' }} /> Save
              </button>
            </div>
          </div>
        </>
      ) : (
        // For circular geofences in edit mode, show a message that they can't be edited directly
        <div className="geofence-header">
          <p>Circular geofences cannot be edited directly.</p>
          <div className="geofence-actions">
            <div className="right-actions">
              <button onClick={onCancel} className="cancel-button">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Main component that creates a portal to render the controls in the map container
const GeofenceControls = (props) => {
  const [container, setContainer] = useState(null);

  useEffect(() => {
    // Find the map container element
    const mapContainer = document.querySelector('.map-view-main-container');
    if (!mapContainer) {
      console.error('Map container not found');
      return;
    }

    // Create a div to hold our controls
    const controlsContainer = document.createElement('div');
    controlsContainer.className = 'geofence-controls-container';
    mapContainer.appendChild(controlsContainer);
    setContainer(controlsContainer);

    // Cleanup function
    return () => {
      if (controlsContainer.parentNode) {
        controlsContainer.parentNode.removeChild(controlsContainer);
      }
    };
  }, []);

  // Only render the portal when we have a container
  return container ? createPortal(<GeofenceControlsPortal {...props} />, container) : null;
};

export default GeofenceControls;
