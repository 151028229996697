import React, { useState, useRef, useEffect, useMemo } from 'react';
import '../styles/Coaching.css';
import {
  RiShieldCheckLine,
  RiInformationLine,
  RiTimeLine,
  RiPlayLine,
  RiThumbUpLine,
  RiThumbDownLine,
  RiFlagLine,
  RiArrowRightLine,
  RiCalendarLine,
  RiUserLine,
  RiHashtag,
  RiVolumeUpLine,
  RiVolumeMuteLine,
  RiSkipBackLine,
  RiSkipForwardLine,
  RiPauseLine,
  RiListCheck,
  RiAlertLine,
  RiHistoryLine,
  RiErrorWarningLine,
  RiCheckboxCircleLine,
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiTimerLine,
  RiRocketLine,
  RiFileWarningLine,
  RiScissorsCutLine,
  RiShareLine,
  RiCheckDoubleLine,
  RiCloseLine,
} from 'react-icons/ri';
import VideoPlayer from './VideoPlayer';

// Update the tabs to match Settings pattern
const CoachingControls = ({ activeTab, setActiveTab }) => {
  return (
    <div className="tabs-header">
      <button className={`tab ${activeTab === 'events' ? 'active' : ''}`} onClick={() => setActiveTab('events')}>
        <RiListCheck className="tab-icon" />
        <span>Timeline Events</span>
      </button>
      <button className={`tab ${activeTab === 'outcome' ? 'active' : ''}`} onClick={() => setActiveTab('outcome')}>
        <RiAlertLine className="tab-icon" />
        <span>Outcome Analysis</span>
      </button>
    </div>
  );
};

// First demo - current analysis
const demoTrespassingAnalysis1 = {
  id: '721e92ce-b062-49cf-a3f8-1b53918cfdb2',
  incidentId: 'a2cb75c5-2062-4124-8c4e-74f549ee4add',
  type: 'securityCoachingAnalysis',
  deviceId: 'plixbwc11',
  videoFilename: 'plixbwc11-shift-20241218_222433.mp4',
  assignedTo: 'Zayshonte Mcadoo',
  timestamp: '2024-12-19T06:24:34Z',
  escalationStartTimestamp: '2024-12-19T06:26:08Z',
  description: 'recording started.',
  userId: 'auth0|66623577e8564b8fb0940504',
  analysis: {
    contextSummary:
      'Officer encountered an individual tying something to private property. The interaction began with a request to leave and quickly escalated with mutual verbal confrontation.',
    incidentTimeline: [
      {
        startTime: 1,
        endTime: 6,
        category: 'Initial Contact',
        description:
          "Officer asks, 'can you please leave?' to the subject followed by 'leave it alone.', initiating the verbal exchange.",
        coachableMoments: [
          {
            type: 'positive',
            category: 'Clear Communication',
          },
        ],
      },
      {
        startTime: 9,
        endTime: 18,
        category: 'Verbal Escalation',
        description:
          "Subject ties something to property, officer says, 'okay, but you're tying it to my property.' Conversation escalates as subject comments on how they are spoken to, 'you know, you just talk to me like i'm a dog.'",
        coachableMoments: [
          {
            type: 'negative',
            category: 'Demeanor',
          },
          {
            type: 'negative',
            category: 'Language',
          },
        ],
      },
      {
        startTime: 20,
        endTime: 33,
        category: 'Threat Made',
        description:
          "Exchange becomes hostile, 'stop acting like one.' Officer threatens, 'get the fuck up out my face for i fuck you up, bro.' and, 'you making the threat.'",
        coachableMoments: [
          {
            type: 'negative',
            category: 'Hostility',
          },
          {
            type: 'negative',
            category: 'Language',
          },
        ],
      },
      {
        startTime: 33,
        endTime: 49,
        category: 'Disengagement Attempt',
        description:
          "Officer attempts to defuse situation, 'shut up and go.' Officer continues aggressive language, 'you damn right. get your ass up out my face. you making the threat.'",
        coachableMoments: [
          {
            type: 'negative',
            category: 'Language',
          },
          {
            type: 'negative',
            category: 'De-escalation',
          },
        ],
      },
    ],
    outcome: {
      outcomeType: 'subject_fled',
      description:
        'The subject eventually left after the verbal confrontation, with officer telling them to keep going.',
      timestamp: 49,
      coachableMoments: [
        {
          type: 'negative',
          category: 'Language',
        },
        {
          type: 'negative',
          category: 'De-escalation',
        },
      ],
    },
  },
};

// Second demo - alternative analysis
const demoTrespassingAnalysis2 = {
  id: '8cf498b8-525b-497f-b894-0069c9597da9',
  incidentId: 'cf5004e4-da37-42f3-8761-97aecb24373c',
  type: 'securityCoachingAnalysis',
  deviceId: 'cpg2',
  videoFilename: 'cpg2-escalation-20241008_233950.mp4',
  assignedTo: 'Kevin Cornelius',
  timestamp: '2024-10-09T06:42:03Z',
  escalationStartTimestamp: '2024-10-09T06:41:40Z',
  description: "I'll kill you in that dog.",
  userId: 'auth0|66623577e8564b8fb0940504',
  analysis: {
    contextSummary:
      'The officer contacted an individual causing a disturbance. The interaction began with a demand for the individual to leave, escalating into a verbal altercation with threatening language from both parties.',
    incidentTimeline: [
      {
        startTime: 1,
        endTime: 6,
        category: 'Initial Contact',
        description:
          "Officer stated, 'you can get up on your own or you can be assisted.' and asked 'what's it gonna be?'.",
        coachableMoments: [
          {
            type: 'negative',
            category: 'Command Tone',
          },
        ],
      },
      {
        startTime: 13,
        endTime: 16,
        category: 'Verbal Escalation',
        description: "Officer responded defensively, 'ain't no niggas over here, bro. watch your motherfucking mouth.'",
        coachableMoments: [
          {
            type: 'negative',
            category: 'Language',
          },
        ],
      },
      {
        startTime: 19,
        endTime: 36,
        category: 'Threat Made',
        description: "Officer used threatening language, 'watch your motherfucking mouth. you a boy to me, boy.'",
        coachableMoments: [
          {
            type: 'negative',
            category: 'Threatening Language',
          },
        ],
      },
      {
        startTime: 47,
        endTime: 56,
        category: 'Verbal Escalation',
        description:
          "Continued hostility with phrases like 'i'll take this shit off and still whoop your motherfucking ass.'",
        coachableMoments: [
          {
            type: 'negative',
            category: 'Hostility',
          },
        ],
      },
      {
        startTime: 91,
        endTime: 104,
        category: 'Physical Threat',
        description: "Threats continued, 'i'll kill you in that dog.' and repeated derogatory language.",
        coachableMoments: [
          {
            type: 'negative',
            category: 'Physical Threat',
          },
        ],
      },
    ],
    outcome: {
      outcomeType: 'verbal_conflict',
      description:
        'The confrontation resulted in a prolonged verbal dispute without physical altercation but with clear hostile intent.',
      timestamp: 168,
      coachableMoments: [
        {
          type: 'negative',
          category: 'Conflict Resolution',
        },
      ],
    },
  },
};

// Assume a generic SAS token for demo purposes
const demoBlobSAS =
  'sp=racwdl&st=2024-04-29T19:39:54Z&se=2025-04-30T03:39:54Z&spr=https&sv=2022-11-02&sr=c&sig=aosKEIjaKV2Ix8VL0clSiUIA%2FciyvCUK9VVG5%2Fqvk0U%3D';
// Assume deviceVersion for demo purposes
const demoDeviceVersion = 'Mocha';

// Mapping of coachable moment categories to icons and colors
const categoryIcons = {
  'Communication Clarity': <RiVolumeUpLine />,
  'De-escalation Technique': <RiArrowDownSLine />,
  'Threat Response': <RiErrorWarningLine />,
  'Emotional Regulation': <RiThumbDownLine />,
  'Conflict Resolution': <RiShieldCheckLine />,
  default: <RiInformationLine />,
};

// Simplified color scheme based on positive/negative
const momentTypeColors = {
  positive: '#4CAF50', // Green for positive
  negative: '#F44336', // Red for negative
  default: '#607D8B', // Grey for neutral
};

// Timeline event categories with colors matching coaching categories
const eventCategoryColors = {
  'Initial Contact': '#2196F3', // Blue - match Communication Clarity
  'Verbal Escalation': '#FF9800', // Orange - match De-escalation
  'Threat Made': '#F44336', // Red - match Threat Response
  'Verbal Exchange': '#9C27B0', // Purple - match Emotional Regulation
  default: '#607D8B', // Grey
};

function Coaching() {
  const videoPlayerRef = useRef(null);
  const [demoIndex, setDemoIndex] = useState(0); // Track which demo is active
  const [analysisData, setAnalysisData] = useState(demoTrespassingAnalysis1);
  const [markers, setMarkers] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const timelineRef = useRef(null);
  const [clipNotification, setClipNotification] = useState({ visible: false, message: '' });

  // Toggle between demo analyses
  const toggleDemo = () => {
    if (demoIndex === 0) {
      setAnalysisData(demoTrespassingAnalysis2);
      setDemoIndex(1);
    } else {
      setAnalysisData(demoTrespassingAnalysis1);
      setDemoIndex(0);
    }
    setSelectedEvent(null);
  };

  // Update maxTimeInTimeline and totalDuration when analysisData changes
  const maxTimeInTimeline = useMemo(() => {
    return analysisData.analysis.incidentTimeline.reduce((max, event) => Math.max(max, event.endTime), 0);
  }, [analysisData]);

  const totalDuration = useMemo(() => {
    return Math.max(maxTimeInTimeline, analysisData.analysis.outcome?.timestamp || 0);
  }, [maxTimeInTimeline, analysisData]);

  useEffect(() => {
    // Create markers for video timeline
    if (analysisData?.analysis) {
      const newMarkers = [
        // Add markers for each timeline event
        ...analysisData.analysis.incidentTimeline.map((event) => {
          const hasNegative = event.coachableMoments.some((moment) => moment.type === 'negative');
          return {
            time: event.startTime,
            label: event.category,
            type: hasNegative ? 'negative' : 'positive',
          };
        }),
        // Add marker for outcome
        ...(analysisData.analysis.outcome
          ? [
              {
                time: analysisData.analysis.outcome.timestamp,
                label: `Outcome: ${analysisData.analysis.outcome.outcomeType}`,
                type: analysisData.analysis.outcome.coachableMoments.some((m) => m.type === 'negative')
                  ? 'negative'
                  : 'positive',
              },
            ]
          : []),
      ];
      setMarkers(newMarkers);
    }
  }, [analysisData]);

  // Auto-hide clip notification after 3 seconds
  useEffect(() => {
    if (clipNotification.visible) {
      const timer = setTimeout(() => {
        setClipNotification({ visible: false, message: '' });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [clipNotification]);

  // Count total improvement areas based on current analysisData - only one per event, excluding outcome
  const totalNegativeMoments = useMemo(() => {
    // Count events that have at least one negative moment
    return analysisData.analysis.incidentTimeline.filter((event) =>
      event.coachableMoments.some((moment) => moment.type === 'negative')
    ).length;

    // Outcome is no longer displayed, so we don't count it
  }, [analysisData]);

  // Count total positive actions based on current analysisData - only one per event, excluding outcome
  const totalPositiveMoments = useMemo(() => {
    // Count events that have at least one positive moment and no negative moments
    return analysisData.analysis.incidentTimeline.filter((event) => {
      const hasPositive = event.coachableMoments.some((moment) => moment.type === 'positive');
      const hasNegative = event.coachableMoments.some((moment) => moment.type === 'negative');
      // Only count as positive if there are no negative moments
      return hasPositive && !hasNegative;
    }).length;

    // Outcome is no longer displayed, so we don't count it
  }, [analysisData]);

  const jumpToTimestamp = (startTime) => {
    if (videoPlayerRef.current?.plyr) {
      // Set the current time first
      videoPlayerRef.current.plyr.currentTime = startTime;

      // Check if the player is not already playing to avoid race conditions
      if (videoPlayerRef.current.plyr.paused) {
        // Small timeout to ensure the seeking operation completes
        setTimeout(() => {
          // Only play if it's still paused (user hasn't interacted in the meantime)
          if (videoPlayerRef.current?.plyr?.paused) {
            videoPlayerRef.current.plyr.play().catch((err) => {
              console.log('Playback could not start:', err);
              // Fallback in case of error - just seek without playing
            });
          }
        }, 50);
      }
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const calculateDuration = (start, end) => {
    const duration = end - start;
    return `${duration}s`;
  };

  // Simplified position calculation to ensure bars fit within container
  const calculateEventPosition = (startTime, endTime) => {
    if (totalDuration === 0) return { left: 0, width: 0 };

    // Calculate positions as percentages
    const left = (startTime / totalDuration) * 100;
    const width = ((endTime - startTime) / totalDuration) * 100;

    // Make sure the width is at least 1% for visibility of small events
    const minWidth = 1;
    const adjustedWidth = Math.max(width, minWidth);

    return {
      left: `${left}%`,
      width: `${adjustedWidth}%`,
    };
  };

  const getCategoryColor = (category) => {
    return momentTypeColors[category] || momentTypeColors.default;
  };

  const getEventCategoryColor = (category) => {
    return eventCategoryColors[category] || eventCategoryColors.default;
  };

  const getEventStatus = (event) => {
    if (!event.coachableMoments || event.coachableMoments.length === 0) return 'neutral';

    const hasNegative = event.coachableMoments.some((moment) => moment.type === 'negative');
    const hasPositive = event.coachableMoments.some((moment) => moment.type === 'positive');

    if (hasNegative) return 'negative';
    if (hasPositive) return 'positive';
    return 'neutral';
  };

  const handleShareClip = (event, startTime, endTime) => {
    event.stopPropagation();

    // Generate a mock clip URL (in a real app, this would create an actual shareable link)
    const clipUrl = `https://app.example.com/clip/${analysisData.incidentId}?start=${startTime}&end=${endTime}`;

    // Copy to clipboard
    navigator.clipboard
      .writeText(clipUrl)
      .then(() => {
        setClipNotification({
          visible: true,
          message: 'Clip link copied to clipboard!',
        });
      })
      .catch((err) => {
        console.error('Could not copy clip link:', err);
        setClipNotification({
          visible: true,
          message: 'Failed to copy clip link.',
        });
      });
  };

  const renderTimeMarkers = () => {
    if (totalDuration === 0) return null;

    // Create markers every 30 seconds
    const interval = 30;
    const markers = [];

    for (let i = interval; i < totalDuration; i += interval) {
      const position = (i / totalDuration) * 100;
      markers.push(
        <div key={i} className="timeline-time-marker" style={{ left: `${position}%` }} data-time={formatTime(i)} />
      );
    }

    return <div className="timeline-time-markers">{markers}</div>;
  };

  const renderTimeLegend = () => {
    // Simplified legend
    return (
      <div className="timeline-legend">
        <div className="legend-item">
          <div className="legend-color" style={{ backgroundColor: '#4CAF50' }} />
          <span>Positive Action</span>
        </div>
        <div className="legend-item">
          <div className="legend-color" style={{ backgroundColor: '#F44336' }} />
          <span>Needs Improvement</span>
        </div>
        <div className="legend-item">
          <div className="legend-color" style={{ backgroundColor: '#673AB7' }} />
          <span>Outcome</span>
        </div>
      </div>
    );
  };

  // Renders the coachable moments for an event with category badges
  const renderCoachableMoments = (moments) => {
    if (!moments || moments.length === 0) {
      return <li className="no-moments">No coachable moments identified.</li>;
    }

    return moments.map((moment, index) => (
      <li key={index} className={`coachable-moment ${moment.type}`}>
        <div className="moment-category-badge" style={{ backgroundColor: getCategoryColor(moment.type) }}>
          {categoryIcons[moment.category] || categoryIcons.default}
          <span className="category-name">{moment.category}</span>
        </div>
        <div className="moment-content">
          <span className="moment-description">{moment.description}</span>
        </div>
      </li>
    ));
  };

  // Updated compact event card with simpler color scheme
  const renderCompactEvent = (event, index) => {
    const duration = calculateDuration(event.startTime, event.endTime);
    const isSelected = selectedEvent === index;

    // Get primary category from the first coachable moment
    const primaryCategory = event.coachableMoments?.[0]?.category;
    const momentType = event.coachableMoments?.[0]?.type || 'default';
    const categoryColor = momentTypeColors[momentType];
    const categoryIcon = categoryIcons[primaryCategory] || categoryIcons.default;

    // First remove "Quote:" text
    let processedDescription = event.description.replace(/Quote:\s*/g, '');

    // Handle quotes with a safer, more robust approach for text containing apostrophes
    // This uses a positive lookahead to ensure we don't match apostrophes
    const quoteRegex = /'((?:[^']|(?<=\w)'(?=\w))+)'/g;
    processedDescription = processedDescription.replace(quoteRegex, (match, quoteContent) => {
      return `<div class="event-quote">${quoteContent}</div>`;
    });

    return (
      <div
        key={index}
        className={`event-compact ${isSelected ? 'selected' : ''}`}
        onClick={() => {
          setSelectedEvent(index);
          jumpToTimestamp(event.startTime);
        }}
      >
        {/* Share button at the top right */}
        <button
          className="event-compact-action share-btn"
          onClick={(e) => {
            e.stopPropagation();
            handleShareClip(e, event.startTime, event.endTime);
          }}
          title="Share this clip"
        >
          <RiShareLine />
          <span>Share</span>
        </button>

        <div className="event-compact-row">
          {/* Category badge on the left */}
          <div className={`category-badge ${momentType}`} style={{ backgroundColor: categoryColor }}>
            {categoryIcon}
            <span>{primaryCategory}</span>
          </div>

          {/* Content on the right */}
          <div className="event-content">
            <div className="event-time-header">
              <div className="event-timestamp">
                <RiTimeLine className="time-icon" />
                <span className="timestamp-value">{formatTime(event.startTime)}</span>
                <span className="duration-badge">{duration}</span>
              </div>
            </div>

            <div className="event-description-content">
              <p className="event-description" dangerouslySetInnerHTML={{ __html: processedDescription }}></p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Renders a minimal, non-overlapping timeline
  const renderIncidentTimeline = () => {
    const maxTimeInTimeline = analysisData.analysis.incidentTimeline.reduce(
      (max, event) => Math.max(max, event.endTime),
      0
    );

    return (
      <div className="timeline-wrapper">
        <h3 className="timeline-title">
          <RiHistoryLine />
          Incident Timeline
        </h3>

        <div className="timeline-container" ref={timelineRef}>
          <div className="timeline-axis">
            <div className="timeline-start">0:00</div>
            <div className="timeline-end">{formatTime(maxTimeInTimeline)}</div>
          </div>

          {/* Simple minimal track */}
          <div className="timeline-track">
            {/* Events as simple markers with gaps */}
            {analysisData.analysis.incidentTimeline.map((event, index) => {
              // Calculate positions with built-in gaps
              // Use 90% of available width to leave margins on both sides
              const adjustedMaxTime = maxTimeInTimeline * 1.1; // Add 10% extra space
              const startPos = (event.startTime / adjustedMaxTime) * 96 + 2; // Start at 5% and use 90% width

              const momentType = event.coachableMoments?.[0]?.type || 'default';

              return (
                <div
                  key={index}
                  className={`timeline-marker-dot ${selectedEvent === index ? 'selected' : ''} ${momentType}`}
                  style={{ left: `${startPos}%` }}
                  onClick={() => {
                    setSelectedEvent(index);
                    jumpToTimestamp(event.startTime);
                  }}
                  title={`${event.category} (${formatTime(event.startTime)})`}
                >
                  <span className="marker-time">{formatTime(event.startTime)}</span>
                </div>
              );
            })}
          </div>

          {/* Simple legend */}
          <div className="timeline-legend">
            <div className="legend-item">
              <div className="legend-color positive"></div>
              <span>Positive</span>
            </div>
            <div className="legend-item">
              <div className="legend-color negative"></div>
              <span>Needs Improvement</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Renders the context summary card
  const renderContextCard = () => {
    return (
      <div className="context-card">
        <div className="context-header">
          <h2>
            <RiInformationLine className="context-icon" />
            Context Summary
          </h2>
        </div>
        <div className="context-content">
          <p>{analysisData.analysis.contextSummary}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="coaching-container">
      <div className="coaching-header">
        <div className="coaching-title">
          <h1>Plix Coach</h1>
          <div className="coaching-summary">
            <div className="summary-stat positive">
              <RiThumbUpLine />
              <span>{totalPositiveMoments} Positive Actions</span>
            </div>
            <div className="summary-stat negative">
              <RiThumbDownLine />
              <span>{totalNegativeMoments} Areas for Improvement</span>
            </div>
          </div>
        </div>

        <div className="incident-info">
          <div className="info-item">
            <RiCalendarLine className="info-icon" />
            <span>{new Date(analysisData.timestamp).toLocaleString()}</span>
          </div>
          <div className="info-item">
            <RiUserLine className="info-icon" />
            <span>Officer: {analysisData.assignedTo}</span>
          </div>
          <div className="info-item">
            <RiHashtag className="info-icon" />
            <span>ID: {analysisData.incidentId.substring(0, 8)}</span>
          </div>

          {/* Next incident navigation button */}
          <button className="next-incident-btn" onClick={toggleDemo}>
            <span>Next Incident</span>
            <RiArrowRightLine />
          </button>
        </div>
      </div>

      <div className="coaching-grid">
        {/* Left Column - Video and Timeline */}
        <div className="coaching-content-left">
          <div className="video-card">
            {analysisData.videoFilename && demoBlobSAS ? (
              <VideoPlayer
                ref={videoPlayerRef}
                videoFileName={analysisData.videoFilename}
                token={demoBlobSAS}
                markers={markers}
                deviceVersion={demoDeviceVersion}
                assignedTo={analysisData.assignedTo}
              />
            ) : (
              <div className="video-placeholder">
                <p>Video not available.</p>
              </div>
            )}
          </div>

          {renderIncidentTimeline()}
          {renderContextCard()}
        </div>

        {/* Right Column - Analysis */}
        <div className="coaching-content-right">
          <div className="analysis-wrapper">
            <div className="analysis-content">
              <div className="events-chronological">
                {/* Only render timeline events */}
                {analysisData.analysis.incidentTimeline.map((event, index) => renderCompactEvent(event, index))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Clip notification toast */}
      {clipNotification.visible && (
        <div className="clip-notification">
          <RiCheckDoubleLine className="notification-icon" />
          <span>{clipNotification.message}</span>
        </div>
      )}
    </div>
  );
}

export default Coaching;
