import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import '../styles/UnsavedChangesPopup.css';

const UnsavedChangesPopup = ({ onDiscard, onGoBack }) => {
  return (
    <div className="unsaved-changes-popup">
      <div className="unsaved-changes-content">
        <div className="unsaved-changes-header">
          {/* <FontAwesomeIcon icon={faExclamationTriangle} className="warning-icon" /> */}
          <h3>Unsaved Changes</h3>
        </div>
        <p>You have unsaved changes to this site. What would you like to do?</p>
        <div className="unsaved-changes-actions">
          <button className="discard-button" onClick={onDiscard}>
            Discard Changes
          </button>
          <button className="go-back-button" onClick={onGoBack}>
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default UnsavedChangesPopup;
