import React, { createContext, useContext, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { fetchStaticDevices, fetchDeviceStatuses } from '../api/beaverApi';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import debounce from 'lodash/debounce';

const DeviceFilterContext = createContext();

// Helper function for formatting time in shift
const formatTimeInShift = (seconds) => {
  if (!seconds) return '';

  // Convert seconds to minutes
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return hours > 0 ? `${hours}h ${remainingMinutes}m` : `${minutes}m`;
};

// Helper function for comparing dates
function areDatesEqual(date1, date2) {
  if (!date1 && !date2) return true;
  if (!date1 || !date2) return false;
  return date1.getTime() === date2.getTime();
}

// Helper function to check if device state has changed
function deviceStateHasChanged(prevState, nextState) {
  if (!prevState || !nextState) return true;

  // Check only the fields that matter for rendering
  return (
    prevState.deviceState !== nextState.deviceState ||
    prevState.batteryStatus !== nextState.batteryStatus ||
    prevState.shiftStatus !== nextState.shiftStatus ||
    prevState.isRecording !== nextState.isRecording ||
    !areLocationsEqual(prevState.location, nextState.location) ||
    !areDatesEqual(prevState.lastLocatedTime, nextState.lastLocatedTime)
  );
}

// Helper function for comparing locations
function areLocationsEqual(loc1, loc2) {
  if (!loc1 || !loc2) return loc1 === loc2;
  return loc1[0] === loc2[0] && loc1[1] === loc2[1];
}

export const DeviceFilterProvider = ({ children, userId, userRole }) => {
  // Static device data - rarely changes
  const [staticDevices, setStaticDevices] = useState([]);

  // Dynamic status data - frequently changes
  const [deviceStatuses, setDeviceStatuses] = useState({});

  // Last update timestamp for optimizing status requests
  const lastUpdateRef = useRef(null);

  // Other state
  const [deviceFilter, setDeviceFilter] = useState(() => {
    const savedFilter = localStorage.getItem('deviceFilter');
    return savedFilter || null;
  });
  const [selectedOrg, setSelectedOrg] = useState(() => localStorage.getItem('selectedOrg') || PLIX_OVERWATCH_ACCOUNT);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isLoading, setIsLoading] = useState(true);

  // Network status monitoring
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Reset device filter when org changes for admin users
  useEffect(() => {
    if (userId === PLIX_OVERWATCH_ACCOUNT) {
      setDeviceFilter(null);
      localStorage.removeItem('deviceFilter');
    }
  }, [selectedOrg, userId]);

  // Fetch static device data (less frequently)
  const fetchStaticDeviceData = useCallback(async () => {
    if (!isOnline || !userId) return;

    try {
      const effectiveUserId = userId === PLIX_OVERWATCH_ACCOUNT ? selectedOrg : userId;
      const staticData = await fetchStaticDevices(effectiveUserId, userRole);

      // Process static data
      const processedStaticData = staticData.map((device) => ({
        ...device,
        // Transform streamUrl to config object for compatibility
        config: {
          streamAddress: device.streamUrl || '',
        },
        // Remove redundant field after transforming
        streamUrl: undefined,
      }));

      setStaticDevices(processedStaticData);

      // Automatically select first device if none selected
      if (!deviceFilter && processedStaticData.length > 0) {
        const initialDeviceId = processedStaticData[0].deviceId;
        setDeviceFilter(initialDeviceId);
        localStorage.setItem('deviceFilter', initialDeviceId);
      }

      if (isLoading && processedStaticData.length > 0) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching static device data:', error);
      setIsLoading(false);
    }
  }, [isOnline, userId, selectedOrg, userRole, deviceFilter, isLoading]);

  // Fetch dynamic device status (more frequently)
  const fetchDeviceStatus = useCallback(
    debounce(async () => {
      if (!isOnline || staticDevices.length === 0) {
        if (isLoading) setIsLoading(false);
        return;
      }

      try {
        const effectiveUserId = userId === PLIX_OVERWATCH_ACCOUNT ? selectedOrg : userId;
        const deviceIds = staticDevices.map((device) => device.deviceId);

        // Pass device IDs to optimize the backend query
        // This will now send deviceIds in the request body via POST
        const statusData = await fetchDeviceStatuses(effectiveUserId, deviceIds, userRole);

        // Process timestamps to Date objects
        const processedStatusData = Object.entries(statusData).reduce((acc, [deviceId, status]) => {
          // Process date fields
          const processedStatus = {
            ...status,
            lastLocatedTime: status.lastLocatedTime ? new Date(status.lastLocatedTime) : null,
            lastUpdateTime: status.lastUpdateTime ? new Date(status.lastUpdateTime) : null,
            lastChargeTime: status.lastChargeTime ? new Date(status.lastChargeTime) : null,
            lastRecordTime: status.lastRecordTime ? new Date(status.lastRecordTime) : null,
            // For lastShiftTime, keep 'Now' as a string but convert other values to Date
            lastShiftTime:
              status.lastShiftTime === 'Now' ? 'Now' : status.lastShiftTime ? new Date(status.lastShiftTime) : null,
          };

          acc[deviceId] = processedStatus;
          return acc;
        }, {});

        // Update state only if there are changes to prevent unnecessary re-renders
        setDeviceStatuses((prev) => {
          const hasChanges = Object.keys(processedStatusData).some((deviceId) =>
            deviceStateHasChanged(prev[deviceId], processedStatusData[deviceId])
          );

          return hasChanges ? processedStatusData : prev;
        });

        // Update last update timestamp
        lastUpdateRef.current = new Date().toISOString();

        if (isLoading) setIsLoading(false);
      } catch (error) {
        console.error('Error fetching device status:', error);
        if (isLoading) setIsLoading(false);
      }
    }, 1000),
    [isOnline, userId, selectedOrg, staticDevices, userRole, isLoading]
  );

  // Fetch static data on initial load and less frequently
  useEffect(() => {
    fetchStaticDeviceData();
    const interval = setInterval(fetchStaticDeviceData, 5 * 60 * 1000); // every 5 minutes

    return () => clearInterval(interval);
  }, [fetchStaticDeviceData]);

  // Fetch dynamic data more frequently
  useEffect(() => {
    fetchDeviceStatus();
    const interval = setInterval(fetchDeviceStatus, 30 * 1000); // every 30 seconds

    return () => {
      clearInterval(interval);
      fetchDeviceStatus.cancel(); // Cancel any pending debounced calls
    };
  }, [fetchDeviceStatus]);

  // Combine static and dynamic data for components that need the full device object
  const devices = useMemo(() => {
    if (staticDevices.length === 0) return [];

    return staticDevices
      .map((staticDevice) => {
        const status = deviceStatuses[staticDevice.deviceId] || {};

        return {
          ...staticDevice,
          ...status,
          // Ensure all required fields are present with defaults
          location: status.location || [0, 0],
          isStreamActive: false,
          isAlertsActive: true,
          deviceState: status.deviceState || 'Offline',
          batteryStatus: status.batteryStatus || null,
          shiftStatus: status.shiftStatus || false,
          timeInShift: status.timeInShift || null,
          isRecording: status.isRecording || false,
          isCharging: status.isCharging || false,
          isOffline: status.isOffline || false,
          isSmartRecordingOff: status.isSmartRecordingOff || false,
        };
      })
      .filter((device) => {
        // Filter out devices with invalid locations
        return device.location && (device.location[0] !== 0 || device.location[1] !== 0);
      });
  }, [staticDevices, deviceStatuses]);

  // Extract shift data for components that only need that info
  const deviceShifts = useMemo(() => {
    return Object.entries(deviceStatuses).reduce((acc, [deviceId, status]) => {
      if (!status) return acc;

      acc[deviceId] = {
        shiftStatus: status.shiftStatus || false,
        lastShiftTime: status.lastShiftTime || null,
        timeInShift: status.timeInShift || null,
        isRecording: status.isRecording || false,
        isSmartRecordingOff: status.isSmartRecordingOff || false,
        formattedTimeInShift: status.timeInShift ? formatTimeInShift(status.timeInShift) : '',
      };
      return acc;
    }, {});
  }, [deviceStatuses]);

  // Filter devices based on selected filter
  const filteredDevices = useMemo(() => {
    if (!deviceFilter) return devices;
    return devices.filter((device) => device.deviceId === deviceFilter);
  }, [devices, deviceFilter]);

  // Add this function to update devices
  const updateDevice = useCallback((deviceId, updates) => {
    setStaticDevices((prevDevices) =>
      prevDevices.map((device) => (device.deviceId === deviceId ? { ...device, ...updates } : device))
    );
  }, []);

  // Update the context value to include updateDevice
  const value = useMemo(
    () => ({
      devices,
      deviceShifts,
      filteredDevices,
      deviceFilter,
      setDeviceFilter,
      selectedOrg,
      setSelectedOrg,
      isLoading,
      isOnline,
      updateDevice,
    }),
    [devices, deviceShifts, filteredDevices, deviceFilter, selectedOrg, isLoading, isOnline, updateDevice]
  );

  return <DeviceFilterContext.Provider value={value}>{children}</DeviceFilterContext.Provider>;
};

export const useDeviceFilter = () => {
  const context = useContext(DeviceFilterContext);
  if (!context) {
    throw new Error('useDeviceFilter must be used within a DeviceFilterProvider');
  }
  return context;
};
