import React, { createContext, useContext, useState } from 'react';

const FeatureFlagContext = createContext();

export function FeatureFlagProvider({ children }) {
  const [flags, setFlags] = useState({
    searchExperimental: false, // Set to false to disable new layout and search
  });

  const updateFlag = (flagName, value) => {
    setFlags((prev) => ({
      ...prev,
      [flagName]: value,
    }));
  };

  return <FeatureFlagContext.Provider value={{ flags, updateFlag }}>{children}</FeatureFlagContext.Provider>;
}

export function useFeatureFlags() {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider');
  }
  return context;
}
