// In Reports.js

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReportCardList from './ReportCardList';
import ReportEditor from './ReportEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faArrowLeft, faMicrophone, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useDeviceFilter } from '../contexts/DeviceFilterContext';
import { useMediaQuery } from '@mui/material';
import { fetchReports, deleteReport } from '../api/beaverApi';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import '../styles/Reports/Reports.css';

const Reports = ({ userId, userRole, userEmail, parentOrgUserId }) => {
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { devices, selectedOrg } = useDeviceFilter();
  console.log('devices in reports', devices);
  const [refreshKey, setRefreshKey] = useState(0);

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [newReportData, setNewReportData] = useState({
    assignedTo: '',
    incidentTime: new Date().toLocaleString('sv-SE').replace(' ', 'T').slice(0, 16),
  });
  const [assignedToOptions, setAssignedToOptions] = useState([]);

  const isMobile = useMediaQuery('(max-width:768px)');

  const [isLoadingReport, setIsLoadingReport] = useState(false);

  const [lastFetch, setLastFetch] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const getEffectiveUserId = (userId, selectedOrg) => {
    return userId === PLIX_OVERWATCH_ACCOUNT
      ? selectedOrg === PLIX_OVERWATCH_ACCOUNT
        ? undefined
        : selectedOrg
      : userId;
  };

  const loadReports = async () => {
    // Don't fetch if we're waiting for devices data for a guard
    if (userRole === 'guard' && (!devices || devices.length === 0)) {
      console.log('Waiting for devices data...');
      return;
    }

    setIsLoading(true);
    try {
      const options = {};
      if (userRole === 'guard') {
        // Send all deviceIds for the guard
        options.deviceId = devices.map((device) => device.deviceId).join(',');
        console.log('Fetching reports with deviceId:', options.deviceId);
      }
      const fetchedReports = await fetchReports(getEffectiveUserId(userId, selectedOrg), options);
      console.log('fetchedReports', fetchedReports);

      // Filter reports based on available device IDs
      const deviceIds = devices.map((device) => device.deviceId);
      const filteredReports = fetchedReports.filter((report) => deviceIds.includes(report.deviceId));

      setReports(filteredReports);
      setLastFetch(new Date());
    } catch (error) {
      console.error('Error loading reports:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadReports();
  }, [userRole, devices.length]);

  useEffect(() => {
    const fetchAssignedToOptions = async () => {
      const options = devices.map((d) => d.assignedTo);
      setAssignedToOptions(options);
      setNewReportData({ ...newReportData, assignedTo: options[0] });
    };
    fetchAssignedToOptions();
  }, [devices.length]);

  useEffect(() => {
    const handleReportNavigation = async () => {
      const params = new URLSearchParams(location.search);
      const reportId = params.get('reportId');
      const alertId = params.get('alertId');

      if (isLoadingReport) return;

      if (reportId || alertId) {
        setIsLoadingReport(true);
        try {
          const options = {};
          if (userRole === 'guard' && devices.length > 0) {
            options.deviceId = devices[0].deviceId;
          }
          const fetchedReports = await fetchReports(getEffectiveUserId(userId, selectedOrg), options);
          const report = fetchedReports.find((r) => r.reportId === reportId || r.alertId === alertId);

          if (report) {
            setSelectedReport(report);
            setReports(fetchedReports);
          } else {
            console.log('No report found');
            setSelectedReport(null);
          }
        } catch (error) {
          console.error('Error handling report navigation:', error);
          setSelectedReport(null);
        } finally {
          setIsLoadingReport(false);
        }
      } else {
        setSelectedReport(null);
      }
    };

    handleReportNavigation();
  }, [location.search, userId, selectedOrg, userRole, devices.length]);

  const handleOpenEditor = (report = null) => {
    if (report) {
      navigate(`/reports?reportId=${report.reportId}`);
    } else {
      setIsCreateDialogOpen(true);
    }
  };

  const handleCreateReport = () => {
    if (newReportData.assignedTo && newReportData.incidentTime) {
      setIsCreateDialogOpen(false);
      navigate(
        `/reports?assignedTo=${encodeURIComponent(newReportData.assignedTo)}&timestamp=${newReportData.incidentTime}`
      );
    }
  };

  const handleCloseEditor = () => {
    navigate('/reports');
    loadReports();
  };

  const handleDeleteReport = async (reportId) => {
    try {
      let effectiveUserId = getEffectiveUserId(userId, selectedOrg);
      if (userRole === 'ASP' || userRole === 'guard') {
        effectiveUserId = parentOrgUserId;
      }
      const result = await deleteReport(reportId, effectiveUserId);
      if (result.success) {
        setReports((prevReports) => prevReports.filter((report) => report.reportId !== reportId));
      } else {
        console.error('Failed to delete report:', result.message);
      }
    } catch (error) {
      console.error('Error deleting report:', error);
    }
  };

  const isEditorOpen = Boolean(selectedReport);

  const NoReportsView = () => (
    <div className="no-reports-container">
      <div className="no-reports-content">
        <div className="no-reports-icon">
          <FontAwesomeIcon icon={faMicrophone} />
        </div>
        <h2>No Reports Yet</h2>
        <p>To create your first report:</p>
        <ol>
          <li>Start a recording on your device</li>
          <li>Say something like "I want to make a report..."</li>
          <li>Dictate your report</li>
          <li>Wait for it to appear on the platform</li>
        </ol>
      </div>
    </div>
  );

  useEffect(() => {
    if (!location.search) {
      loadReports();
    }
  }, [location.search]);

  return (
    <div className="reports-container">
      <div className="reports-header">
        <div className="reports-header-left">
          {isMobile ? (
            <>
              <Link to="/" className="back-button">
                <FontAwesomeIcon icon={faArrowLeft} />
              </Link>
              <h1 className="mobile-header">
                Reports
                {!isLoading && reports.length > 0 && (
                  <Tooltip
                    title={
                      <div style={{ padding: '8px' }}>
                        <h3 style={{ margin: '0 0 8px 0', fontSize: '1rem' }}>How to Create Reports:</h3>
                        <ol style={{ margin: 0, paddingLeft: '16px' }}>
                          <li>Start a recording on your device</li>
                          <li>Say something like "I want to make a report..."</li>
                          <li>Dictate your report</li>
                          <li>Wait for it to appear on the platform</li>
                        </ol>
                      </div>
                    }
                    arrow
                    classes={{
                      tooltip: 'configuration-wrapper',
                      arrow: 'configuration-wrapper',
                    }}
                    sx={{
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        color: 'rgba(0, 0, 0, 0.87)',
                        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
                        fontSize: '0.875rem',
                        maxWidth: '300px',
                      },
                      '& .MuiTooltip-arrow': {
                        color: 'white',
                      },
                    }}
                  >
                    <IconButton size="small" sx={{ ml: 1 }}>
                      <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                    </IconButton>
                  </Tooltip>
                )}
              </h1>
            </>
          ) : (
            <h1>
              Reports
              {!isLoading && reports.length > 0 && (
                <Tooltip
                  title={
                    <div style={{ padding: '8px' }}>
                      <h3 style={{ margin: '0 0 8px 0', fontSize: '1rem' }}>How to Create Reports:</h3>
                      <ol style={{ margin: 0, paddingLeft: '16px' }}>
                        <li>Start a recording on your device</li>
                        <li>Say something like "I want to make a report..."</li>
                        <li>Dictate your report</li>
                        <li>Wait for it to appear on the platform</li>
                      </ol>
                    </div>
                  }
                  arrow
                  classes={{
                    tooltip: 'configuration-wrapper',
                    arrow: 'configuration-wrapper',
                  }}
                  sx={{
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'white',
                      color: 'rgba(0, 0, 0, 0.87)',
                      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
                      fontSize: '0.875rem',
                      maxWidth: '300px',
                    },
                    '& .MuiTooltip-arrow': {
                      color: 'white',
                    },
                  }}
                >
                  <IconButton size="small" sx={{ ml: 1 }}>
                    <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                  </IconButton>
                </Tooltip>
              )}
            </h1>
          )}
        </div>
      </div>
      <div className="reports-body">
        {!isEditorOpen && (
          <>
            {isLoading ? (
              <div className="reports-loading-container">Loading reports...</div>
            ) : reports.length > 0 ? (
              <ReportCardList
                reports={reports}
                onReportClick={handleOpenEditor}
                onDeleteReport={handleDeleteReport}
                isMobile={isMobile}
              />
            ) : (
              <NoReportsView />
            )}
          </>
        )}
        {isEditorOpen && (
          <ReportEditor
            report={selectedReport}
            userEmail={userEmail}
            userId={getEffectiveUserId(userId, selectedOrg)}
            isMobile={isMobile}
            userRole={userRole}
            parentOrgUserId={parentOrgUserId}
          />
        )}
      </div>

      <Dialog open={isCreateDialogOpen} onClose={() => setIsCreateDialogOpen(false)}>
        <DialogTitle>Create New Report</DialogTitle>
        <DialogContent>
          <Select
            value={newReportData.assignedTo}
            onChange={(e) => setNewReportData((prev) => ({ ...prev, assignedTo: e.target.value }))}
            displayEmpty
            fullWidth
            style={{ marginBottom: '20px', marginTop: '10px' }}
          >
            <MenuItem value="" disabled>
              Select User
            </MenuItem>
            {assignedToOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          <TextField
            type="datetime-local"
            value={newReportData.incidentTime}
            onChange={(e) => setNewReportData((prev) => ({ ...prev, incidentTime: e.target.value }))}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCreateDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleCreateReport} disabled={!newReportData.assignedTo || !newReportData.incidentTime}>
            Create Report
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Reports;
