import React, { useEffect, useRef, forwardRef, useState, useCallback } from 'react';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import '../styles/VideoPlayer.css';
import { trackVideoPlayed } from '../utils/analytics';

const VideoPlayer = forwardRef(
  (
    {
      videoFileName,
      markers,
      token,
      preStart = 10,
      onPlayerUpdate,
      transcriptUrl,
      initialTime,
      deviceVersion,
      assignedTo,
    },
    ref
  ) => {
    const [captionsAvailable, setCaptionsAvailable] = useState(false);
    const plyrInstanceRef = useRef(null);
    const intervalRef = useRef(null);

    console.log('assignedTo in VideoPlayer:', assignedTo);

    const url =
      token && videoFileName
        ? `https://plixvideostorage.blob.core.windows.net/videos-whole/${videoFileName}?${token}#t=${markers.length ? Math.max(0, markers[0].time - preStart) : 0}`
        : null;
    console.log('url', url);
    const transcriptUrlWithToken = transcriptUrl
      ? transcriptUrl + `?${process.env.REACT_APP_BLOB_SAS_TOKEN_2}`
      : `https://plixvideostorage.blob.core.windows.net/transcripts-vtt/${videoFileName?.replace('.mp4', '.vtt')}?${process.env.REACT_APP_BLOB_SAS_TOKEN_2}`;

    // Store modified VTT content
    const [modifiedVttUrl, setModifiedVttUrl] = useState(null);

    const videoSource = {
      type: 'video',
      sources: [{ src: url, type: 'video/mp4' }],
      tracks:
        captionsAvailable && modifiedVttUrl
          ? [{ kind: 'captions', label: 'English', srclang: 'en', src: modifiedVttUrl }]
          : [
              {
                kind: 'captions',
                label: 'English',
                srclang: 'en',
                src: 'data:text/vtt,WEBVTT\n\n00:00:00.000 --> 00:00:03.000\n<i>Transcription in progress...</i>',
              },
            ],
    };

    console.log('VideoPlayer deviceVersion:', deviceVersion);

    const plyrOptions = {
      markers: { enabled: true, points: markers.sort((a, b) => a.time - b.time) },
      controls: [
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'captions',
        'settings',
        'pip',
        'airplay',
        'fullscreen',
      ],
      captions: { active: captionsAvailable, update: true, language: 'en' },
      crossorigin: 'anonymous',
      blankVideo: '',
      ratio: deviceVersion === 'Mocha' ? '16:9' : '1:1', // Use landscape ratio for Mocha devices
    };

    console.log('markers: ', markers);

    // const cleanupAndDestroy = useCallback(() => {
    //   console.error('PLYR: Player instance became undefined, cleaning up...');
    //   if (intervalRef.current) {
    //     clearInterval(intervalRef.current);
    //     intervalRef.current = null;
    //   }
    //   if (plyrInstanceRef.current && plyrInstanceRef.current.destroy) {
    //     plyrInstanceRef.current.destroy();
    //   }
    //   plyrInstanceRef.current = null;
    //   onError('Player instance became undefined');
    // }, [onError]);

    const checkPlayerState = useCallback(() => {
      const plyrInstance = plyrInstanceRef.current;
      try {
        if (plyrInstance?.currentTime != null && plyrInstance?.paused != null) {
          const newState = {
            currentTime: plyrInstance.currentTime ?? 0,
            isPlaying: !plyrInstance.paused,
          };
          if (typeof onPlayerUpdate === 'function') {
            onPlayerUpdate(newState);
          }
          console.log(`PLYR: Current time: ${newState.currentTime}`);
        }
      } catch (error) {
        console.error('Error checking player state:', error);
      }
    }, [onPlayerUpdate]);

    useEffect(() => {
      let isMounted = true;

      const setupInterval = () => {
        if (isMounted && plyrInstanceRef.current) {
          console.log('PLYR: Player is ready');
          intervalRef.current = setInterval(checkPlayerState, 1000);
        }
      };

      // Delay the setup slightly to ensure the Plyr instance is fully initialized
      const timeoutId = setTimeout(setupInterval, 100);

      return () => {
        isMounted = false;
        clearTimeout(timeoutId);
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }, [checkPlayerState]);

    // Handle initialTime with a more aggressive approach
    useEffect(() => {
      if (initialTime == null || !plyrInstanceRef.current) return;

      console.log(`PLYR: Attempting to set initial time to ${initialTime}`);

      const setInitialTime = () => {
        try {
          if (plyrInstanceRef.current && plyrInstanceRef.current.media) {
            // Force load metadata if needed
            if (plyrInstanceRef.current.media.readyState === 0) {
              plyrInstanceRef.current.media.load();
            }

            plyrInstanceRef.current.currentTime = initialTime;
            console.log(`PLYR: Successfully set initial time to ${initialTime}`);

            // Verify the time was set correctly
            setTimeout(() => {
              if (plyrInstanceRef.current && Math.abs(plyrInstanceRef.current.currentTime - initialTime) > 0.5) {
                console.log(
                  `PLYR: Time verification failed, trying again. Current: ${plyrInstanceRef.current.currentTime}, Target: ${initialTime}`
                );
                plyrInstanceRef.current.currentTime = initialTime;
              }
            }, 100);

            return true;
          }
          return false;
        } catch (error) {
          console.error('Error setting initial time:', error);
          return false;
        }
      };

      // Try multiple approaches to ensure the time gets set

      // 1. Try immediately
      setInitialTime();

      // 2. Add multiple event listeners for different player states
      const events = ['loadedmetadata', 'canplay', 'playing', 'seeked'];
      const eventHandlers = {};

      events.forEach((event) => {
        const handler = () => {
          console.log(`PLYR: '${event}' event triggered, setting time`);
          setInitialTime();
        };
        eventHandlers[event] = handler;

        if (plyrInstanceRef.current && plyrInstanceRef.current.media) {
          plyrInstanceRef.current.media.addEventListener(event, handler);
        }
      });

      // 3. Try with increasing delays as fallback
      const delays = [100, 500, 1000, 2000];
      const timeoutIds = delays.map((delay) =>
        setTimeout(() => {
          console.log(`PLYR: Trying to set time after ${delay}ms delay`);
          setInitialTime();
        }, delay)
      );

      // 4. Force preload attribute
      if (plyrInstanceRef.current && plyrInstanceRef.current.media) {
        plyrInstanceRef.current.media.preload = 'auto';
      }

      // Cleanup function
      return () => {
        // Remove all event listeners
        if (plyrInstanceRef.current && plyrInstanceRef.current.media) {
          events.forEach((event) => {
            plyrInstanceRef.current.media.removeEventListener(event, eventHandlers[event]);
          });
        }

        // Clear all timeouts
        timeoutIds.forEach((id) => clearTimeout(id));
      };
    }, [initialTime]);

    useEffect(() => {
      let cleanup = () => {};

      if (transcriptUrlWithToken) {
        console.log('[ASSIGNED_TO] Loading transcript from:', transcriptUrlWithToken);
        fetch(transcriptUrlWithToken)
          .then((response) => {
            if (response.ok) {
              return response.text().then((text) => {
                // Replace "Officer:" with assigned user in VTT content
                console.log('[ASSIGNED_TO] Full VTT content:', text);
                console.log('[ASSIGNED_TO] assignedTo value:', assignedTo);

                const officerMatches = text.match(/Officer:/g) || [];
                const upperOfficerMatches = text.match(/OFFICER:/g) || [];
                console.log('[ASSIGNED_TO] Found matches:', {
                  'Officer:': officerMatches.length,
                  'OFFICER:': upperOfficerMatches.length,
                });

                if (assignedTo) {
                  console.log('[ASSIGNED_TO] Replacing with:', assignedTo + ':');
                  text = text.replace(/Officer:/g, `${assignedTo}:`);
                  text = text.replace(/OFFICER:/g, `${assignedTo}:`);

                  const newMatches = text.match(new RegExp(`${assignedTo}:`, 'g')) || [];
                  console.log('[ASSIGNED_TO] After replacement matches:', newMatches.length);
                } else {
                  console.log('[ASSIGNED_TO] Using Personnel fallback');
                  text = text.replace(/Officer:/g, `Personnel:`);
                  text = text.replace(/OFFICER:/g, `PERSONNEL:`);
                }

                console.log('[ASSIGNED_TO] Modified VTT content:', text);

                // Create blob URL from modified content
                const blob = new Blob([text], { type: 'text/vtt' });
                const url = URL.createObjectURL(blob);
                console.log('[ASSIGNED_TO] Created modified VTT URL:', url);

                // Set the modified URL in state, which will update videoSource
                setModifiedVttUrl(url);
                setCaptionsAvailable(true);

                // Clean up old blob URL if it exists
                return () => {
                  if (modifiedVttUrl) {
                    URL.revokeObjectURL(modifiedVttUrl);
                  }
                };
              });
            } else {
              setCaptionsAvailable(false);
              console.log('Transcript is not available');
            }
          })
          .catch((error) => {
            console.error('Error checking transcript availability:', error);
            setCaptionsAvailable(false);
          });
      }

      // Return cleanup function to revoke blob URLs
      return () => {
        cleanup();
        if (modifiedVttUrl) {
          console.log('[ASSIGNED_TO] Cleaning up blob URL:', modifiedVttUrl);
          URL.revokeObjectURL(modifiedVttUrl);
          setModifiedVttUrl(null);
        }
      };
    }, [transcriptUrlWithToken, assignedTo]);

    // Activate captions when they become available
    useEffect(() => {
      if (captionsAvailable && modifiedVttUrl && plyrInstanceRef.current?.media) {
        console.log('[ASSIGNED_TO] Attempting to activate captions');
        const textTracks = plyrInstanceRef.current.media.textTracks;

        // Short delay to ensure track is loaded
        setTimeout(() => {
          if (textTracks.length > 0) {
            console.log('[ASSIGNED_TO] Setting first track to showing');
            textTracks[0].mode = 'showing';

            // Force captions option to be active
            if (plyrInstanceRef.current.captions) {
              plyrInstanceRef.current.captions.active = true;
            }
          }
        }, 500);
      }
    }, [captionsAvailable, modifiedVttUrl]);

    const handleVideoClick = useCallback(() => {
      // if (plyrInstanceRef.current) {
      //   if (plyrInstanceRef.current.paused) {
      //     plyrInstanceRef.current.play();
      //   } else {
      //     plyrInstanceRef.current.pause();
      //   }
      // }
    }, []);

    if (!token || !videoFileName) {
      console.error('SAS token or video filename is missing or null');
      return null;
    }

    return (
      <div className="video-container">
        <Plyr
          ref={(el) => {
            if (el) {
              plyrInstanceRef.current = el.plyr;
              console.log('[ASSIGNED_TO] Player instance set:', !!plyrInstanceRef.current);

              // Log initial track state
              if (plyrInstanceRef.current.media) {
                const tracks = plyrInstanceRef.current.media.textTracks;
                console.log(
                  '[ASSIGNED_TO] Initial text tracks:',
                  Array.from(tracks).map((t) => ({
                    label: t.label,
                    mode: t.mode,
                    kind: t.kind,
                  }))
                );
              }
            }
            if (typeof ref === 'function') ref(el);
            else if (ref) ref.current = el;
          }}
          source={videoSource}
          options={plyrOptions}
        />
      </div>
    );
  }
);

export default VideoPlayer;
