import React, { useState, useEffect, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import { format } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';
import TableSortLabel from '@mui/material/TableSortLabel';
import { cosmosClient } from '../utils/eventstorage';

import '../styles/DeviceAssignment.css';

// Helper functions
const formatLastShiftTime = (lastShiftTime, shiftStatus, timeInShift, isOffline, isSmartRecordingOff, isRecording) => {
  if (shiftStatus) {
    const formattedTime = formatTimeInShift(timeInShift);
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {formattedTime ? `Now (${formattedTime})` : 'Now'}
        {isOffline && (
          <Tooltip title="Connection Lost" placement="top">
            <SignalWifiOffIcon style={{ marginLeft: '4px', color: 'gray', fontSize: '0.875rem' }} />
          </Tooltip>
        )}
      </div>
    );
  }

  if (!lastShiftTime) return '';

  // Handle the case where lastShiftTime is already a Date object
  const date = lastShiftTime instanceof Date ? lastShiftTime : new Date(lastShiftTime);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error('Invalid date:', lastShiftTime);
    return '';
  }

  const now = new Date();
  if (date > now) return 'Future date';
  if (date.toDateString() === now.toDateString()) {
    return `Today at ${format(date, 'h:mm a')}`;
  }
  if (date.toDateString() === new Date(now.getTime() - 86400000).toDateString()) {
    return `Yesterday at ${format(date, 'h:mm a')}`;
  }
  if (date.getFullYear() === now.getFullYear()) {
    return format(date, 'M/d h:mm a');
  }
  return format(date, 'M/d/yyyy h:mm a');
};

const formatTimeInShift = (timeInShift) => {
  if (timeInShift === null) return '';
  if (timeInShift < 1) return 'Just started';
  if (timeInShift < 60) return `${timeInShift}m`;

  const hours = Math.floor(timeInShift / 60);
  const remainingMinutes = timeInShift % 60;
  return `${hours}h ${remainingMinutes}m`;
};

// GuardActionBanner component for mobile view
const GuardActionBanner = ({ devices: initialDevices }) => {
  const [state, setState] = useState(2);
  const [message, setMessage] = useState('Device is off');
  const [isVisible, setIsVisible] = useState(true);
  const [deviceSettings, setDeviceSettings] = useState({
    buttonType: 'MULTI_TOUCH',
    recordingPolicy: 'User',
  });
  const previousMessageRef = useRef('Device is off');

  useEffect(() => {
    const fetchDeviceSettings = async () => {
      try {
        const devicesContainer = cosmosClient.database('Devices').container('Devices');
        const { resources: devices } = await devicesContainer.items
          .query({
            query: 'SELECT c.buttonType, c.recordingPolicy FROM c WHERE c.deviceId = @deviceId',
            parameters: [{ name: '@deviceId', value: initialDevices[0].deviceId }],
          })
          .fetchAll();

        console.log('devices config: ', devices);

        if (devices.length > 0) {
          setDeviceSettings({
            buttonType: devices[0].buttonType || 'MULTI_TOUCH',
            recordingPolicy: devices[0].recordingPolicy || 'User',
          });
        }
      } catch (error) {
        console.error('Failed to fetch device settings:', error);
      }
    };

    fetchDeviceSettings();
  }, [initialDevices]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newState = (state + 1) % 16;
      const newMessage = getMessage(newState, deviceSettings);

      if (newMessage !== previousMessageRef.current) {
        setIsVisible(false); // Start fade out
        setTimeout(() => {
          setState(newState);
          setMessage(newMessage);
          previousMessageRef.current = newMessage;
          setIsVisible(true); // Start fade in
        }, 100); // Wait for 0.1s fade out to complete
      } else {
        setState(newState);
        setMessage(newMessage);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [state, deviceSettings]);

  const getLogoClass = () => {
    if (state < 4) return 'black';
    if (state < 7) return 'green';
    if (state < 13) return 'red';
    if (state < 16) return 'green';
    return 'black';
  };

  const getRecordingControlText = () => {
    switch (deviceSettings.buttonType) {
      case 'SINGLE_TAP':
        return 'Tap';
      case 'DOUBLE_TAP':
        return 'Double tap';
      case 'MULTI_TOUCH':
        return 'Two-finger hold';
      default:
        return 'Tap';
    }
  };

  const getMessage = () => {
    const isShiftRecording = deviceSettings.recordingPolicy === 'Shift';

    switch (state) {
      case 0:
      case 1:
        return 'Device is off';
      case 2:
      case 3:
      case 4:
        return 'Hold to start shift';
      case 5:
      case 6:
      case 7:
        if (isShiftRecording) {
          return 'Recording starts automatically';
        } else {
          return `${getRecordingControlText()} to start recording`;
        }
      case 8:
      case 9:
      case 10:
        return 'Recording in progress';
      case 11:
      case 12:
      case 13:
        if (isShiftRecording) {
          return 'Hold to end shift and recording';
        } else {
          return `${getRecordingControlText()} to end recording`;
        }
      case 14:
      case 15:
        return 'Hold to end shift';
      default:
        return '';
    }
  };

  return (
    <div className="device-assignment-guard-action-banner">
      <div className="device-assignment-logo-container">
        <img src="/plix_logo_red.svg" alt="Plix Logo" className={`device-assignment-plix-logo ${getLogoClass()}`} />
      </div>
      <div className="device-assignment-text-container">
        <span
          className={`device-assignment-action-text ${isVisible ? 'device-assignment-fade-in' : 'device-assignment-fade-out'}`}
        >
          {message}
        </span>
      </div>
    </div>
  );
};

// Main component
const DeviceAssignment = ({
  handleDeviceClick,
  devices,
  deviceShifts,
  updateDeviceAssignment,
  userRole,
  isMobile,
  userId,
}) => {
  const [rows, setRows] = useState([]);
  const [editedRow, setEditedRow] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('shiftStatus');
  const [sortDirection, setSortDirection] = useState('asc');
  const isGuard = userRole === 'guard';
  const showModeColumn = userId !== 'auth0|669fed96e6d5fdc532aedf04';

  // Combine devices with shift data
  const devicesWithShiftData = useMemo(() => {
    return devices.map((device) => ({
      ...device,
      ...deviceShifts[device.deviceId],
    }));
  }, [devices, deviceShifts]);

  // Sort rows based on sort criteria
  const sortedRows = useMemo(() => {
    return [...devicesWithShiftData].sort((a, b) => {
      const multiplier = sortDirection === 'asc' ? 1 : -1;

      // Default sorting (active shifts first, then by time in shift)
      if (sortBy === 'shiftStatus') {
        if (a.shiftStatus && !b.shiftStatus) return -1 * multiplier;
        if (!a.shiftStatus && b.shiftStatus) return 1 * multiplier;
        if (a.shiftStatus && b.shiftStatus) {
          return ((a.timeInShift || 0) - (b.timeInShift || 0)) * multiplier;
        }
        const aTime = a.lastShiftTime ? new Date(a.lastShiftTime) : new Date(0);
        const bTime = b.lastShiftTime ? new Date(b.lastShiftTime) : new Date(0);
        return (bTime - aTime) * multiplier;
      }

      // Sort by device name
      if (sortBy === 'deviceName') {
        const aName = (a.assignedTo || a.deviceName || '').toLowerCase();
        const bName = (b.assignedTo || b.deviceName || '').toLowerCase();
        return aName.localeCompare(bName) * multiplier;
      }

      return 0;
    });
  }, [devicesWithShiftData, sortBy, sortDirection]);

  // Update rows when data changes
  useEffect(() => {
    setRows(sortedRows);
  }, [sortedRows]);

  // Filter devices based on search query
  const filteredRows = useMemo(() => {
    if (!searchQuery) return rows;

    return rows.filter((device) => {
      const searchLower = searchQuery.toLowerCase();
      const deviceIdMatch = device.deviceId.toLowerCase().includes(searchLower);
      const deviceNameMatch = (device.assignedTo || device.deviceName || '').toLowerCase().includes(searchLower);
      return deviceIdMatch || deviceNameMatch;
    });
  }, [rows, searchQuery]);

  // Handle row click
  const handleRowClick = (device) => {
    if (device.deviceId) {
      handleDeviceClick(device.deviceId, [device.latitude, device.longitude]);
    } else {
      console.error('Device ID is missing in the row data');
    }
  };

  // Clear search
  const handleClearSearch = () => {
    setSearchQuery('');
  };

  // Handle column header click for sorting
  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  return (
    <div className="device-assignment-container">
      {/* Search Bar */}
      <div className="device-assignment-controls">
        <div className="device-assignment-search-container">
          <input
            type="text"
            placeholder="Search devices..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="device-assignment-search-input"
          />
          {searchQuery && (
            <button className="device-assignment-search-clear" onClick={handleClearSearch} aria-label="Clear search">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </div>
      </div>

      {/* Devices Table */}
      <div className="device-assignment-devices-table" style={{ height: isMobile ? 'calc(100% - 80px)' : '100%' }}>
        <table>
          <thead>
            <tr>
              <th>
                <TableSortLabel
                  active={sortBy === 'deviceName'}
                  direction={sortBy === 'deviceName' ? sortDirection : 'asc'}
                  onClick={() => handleRequestSort('deviceName')}
                >
                  Device Name
                </TableSortLabel>
              </th>
              <th>
                <TableSortLabel
                  active={sortBy === 'shiftStatus'}
                  direction={sortBy === 'shiftStatus' ? sortDirection : 'asc'}
                  onClick={() => handleRequestSort('shiftStatus')}
                >
                  Last Shift
                </TableSortLabel>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredRows.map((device) => (
              <tr
                key={device.deviceId}
                onClick={() => handleRowClick(device)}
                className={device.shiftStatus ? 'device-assignment-active-shift' : ''}
              >
                <td>{device.assignedTo || device.deviceName || 'Unassigned'}</td>
                <td>
                  <div className="device-assignment-shift-status-container">
                    {formatLastShiftTime(
                      device.lastShiftTime,
                      device.shiftStatus,
                      device.timeInShift,
                      device.isOffline,
                      device.isSmartRecordingOff,
                      device.isRecording
                    )}
                    {device.shiftStatus && !device.isRecording && (
                      <Tooltip title={`In shift`} placement="top">
                        <FontAwesomeIcon icon={faCircle} className="device-assignment-shift-dot" />
                      </Tooltip>
                    )}
                    {device.isRecording && (
                      <Tooltip title={`Recording now`} placement="top">
                        <FontAwesomeIcon icon={faCircle} className="device-assignment-recording-dot" />
                      </Tooltip>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Device count info */}
      <div className="device-assignment-count-info">
        <span className="device-assignment-count">
          Showing {filteredRows.length} of {rows.length} devices
        </span>
      </div>

      {/* Mobile guard action banner */}
      {isMobile && showModeColumn && <GuardActionBanner devices={devices} />}
    </div>
  );
};

export default DeviceAssignment;
