import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  Tooltip,
  Checkbox,
  Snackbar,
  Alert,
  TableSortLabel,
  CircularProgress,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faPlus } from '@fortawesome/free-solid-svg-icons';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import '../../../../styles/Settings/Section/PersonnelAccounts/PersonnelAccounts.css';
import {
  fetchPersonnelAccounts,
  deletePersonnelAccount,
  updatePersonnelAccount,
  createPersonnelAccount,
} from '../../../../api/beaverApi';
import { useDeviceFilter } from '../../../../contexts/DeviceFilterContext';
import SettingsDialog from '../SettingsDialog';

const PersonnelAccounts = ({ userId, isGuard, isOverwatch, userRole, parentOrgUserId }) => {
  const { devices: initialDevices, selectedOrg } = useDeviceFilter();

  // Constants
  // const accountsPerPage = 5; // Match the devicesPerPage from DeviceManagement

  // State for personnel accounts
  const [personnelAccounts, setPersonnelAccounts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('assignedTo');

  // State for add/edit dialogs
  const [showAddPersonnelDialog, setShowAddPersonnelDialog] = useState(false);
  const [editPersonnelDialogOpen, setEditPersonnelDialogOpen] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [editSelectedDevices, setEditSelectedDevices] = useState([]);
  const [editingAccount, setEditingAccount] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState(null);
  const [accessLevelChanged, setAccessLevelChanged] = useState(false);
  const [editAccessLevel, setEditAccessLevel] = useState('guard');
  const [accessLevel, setAccessLevel] = useState('guard');
  const [newPersonnelFirstName, setNewPersonnelFirstName] = useState('');
  const [newPersonnelLastName, setNewPersonnelLastName] = useState('');
  const [newPersonnelEmail, setNewPersonnelEmail] = useState('');
  const [newPersonnelPhone, setNewPersonnelPhone] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [editPersonnelFirstName, setEditPersonnelFirstName] = useState('');
  const [editPersonnelLastName, setEditPersonnelLastName] = useState('');
  const [editPersonnelPhone, setEditPersonnelPhone] = useState('');

  // State for snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const loadPersonnelAccounts = async () => {
      try {
        const accounts = await fetchPersonnelAccounts(getEffectiveUserId(true));
        setPersonnelAccounts(accounts || []);
      } catch (error) {
        console.error('Error loading personnel accounts:', error);
        setSnackbarMessage('Failed to load personnel accounts');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    loadPersonnelAccounts();
  }, []);

  // Add getEffectiveUserId helper function
  const getEffectiveUserId = (beaverCall = false) => {
    if (isOverwatch) {
      return selectedOrg;
    }
    if (userRole === 'ASP' && !beaverCall) {
      return parentOrgUserId;
    } else if (beaverCall) {
      return userId;
    }
    return userId;
  };

  const isAspOwnAccount = (account) => {
    return userRole === 'ASP' && account.orgUserId === userId;
  };

  // Add sorting and filtering logic
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Filter and sort accounts without pagination
  const filteredAccounts = personnelAccounts.filter((account) => {
    const searchLower = searchQuery.toLowerCase();
    const nameMatch = (account.assignedTo || '').toLowerCase().includes(searchLower);
    const emailMatch = (account.emailAddress || '').toLowerCase().includes(searchLower);
    return nameMatch || emailMatch;
  });

  const sortedAccounts = filteredAccounts.sort((a, b) => {
    if (orderBy === 'assignedTo') {
      return order === 'asc'
        ? (a.assignedTo || '').localeCompare(b.assignedTo || '')
        : (b.assignedTo || '').localeCompare(a.assignedTo || '');
    }
    return 0;
  });

  // Helper functions
  const formatDisplayName = (name) => {
    if (!name) return '';
    return name.split('_').join(' ');
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length <= 10) {
      value = value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      setNewPersonnelPhone(value);
    }
  };

  const handleEditPhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length <= 10) {
      value = value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      setEditPersonnelPhone(value);
    }
  };

  // Restore original functions:
  const handleAddPersonnelDialog = () => {
    setShowAddPersonnelDialog(true);
  };

  const handleEditPersonnel = (account) => {
    setEditingAccount(account);
    const [firstName, lastName] = (account.assignedTo || '').split('_');
    setEditPersonnelFirstName(firstName || '');
    setEditPersonnelLastName(lastName || '');
    setEditSelectedDevices(account.deviceIds || []);
    setEditAccessLevel(account.accessLevel || 'guard');
    setEditPersonnelPhone(account.phoneNumber || '');
    setAccessLevelChanged(false);
    setEditPersonnelDialogOpen(true);
  };

  // Add these handler functions
  const handleAddPersonnel = async (e) => {
    e.preventDefault();

    try {
      const fullName = newPersonnelLastName
        ? `${newPersonnelFirstName}_${newPersonnelLastName}`
        : newPersonnelFirstName;
      // console.log('fullName', fullName);
      // Use getEffectiveUserId() just like in Configuration.js
      const response = await createPersonnelAccount(
        getEffectiveUserId(), // Use the helper function
        newPersonnelEmail,
        selectedDevices,
        fullName,
        accessLevel,
        newPersonnelPhone
      );

      const newAccount = response.organization || response;
      setPersonnelAccounts((prevAccounts) => [...prevAccounts, newAccount]);

      // Reset form
      setShowAddPersonnelDialog(false);
      setNewPersonnelFirstName('');
      setNewPersonnelLastName('');
      setNewPersonnelEmail('');
      setConfirmEmail('');
      setSelectedDevices([]);
      setAccessLevel('guard');
      setNewPersonnelPhone('');

      showMessage('Personnel account created successfully');
    } catch (error) {
      console.error('Error creating personnel account:', error);
      showMessage(`Failed to create personnel account: ${error.message}`, 'error');
    }
  };

  const handleDeletePersonnel = (account) => {
    setAccountToDelete(account);
    setDeleteConfirmOpen(true);
  };

  // Add showMessage function
  const showMessage = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Add handleUpdatePersonnel function
  const handleUpdatePersonnel = async (e) => {
    e.preventDefault();

    try {
      // Combine names for DB storage
      const fullName = editPersonnelLastName
        ? `${editPersonnelFirstName}_${editPersonnelLastName}`
        : editPersonnelFirstName;

      // Use getEffectiveUserId() just like in Configuration.js
      await updatePersonnelAccount(
        editingAccount.orgUserId, // This is the account being edited
        editSelectedDevices,
        fullName,
        editAccessLevel,
        accessLevelChanged,
        editPersonnelPhone
      );

      // Update local state after successful API call
      setPersonnelAccounts((prevAccounts) =>
        prevAccounts.map((account) =>
          account.orgUserId === editingAccount.orgUserId
            ? {
                ...account,
                assignedTo: fullName,
                deviceIds: editSelectedDevices,
                accessLevel: editAccessLevel,
                phoneNumber: editPersonnelPhone,
              }
            : account
        )
      );

      setEditPersonnelDialogOpen(false);
      setEditingAccount(null);
      setEditPersonnelFirstName('');
      setEditPersonnelLastName('');
      setEditSelectedDevices([]);
      setEditAccessLevel('guard');
      setAccessLevelChanged(false);
      setEditPersonnelPhone('');

      showMessage('Personnel account updated successfully');
    } catch (error) {
      console.error('Error updating personnel account:', error);
      showMessage('Failed to update personnel account', 'error');
    }
  };

  // Add Personnel Dialog
  const addPersonnelDialog = (
    <SettingsDialog
      open={showAddPersonnelDialog}
      onClose={() => setShowAddPersonnelDialog(false)}
      title="Add Personnel Account"
      actions={
        <>
          <Button onClick={() => setShowAddPersonnelDialog(false)} color="inherit">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              !newPersonnelFirstName ||
              !newPersonnelLastName ||
              !newPersonnelEmail ||
              !newPersonnelPhone ||
              newPersonnelPhone.length < 14 ||
              newPersonnelEmail !== confirmEmail ||
              selectedDevices.length === 0
            }
            onClick={handleAddPersonnel}
          >
            Add Account
          </Button>
        </>
      }
    >
      <form onSubmit={handleAddPersonnel} className="personnel-form">
        <TextField
          required
          label="First Name"
          value={newPersonnelFirstName}
          onChange={(e) => setNewPersonnelFirstName(e.target.value)}
          error={newPersonnelFirstName.includes('_')}
          helperText={newPersonnelFirstName.includes('_') ? 'Name cannot contain underscore' : ''}
        />
        <TextField
          required
          label="Last Name"
          value={newPersonnelLastName}
          onChange={(e) => setNewPersonnelLastName(e.target.value)}
          error={newPersonnelLastName.includes('_')}
          helperText={newPersonnelLastName.includes('_') ? 'Name cannot contain underscore' : ''}
        />
        <TextField
          required
          label="Email"
          type="email"
          value={newPersonnelEmail}
          onChange={(e) => setNewPersonnelEmail(e.target.value)}
        />
        <TextField
          required
          label="Confirm Email"
          type="email"
          value={confirmEmail}
          onChange={(e) => setConfirmEmail(e.target.value)}
          error={confirmEmail && newPersonnelEmail !== confirmEmail}
          helperText={confirmEmail && newPersonnelEmail !== confirmEmail ? 'Email addresses do not match' : ''}
        />
        <TextField
          label="Phone Number"
          value={newPersonnelPhone}
          onChange={handlePhoneChange}
          required
          error={newPersonnelPhone.length > 0 && newPersonnelPhone.length < 14}
          helperText={
            newPersonnelPhone.length > 0 && newPersonnelPhone.length < 14 ? 'Please enter complete phone number' : ''
          }
        />
        <FormControl fullWidth required>
          <InputLabel>Access Level</InputLabel>
          <Select
            value={accessLevel}
            onChange={(e) => {
              setAccessLevel(e.target.value);
              if (e.target.value === 'ASP') {
                // Select all devices when ASP is chosen
                setSelectedDevices(initialDevices.map((d) => d.deviceId));
              }
            }}
            label="Access Level"
          >
            <MenuItem value="guard">Limited Access</MenuItem>
            <MenuItem value="ASP">Full Access</MenuItem>
          </Select>
        </FormControl>

        <div className="device-selection">
          <Typography variant="subtitle1" gutterBottom>
            Select Devices
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 200 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={selectedDevices.length > 0 && selectedDevices.length < initialDevices.length}
                      checked={initialDevices.length > 0 && selectedDevices.length === initialDevices.length}
                      onChange={(e) => {
                        if (accessLevel !== 'ASP') {
                          if (e.target.checked) {
                            setSelectedDevices(initialDevices.map((d) => d.deviceId));
                          } else {
                            setSelectedDevices([]);
                          }
                        }
                      }}
                      disabled={accessLevel === 'ASP'}
                    />
                  </TableCell>
                  <TableCell>Device ID</TableCell>
                  <TableCell>Device Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {initialDevices.map((device) => (
                  <TableRow key={device.deviceId}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedDevices.includes(device.deviceId)}
                        onChange={(e) => {
                          if (accessLevel !== 'ASP') {
                            if (e.target.checked) {
                              setSelectedDevices([...selectedDevices, device.deviceId]);
                            } else {
                              setSelectedDevices(selectedDevices.filter((id) => id !== device.deviceId));
                            }
                          }
                        }}
                        disabled={accessLevel === 'ASP'}
                      />
                    </TableCell>
                    <TableCell>{device.deviceId}</TableCell>
                    <TableCell>{device.assignedTo || 'Unassigned'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </form>
    </SettingsDialog>
  );

  // Edit Personnel Dialog
  const editPersonnelDialog = (
    <SettingsDialog
      open={editPersonnelDialogOpen}
      onClose={() => setEditPersonnelDialogOpen(false)}
      title="Edit Personnel Account"
      actions={
        <>
          <Button onClick={() => setEditPersonnelDialogOpen(false)} color="inherit">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              editSelectedDevices.length === 0 ||
              !editPersonnelFirstName ||
              !editPersonnelLastName ||
              !editPersonnelPhone ||
              editPersonnelPhone.length < 14
            }
            onClick={handleUpdatePersonnel}
          >
            Save Changes
          </Button>
        </>
      }
    >
      <form onSubmit={handleUpdatePersonnel} className="personnel-form">
        <TextField
          required
          label="First Name"
          value={editPersonnelFirstName}
          onChange={(e) => setEditPersonnelFirstName(e.target.value)}
          error={editPersonnelFirstName.includes('_')}
          helperText={editPersonnelFirstName.includes('_') ? 'Name cannot contain underscore' : ''}
        />
        <TextField
          required
          label="Last Name"
          value={editPersonnelLastName}
          onChange={(e) => setEditPersonnelLastName(e.target.value)}
          error={editPersonnelLastName.includes('_')}
          helperText={editPersonnelLastName.includes('_') ? 'Name cannot contain underscore' : ''}
        />
        <TextField label="Email" type="email" value={editingAccount?.emailAddress || ''} disabled fullWidth />
        <TextField
          label="Phone Number"
          value={editPersonnelPhone}
          onChange={handleEditPhoneChange}
          fullWidth
          required
          error={editPersonnelPhone.length > 0 && editPersonnelPhone.length < 14}
          helperText={
            editPersonnelPhone.length > 0 && editPersonnelPhone.length < 14 ? 'Please enter complete phone number' : ''
          }
        />
        <FormControl fullWidth required>
          <InputLabel>Access Level</InputLabel>
          <Select
            value={editAccessLevel}
            onChange={(e) => {
              setEditAccessLevel(e.target.value);
              setAccessLevelChanged(true);
              if (e.target.value === 'ASP') {
                // Select all devices when ASP is chosen
                setEditSelectedDevices(initialDevices.map((d) => d.deviceId));
              }
            }}
            label="Access Level"
            disabled={isAspOwnAccount(editingAccount)}
          >
            <MenuItem value="guard">Limited Access</MenuItem>
            <MenuItem value="ASP">Full Access</MenuItem>
          </Select>
        </FormControl>

        <div className="device-selection">
          <Typography variant="subtitle1" gutterBottom>
            Select Devices
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 200 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        editSelectedDevices.length > 0 && editSelectedDevices.length < initialDevices.length
                      }
                      checked={initialDevices.length > 0 && editSelectedDevices.length === initialDevices.length}
                      onChange={(e) => {
                        if (editAccessLevel !== 'ASP') {
                          if (e.target.checked) {
                            setEditSelectedDevices(initialDevices.map((d) => d.deviceId));
                          } else {
                            setEditSelectedDevices([]);
                          }
                        }
                      }}
                      disabled={editAccessLevel === 'ASP'}
                    />
                  </TableCell>
                  <TableCell>Device ID</TableCell>
                  <TableCell>Device Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {initialDevices.map((device) => (
                  <TableRow key={device.deviceId}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={editSelectedDevices.includes(device.deviceId)}
                        onChange={(e) => {
                          if (editAccessLevel !== 'ASP') {
                            if (e.target.checked) {
                              setEditSelectedDevices([...editSelectedDevices, device.deviceId]);
                            } else {
                              setEditSelectedDevices(editSelectedDevices.filter((id) => id !== device.deviceId));
                            }
                          }
                        }}
                        disabled={editAccessLevel === 'ASP'}
                      />
                    </TableCell>
                    <TableCell>{device.deviceId}</TableCell>
                    <TableCell>{device.assignedTo || 'Unassigned'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </form>
    </SettingsDialog>
  );

  // Handle confirm delete
  const handleConfirmDelete = async () => {
    try {
      // Use getEffectiveUserId() just like in Configuration.js
      await deletePersonnelAccount(accountToDelete.orgUserId);

      setPersonnelAccounts((prevAccounts) =>
        prevAccounts.filter((account) => account.orgUserId !== accountToDelete.orgUserId)
      );

      setDeleteConfirmOpen(false);
      setAccountToDelete(null);
      showMessage('Account deleted successfully');
    } catch (error) {
      console.error('Error deleting account:', error);
      showMessage('Failed to delete account', 'error');
    }
  };

  // Add the delete confirmation dialog
  const deleteConfirmationDialog = (
    <SettingsDialog
      open={deleteConfirmOpen}
      onClose={() => setDeleteConfirmOpen(false)}
      title="Confirm Delete"
      actions={
        <>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </>
      }
    >
      <Typography>
        Are you sure you want to delete the account for{' '}
        {accountToDelete?.assignedTo?.includes('_')
          ? accountToDelete.assignedTo.replace('_', ' ')
          : accountToDelete?.assignedTo}
        ? This action cannot be undone.
      </Typography>
    </SettingsDialog>
  );

  // Replace pagination controls with a simple count display
  const accountCountInfo = (
    <div className="account-count-info">
      <span className="account-count">
        Showing {sortedAccounts.length} of {personnelAccounts.length} accounts
      </span>
    </div>
  );

  return (
    <>
      <div className="personnel-accounts-container">
        <div className="personnel-accounts-header">
          <h2>Personnel Accounts</h2>
          {!isGuard && (
            <button className="add-account-btn" onClick={handleAddPersonnelDialog}>
              Add Account
            </button>
          )}
        </div>

        <div className="personnel-info">
          <FontAwesomeIcon icon={faUsers} className="personnel-icon" />
          <span>Create and manage personnel accounts for your organization.</span>
        </div>

        <div className="personnel-management-controls">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search accounts..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="personnel-accounts-search-input"
            />
          </div>
        </div>

        <div className="personnel-table">
          <table>
            <thead>
              <tr>
                <th>
                  <TableSortLabel
                    active={orderBy === 'assignedTo'}
                    direction={orderBy === 'assignedTo' ? order : 'asc'}
                    onClick={() => handleRequestSort('assignedTo')}
                  >
                    Name
                  </TableSortLabel>
                </th>
                <th>Email</th>
                <th>Device ID</th>
                <th>Access</th>
                <th align="right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedAccounts.map((account) => (
                <tr key={account.emailAddress}>
                  <td>{formatDisplayName(account.assignedTo)}</td>
                  <td>{account.emailAddress}</td>
                  <td>
                    {(() => {
                      const deviceIds = account.deviceIds || [];
                      if (deviceIds.length === 0) return 'No devices';

                      const deviceNames = deviceIds.map((deviceId) => {
                        const device = initialDevices.find((d) => d.deviceId === deviceId);
                        return device?.deviceName || device?.assignedTo || deviceId;
                      });

                      const tooltipContent = (
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            maxWidth: '300px',
                          }}
                        >
                          {deviceNames.map((name, index) => (
                            <div key={index} className="personnel-device-chip">
                              {name}
                            </div>
                          ))}
                        </div>
                      );

                      const content = (
                        <div
                          style={{
                            display: 'flex',
                            gap: '4px',
                            alignItems: 'center',
                            maxWidth: '300px',
                            overflow: 'hidden',
                          }}
                        >
                          {deviceNames.slice(0, 2).map((name, index) => (
                            <div
                              key={index}
                              style={{
                                backgroundColor: '#f0f0f0',
                                padding: '2px 8px',
                                borderRadius: '12px',
                                fontSize: '0.875rem',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {name}
                            </div>
                          ))}
                          {deviceNames.length > 2 && (
                            <div className="personnel-device-chip">+{deviceNames.length - 2}</div>
                          )}
                        </div>
                      );

                      return deviceNames.length > 2 ? (
                        <Tooltip
                          title={tooltipContent}
                          arrow={false}
                          classes={{
                            tooltip: 'personnel-device-tooltip',
                          }}
                        >
                          {content}
                        </Tooltip>
                      ) : (
                        content
                      );
                    })()}
                  </td>
                  <td>{account.accessLevel === 'ASP' ? 'Full' : 'Limited'}</td>
                  <td align="right">
                    <div className="action-buttons">
                      <Tooltip title={isAspOwnAccount(account) ? 'Cannot edit own account' : 'Edit account'}>
                        <span className="edit-btn">
                          <IconButton
                            onClick={() => handleEditPersonnel(account)}
                            // disabled={isAspOwnAccount(account)}
                            size="small"
                            sx={{ marginRight: 1 }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title={isAspOwnAccount(account) ? 'Cannot delete own account' : 'Delete account'}>
                        <span>
                          <IconButton
                            onClick={() => handleDeletePersonnel(account)}
                            disabled={isAspOwnAccount(account)}
                            size="small"
                            sx={{
                              color: '#cb2d3e',
                              '&:hover': {
                                backgroundColor: 'rgba(203, 45, 62, 0.04)',
                              },
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {accountCountInfo}

        {addPersonnelDialog}
        {editPersonnelDialog}
        {deleteConfirmationDialog}

        {/* Add Snackbar component */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default PersonnelAccounts;
