import { useState, useEffect } from 'react';
import VideoView from './VideoView';
import ImageView from './ImageView';
import MapView from './MapView';
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventDetail/EventDetail.css';

const EventDetail = ({
  showVideo,
  event,
  events,
  onShowVideo,
  alertData,
  locations,
  videoPlayerRef,
  onPlayerUpdate,
  geofences,
  retentionPeriodDays,
  markers,
  assignedTo,
}) => {
  const [focusLocation, setFocusLocation] = useState(null);

  useEffect(() => {
    if (event && event.latitude && event.longitude) {
      setFocusLocation({ latitude: event.latitude, longitude: event.longitude });
    } else {
      setFocusLocation(null);
    }
  }, [event]);

  const renderContent = () => {
    switch (event.type) {
      case 'escalation':
      case 'recordingVideo':
        return (
          <VideoView
            event={event}
            alertData={alertData}
            videoPlayerRef={videoPlayerRef}
            onPlayerUpdate={onPlayerUpdate}
            retentionPeriodDays={retentionPeriodDays}
            markers={markers}
            assignedTo={assignedTo}
          />
        );
      case 'photo':
        return (
          <ImageView event={event} events={events} retentionPeriodDays={retentionPeriodDays} assignedTo={assignedTo} />
        );
      default:
        return (
          <MapView
            locations={locations}
            size="large"
            event={event}
            events={events}
            geofences={geofences}
            focusLocation={focusLocation}
          />
        );
    }
  };

  return <div className={`event-detail-container ${event.type}`}>{renderContent()}</div>;
};

export default EventDetail;
