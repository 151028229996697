import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Tooltip,
  CircularProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Switch,
  Typography,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ConstructionIcon from '@mui/icons-material/Construction';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import { cosmosClient } from '../../../../utils/eventstorage';
import { useDeviceFilter } from '../../../../contexts/DeviceFilterContext';
import '../../../../styles/Settings/Section/DeviceManagement/DeviceManagement.css';
import '../../../../styles/Settings/Section/SettingsDialog.css';
import { StyledTooltip } from '../../../../styles/StyledComponents';
import { DEVICE_FEEDBACK_INFO, RECORDING_ACTIVATION_INFO } from '../../../../utils/constants';
import SettingsDialog from '../SettingsDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass } from '@fortawesome/free-solid-svg-icons';

const DeviceManagement = ({ userId, isGuard, isOverwatch, userRole, parentOrgUserId }) => {
  const { devices: initialDevices, selectedOrg, updateDevice } = useDeviceFilter();

  // State management
  const [devices, setDevices] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [configureDeviceDialogOpen, setConfigureDeviceDialogOpen] = useState(false);
  const [editingDevice, setEditingDevice] = useState(null);
  const [currentDeviceValues, setCurrentDeviceValues] = useState({});
  const [pendingDeviceChanges, setPendingDeviceChanges] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [restartingDevices, setRestartingDevices] = useState({});
  const [bulkConfig, setBulkConfig] = useState({
    recordingMode: 'manual',
    manualTrigger: 'MULTI_TOUCH',
    smartTrigger: 'all',
    deviceMode: 'Balanced',
    deviceName: '',
    swarmEnabled: false,
  });
  const [configureAllDialogOpen, setConfigureAllDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Device mode settings from Configuration component
  const getModeSettings = (mode) => {
    const modeSettings = {
      Balanced: {
        deviceMode: 'Balanced',
        screenDim: true,
        recBeep: false,
        TTSEnabled: true,
        hapticEnabled: true,
      },
      Stealth: {
        deviceMode: 'Stealth',
        screenDim: true,
        recBeep: false,
        TTSEnabled: false,
        hapticEnabled: true,
      },
      Deterrent: {
        deviceMode: 'Deterrent',
        screenDim: false,
        recBeep: true,
        TTSEnabled: true,
        hapticEnabled: true,
      },
    };
    return modeSettings[mode] || modeSettings.Balanced;
  };

  const handleDeviceModeChange = (deviceId, newMode) => {
    const modeSettings = getModeSettings(newMode);

    setPendingDeviceChanges((prev) => ({
      ...prev,
      [deviceId]: { ...prev[deviceId], ...modeSettings },
    }));

    setCurrentDeviceValues((prev) => ({
      ...prev,
      [deviceId]: { ...prev[deviceId], ...modeSettings },
    }));

    setUnsavedChanges(true);
  };

  const getRestartButtonTooltip = (deviceId) => {
    if (restartingDevices[deviceId] === 'loading') {
      return 'Restarting...';
    } else if (restartingDevices[deviceId] === 'completed') {
      return 'Restart initiated';
    } else if (restartingDevices[deviceId]) {
      const timeLeft = Math.ceil((900000 - (Date.now() - restartingDevices[deviceId])) / 60000);
      return `Wait ${timeLeft} minute${timeLeft !== 1 ? 's' : ''} before restarting again`;
    }
    return 'Restart device';
  };

  const handleConfigureDevice = (device) => {
    // Set the current device values to the bulk config
    setBulkConfig({
      recordingMode: device.recordingPolicy === 'Shift' ? 'automatic' : 'manual',
      manualTrigger: device.buttonType || 'MULTI_TOUCH',
      smartTrigger: device.escalationMode === 'on' ? 'all' : device.escalationMode === 'trigger' ? 'phrases' : 'never',
      deviceMode: device.deviceMode || 'Balanced',
      deviceName: device.assignedTo || device.deviceName || device.deviceId,
    });
    setEditingDevice(device);
    setConfigureDeviceDialogOpen(true);
  };

  const handleRestartDevice = async (deviceId) => {
    setRestartingDevices((prev) => ({ ...prev, [deviceId]: 'loading' }));

    // Show check mark after a brief loading animation (300ms)
    setTimeout(() => {
      setRestartingDevices((prev) => ({ ...prev, [deviceId]: Date.now() }));
    }, 300);

    try {
      const response = await fetch('https://utils.plix.ai/v1/restartdevice', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ deviceId: deviceId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(`Device ${deviceId} restart triggered:`, result);

      // Add success message
      setSnackbarMessage('Device restart initiated');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error(`Failed to restart device ${deviceId}:`, error);
      // Add error message
      setSnackbarMessage('Failed to restart device');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }

    // Reset the button after 15 minutes
    setTimeout(() => {
      setRestartingDevices((prev) => {
        const updated = { ...prev };
        delete updated[deviceId];
        return updated;
      });
    }, 900000); // 15 minutes
  };

  const handleConfigureAllDevices = async () => {
    try {
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');

      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];

      // Set initial bulk config based on org settings
      setBulkConfig({
        recordingMode: org.recordingPolicy === 'Shift' ? 'automatic' : 'manual',
        manualTrigger: org.buttonType || 'MULTI_TOUCH',
        smartTrigger: org.escalationMode === 'on' ? 'all' : org.escalationMode === 'trigger' ? 'phrases' : 'never',
        deviceMode: org.deviceMode || 'Balanced',
        deviceName: '', // This won't be used in bulk
        swarmEnabled: org.swarmEnabled || false,
      });

      setConfigureAllDialogOpen(true);
    } catch (error) {
      console.error('Error fetching organization defaults:', error);
      setSnackbarMessage('Failed to load organization defaults');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleBulkConfigSave = async () => {
    try {
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');
      const devicesContainer = cosmosClient.database('Devices').container('Devices');

      // Update organization settings
      const {
        resources: [org],
      } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (!org) {
        throw new Error('Organization not found');
      }

      // Get mode settings for the selected mode
      const modeSettings = getModeSettings(bulkConfig.deviceMode);

      // Update org-level settings
      const updatedOrg = {
        ...org,
        deviceMode: bulkConfig.deviceMode,
        screenDim: modeSettings.screenDim,
        recBeep: modeSettings.recBeep,
        TTSEnabled: modeSettings.TTSEnabled,
        hapticEnabled: modeSettings.hapticEnabled,
        recordingPolicy: bulkConfig.recordingMode === 'automatic' ? 'Shift' : 'User',
        buttonType: bulkConfig.manualTrigger,
        escalationMode:
          bulkConfig.smartTrigger === 'all' ? 'on' : bulkConfig.smartTrigger === 'phrases' ? 'trigger' : 'off',
        swarmEnabled: bulkConfig.swarmEnabled,
      };

      console.log('Updating organization with:', updatedOrg);
      await orgContainer.item(org.id, org.orgName).replace(updatedOrg);

      // Update all devices
      const { resources: orgDevices } = await devicesContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.userId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      const updatePromises = orgDevices.map(async (device) => {
        const updatedDevice = {
          ...device,
          deviceMode: bulkConfig.deviceMode,
          screenDim: modeSettings.screenDim,
          recBeep: modeSettings.recBeep,
          TTSEnabled: modeSettings.TTSEnabled,
          hapticEnabled: modeSettings.hapticEnabled,
          recordingPolicy: bulkConfig.recordingMode === 'automatic' ? 'Shift' : 'User',
          buttonType: bulkConfig.manualTrigger,
          escalationMode:
            bulkConfig.smartTrigger === 'all' ? 'on' : bulkConfig.smartTrigger === 'phrases' ? 'trigger' : 'off',
          swarmEnabled: bulkConfig.swarmEnabled,
        };

        await devicesContainer.item(device.id, device.deviceId).replace(updatedDevice);

        // Trigger device config update
        await fetch('https://utils.plix.ai/v1/updateconfig', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            deviceId: device.deviceId,
            updateType: 'fetch and update',
          }),
        });
      });

      await Promise.all(updatePromises);

      // Update local state with new values
      const updatedDevices = orgDevices.map((device) => ({
        ...device,
        deviceMode: bulkConfig.deviceMode,
        screenDim: modeSettings.screenDim,
        recBeep: modeSettings.recBeep,
        TTSEnabled: modeSettings.TTSEnabled,
        hapticEnabled: modeSettings.hapticEnabled,
        recordingPolicy: bulkConfig.recordingMode === 'automatic' ? 'Shift' : 'User',
        buttonType: bulkConfig.manualTrigger,
        escalationMode:
          bulkConfig.smartTrigger === 'all' ? 'on' : bulkConfig.smartTrigger === 'phrases' ? 'trigger' : 'off',
        swarmEnabled: bulkConfig.swarmEnabled,
      }));

      setDevices(updatedDevices);

      // Update current device values for all devices
      const newCurrentValues = {};
      updatedDevices.forEach((device) => {
        newCurrentValues[device.deviceId] = {
          deviceMode: bulkConfig.deviceMode,
          buttonType: bulkConfig.manualTrigger,
          recordingPolicy: bulkConfig.recordingMode === 'automatic' ? 'Shift' : 'User',
          escalationMode:
            bulkConfig.smartTrigger === 'all' ? 'on' : bulkConfig.smartTrigger === 'phrases' ? 'trigger' : 'off',
          swarmEnabled: bulkConfig.swarmEnabled,
        };
      });
      setCurrentDeviceValues(newCurrentValues);

      setConfigureAllDialogOpen(false);
      setSnackbarMessage('All devices updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to update all devices:', error);
      setSnackbarMessage('Failed to update all devices');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleConfigChange = (field, value) => {
    setBulkConfig((prev) => ({
      ...prev,
      [field]: value,
    }));
    setUnsavedChanges(true);
  };

  const handleSaveConfig = async () => {
    try {
      const effectiveUserId = getEffectiveUserId();
      const devicesContainer = cosmosClient.database('Devices').container('Devices');

      // Find the device to update
      const {
        resources: [device],
      } = await devicesContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.deviceId = @deviceId',
          parameters: [{ name: '@deviceId', value: editingDevice.deviceId }],
        })
        .fetchAll();

      if (!device) {
        throw new Error('Device not found');
      }

      // Get mode settings and combine with other config
      const modeSettings = getModeSettings(bulkConfig.deviceMode);

      // Create updated device object
      const updatedDevice = {
        ...device,
        deviceMode: bulkConfig.deviceMode,
        screenDim: modeSettings.screenDim,
        recBeep: modeSettings.recBeep,
        TTSEnabled: modeSettings.TTSEnabled,
        hapticEnabled: modeSettings.hapticEnabled,
        recordingPolicy: bulkConfig.recordingMode === 'automatic' ? 'Shift' : 'User',
        buttonType: bulkConfig.manualTrigger,
        escalationMode:
          bulkConfig.smartTrigger === 'all' ? 'on' : bulkConfig.smartTrigger === 'phrases' ? 'trigger' : 'off',
        assignedTo: bulkConfig.deviceName,
      };

      console.log('Updating device with:', updatedDevice);

      // Save the updated device
      await devicesContainer.item(device.id, device.deviceId).replace(updatedDevice);

      // Update local state
      setDevices(devices.map((d) => (d.deviceId === editingDevice.deviceId ? updatedDevice : d)));

      // Update current device values
      setCurrentDeviceValues((prev) => ({
        ...prev,
        [editingDevice.deviceId]: {
          deviceMode: bulkConfig.deviceMode,
          buttonType: bulkConfig.manualTrigger,
          recordingPolicy: bulkConfig.recordingMode === 'automatic' ? 'Shift' : 'User',
          escalationMode:
            bulkConfig.smartTrigger === 'all' ? 'on' : bulkConfig.smartTrigger === 'phrases' ? 'trigger' : 'off',
        },
      }));

      // Trigger device config update
      await fetch('https://utils.plix.ai/v1/updateconfig', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          deviceId: editingDevice.deviceId,
          updateType: 'fetch and update',
        }),
      });

      // After successful save, update the device in context
      updateDevice(editingDevice.deviceId, {
        assignedTo: bulkConfig.deviceName,
        deviceMode: bulkConfig.deviceMode,
        recordingPolicy: bulkConfig.recordingMode === 'automatic' ? 'Shift' : 'User',
        buttonType: bulkConfig.manualTrigger,
        escalationMode:
          bulkConfig.smartTrigger === 'all' ? 'on' : bulkConfig.smartTrigger === 'phrases' ? 'trigger' : 'off',
      });

      setConfigureDeviceDialogOpen(false);
      setSnackbarMessage('Device configuration updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to save device configuration:', error);
      setSnackbarMessage('Failed to update device configuration');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Filter logic (without pagination)
  const filteredDevices = devices.filter((device) => {
    const searchLower = searchQuery.toLowerCase();
    const deviceIdMatch = device.deviceId.toLowerCase().includes(searchLower);
    const deviceNameMatch = (device.assignedTo || device.deviceName || '').toLowerCase().includes(searchLower);
    return deviceIdMatch || deviceNameMatch;
  });

  // Add getEffectiveUserId helper function
  const getEffectiveUserId = (beaverCall = false) => {
    if (isOverwatch) {
      return selectedOrg;
    }
    if (userRole === 'ASP' && !beaverCall) {
      return parentOrgUserId;
    } else if (beaverCall) {
      return userId;
    }
    return userId;
  };

  // Add fetchInitialData effect
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const effectiveUserId = getEffectiveUserId();
        const devicesContainer = cosmosClient.database('Devices').container('Devices');
        const { resources: fetchedDevices } = isGuard
          ? await devicesContainer.items
              .query({
                query: 'SELECT * FROM c WHERE c.deviceId = @deviceId',
                parameters: [{ name: '@deviceId', value: initialDevices[0].deviceId }],
              })
              .fetchAll()
          : await devicesContainer.items
              .query({
                query: 'SELECT * FROM c WHERE c.userId = @userId',
                parameters: [{ name: '@userId', value: effectiveUserId }],
              })
              .fetchAll();

        const fetchedDevicesWithDefaults = fetchedDevices.map((device) => ({
          ...device,
          escalationMode: device.escalationMode || 'on',
          buttonType: device.buttonType || 'MULTI_TOUCH',
        }));
        setDevices(fetchedDevicesWithDefaults);

        // Set initial device values
        const initialValues = fetchedDevicesWithDefaults.reduce((acc, device) => {
          acc[device.deviceId] = {
            deviceMode: device.deviceMode || 'Balanced',
            buttonType: device.buttonType || 'MULTI_TOUCH',
            recordingPolicy: device.recordingPolicy || 'User',
            escalationMode: device.escalationMode || 'on',
          };
          return acc;
        }, {});
        setCurrentDeviceValues(initialValues);
      } catch (error) {
        console.error('Failed to fetch initial data:', error);
        // Add error handling here (e.g., show error message to user)
      }
    };

    fetchInitialData();
  }, [userId, selectedOrg, isGuard, initialDevices]);

  // Replace the configureDeviceDialog with SettingsDialog
  const configureDeviceDialog = (
    <SettingsDialog
      open={configureDeviceDialogOpen}
      onClose={() => setConfigureDeviceDialogOpen(false)}
      title={`Configure ${editingDevice?.deviceName || editingDevice?.deviceId}`}
      maxWidth="sm"
      fullWidth
      actions={
        <>
          <Button onClick={() => setConfigureDeviceDialogOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleSaveConfig}
            sx={{
              backgroundColor: 'var(--button-bg)',
              '&:hover': {
                backgroundColor: 'var(--button-hover-bg)',
              },
            }}
          >
            Save Changes
          </Button>
        </>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
        {/* Device Name section */}
        {editingDevice && (
          <Box sx={{ mb: 2 }}>
            <div className="device-dialog-heading">
              <Typography variant="h6">Device Name</Typography>
              <StyledTooltip title="Assign a friendly name to this device for easy identification">
                <InfoIcon sx={{ ml: 1, fontSize: 20, color: 'action.active', cursor: 'help' }} />
              </StyledTooltip>
            </div>
            <TextField
              fullWidth
              value={bulkConfig.deviceName}
              onChange={(e) => setBulkConfig({ ...bulkConfig, deviceName: e.target.value })}
            />
          </Box>
        )}

        {/* Device Feedback section */}
        <Box sx={{ mb: 2 }}>
          <div className="device-dialog-heading">
            <Typography variant="h6">Device Feedback</Typography>
            <StyledTooltip title={<div dangerouslySetInnerHTML={{ __html: DEVICE_FEEDBACK_INFO }} />}>
              <InfoIcon sx={{ ml: 1, fontSize: 20, color: 'action.active', cursor: 'help' }} />
            </StyledTooltip>
          </div>
          <FormControl fullWidth>
            <Select
              value={bulkConfig.deviceMode}
              onChange={(e) => setBulkConfig({ ...bulkConfig, deviceMode: e.target.value })}
              fullWidth
            >
              <MenuItem value="Balanced">Balanced</MenuItem>
              <MenuItem value="Deterrent">Deterrent</MenuItem>
              <MenuItem value="Stealth">Stealth</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Recording Activation section */}
        <Box sx={{ mb: 0 }}>
          <div className="device-dialog-heading">
            <Typography variant="h6">Recording Activation</Typography>
            <StyledTooltip title={<div dangerouslySetInnerHTML={{ __html: RECORDING_ACTIVATION_INFO }} />}>
              <InfoIcon sx={{ ml: 1, fontSize: 20, color: 'action.active', cursor: 'help' }} />
            </StyledTooltip>
          </div>
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              value={bulkConfig.recordingMode}
              onChange={(e) =>
                setBulkConfig({
                  ...bulkConfig,
                  recordingMode: e.target.value,
                })
              }
            >
              <FormControlLabel value="automatic" control={<Radio />} label="Shift Based" />
              <FormControlLabel value="manual" control={<Radio />} label="Flexible Control" />
            </RadioGroup>
          </FormControl>

          <Box sx={{ ml: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {/* Manual Trigger subsection */}
            {/* <Box sx={{ mt: 1 }}>
              <div className="device-dialog-subheading">
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  Manual Trigger
                </Typography>
                <StyledTooltip title="Configure how to manually turn on video recording.">
                  <InfoIcon sx={{ ml: 1, fontSize: 18, color: 'action.active', cursor: 'help' }} />
                </StyledTooltip>
              </div>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <Select
                  value={bulkConfig.manualTrigger}
                  onChange={(e) => setBulkConfig({ ...bulkConfig, manualTrigger: e.target.value })}
                  displayEmpty
                >
                  <MenuItem value="SINGLE_TAP">Single Tap</MenuItem>
                  <MenuItem value="DOUBLE_TAP">Double Tap</MenuItem>
                  <MenuItem value="MULTI_TOUCH">Two-Finger Hold</MenuItem>
                </Select>
              </FormControl>
            </Box> */}

            {/* Smart Trigger subsection */}
            <Box>
              <div className="device-dialog-subheading">
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  Smart Trigger
                </Typography>
                <StyledTooltip title="Configure automatic recording triggers based on keywords and escalations.">
                  <InfoIcon sx={{ ml: 1, fontSize: 18, color: 'action.active', cursor: 'help' }} />
                </StyledTooltip>
              </div>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <Select
                  value={bulkConfig.smartTrigger}
                  onChange={(e) => setBulkConfig({ ...bulkConfig, smartTrigger: e.target.value })}
                >
                  <MenuItem value="all">All Triggers</MenuItem>
                  <MenuItem value="phrases">Trigger Phrases Only</MenuItem>
                  <MenuItem value="never">Never</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Box>
    </SettingsDialog>
  );

  // Replace the configureAllDevicesDialog with SettingsDialog
  const configureAllDevicesDialog = (
    <SettingsDialog
      open={configureAllDialogOpen}
      onClose={() => setConfigureAllDialogOpen(false)}
      title="Configure All Devices"
      maxWidth="sm"
      fullWidth
      actions={
        <>
          <Button onClick={() => setConfigureAllDialogOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleBulkConfigSave}
            sx={{
              backgroundColor: 'var(--button-bg)',
              '&:hover': {
                backgroundColor: 'var(--button-hover-bg)',
              },
            }}
          >
            Save Changes
          </Button>
        </>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
        {/* Device Feedback section */}
        <Box sx={{ mb: 2 }}>
          <div className="device-dialog-heading">
            <Typography variant="h6">Device Feedback</Typography>
            <StyledTooltip title={<div dangerouslySetInnerHTML={{ __html: DEVICE_FEEDBACK_INFO }} />}>
              <InfoIcon sx={{ ml: 1, fontSize: 20, color: 'action.active', cursor: 'help' }} />
            </StyledTooltip>
          </div>
          <FormControl fullWidth>
            <Select
              value={bulkConfig.deviceMode}
              onChange={(e) => setBulkConfig({ ...bulkConfig, deviceMode: e.target.value })}
              fullWidth
            >
              <MenuItem value="Balanced">Balanced</MenuItem>
              <MenuItem value="Deterrent">Deterrent</MenuItem>
              <MenuItem value="Stealth">Stealth</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Group Activation section */}
        <Box sx={{ mb: 2 }}>
          <div className="device-dialog-heading">
            <Typography variant="h6">Group Activation</Typography>
            <StyledTooltip title="Enable group recording activation across multiple devices">
              <InfoIcon sx={{ ml: 1, fontSize: 20, color: 'action.active', cursor: 'help' }} />
            </StyledTooltip>
          </div>
          <FormControlLabel
            className="group-activation-switch"
            control={
              <Switch
                checked={bulkConfig.swarmEnabled}
                onChange={(e) => setBulkConfig({ ...bulkConfig, swarmEnabled: e.target.checked })}
              />
            }
            label={bulkConfig.swarmEnabled ? 'Enabled' : 'Disabled'}
          />
        </Box>

        {/* Recording Activation section */}
        <Box sx={{ mb: 0 }}>
          <div className="device-dialog-heading">
            <Typography variant="h6">Recording Activation</Typography>
            <StyledTooltip title={<div dangerouslySetInnerHTML={{ __html: RECORDING_ACTIVATION_INFO }} />}>
              <InfoIcon sx={{ ml: 1, fontSize: 20, color: 'action.active', cursor: 'help' }} />
            </StyledTooltip>
          </div>
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              value={bulkConfig.recordingMode}
              onChange={(e) => setBulkConfig({ ...bulkConfig, recordingMode: e.target.value })}
            >
              <FormControlLabel value="automatic" control={<Radio />} label="Shift Based" />
              <FormControlLabel value="manual" control={<Radio />} label="Flexible Control" />
            </RadioGroup>
          </FormControl>
          <Box sx={{ ml: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {/* Smart Trigger subsection */}
            <Box>
              <div className="device-dialog-subheading">
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  Smart Trigger
                </Typography>
                <StyledTooltip title="Configure automatic recording triggers based on keywords and escalations.">
                  <InfoIcon sx={{ ml: 1, fontSize: 18, color: 'action.active', cursor: 'help' }} />
                </StyledTooltip>
              </div>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <Select
                  value={bulkConfig.smartTrigger}
                  onChange={(e) => setBulkConfig({ ...bulkConfig, smartTrigger: e.target.value })}
                >
                  <MenuItem value="all">All Triggers</MenuItem>
                  <MenuItem value="phrases">Trigger Phrases Only</MenuItem>
                  <MenuItem value="never">Never</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Box>
    </SettingsDialog>
  );

  return (
    <div className="device-management-container">
      <div className="device-management-header">
        <h2>Device Management</h2>
        {!isGuard && (
          <button className="configure-all-btn" onClick={handleConfigureAllDevices}>
            Configure All Devices
          </button>
        )}
      </div>
      <div className="personnel-info">
        <FontAwesomeIcon icon={faCompass} className="personnel-icon" />
        <span>Manage and configure device controls.</span>
      </div>
      <div className="device-management-controls">
        <div className="device-management-search-container">
          <input
            type="text"
            placeholder="Search devices..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="device-management-search-input"
          />
        </div>
      </div>

      <div className="devices-table">
        <table>
          <thead>
            <tr>
              <th>Device ID</th>
              <th>Device Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredDevices.map((device) => (
              <tr key={device.deviceId}>
                <td>{device.deviceId}</td>
                <td>{device.assignedTo || device.deviceName || device.deviceId}</td>
                <td>
                  <div className="action-buttons">
                    <Tooltip title="Configure device">
                      <span className="configure-btn">
                        <IconButton onClick={() => handleConfigureDevice(device)} size="small" sx={{ marginRight: 1 }}>
                          <ConstructionIcon fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={getRestartButtonTooltip(device.deviceId)}>
                      <span>
                        <IconButton
                          onClick={() => handleRestartDevice(device.deviceId)}
                          disabled={!!restartingDevices[device.deviceId]}
                          size="small"
                          sx={{
                            color: '#cb2d3e',
                            '&:hover': {
                              backgroundColor: 'rgba(203, 45, 62, 0.04)',
                            },
                          }}
                        >
                          {restartingDevices[device.deviceId] === 'loading' ? (
                            <CircularProgress size={20} sx={{ color: '#cb2d3e' }} />
                          ) : restartingDevices[device.deviceId] ? (
                            <CheckIcon fontSize="small" />
                          ) : (
                            <RestartAltIcon fontSize="small" />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="device-count-info">
        <span className="device-count">
          Showing {filteredDevices.length} of {devices.length} devices
        </span>
      </div>

      {configureDeviceDialog}
      {configureAllDevicesDialog}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DeviceManagement;
