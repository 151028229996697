import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert as MuiAlert,
  Tooltip,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import { cosmosClient } from '../../../../utils/eventstorage';
import '../../../../styles/Settings/Section/ActivityReports/ActivityReports.css';
import SettingsDialog from '../SettingsDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';

const ActivityReports = ({ userId, isGuard, isOverwatch, userRole, parentOrgUserId, selectedOrg }) => {
  // State management
  const [reportSubscribers, setReportSubscribers] = useState([]);
  const [showAddReportDialog, setShowAddReportDialog] = useState(false);
  const [editingReport, setEditingReport] = useState(null);
  const [newReport, setNewReport] = useState({
    emailAddress: '',
    frequency: 'weekly',
    timeOfDay: '16:00',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    reportType: 'site',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);

  // Helper function to get effective user ID
  const getEffectiveUserId = () => {
    if (isOverwatch) {
      return selectedOrg;
    }
    if (userRole === 'ASP') {
      return parentOrgUserId;
    }
    return userId;
  };

  // Fetch report subscribers on component mount
  useEffect(() => {
    fetchReportSubscribers();
  }, []);

  const fetchReportSubscribers = async () => {
    try {
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');

      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length > 0) {
        setReportSubscribers(orgs[0].reportSubscribers || []);
      }
    } catch (error) {
      console.error('Error fetching report subscribers:', error);
      showMessage('Failed to fetch report subscribers', 'error');
    }
  };

  const handleSaveReport = async (e) => {
    e.preventDefault();
    try {
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');

      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];
      const updatedSubscribers = editingReport
        ? reportSubscribers.map((sub) => (sub.emailAddress === editingReport.emailAddress ? newReport : sub))
        : [...reportSubscribers, newReport];

      await orgContainer.item(org.id, org.orgName).replace({
        ...org,
        reportSubscribers: updatedSubscribers,
      });

      setReportSubscribers(updatedSubscribers);
      setShowAddReportDialog(false);
      setEditingReport(null);
      setNewReport({
        emailAddress: '',
        frequency: 'weekly',
        timeOfDay: '16:00',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        reportType: 'site',
      });

      showMessage(editingReport ? 'Report updated successfully' : 'Report subscription added successfully', 'success');
    } catch (error) {
      console.error('Failed to save report:', error);
      showMessage('Failed to save report subscription', 'error');
    }
  };

  const handleDeleteReport = async () => {
    try {
      const effectiveUserId = getEffectiveUserId();
      const orgContainer = cosmosClient.database('Organizations').container('Organizations');

      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: effectiveUserId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];
      const updatedSubscribers = reportSubscribers.filter((sub) => sub.emailAddress !== reportToDelete.emailAddress);

      await orgContainer.item(org.id, org.orgName).replace({
        ...org,
        reportSubscribers: updatedSubscribers,
      });

      setReportSubscribers(updatedSubscribers);
      setDeleteConfirmOpen(false);
      setReportToDelete(null);
      showMessage('Report subscription deleted successfully', 'success');
    } catch (error) {
      console.error('Failed to delete report:', error);
      showMessage('Failed to delete report subscription', 'error');
    }
  };

  const showMessage = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  return (
    <div className="activity-reports-container">
      <div className="activity-reports-header">
        <h2>Activity Reports</h2>
        {!isGuard && (
          <button className="add-report-btn" onClick={() => setShowAddReportDialog(true)}>
            Add Report
          </button>
        )}
      </div>
      <div className="personnel-info">
        <FontAwesomeIcon icon={faMailBulk} className="personnel-icon" />
        <span>
          Receive recurring reports about personnel or sites visited.
          <br />
        </span>
      </div>

      {reportSubscribers.length > 0 ? (
        <div className="activity-reports-table">
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Frequency</th>
                <th>Time</th>
                <th>Report Type</th>
                <th align="right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {reportSubscribers.map((report, index) => (
                <tr key={index}>
                  <td>{report.emailAddress}</td>
                  <td style={{ textTransform: 'capitalize' }}>{report.frequency}</td>
                  <td>
                    {dayjs(`2000-01-01T${report.timeOfDay}`).format('h:mm A')} {report.timeZone}
                  </td>
                  <td style={{ textTransform: 'capitalize' }}>{report.reportType}</td>
                  <td align="right">
                    {!isGuard && (
                      <div className="action-buttons">
                        <Tooltip title="Edit report">
                          <span className="edit-btn">
                            <IconButton
                              size="small"
                              onClick={() => {
                                setEditingReport(report);
                                setNewReport(report);
                                setShowAddReportDialog(true);
                              }}
                              sx={{ marginRight: 1 }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Delete report">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setReportToDelete(report);
                              setDeleteConfirmOpen(true);
                            }}
                            sx={{
                              color: '#cb2d3e',
                              '&:hover': {
                                backgroundColor: 'rgba(203, 45, 62, 0.04)',
                              },
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <Typography variant="body1" sx={{ textAlign: 'center', py: 4 }}>
          No report subscriptions configured.
        </Typography>
      )}

      {/* Add report count info */}
      {reportSubscribers.length > 0 && (
        <div className="report-count-info">
          <span className="report-count">Showing {reportSubscribers.length} report subscriptions</span>
        </div>
      )}

      {/* Add/Edit Report Dialog */}
      <SettingsDialog
        open={showAddReportDialog}
        onClose={() => {
          setShowAddReportDialog(false);
          setEditingReport(null);
          setNewReport({
            emailAddress: '',
            frequency: 'weekly',
            timeOfDay: '16:00',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            reportType: 'site',
          });
        }}
        title={editingReport ? 'Edit Report Subscription' : 'Add Report Subscription'}
        actions={
          <>
            <Button onClick={() => setShowAddReportDialog(false)}>Cancel</Button>
            <Button
              onClick={handleSaveReport}
              variant="contained"
              sx={{
                backgroundColor: 'var(--button-bg)',
                '&:hover': {
                  backgroundColor: 'var(--button-hover-bg)',
                },
              }}
            >
              {editingReport ? 'Save Changes' : 'Add Subscription'}
            </Button>
          </>
        }
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <TextField
            required
            label="Email Address"
            type="email"
            value={newReport.emailAddress}
            onChange={(e) => setNewReport({ ...newReport, emailAddress: e.target.value })}
            disabled={editingReport}
          />

          <FormControl required>
            <InputLabel>Frequency</InputLabel>
            <Select
              value={newReport.frequency}
              onChange={(e) => setNewReport({ ...newReport, frequency: e.target.value })}
              label="Frequency"
            >
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Time of Day"
              value={newReport.timeOfDay ? dayjs(`2000-01-01T${newReport.timeOfDay}`) : null}
              onChange={(newValue) =>
                setNewReport({
                  ...newReport,
                  timeOfDay: newValue ? newValue.format('HH:mm') : '',
                })
              }
              sx={{ width: '100%' }}
              slotProps={{
                textField: {
                  required: true,
                  error: !newReport.timeOfDay,
                },
              }}
            />
          </LocalizationProvider>

          <FormControl required>
            <InputLabel>Time Zone</InputLabel>
            <Select
              value={newReport.timeZone}
              onChange={(e) => setNewReport({ ...newReport, timeZone: e.target.value })}
              label="Time Zone"
            >
              {Intl.supportedValuesOf('timeZone').map((zone) => (
                <MenuItem key={zone} value={zone}>
                  {zone}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl required>
            <InputLabel>Report Type</InputLabel>
            <Select
              value={newReport.reportType}
              onChange={(e) => setNewReport({ ...newReport, reportType: e.target.value })}
              label="Report Type"
            >
              <MenuItem value="site">Site Activity</MenuItem>
              <MenuItem value="personnel">Personnel Activity</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </SettingsDialog>

      {/* Delete Confirmation Dialog */}
      <SettingsDialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        title="Confirm Delete"
        actions={
          <>
            <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
            <Button onClick={handleDeleteReport} color="error" variant="contained">
              Delete
            </Button>
          </>
        }
      >
        <Typography>
          Are you sure you want to delete the report subscription for {reportToDelete?.emailAddress}?
        </Typography>
      </SettingsDialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ActivityReports;
