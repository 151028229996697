import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    fontSize: 14,
    border: '1px solid #dadde9',
    maxWidth: 320,
  },
}));
