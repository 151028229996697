import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/CustomizedTimeline.css';
import { formatDuration } from '../../../../../../../utils/timeUtils';
import { useState, useEffect } from 'react';
import BatteryIcon from './BatteryIcon';
import { fetchAlertFromApi } from '../../../../../../../api/beaverApi';
import { getCategoryDisplay, shouldDisplayCategory } from '../../../../../../../utils/categoryUtils';
import { trackEventClick } from '../../../../../../../utils/analytics';
import {
  Videocam,
  PhotoCamera,
  LocationOn,
  PowerSettingsNew,
  Warning,
  FiberManualRecord,
  Description,
} from '@mui/icons-material';

const formatShortDuration = (duration) => {
  const seconds = Math.floor(duration / 1000);
  if (seconds < 60) {
    return `${seconds}s`;
  }
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes}m`;
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}m`;
};

const truncateGeofenceId = (geofenceId, geofences, event) => {
  // Check if we have a matched geofence in the array
  if (geofences && Array.isArray(geofences)) {
    const geofence = geofences.find((g) => g.id === geofenceId);
    if (geofence && geofence.nickname) {
      return geofence.nickname;
    }
  }

  // Check if event has a nickname field directly
  if (event && event.nickname) {
    return event.nickname;
  }

  // For circular geofences (identified by whitespace in geofenceId)
  if (geofenceId && geofenceId.includes(' ')) {
    return geofenceId.split(' ')[0].trim();
  }

  // Check if event has boundary information when geofence not found
  if (event && event.boundary) {
    // For circular geofences, boundary would be the center location hash
    if (geofenceId && geofenceId.includes(' ')) {
      return event.boundary;
    }
    // For other geofence types, truncate boundary if needed
    if (event.boundary.length > 30) {
      return event.boundary.substring(0, 30) + '...';
    }
    return event.boundary;
  }

  // Default truncation behavior
  if (geofenceId && geofenceId.length <= 30) {
    return geofenceId;
  }

  if (geofenceId) {
    const parts = geofenceId.split(',');
    if (parts[0].match(/\d/)) {
      return `${parts[0]}`.trim();
    }
    return parts[0].trim();
  }

  return 'Unknown Location';
};

const getEventIcon = (event, index, events) => {
  const commonStyles = {
    width: '32px',
    height: '32px',
    color: 'white',
    filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.2))',
  };

  const smallIconStyles = {
    width: '12px',
    height: '12px',
    color: 'white',
    filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.2))',
  };

  switch (event.type) {
    case 'recordingVideo':
      return <Videocam style={commonStyles} />;
    case 'locationEnter':
      return <LocationOn style={commonStyles} />;
    case 'photo':
      return <PhotoCamera style={commonStyles} />;
    case 'start':
      return index === 0 || (index === 1 && events[0].type === 'locationEnter') ? (
        <PowerSettingsNew style={commonStyles} />
      ) : (
        <FiberManualRecord style={smallIconStyles} />
      );
    case 'end':
      return index === events.length - 1 ? (
        <PowerSettingsNew style={commonStyles} />
      ) : (
        <FiberManualRecord style={smallIconStyles} />
      );
    case 'escalation':
      // Check if this is a report first
      if (event.isReport) {
        return <Description style={commonStyles} />;
      }
      return <Warning style={commonStyles} />;
    default:
      return <FiberManualRecord style={smallIconStyles} />;
  }
};

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const fetchAlertWithRetry = async (alertId, retries = 0) => {
  try {
    return await fetchAlertFromApi(alertId);
  } catch (err) {
    if (retries < MAX_RETRIES) {
      console.log(`Retrying fetch for alert ${alertId}, attempt ${retries + 1}`);
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      return fetchAlertWithRetry(alertId, retries + 1);
    }
    console.error(`Failed to fetch alert data after ${MAX_RETRIES} attempts:`, err);
    return null;
  }
};

const groupPhotoEvents = (events) => {
  const groupedEvents = [];
  let currentPhotoGroup = null;

  events.forEach((event) => {
    if (event.type === 'photo') {
      const eventMinute = new Date(event.timestamp).setSeconds(0, 0);

      if (currentPhotoGroup && currentPhotoGroup.minute === eventMinute) {
        // Add to existing group
        currentPhotoGroup.count++;
        currentPhotoGroup.events.push(event);
      } else {
        // If there was a previous group, add it to the result
        if (currentPhotoGroup) {
          groupedEvents.push({
            type: 'photo',
            timestamp: currentPhotoGroup.events[0].timestamp,
            events: currentPhotoGroup.events,
            count: currentPhotoGroup.count,
            fileName: currentPhotoGroup.events[0].fileName, // Keep the first photo's filename
            alertId: currentPhotoGroup.events[0].alertId, // Preserve any alert ID if exists
          });
        }
        // Start new group
        currentPhotoGroup = {
          minute: eventMinute,
          events: [event],
          count: 1,
        };
      }
    } else {
      // Add any existing photo group before adding non-photo event
      if (currentPhotoGroup) {
        groupedEvents.push({
          type: 'photo',
          timestamp: currentPhotoGroup.events[0].timestamp,
          events: currentPhotoGroup.events,
          count: currentPhotoGroup.count,
          fileName: currentPhotoGroup.events[0].fileName,
          alertId: currentPhotoGroup.events[0].alertId,
        });
        currentPhotoGroup = null;
      }
      groupedEvents.push(event);
    }
  });

  // Add final photo group if exists
  if (currentPhotoGroup) {
    groupedEvents.push({
      type: 'photo',
      timestamp: currentPhotoGroup.events[0].timestamp,
      events: currentPhotoGroup.events,
      count: currentPhotoGroup.count,
      fileName: currentPhotoGroup.events[0].fileName,
      alertId: currentPhotoGroup.events[0].alertId,
    });
  }

  return groupedEvents;
};

export default function CustomizedTimeline({
  events,
  onEventSelect,
  deviceId,
  batteryLevels,
  eventTypes,
  isShiftInProgress,
  geofences,
}) {
  // Group events once when the component receives new events
  const groupedEvents = React.useMemo(() => groupPhotoEvents(events), [events]);

  const [selectedItem, setSelectedItem] = useState(0);
  const [clickedItem, setClickedItem] = useState(null);
  const [escalationDescriptions, setEscalationDescriptions] = useState({});
  const [escalationCategory, setEscalationCategory] = useState({});
  const [interactionFlagMap, setInteractionFlagMap] = useState({});

  useEffect(() => {
    if (groupedEvents.length > 0) {
      onEventSelect(groupedEvents[0]);
    }
    const fetchDescriptionsAndCategory = async () => {
      const descriptions = await Promise.all(
        groupedEvents
          .filter((event) => event.type === 'escalation')
          .map(async (event) => {
            const data = await fetchAlertWithRetry(event.alertId);
            if (data) {
              let category = data.CategorizationResult[data.DeployedModelUsed] || 'Escalation';
              // If the category is a comma-separated list, take the first one. This is to ensure backwards
              // compatibility with old data where we had multiple categories in the string
              if (category.includes(',')) {
                category = category.split(',')[0].trim();
              }
              let label = data.Label;
              let description = data.Description;
              if (data.InteractionFlag === 'on') {
                label = data.CategorizationResult['gpt4o-interaction-i'];
                category = label;
                console.log('label', label);
              }
              // If the label is not "Escalation", extract the actual keyword
              else if (label !== 'Escalation' && data.Prompt) {
                const keywordMatch = data.Prompt.match(/keyword\s+(.+?):/i);
                if (keywordMatch) {
                  const keyword = keywordMatch[1].trim();
                  description = `"${keyword.length > 20 ? keyword.substring(0, 17) + '...' : keyword}"`;
                  label = 'Trigger Phrase';
                } else {
                  label = 'Trigger Phrase';
                  description = '';
                }
                category = label; // Use the extracted keyword or "Trigger Phrase" as the category
              }

              return {
                description: description,
                id: event.alertId,
                label: label,
                category: category,
                interactionFlag: data.InteractionFlag,
                isReport: data.IsReport || false,
              };
            }
            return null;
          })
      );
      const interactionFlagMap = descriptions.reduce((acc, description) => {
        if (description) {
          acc[description.id] = description.interactionFlag;
        }
        return acc;
      }, {});
      const descriptionsMap = descriptions.reduce((acc, description) => {
        if (description) {
          acc[description.id] = description.description;
        }
        return acc;
      }, {});
      const categoryMap = descriptions.reduce((acc, description) => {
        if (description) {
          acc[description.id] = description.category;
        }
        return acc;
      }, {});

      // Update the events with isReport flag
      groupedEvents.forEach((event) => {
        if (event.type === 'escalation' && event.alertId) {
          const description = descriptions.find((d) => d && d.id === event.alertId);
          if (description) {
            event.isReport = description.isReport;
          }
        }
      });

      setEscalationDescriptions(descriptionsMap);
      setEscalationCategory(categoryMap);
      setInteractionFlagMap(interactionFlagMap);
    };

    fetchDescriptionsAndCategory();
  }, [groupedEvents, deviceId]);

  const handleItemClick = (index) => {
    setSelectedItem(index);
    setClickedItem(index);
    const event = groupedEvents[index];

    if (event.type === 'photo' && event.events) {
      onEventSelect({
        index: index,
        event: event,
        photoGroup: event.events,
      });
    } else {
      onEventSelect({ index: index, event: event });
    }

    trackEventClick(event.shiftId, event.type, event.timestamp);
    setTimeout(() => setClickedItem(null), 300);
  };

  const getEventTitle = (event, index, events, geofences) => {
    if (event.type === 'photo') {
      return event.count > 1 ? `Images (${event.count})` : 'Image';
    }
    if (event.type === 'escalation') {
      const alertData = escalationCategory[event.alertId];
      const interactionFlag = interactionFlagMap[event.alertId];

      // First check if it's a report
      if (event.isReport) {
        return 'Report';
      }

      if (interactionFlag === 'on') {
        return alertData;
      } else if (shouldDisplayCategory(alertData)) {
        return getCategoryDisplay(alertData);
      } else if (alertData === 'Trigger Phrase') {
        const description = escalationDescriptions[event.alertId];
        if (description) {
          const keywordMatch = description.match(/keyword\s+(.+?):/i);
          if (keywordMatch) {
            const keyword = keywordMatch[1].trim();
            return `"${keyword.length > 20 ? keyword.substring(0, 17) + '...' : keyword}"`;
          }
        }
        return 'Trigger Phrase';
      }
      return 'Escalation';
    } else if (event.type === 'locationEnter') {
      // If event has a nickname directly, use it first
      if (event.nickname) {
        return event.nickname;
      }

      // Try to find geofence in the provided geofences array
      if (geofences && Array.isArray(geofences)) {
        const geofence = geofences.find((g) => g.id === event.geofenceId);
        if (geofence && geofence.nickname) {
          return geofence.nickname;
        }
      }

      // Fall back to truncated geofenceId or boundary information
      return truncateGeofenceId(event.geofenceId, geofences, event);
    } else if (event.type === 'start') {
      return index === 0 || (index === 1 && events[0].type === 'locationEnter') ? 'Shift Started' : 'Activated';
    } else if (event.type === 'end') {
      return index === events.length - 1 ? 'Shift Ended' : 'Deactivated';
    } else {
      return eventTypes[event.type]?.title || eventTypes.default.title;
    }
  };

  const getEventColor = (event, index, events) => {
    switch (event.type) {
      case 'start':
        // This code determines the color for 'start' events in the timeline
        // If it's the first event (index 0) or the second event (index 1) after a location entry,
        // it returns green (#66bb6a) to highlight the shift start
        // Otherwise, it returns gray (#888) for subsequent activation events
        return '#66bb6a';
      case 'end':
        return index === events.length - 1 ? '#f44336' : '#888'; // red or gray
      case 'recordingVideo':
        return '#29b6f6'; // blue
      case 'locationEnter':
        return '#ffd700'; // yellow
      case 'escalation':
        return '#ffa726'; // orange
      case 'start':
        return '#66bb6a'; // green
      case 'offline':
        return '#888'; // gray
      default:
        return '#29b6f6'; // blue
    }
  };

  const getDeactivationDuration = (currentIndex, events) => {
    const currentEvent = events[currentIndex];
    const nextEvent = events[currentIndex + 1];

    if (currentEvent.type === 'end' && nextEvent) {
      const duration = new Date(nextEvent.timestamp) - new Date(currentEvent.timestamp);
      return formatDuration(duration);
    }
    return null;
  };

  const getLocationEnterDescription = (event, index, events) => {
    const startTime = new Date(event.timestamp);
    let endTime;
    if (event.exitTime) {
      const exitTime = new Date(event.exitTime);
      const lastEventTime = new Date(events[events.length - 1].timestamp);
      endTime = exitTime > lastEventTime ? lastEventTime : exitTime;
    } else {
      endTime = isShiftInProgress ? new Date() : new Date(events[events.length - 1].timestamp);
    }
    return formatShortDuration(endTime - startTime);
  };

  const formatTime = (event) => {
    const dateString = event.timestamp;
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? 'Invalid Date'
      : date.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' });
  };

  const getBatteryIcon = (event, index, events) => {
    let level;
    if (index === 0 && event.type === 'locationEnter' && events[1] && events[1].type === 'start') {
      level = batteryLevels[events[1].timestamp];
    } else {
      level = batteryLevels[event.timestamp];
    }

    if (level === null || level === undefined) return null;

    const percentage = parseInt(level);
    if (isNaN(percentage)) return null;

    return (
      <span className="battery-info">
        <BatteryIcon percentage={percentage} size="0.9em" />
        <span className="battery-percentage">{percentage}%</span>
      </span>
    );
  };

  console.log('Current battery levels state:', batteryLevels); // Debug log

  const isSmallDot = (event, index, events) => {
    return (
      (event.type === 'start' && index !== 0 && !(index === 1 && events[0].type === 'locationEnter')) ||
      (event.type === 'end' && index !== events.length - 1) ||
      event.type === 'online' ||
      event.type === 'offline'
    );
  };

  return (
    <Timeline
      position="right"
      className="timeline-container"
      sx={{
        '& .MuiTimelineItem-root': { minHeight: 'auto' },
        '& .MuiTimelineOppositeContent-root': {
          flex: 0.3,
          paddingLeft: '5px',
          color: 'var(--text-secondary)',
        },
        '& .MuiTimelineContent-root': {
          flex: 0.7,
          paddingRight: '5px',
          marginTop: '-4px',
        },
        '& .MuiTimelineConnector-root': {
          minHeight: '40px',
          width: '2px',
        },
        margin: '0',
      }}
    >
      {groupedEvents.map((event, index) => (
        <TimelineItem
          key={index}
          onClick={() => handleItemClick(index)}
          sx={{
            cursor: 'pointer',
            opacity: selectedItem === index ? 1 : 0.7,
            transition: 'all 0.2s ease',
            '&:hover': {
              opacity: 1,
              '& .MuiTimelineDot-root': {
                transform: 'scale(1.1)',
              },
            },
            minWidth: '10px',
          }}
          className={`${selectedItem === index ? 'selected' : ''} ${clickedItem === index ? 'timeline-item-clicked' : ''}`}
        >
          <TimelineOppositeContent>{formatTime(event)}</TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot
              className={isSmallDot(event, index, groupedEvents) ? 'small-dot' : ''}
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                padding: 0,
                margin: '6px 0',
                transition: 'transform 0.2s ease',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {getEventIcon(event, index, groupedEvents)}
            </TimelineDot>
            {index < groupedEvents.length - 1 && event.type !== 'end' && (
              <TimelineConnector
                sx={{
                  backgroundColor: getEventColor(event, index, groupedEvents),
                  opacity: 0.4,
                }}
              />
            )}
            {event.type === 'end' && (
              <>
                <br />
                <br />
              </>
            )}
          </TimelineSeparator>
          <TimelineContent>
            <Typography
              variant="h6"
              component="span"
              className={`timeline-item-title ${
                (event.type === 'start' &&
                  index !== 0 &&
                  !(index === 1 && groupedEvents[0].type === 'locationEnter')) ||
                (event.type === 'end' && index !== groupedEvents.length - 1)
                  ? event.type === 'start'
                    ? 'activated'
                    : 'deactivated'
                  : ''
              }`}
            >
              {getEventTitle(event, index, groupedEvents, geofences)}
              {((index === 0 && event.type === 'start') ||
                (index === 1 && event.type === 'start' && groupedEvents[0].type === 'locationEnter') ||
                (index === groupedEvents.length - 1 && event.type === 'end')) && (
                <span className="battery-icon-wrapper">{getBatteryIcon(event, index, groupedEvents)}</span>
              )}
            </Typography>
            {event.type === 'escalation' && (
              <span className="timeline-item-description">{escalationDescriptions[event.alertId]}</span>
            )}
            {event.type === 'recordingVideo' && (
              <span className="timeline-item-description">{formatShortDuration(parseInt(event.duration))}</span>
            )}
            {event.type === 'end' && index !== groupedEvents.length - 1 && (
              <div className="timeline-item-duration">{getDeactivationDuration(index, groupedEvents)}</div>
            )}
            {event.type === 'locationEnter' && (
              <span className="timeline-item-description">
                {getLocationEnterDescription(event, index, groupedEvents)}
              </span>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
