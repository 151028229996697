import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBatteryFull,
  faBatteryThreeQuarters,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryEmpty,
} from '@fortawesome/free-solid-svg-icons';

const BatteryIcon = ({ percentage, size = '0.9em' }) => {
  // Add size prop with default value
  const getBatteryIcon = (level) => {
    if (level > 87.5) return faBatteryFull;
    if (level > 62.5) return faBatteryThreeQuarters;
    if (level > 37.5) return faBatteryHalf;
    if (level > 12.5) return faBatteryQuarter;
    return faBatteryEmpty;
  };

  const icon = getBatteryIcon(percentage);
  const color = percentage <= 20 ? '#ff4444' : 'var(--text-primary)';

  return <FontAwesomeIcon icon={icon} style={{ color, fontSize: size }} />;
};

export default BatteryIcon;
