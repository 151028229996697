import React from 'react';
import '../styles/NoAccessPage.css';

/**
 * Component that displays a message for users with no access to any features
 */
function NoAccessPage() {
  return (
    <div className="no-access-container">
      <div className="no-access-card">
        <div className="no-access-icon-container">
          <svg
            className="no-access-icon"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" />
            <path d="M11 15H13V17H11V15Z" />
            <path d="M11 7H13V13H11V7Z" />
          </svg>
        </div>
        <h2 className="no-access-title">No Access Available</h2>
        <p className="no-access-message">You currently don't have access to any platform features.</p>
        <p className="no-access-note">
          Note: Platform permissions for limited access accounts can be configured in Settings by the administrator.
        </p>
      </div>
    </div>
  );
}

export default NoAccessPage;
