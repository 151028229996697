import RealTimeMap from './RealTimeMap';
import NewDashboard from './NewDashboard/NewDashboard';
import VideoManagement from './VideoManagement';
import Loading from './Loading';
import Reports from './Reports';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useEffect, useState } from 'react';
import { AlertsProvider } from '../contexts/AlertsContext';
import { DeviceFilterProvider } from '../contexts/DeviceFilterContext';
import { FeatureFlagProvider } from '../contexts/FeatureFlagContext';
import DeviceManagement from './DeviceManagement';
import Coaching from './Coaching';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from './ErrorBoundary';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchUserRoles } from '../api/beaverApi';
import {
  fetchUserMapping,
  getUserRoleFromToken,
  fetchConfig,
  fetchRetentionPeriod,
  fetchOrganizationAccessControls,
} from '../api/beaverApi';
import Announcements from './AnnouncementPopup';
import mixpanel from 'mixpanel-browser';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import { trackPageView } from '../utils/analytics';
import Settings from './Settings/Settings';
import PlatformHelp from './PlatformHelp';
import NoAccessPage from './NoAccessPage';
const REQUIRED_AUTH_VERSION = '1.0';

function GuardRedirect({ organizationAccessControls }) {
  const accessControls = organizationAccessControls?.accessControls?.limitedAccess;

  // If no access controls are defined, check if we need to show no access page
  if (!accessControls) {
    // Default to no access if controls are undefined
    return <NoAccessPage />;
  }

  // Check if user has access to any features
  const hasAnyAccess =
    accessControls.liveView || accessControls.dashboard || accessControls.reports || accessControls.assetManagement;

  // If no access to any features, show the no access page
  if (!hasAnyAccess) {
    return <NoAccessPage />;
  }

  // Redirect to first available feature in sidebar order
  if (accessControls.liveView) return <Navigate to="/" replace />;
  if (accessControls.dashboard) return <Navigate to="/dashboard" replace />;
  if (accessControls.reports) return <Navigate to="/reports" replace />;
  if (accessControls.assetManagement) return <Navigate to="/videos" replace />;

  // If we somehow get here despite checking hasAnyAccess,
  // show the no access page as a fallback
  return <NoAccessPage />;
}

function App({ isAuthenticated, user, logout }) {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const [config, setConfig] = useState(null);
  const [userSub, setUserSub] = useState(user.sub);
  const [featureMode, setFeatureMode] = useState('prod');
  const [error, setError] = useState(null);
  const [retentionPeriodDays, setRetentionPeriodDays] = useState(30);
  const [showOutageAlert, setShowOutageAlert] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [realTimeMapRefresh, setRealTimeMapRefresh] = useState(0);
  const [parentOrgUserId, setParentOrgUserId] = useState(null);
  const [organizationAccessControls, setOrganizationAccessControls] = useState(null);
  const refreshRealTimeMap = () => {
    setRealTimeMapRefresh((prev) => prev + 1);
  };

  // Check if guard has access to a specific feature
  const hasGuardAccess = (feature) => {
    if (userRole !== 'guard') return true;
    return organizationAccessControls?.accessControls?.limitedAccess?.[feature] ?? true;
  };

  // Check if guard has any access at all
  const guardHasAnyAccess = () => {
    if (userRole !== 'guard') return true;
    const access = organizationAccessControls?.accessControls?.limitedAccess;
    return access?.liveView || access?.dashboard || access?.reports || access?.assetManagement;
  };

  useEffect(() => {
    if (window.location.pathname === '/') {
      // refreshRealTimeMap();
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const getAndStoreAccessToken = async () => {
      if (!isAuthenticated) return;

      try {
        localStorage.setItem('token_fetch_in_progress', Date.now().toString());

        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: 'openid profile email',
          },
        });

        if (accessToken) {
          localStorage.setItem('access_token', accessToken);
          localStorage.setItem('auth_version', REQUIRED_AUTH_VERSION);
        }
        localStorage.removeItem('token_fetch_in_progress');
      } catch (error) {
        console.error('Token fetch failed:', error);
        localStorage.removeItem('token_fetch_in_progress');

        if (error.error === 'invalid_grant' || error.response?.status === 400) {
          console.error('Invalid grant or 400 error - session may be expired');
        }
        localStorage.clear();
        logout({ returnTo: window.location.origin });
      }
    };

    if (!isLoading && isAuthenticated) {
      getAndStoreAccessToken();
    }
  }, [isAuthenticated, getAccessTokenSilently, isLoading, logout]);

  useEffect(() => {
    const getUserRole = () => {
      if (isAuthenticated && user) {
        try {
          const roleName = getUserRoleFromToken(user);
          setUserRole(roleName);
        } catch (error) {
          console.error('Error getting user role:', error);
        }
      }
    };

    getUserRole();
  }, [isAuthenticated, user]);

  useEffect(() => {
    let isMounted = true;

    async function fetchUserMappingAndConfig() {
      try {
        if (!isAuthenticated || isLoading) return;

        const mapping = await fetchUserMapping(getAccessTokenSilently);
        if (!isMounted) return;

        const { mapped } = mapping;
        setUserSub(mapped.userId);
        setFeatureMode(mapped.mode);
        if (mapped.parentOrgUserId) setParentOrgUserId(mapped.parentOrgUserId);

        const [configFromDB, retentionPeriodDays, rawOrganizationAccessControls] = await Promise.all([
          fetchConfig(mapped.userId),
          fetchRetentionPeriod(mapped.userId),
          fetchOrganizationAccessControls(),
        ]);

        // Process the access controls with defaults
        const processedControls = {
          ...rawOrganizationAccessControls,
          accessControls: {
            ...rawOrganizationAccessControls?.accessControls,
            limitedAccess: {
              liveView: rawOrganizationAccessControls?.accessControls?.limitedAccess?.liveView ?? true,
              dashboard: rawOrganizationAccessControls?.accessControls?.limitedAccess?.dashboard ?? true,
              reports: rawOrganizationAccessControls?.accessControls?.limitedAccess?.reports ?? true,
              assetManagement: rawOrganizationAccessControls?.accessControls?.limitedAccess?.assetManagement ?? true,
            },
          },
        };

        setConfig(configFromDB);
        setRetentionPeriodDays(retentionPeriodDays);
        setOrganizationAccessControls(processedControls);
      } catch (error) {
        if (!isMounted) return;
        console.error('Failed to fetch user mapping or config:', error);
        setError(error.message || 'Failed to load user configuration. Please try again later.');
      }
    }

    if (user?.sub && isAuthenticated && !isLoading) {
      fetchUserMappingAndConfig();
    }

    return () => {
      isMounted = false;
    };
  }, [user?.sub, isAuthenticated, isLoading, getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && user) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
        debug: true,
        track_pageview: false,
        persistence: 'localStorage',
        ignore_dnt: true,
        identify_prefix: '',
        disable_persistence: false,
        disable_cookie: true,
        secure_cookie: true,
      });

      mixpanel.reset();
      mixpanel.identify(user.sub);
      mixpanel.people.set({
        $name: user.name,
        $email: user.email,
        feature_mode: featureMode,
        role: userRole,
      });

      trackPageView(true);
    }
  }, [isAuthenticated, user]);

  if (error) {
    return (
      <div className="error-container">
        <p className="error-message">{error}</p>
      </div>
    );
  }

  if (!config) {
    return <Loading />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <div>Please log in</div>;
  }

  return (
    <ErrorBoundary>
      <AlertsProvider user={user}>
        <FeatureFlagProvider>
          <Router>
            <div style={{ display: 'flex', overflow: 'clip' }}>
              <DeviceFilterProvider userId={userSub} userRole={userRole}>
                <Sidebar
                  userName={user.name}
                  userEmail={user.email}
                  featureMode={featureMode}
                  userRole={userRole}
                  organizationAccessControls={organizationAccessControls}
                  isOverwatch={featureMode === 'dev' && user.name.startsWith('overwatch')}
                />
                <div className="main-content-area">
                  <Announcements
                    userId={userSub}
                    isOverwatch={featureMode === 'dev' && user.name.startsWith('overwatch')}
                  />
                  <Routes>
                    <Route
                      path="/"
                      element={
                        userRole === 'guard' && !guardHasAnyAccess() ? (
                          <NoAccessPage />
                        ) : userRole === 'guard' && !hasGuardAccess('liveView') ? (
                          <GuardRedirect organizationAccessControls={organizationAccessControls} />
                        ) : (
                          <RealTimeMap
                            refreshTrigger={realTimeMapRefresh}
                            isAuthenticated={isAuthenticated}
                            logout={logout}
                            userId={userSub}
                            config={config}
                            setConfig={setConfig}
                            userRole={userRole}
                            parentOrgUserId={parentOrgUserId}
                            retentionPeriodDays={retentionPeriodDays}
                          />
                        )
                      }
                    />
                    <Route
                      path="videos"
                      element={
                        userRole === 'guard' && !guardHasAnyAccess() ? (
                          <NoAccessPage />
                        ) : userRole === 'guard' && !hasGuardAccess('assetManagement') ? (
                          <GuardRedirect organizationAccessControls={organizationAccessControls} />
                        ) : (
                          <VideoManagement
                            isAuthenticated={isAuthenticated}
                            logout={logout}
                            userId={userSub}
                            parentOrgUserId={parentOrgUserId}
                            retentionPeriodDays={retentionPeriodDays}
                            userName={user.name}
                            userRole={userRole}
                          />
                        )
                      }
                    />
                    <Route
                      path="dashboard"
                      element={
                        userRole === 'guard' && !guardHasAnyAccess() ? (
                          <NoAccessPage />
                        ) : userRole === 'guard' && !hasGuardAccess('dashboard') ? (
                          <GuardRedirect organizationAccessControls={organizationAccessControls} />
                        ) : (
                          <NewDashboard
                            userId={userSub}
                            userRole={userRole}
                            retentionPeriodDays={retentionPeriodDays}
                          />
                        )
                      }
                    />
                    <Route
                      path="reports"
                      element={
                        userRole === 'guard' && !guardHasAnyAccess() ? (
                          <NoAccessPage />
                        ) : userRole === 'guard' && !hasGuardAccess('reports') ? (
                          <GuardRedirect organizationAccessControls={organizationAccessControls} />
                        ) : (
                          <Reports
                            userId={userSub}
                            userRole={userRole}
                            userEmail={user.email}
                            parentOrgUserId={parentOrgUserId}
                          />
                        )
                      }
                    />
                    <Route
                      path="help"
                      element={userRole === 'guard' && !guardHasAnyAccess() ? <NoAccessPage /> : <PlatformHelp />}
                    />
                    <Route
                      path="coaching"
                      element={featureMode === 'dev' ? <Coaching /> : <Navigate to="/" replace />}
                    />
                    <Route
                      path="settings"
                      element={
                        userRole === 'guard' ? (
                          <Navigate to="/" replace />
                        ) : (
                          <Settings
                            userId={userSub}
                            featureMode={featureMode}
                            userRole={userRole}
                            parentOrgUserId={parentOrgUserId}
                            organizationAccessControls={organizationAccessControls}
                          />
                        )
                      }
                    />
                    {featureMode === 'dev' && (
                      <Route path="device-management" element={<DeviceManagement userId={userSub} />} />
                    )}

                    {/* Catch-all route for guards with no access */}
                    {userRole === 'guard' && !guardHasAnyAccess() && <Route path="*" element={<NoAccessPage />} />}
                  </Routes>
                </div>
              </DeviceFilterProvider>
            </div>
            <ToastContainer />
          </Router>
        </FeatureFlagProvider>
      </AlertsProvider>
    </ErrorBoundary>
  );
}

export default App;
