// SiteInfo.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClock, faCalendar, faMapMarkerAlt, faMapPin, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { formatDuration } from '../../../../../../../utils/timeUtils';

// Helper function to get the geofence name
const getGeofenceName = (event, geofences) => {
  if (geofences && Array.isArray(geofences)) {
    const geofence = geofences.find((g) => g.id === event.geofenceId);
    if (geofence) {
      // Return nickname for polygon geofences or any geofence with a nickname
      if (geofence.nickname) {
        return geofence.nickname;
      }
    }
  }

  if (event.nickname) {
    return event.nickname;
  }

  // Fallback to truncating the geofence ID if no nickname is found
  if (event.geofenceId && event.geofenceId.length > 30) {
    const parts = event.geofenceId.split(',');
    if (parts[0].match(/\d/)) {
      return `${parts[0]}`.trim();
    }
    return parts[0].trim();
  }

  return event.geofenceId || 'Unknown Site';
};

const SiteInfo = ({ event, assignedTo, shiftEndTime, isShiftInProgress, geofences = [] }) => {
  const startTime = new Date(event.timestamp);
  const endTime = event.exitTime ? new Date(event.exitTime) : isShiftInProgress ? new Date() : new Date(shiftEndTime);
  const duration = endTime - startTime;

  const formatTimeRange = (start, end) => {
    return `${start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} \u2013 ${end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  };

  const formatDateRange = (start, end) => {
    const startDate = start.toLocaleDateString([], { month: 'long', day: 'numeric', year: 'numeric' });
    const endDate = end.toLocaleDateString([], { month: 'long', day: 'numeric', year: 'numeric' });

    if (startDate === endDate) {
      return startDate;
    }
    return `${startDate} \u2013 ${endDate}`;
  };

  // Get the geofence name using the helper function
  const geofenceName = getGeofenceName(event, geofences);

  return (
    <div className="location-info-container">
      <div className="location-info-header">
        <h3>Site</h3>
      </div>
      <div className="location-details">
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faUser} />
          </div>
          <div className="location-detail-value">{assignedTo || 'N/A'}</div>
        </div>
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faStopwatch} />
          </div>
          <div className="location-detail-value">{formatDuration(duration)}</div>
        </div>
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faClock} />
          </div>
          <div className="location-detail-value">{formatTimeRange(startTime, endTime)}</div>
        </div>
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faCalendar} />
          </div>
          <div className="location-detail-value">{formatDateRange(startTime, endTime)}</div>
        </div>
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </div>
          <div className="location-detail-value">{geofenceName}</div>
        </div>
      </div>
    </div>
  );
};

export default SiteInfo;
